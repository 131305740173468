import { DataTableCell, DataTableRow } from "rmwc";
import { useCallback, useContext, useState } from "react";
import { AccountPositionViewModel } from "../view-models/AccountPositionViewModel";
import classnames from "classnames";
import sharedStyles from "../SharedStyles.module.scss";
import styles from "./AccountPositionRow.module.scss";
import NumberDisplay from "../../../numeric-values/NumberDisplay";
import { WorkspaceContext } from "../../../../contexts/workspaceContext";
import AccountInfo from "../../../shared/AccountInfo";
import { IasDataModel } from "../../../../dataModels/iasDataModel";
import { usePositionMutation } from "services/positionService";
import AccountPositionMenu from "./AccountPositionMenu";
import EditCurrentTableCell from "./EditChangeTableCell";
import { CommentsAndCurrentValue } from "./CommentsAndCurrentValue";

interface AccountPositionRowProps {
    viewModel: AccountPositionViewModel;
    depth: number;
    ias: IasDataModel;
    "data-testid"?: string;
}

export default function AccountPositionRow(props: AccountPositionRowProps) {
    const context = useContext(WorkspaceContext);

    const depthStyle = sharedStyles[`indent_${props.depth}`];
    const [editCurrentValue, setEditCurrentValue] = useState(false);
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const mutator = usePositionMutation(props.viewModel.position.id, props.viewModel.account.account.id, props.ias.id);

    const revertCurrentValue = useCallback(() => {
        mutator.mutate({ lastModifiedDate: props.ias.dateModified, overwrittenCurrentValue: props.viewModel.originalCurrentValue });
    }, [mutator, props.ias.dateModified, props.viewModel.originalCurrentValue]);

    const isWide = !context.sidebarOpen;

    return <DataTableRow
        data-testid={props["data-testid"]}
        className={
            classnames(styles.row, {
                "fiveColumns": editCurrentValue && !isWide,
            })
        }
    >
        <DataTableCell className={classnames(depthStyle, { [sharedStyles.wide]: isWide })}>
            <div className={styles.accountNumber}>{props.viewModel.account.account.accountNumber}</div>
            <div className={styles.accountInfo}><AccountInfo account={props.viewModel.account.account} /></div>
        </DataTableCell>
        <CommentsAndCurrentValue
            editCurrentValue={editCurrentValue}
            setEditCurrentValue={setEditCurrentValue}
            viewModel={props.viewModel}
            ias={props.ias}
            setCommentDialogOpen={setCommentDialogOpen}
        />
        <DataTableCell className={sharedStyles.value_column}>
            {context.readonlyMode && <NumberDisplay value={props.viewModel.changeValue()} />}
            {
                !context.readonlyMode && <EditCurrentTableCell
                    ias={props.ias}
                    viewModel={props.viewModel}
                />
            }
        </DataTableCell>
        <DataTableCell
            className={sharedStyles.value_column}
            data-testid="proposed"
        >
            <NumberDisplay value={props.viewModel.proposedValue()} />
        </DataTableCell>
        <DataTableCell className={styles.kebabCell}>
            <AccountPositionMenu
                viewModel={props.viewModel}
                ias={props.ias}
                commentDialogOpen={commentDialogOpen}
                setCommentDialogOpen={setCommentDialogOpen}
                setEditCurrentValue={setEditCurrentValue}
                revertCurrentValue={revertCurrentValue}
                menuButtonClassName={styles.visible}
            />
        </DataTableCell>
    </DataTableRow>;
}