import { useContext, useState } from "react";
import classnames from "classnames";
import { WorkspaceContext } from "contexts/workspaceContext";
import { IconName } from "iconNames";
import { MenuSurfaceAnchor, Menu, MenuItem, IconButton } from "rmwc";
import styles from "./SecurityMenu.module.scss";
import { SecurityViewModel } from "../view-models/SecurityViewModel";

export interface SecurityMenuProps {
    security: SecurityViewModel;
    openEditSecurityModal: VoidFunction;
    openCreateCustomGroupModal: VoidFunction;
    visibleClassName?: string;
}

export function SecurityMenu(props: SecurityMenuProps) {
    const { security, openEditSecurityModal, openCreateCustomGroupModal, visibleClassName } = props;

    const context = useContext(WorkspaceContext);

    const [menuOpen, setMenuOpen] = useState(false);
    const [kebabVisible, setKebabVisible] = useState(false);

    const handleMenuClose = () => {
        setMenuOpen(false);
        setKebabVisible(false);
    };

    const handleKebabClick = () => {
        setMenuOpen((menuOpen) => !menuOpen);
    };

    return <MenuSurfaceAnchor className={styles.securityMenu}>
        <Menu
            anchorCorner="bottomLeft"
            open={menuOpen}
            onOpen={() => setKebabVisible(true)}
            onClose={handleMenuClose}
            className={
                classnames(styles.menu, {
                    [styles.sidebarOpen]: context.sidebarOpen,
                    [styles.widestOptionIsEditName]: security.isInCustomGroup,
                })
            }
        >
            <MenuItem
                onClick={openEditSecurityModal}
                className={styles.menuItem}
            >
                {security.isInCustomGroup ? "Edit Name" : "Edit Name/Asset Class"}
            </MenuItem>
            {
                !security.isBlended && !security.isInCustomGroup && <MenuItem
                    onClick={openCreateCustomGroupModal}
                    className={styles.menuItem}
                >
                    Create Group...
                </MenuItem>
            }
        </Menu>
        {
            !context.readonlyMode && <IconButton
                icon={IconName.MoreVert}
                onClick={handleKebabClick}
                className={classnames({ [visibleClassName ?? ""]: kebabVisible })}
            />
        }
    </MenuSurfaceAnchor>;
}