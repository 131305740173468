export enum EventType {
    AltTargetGuidanceHover = "Alt Target Guidance Hover",
    BarChartHover = "Bar Chart Hover",
    BlendedFundUsed = "Blended Fund Used",
    CommentIconHover = "Comment Icon Hover",
    ClientKeyNav = "Client Key Nav",
    CopyIas = "Copy IAS",
    CustomGroupContentsChanged = "Custom Group Contents Changed",
    CustomGroupCreated = "Custom Group Created",
    CustomGroupSegmentChanged = "Custom Group Segment Changed",
    CustomGroupUngrouped = "Custom Group Ungrouped",
    DataUpdated = "Data Updated",
    DeleteAccount = "Delete Account",
    DeleteIAS = "Delete IAS",
    DownloadTrades = "Download Trades",
    EditCurrentValue = "Edit Current Value",
    ExpandCollapseAll = "Expand All / Collapse All",
    FastPassSelected = "Fast Pass Selected",
    IasCreated = "IAS Created",
    IasStateChange = "IAS State Changed",
    LogoNav = "Logo Nav",
    NoteIconHover = "Note Icon Hover",
    PdfExportWorkspace = "PDF Export (From Workspace)",
    PdfExportClientProfile = "PDF Export (From Client Profile)",
    RecommendedFundIconHover = "Recommended Fund Icon Hover",
    RecommendedFundSelected = "Recommended Fund Selected",
    RecommendedFundsTableShown = "Recommended Funds Table Shown",
    SecuritySegmentChanged = "Security Segment Changed",
    SellAll = "Sell All",
    SendTrades = "Send Trades",
    SidebarToggled = "Sidebar Toggled",
    SideNavClientLink = "Side Nav Client Link",
    SideNavOpened = "Side Nav Opened",
    StandardIasSelected = "Standard IAS Selected",
    StatusFiltersChanged = "Status Filters Changed",
    TaxableStatusIconHover = "Taxable Status Icon Hover",
    VarianceIconHover = "Variance Icon Hover",
    VisionSelectionsChanged = "Vision Selections Changed",
}