import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomSegmentName } from "dataModels/customSegmentName";
import { filterMap } from "./ArrayUtils";
import { CustomSegmentGroupName } from "dataModels/customSegmentGroupName";

export function getCustomSegmentNames(assetClasses: AssetClassDataModel[]): CustomSegmentName[] {
    return filterMap(assetClasses.flatMap(assetClass => assetClass.segments),
        segment => segment.name === segment.originalName ? null : {segmentId: segment.id, name: segment.name});
}
    
export function getCustomSegmentGroupNames(assetClasses: AssetClassDataModel[]): CustomSegmentGroupName[] {
    return filterMap(assetClasses.flatMap(assetClass => assetClass.segmentGroups),
        segmentGroup => segmentGroup.name === segmentGroup.originalName ? null : {segmentGroupId: segmentGroup.id, name: segmentGroup.name});       
}