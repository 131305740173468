import { useCallback, useMemo, useState } from "react";
import { FormattedOption, Tooltip } from "rmwc";
import styles from "./AccountSelect.module.scss";
import AccountInfo from "components/shared/AccountInfo";
import { ValeoSelect } from "components/shared/ValeoSelect";
import { AccountDataModel } from "dataModels/accountDataModel";
import classnames from "classnames";

export interface AccountSelectProps {
    accounts: AccountDataModel[];
    accountId: number | null;
    setAccountId: Callback<number | null>;
    label: string;
    additionalOptionText: string;
    disabled?: boolean;
}

interface AccountOption extends Omit<FormattedOption, "value"> {
    value: string;
}

export const noAccountSelectedId = -1;
export const placeholderForNewAccountId = -2;

export function applyAccountFilter(accountId: number): boolean {
    return accountId !== noAccountSelectedId;
}

export function AccountSelect(props: AccountSelectProps) {
    const { accounts, accountId, setAccountId } = props;

    const [showAccountTooltip, setShowAccountTooltip] = useState(false);

    const sortAccounts = (a: AccountDataModel, b: AccountDataModel) => {
        if (a.taxable === b.taxable) {
            return a.accountNumber.localeCompare(b.accountNumber);
        }

        if (a.taxable) {
            return 1;
        }

        return -1;
    };

    const accountsToInclude = useMemo(() => {
        const sortedAccounts = accounts.slice().sort(sortAccounts);
        return sortedAccounts.map(account => {
            const matchingAccounts = sortedAccounts.filter(a => a.accountNumber === account.accountNumber);
            const isUniqueName = matchingAccounts.length === 1;
            const nameToDisplay = isUniqueName ? account.accountNumber
                : `${account.accountNumber} (${matchingAccounts.indexOf(account) + 1})`;

            return {
                ...account,
                accountNumber: nameToDisplay,
            };
        });
    }, [accounts]);

    const accountName: string | null = useMemo(() => {
        if (accountId === noAccountSelectedId) {
            return props.additionalOptionText;
        } else if (accountId === placeholderForNewAccountId) {
            return null;
        } else {
            const account = accountsToInclude.find(account => account.id === accountId);
            return account
                ? account.accountNumber
                : null;
        }
    }, [accountId, props.additionalOptionText, accountsToInclude]);

    const accountOptions: AccountOption[] = useMemo(() => [
        ...accountsToInclude.map(account => ({
            label: <div className={styles.account}>
                <div className={styles.accountNumber}>{account.accountNumber}</div>
                {"\n" /* Newline is only visible in selection (not menu items). It pushes account info out of sight so that selection only shows account number. */}
                <div className={styles.accountInfo}><AccountInfo account={account}/></div>
            </div>,
            value: account.accountNumber,
        })),
        {
            label: <div className={classnames(styles.account, styles.newAccount)}>
                <div>{props.additionalOptionText}</div>
            </div>,
            value: props.additionalOptionText,
        },
    ], [accountsToInclude, props.additionalOptionText]);

    const accountIdAt: (index: number) => number | null = useCallback((index: number) => {
        const accountOption = accountOptions.at(index);
        if (accountOption?.value === props.additionalOptionText) {
            return noAccountSelectedId;
        } else {
            const accountName = accountOption?.value;
            const account = accountsToInclude.find(account => account.accountNumber === accountName);
            return account
                ? account.id
                : null;
        }
    }, [accountOptions, props.additionalOptionText, accountsToInclude]);

    const onSelectAccount = useCallback((optionIndex: number) => {
        setAccountId(accountIdAt(optionIndex));
    }, [setAccountId, accountIdAt]);

    const accountSelected = useMemo(() => {
        return accountId !== null && accountId !== noAccountSelectedId;
    }, [accountId]);

    return (<div
        onMouseEnter={() => setShowAccountTooltip(true)}
        onMouseLeave={() => setShowAccountTooltip(false)}
    >
        <ValeoSelect
            label={props.label}
            options={accountOptions}
            value={accountName}
            onSelectIndex={onSelectAccount}
            onMouseEnter={() => setShowAccountTooltip(false)}
            disabled={props.disabled}
            className={styles.accountSelect}
            data-testid="choose-account"
        />
        {
            (accountName !== null) &&
            <Tooltip
                content={accountName}
                open={showAccountTooltip && accountSelected}
                align="bottom"
            >
                <div />
            </Tooltip>
        }
    </div>);
}