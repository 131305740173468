import { useContext, useEffect, useState } from "react";
import { ConfigurationContext } from "../../../contexts/configurationContext";
import { RefreshTimeMs } from "services/blackDiamondRefreshTime";
import { createCancellationToken } from "utils/CancellationToken";
import { isAuthorized, refreshAuthToken, waitForAuthorizationAsync } from "services/blackDiamondService";
import { BDAuthProgress } from "./BDAuthProgress";

export function useBlackDiamondAuthentication(): BDAuthProgress {
    const [progress, setProgress] = useState(isAuthorized() ? BDAuthProgress.Success : BDAuthProgress.InProgress );

    const configurationContext = useContext(ConfigurationContext);

    useEffect(() => {
        const interval = setInterval(() => refreshAuthToken(), RefreshTimeMs);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (progress !== BDAuthProgress.InProgress) {
            return;
        }
        const cancellationToken = createCancellationToken();
        waitForAuthorizationAsync(configurationContext.blackDiamond, cancellationToken)
            .then((authorized) => {
                setProgress(authorized ? BDAuthProgress.Success : BDAuthProgress.Failed);
            })
            .catch(() => {
                setProgress(BDAuthProgress.Failed);
            });
        return () => cancellationToken.cancel();
    }, [configurationContext.blackDiamond, progress]);

    return progress;
}
