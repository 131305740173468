import { EventType } from "constants/eventType";
import { IconName } from "iconNames";
import { useState } from "react";
import { Icon, Tooltip } from "rmwc";
import { EventLoggingTooltipContent } from "../EventLoggingTooltipContent";
import styles from "./AlternativeTargetGuidanceTooltip.module.scss";

function TooltipContents() {
    const values = [
        { valueRange: "$1M - $2M", low: 0, neutral: 0, high: 10, },
        { valueRange: "$2M - $5M", low: 0, neutral: 10, high: 15, },
        { valueRange: "$5M - $10M", low: 0, neutral: 15, high: 25, },
        { valueRange: "$10M - $20M", low: 0, neutral: 20, high: 30, },
        { valueRange: "$20M - $40M", low: 0, neutral: 25, high: 40, },
        { valueRange: "$40M +", low: 0, neutral: 30, high: 50, },
    ];
    return <EventLoggingTooltipContent eventType={EventType.AltTargetGuidanceHover}>
        <div
            className={styles.table}
            data-testid="alt-target-guidance-tooltip-contents"
        >
            <table>
                <thead>
                    <tr className={styles.headerRow}>
                        <th className={styles.rangeColumn}>Portfolio Value</th>
                        <th className={styles.percentageColumn}>Low</th>
                        <th className={styles.percentageColumn}>Neutral</th>
                        <th className={styles.percentageColumn}>High</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        values.map((valueSet, index) => <tr
                            key={index}
                            className={styles.valuesRow}
                        >
                            <td className={styles.rangeColumn}>{valueSet.valueRange}</td>
                            <td className={styles.percentageColumn}>{valueSet.low}%</td>
                            <td className={styles.percentageColumn}>{valueSet.neutral}%</td>
                            <td className={styles.percentageColumn}>{valueSet.high}%</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    </EventLoggingTooltipContent>;
}

interface InfoIconProps {
    setAltTargetGuidanceShown: (value: boolean) => void;   
}

function InfoIcon(props: InfoIconProps) {
    return <Icon
        data-testid="alt-target-guidance-info-icon"
        icon={{icon: IconName.Info, strategy: "ligature"}}
        className={styles.icon}
        onMouseEnter={() => props.setAltTargetGuidanceShown(true)}
        onMouseLeave={() => props.setAltTargetGuidanceShown(false)}
    />;
}

export function AlternativeTargetGuidanceTooltip() {
    const [altTargetGuidanceShown, setAltTargetGuidanceShown] = useState(false);

    return <Tooltip
        content={<TooltipContents />}
        open={altTargetGuidanceShown}
        align="bottom"
        showArrow={true}
    >
        <InfoIcon setAltTargetGuidanceShown={setAltTargetGuidanceShown} />
    </Tooltip>;
}