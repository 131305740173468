import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "rmwc";
import styles from "./BDPortfoliosPage.module.scss";
import { BDPortfoliosTable } from "./BDPortfoliosTable";
import { PopulatedBlackDiamondPortfolioTemplate } from "./PopulatedBlackDiamondPortfolioTemplate";
import { ClientDataModel } from "dataModels/clientDataModel";
import { PopulatedPortfolio, useRelationshipQuery } from "services/blackDiamondService";
import { isConnectionFailure } from "services/ConnectionFailure";
import { IasBaseModalContent } from "../IasBaseModalContent";
import { BackNextFooter } from "components/shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { useBlackDiamondAuthentication } from "components/ias-workspace/workspace-header/BlackDiamondAuthenticationTemplate";
import { BDAuthProgress } from "components/ias-workspace/workspace-header/BDAuthProgress";
import { LinearProgressOverlay, LinearProgressOverlayProps } from "components/shared/LinearProgressOverlay";
import { PopulatedPortfolioSelection, PortfolioIdSelection } from "./PortfolioSelection";
import { mapSelectable } from "utils/Selectable";
import { BlackDiamondRelationship } from "dataModels/blackDiamond/relationship";
import classnames from "classnames";

export interface BDPortfoliosPageProps {
    stepper: JSX.Element;
    onCancel: VoidFunction;
    onBack: VoidFunction;
    onNext: (portfolio: PopulatedPortfolio | null) => void;
    client: ClientDataModel;
    populatedPortfolio: PopulatedPortfolioSelection;
    setPopulatedPortfolio: (portfolio: PopulatedPortfolioSelection) => void;
}

export function BDPortfoliosPage(props: BDPortfoliosPageProps) {
    const authProgress = useBlackDiamondAuthentication();
    const { status: relationshipStatus, data: relationship } = useRelationshipQuery(props.client.key, authProgress === BDAuthProgress.Success);
    const [connectionFailure, setConnectionFailure] = useState(false);

    const reportConnectionFailure = useCallback(() => setConnectionFailure(true), []);

    useEffect(() => {
        if (authProgress === BDAuthProgress.Failed || isConnectionFailure(relationship)) {
            setConnectionFailure(true);
        }
    }, [authProgress, relationship]);

    if (connectionFailure) {
        return <BDPortfoliosPageContentLoadFailed {...props} />;
    }
    if (relationshipStatus === "success" && !isConnectionFailure(relationship)) {
        return <BDPortfoliosPageContentReady
            {...props}
            relationship={relationship}
            reportConnectionFailure={reportConnectionFailure}
        />;
    }
    return <BDPortfoliosPageContentLoading
        {...props}
    />;
}

function BDPortfoliosPageContentLoading(props: BDPortfoliosPageProps) {
    const { onBack, onCancel } = props;
    const overlayProps = useMemo<LinearProgressOverlayProps>(() => ({
        text: "Finish signing into Black Diamond.",
        onCancel: onBack
    }), [onBack]);

    return <Fragment>
        <IasBaseModalContent
            onCancel={onCancel}
            content={
                <div className={styles.portfolios}>
                    {props.stepper}
                </div>
            }
            footer={
                <BackNextFooter
                    backBtn={
                        <ModalActionButton
                            label="Back"
                            isDefaultAction={true}
                            onClick={onBack}
                        />
                    }
                    nextBtn={
                        <ModalActionButton
                            label="Next"
                            isDefaultAction={true}
                            disabled={true}
                        />
                    }
                />
            }
        />
        <LinearProgressOverlay {...overlayProps} />
    </Fragment>;
}

function BDPortfoliosPageContentLoadFailed(props: BDPortfoliosPageProps) {
    const { onBack, onCancel, onNext } = props;

    const onNextClick = useCallback(() => onNext(null), [onNext]);

    return <IasBaseModalContent
        onCancel={onCancel}
        content={
            <div className={styles.portfolios}>
                {props.stepper}
                <div className={classnames(styles.noPortfolios, styles.connectionFailure)}>
                    <div className={styles.redError}>Black Diamond failed to connect.</div>
                    <div>Continue without a portfolio or try again later.</div>
                </div>
            </div>
        }
        footer={
            <BackNextFooter
                backBtn={
                    <ModalActionButton
                        label="Back"
                        isDefaultAction={true}
                        onClick={onBack}
                    />
                }
                nextBtn={
                    <ModalActionButton
                        label="Next"
                        isDefaultAction={true}
                        onClick={onNextClick}
                    />
                }
            />
        }
    />;
}

interface BDPortfoliosPageContentReadyProps extends BDPortfoliosPageProps {
    relationship: BlackDiamondRelationship | null;
    reportConnectionFailure: VoidFunction;
}

function BDPortfoliosPageContentReady(props: BDPortfoliosPageContentReadyProps) {
    const { onCancel, stepper, onBack, client, onNext, populatedPortfolio, setPopulatedPortfolio, relationship } = props;

    const [selectedPortfolioId, setSelectedPortfolioId] = useState<PortfolioIdSelection>(() => mapSelectable(populatedPortfolio, portfolio => portfolio?.id ?? null));

    const [nextRequested, setNextRequested] = useState(false);

    useEffect(() => {
        if (nextRequested) {
            if (populatedPortfolio.selectionMade && selectedPortfolioId.value === (populatedPortfolio.value?.id ?? null)) {
                onNext(populatedPortfolio.value);
            }
        }
    }, [nextRequested, populatedPortfolio, selectedPortfolioId, onNext]);

    useEffect(() => {
        if (!relationship || relationship.portfolios.length === 0) {
            setPopulatedPortfolio({value: null, selectionMade: true});
            setSelectedPortfolioId({value: null, selectionMade: true});
        }
    }, [relationship, setPopulatedPortfolio]);

    const nextDisabled = useMemo(() =>!selectedPortfolioId.selectionMade,
        [selectedPortfolioId.selectionMade]);

    const overlayProps = useMemo<LinearProgressOverlayProps | null>(() => {
        if (nextRequested) {
            return {
                text: "Loading Black Diamond accounts...",
                onCancel: () => setNextRequested(false)
            };
        }
        return null;
    }, [nextRequested]);

    const nextOrShowSpinner = useCallback(() => {
        if (!selectedPortfolioId.selectionMade) {
            return;
        }
        if (selectedPortfolioId.value === null) {
            onNext(null);
        } else if (selectedPortfolioId.value === populatedPortfolio.value?.id) {
            onNext(populatedPortfolio.value);
        } else {
            setNextRequested(true);
        }
    }, [onNext, populatedPortfolio, selectedPortfolioId]);

    const cancelAndClose = useCallback(() => {
        window.open("https://bd3.bdreporting.com/#/", "_blank", "noopener,noreferrer");
        onCancel();
    }, [onCancel]);

    const launchBlackDiamondButton = useMemo(() => <Button
        className={styles.launchBlackDiamond}
        label="Cancel & Launch Black Diamond"
        onClick={cancelAndClose}
    />, [cancelAndClose]);

    const onPortfolioIdSelected = useCallback((selectedId: string | null) => {
        if (selectedId === null) {
            setPopulatedPortfolio({value: null, selectionMade: true});
        }
        setSelectedPortfolioId({value: selectedId, selectionMade: true});
    }, [setPopulatedPortfolio]);

    return <Fragment>
        <IasBaseModalContent
            onCancel={onCancel}
            content={
                <div className={styles.portfolios}>
                    {stepper}
                    {
                        (!relationship || relationship.portfolios.length === 0)
                            ? <div className={styles.noPortfolios}>
                                <div>No portfolios found</div>
                                {launchBlackDiamondButton}
                            </div>
                            : <div className={styles.portfoliosFound}>
                                {
                                    selectedPortfolioId.selectionMade &&
                                    selectedPortfolioId.value !== null &&
                                    selectedPortfolioId.value !== (populatedPortfolio.value?.id ?? null) &&
                                    <PopulatedBlackDiamondPortfolioTemplate
                                        portfolioId={selectedPortfolioId.value}
                                        relationshipId={relationship.id}
                                        reportPortfolio={(portfolio) => setPopulatedPortfolio({value: portfolio, selectionMade: true})}
                                        reportConnectionFailure={props.reportConnectionFailure}
                                    />
                                }
                                <BDPortfoliosTable
                                    clientReportingName={client.reportingName}
                                    relationshipId={relationship.id}
                                    portfolios={relationship.portfolios}
                                    selectedPortfolioId={selectedPortfolioId}
                                    setSelectedPortfolioId={onPortfolioIdSelected}
                                    reportConnectionFailure={props.reportConnectionFailure}
                                />
                                {launchBlackDiamondButton}
                            </div>
                    }
                </div>
            }
            footer={
                <BackNextFooter
                    backBtn={
                        <ModalActionButton
                            label="Back"
                            isDefaultAction={true}
                            onClick={onBack}
                        />
                    }
                    nextBtn={
                        <ModalActionButton
                            label="Next"
                            isDefaultAction={true}
                            onClick={nextOrShowSpinner}
                            disabled={nextDisabled}
                        />
                    }
                />
            }
        />
        {
            overlayProps && <LinearProgressOverlay {...overlayProps} />
        }
    </Fragment>;
}
