import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { BlackDiamondHolding } from "dataModels/blackDiamond/holding";
import { useCallback } from "react";
import { DataTable, DataTableContent, DataTableBody } from "rmwc";
import styles from "./BDHoldingsClassificationTable.module.scss";
import { PopulatedAccount } from "services/blackDiamondService";
import { BDHoldingClassificationRow } from "./BDHoldingClassificationRow";

interface BDHoldingsClassificationTableProps {
    blackDiamondAccounts: PopulatedAccount[];
    holdingsToClassify: BlackDiamondHolding[];
    setHoldingsToClassify: Callback<BlackDiamondHolding[]>;
    assetClasses: AssetClassDataModel[];
}

export function BDHoldingsClassificationTable(props: BDHoldingsClassificationTableProps) {
    const {blackDiamondAccounts, holdingsToClassify, setHoldingsToClassify, assetClasses} = props;
    const onSegmentChange = useCallback((segmentId: number, holding: BlackDiamondHolding) => {
        // replace unclassified holding with classified one
        const classifiedHolding: BlackDiamondHolding = {
            ...holding,
            segmentId,
        };

        const newHoldings = holdingsToClassify.slice();
        newHoldings[holdingsToClassify.findIndex(h => h.name === holding.name)] = classifiedHolding;
        setHoldingsToClassify(newHoldings);
    }, [holdingsToClassify, setHoldingsToClassify]);

    return <DataTable className={styles.table}>
        <DataTableContent>
            <DataTableBody>
                {
                    holdingsToClassify.map(holding =>
                        <BDHoldingClassificationRow
                            holding={holding}
                            assetClasses={assetClasses}
                            blackDiamondAccounts={blackDiamondAccounts}
                            onSegmentChange={onSegmentChange}
                            key={holding.assetId}
                        />
                    )
                }
            </DataTableBody>
        </DataTableContent>
    </DataTable>;
}