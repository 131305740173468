import { useMutation, useQueryClient } from "@tanstack/react-query";
import { indexOf } from "lodash";
import { ClientDataModel } from "../dataModels/clientDataModel";
import { MeetingDataModel } from "../dataModels/meetingDataModel";
import { putWithAuthAndDiscardResponse } from "./apiService";
import { useUnknownSaveErrorMutator } from "./dataErrorService";
import { iasQueryKey } from "./iasService";

const put = async (id: number, date: Date) => {
    await putWithAuthAndDiscardResponse(`/meeting/${id}`, date);
};

export function useMeetingMutation(meetingId: number, clientId: number) {
    const queryClient = useQueryClient();
    const unknownSaveErrorMutator = useUnknownSaveErrorMutator();
    return useMutation((date: Date) => put(meetingId, date), {
        onMutate: async (date) => {
            const cachedClient = queryClient.getQueryData<ClientDataModel>(["client", clientId]);
            const cachedMeeting = cachedClient?.meetings.find(meeting => meeting.id === meetingId);

            if (cachedClient) {
                if (cachedMeeting) {
                    const newMeeting: MeetingDataModel = { ...cachedMeeting, date };
                    const newMeetings = cachedClient.meetings.slice();
                    newMeetings[indexOf(newMeetings, cachedMeeting)] = newMeeting;
                    queryClient.setQueryData(["client", clientId], { ...cachedClient, meetings: newMeetings });

                    newMeeting.ias.forEach(async (ias) => {
                        await queryClient.invalidateQueries(iasQueryKey(ias.id));
                    });
                } else {
                    throw new Error(`Expected QueryProvider ['client', ${clientId}] cache item to include meeting with id ${meetingId} but found none.`);
                }
            } else {
                throw new Error(`Expected QueryProvider ['client', ${clientId}] cache item but found none.`);
            }
        },
        onError: () => {
            unknownSaveErrorMutator(true);
        },
        onSuccess: () => {
            void queryClient.invalidateQueries(["client", clientId]);
        }
    });
}