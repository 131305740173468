import NumberDisplay from "components/numeric-values/NumberDisplay";
import { EventLoggingTooltipContent } from "components/shared/EventLoggingTooltipContent";
import { EventType } from "constants/eventType";
import { Tooltip } from "rmwc";
import { ValueModel } from "../../IasWorkspaceTypes";
import { ModelPortfolioViewModel } from "../view-models/ModelPortfolioViewModel";
import styles from "./RecommendedFund.module.scss";

export interface RecommendedFundProps {
    modelPortfolio: ModelPortfolioViewModel;
    assetClassTarget: number;
    ticker?: string;
    overallValues: ValueModel;
}

function RecommendedFundTooltipContents(props: { calculatedValue: number }) {
    return <EventLoggingTooltipContent eventType={EventType.RecommendedFundIconHover}>
        <div data-testid="recommended-fund-tooltip-content">
            <div>Recommended Fund</div>
            <div className={styles.value}>
                <NumberDisplay value={props.calculatedValue} />
            </div>
        </div>
    </EventLoggingTooltipContent>;
}

export default function RecommendedFund(props: RecommendedFundProps) {
    const tickerTarget = props.modelPortfolio.recommendedFunds().find(recommend => recommend.ticker === props.ticker)?.targetPercentage ?? 0;
    const calculatedTarget = props.assetClassTarget * tickerTarget / 100;
    const calculatedValue = props.overallValues.currentValue() * calculatedTarget / 100;

    return <Tooltip
        className={styles.tooltip}
        content={<RecommendedFundTooltipContents calculatedValue={calculatedValue} />}
        showArrow
        align="bottomLeft"
    >
        <img
            src="/rec-fund.svg"
            alt="recommended fund"
            className={styles.icon}
            data-testid="recommended-fund-icon"
        />
    </Tooltip>;
}
