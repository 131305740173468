import classnames from "classnames";
import PercentageDisplay from "components/numeric-values/PercentageDisplay";
import { inactiveDisplayOfPercentageValue, isPercentageValueValid, PercentageEntry } from "components/numeric-values/PercentageEntry";
import { useNumericTextField } from "components/numeric-values/useNumericTextField";
import { useEffect } from "react";
import { DataTableHeadCell } from "rmwc";
import { toTestId } from "utils/StringUtils";
import { ModelPortfolioTableSegmentOrGroupViewModel } from "./ModelPortfolioTableSegmentOrGroupCell";
import styles from "./ModelPortfolioTargetsTable.module.scss";
import editStyles from "./CustomModelPortfolioTable.module.scss";

interface ModelPortfolioTableAssetClassHeaderCellProps {
    readonly name: string;
    readonly targetPercentage: number;
    readonly segmentWithEditableTargetPercentage: ModelPortfolioTableSegmentOrGroupViewModel | null;
    readonly readonly: boolean;
    setSegmentValidity?: (name: string, isValid: boolean) => void;
    "data-testid"?: string;
}

export function ModelPortfolioTableAssetClassHeaderCell(props: ModelPortfolioTableAssetClassHeaderCellProps) {
    const { name, targetPercentage, segmentWithEditableTargetPercentage, readonly } = props;

    return <DataTableHeadCell
        data-testid={props["data-testid"]}
    >
        <div className={classnames(styles.entryName, styles.uppercase)}>
            <div className={styles.overflowClamped}>{name}</div>
        </div>
        <div className={styles.entryTarget}>
            {
                !readonly && segmentWithEditableTargetPercentage
                    ? <TargetPercentageEntry
                        {...props}
                        segmentWithEditableTargetPercentage={segmentWithEditableTargetPercentage}
                    />
                    : <PercentageDisplay
                        value={targetPercentage}
                        precision={1}
                    />
            }
        </div>
    </DataTableHeadCell>;
}

interface TargetPercentageEntryProps extends ModelPortfolioTableAssetClassHeaderCellProps {
    readonly segmentWithEditableTargetPercentage: ModelPortfolioTableSegmentOrGroupViewModel;
}

function TargetPercentageEntry(props: TargetPercentageEntryProps) {
    const { name, targetPercentage, setSegmentValidity, segmentWithEditableTargetPercentage } = props;

    const target = useNumericTextField(targetPercentage, inactiveDisplayOfPercentageValue, isPercentageValueValid);

    useEffect(() => {
        if (setSegmentValidity) {
            setSegmentValidity(name, target.isValid);
        }
    }, [name, setSegmentValidity, target.isValid]);

    return <PercentageEntry
        numericValue={target.value} 
        setNumericValue={target.setValue}
        display={target.display}
        setDisplay={target.setDisplay}
        valueIsValid={target.isValid}
        setValueIsValid={target.setIsValid}
        onValidChange={segmentWithEditableTargetPercentage.onValidChange}
        className={editStyles.percentageEntry}
        data-testid={toTestId(`${name}-target`)} 
    />;
}