import { EagerFontLoader } from "components/shared/EagerFontLoader";
import { Fragment } from "react";
import styles from "./SigningIn.module.scss";

export default function SigningIn() {
    return <Fragment>
        <EagerFontLoader />
        <span className={styles.message}>
            Signing In...
        </span>
    </Fragment>;
}