import classnames from "classnames";
import { useState } from "react";
import { TextFieldHTMLProps, TextFieldProps } from "rmwc";
import styles from "./NumericTextField.module.scss";
import { ValeoTextField } from "../shared/ValeoTextField";
import { isNumber } from "utils/NumberUtils";

export interface NumericTextFieldProps extends TextFieldProps, TextFieldHTMLProps {
    value?: never;
    invalid?: never;
    display: string;
    setDisplay: (value: string) => void;
    numericValue: number;
    setNumericValue: (value: number) => void;
    parseNumericValueFromDisplay: (value: string) => number;
    inactiveDisplayOfNumericValue: (value: number) => string;
    activeDisplayOfNumericValue: (value: number) => string;
    valueIsValid: boolean;
    setValueIsValid: (isValid: boolean) => void;
    isValueValid: (value: number) => boolean;
    onValidChange?: (value: number) => void;
    onValidBlur?: VoidFunction;
}

export interface FormattedNumericTextFieldProps extends Omit<NumericTextFieldProps, "parseNumericValueFromDisplay" |  "inactiveDisplayOfNumericValue" | "activeDisplayOfNumericValue" | "isValueValid"> {
    isValueValid?: (value: number) => boolean;
}

export function NumericTextField(props: NumericTextFieldProps) {
    const { display, setDisplay, numericValue, setNumericValue, parseNumericValueFromDisplay, inactiveDisplayOfNumericValue, activeDisplayOfNumericValue, valueIsValid, setValueIsValid, isValueValid, onValidChange, onBlur, onFocus, onValidBlur, ...nativeProps } = props;

    const [lastValidNumericValueSubmitted, setLastValidNumericValueSubmitted] = useState(numericValue);

    return <ValeoTextField
        align="end"
        {...nativeProps}
        value={display}
        setValue={
            (value) => {
                setDisplay(value);
                const parsed = parseNumericValueFromDisplay(value);
                setNumericValue(parsed);
                setValueIsValid(isValueValid(parsed));
            }
        }
        onBlur={
            (event) => {
                onBlur?.(event);

                const parsed = parseNumericValueFromDisplay(event.currentTarget.value);
                if (isValueValid(parsed)) {
                    setDisplay(inactiveDisplayOfNumericValue(parsed));
                    if (parsed !== lastValidNumericValueSubmitted) {
                        onValidChange?.(parsed);
                        setLastValidNumericValueSubmitted(parsed);
                    }
                    onValidBlur?.();
                }
            }
        }
        onFocus={
            (event) => {
                onFocus?.(event);

                const parsed = parseNumericValueFromDisplay(event.currentTarget.value);
                if (isValueValid(parsed)) {
                    setDisplay(activeDisplayOfNumericValue(parsed));
                }
            }
        }
        onChange={
            (event) => {
                setDisplay(event.currentTarget.value);
                const parsed = parseNumericValueFromDisplay(event.currentTarget.value);
                setNumericValue(parsed);
                setValueIsValid(isValueValid(parsed));
            }
        }
        onKeyDown={
            (event) => {
                switch (event.key) {
                case "ArrowUp": {
                    const parsed = parseNumericValueFromDisplay(event.currentTarget.value);
                    if (isNumber(parsed)) {
                        const incremented = parsed + 1;
                        setDisplay(incremented.toString());
                        setNumericValue(incremented);
                        setValueIsValid(isValueValid(incremented));
                    }
                    break;
                }
                case "ArrowDown": {
                    const parsed = parseNumericValueFromDisplay(event.currentTarget.value);
                    if (isNumber(parsed)) {
                        const decremented = parsed - 1;
                        setDisplay(decremented.toString());
                        setNumericValue(decremented);
                        setValueIsValid(isValueValid(decremented));
                    }
                    break;
                }
                case "Enter": {
                    (event.target as HTMLElement).blur();
                    event.preventDefault();
                    event.stopPropagation();
                    break;
                }
                }
            }
        }
        invalid={!valueIsValid}
        className={
            classnames({
                [styles.numericTextField]: true,
                [styles.negative]: valueIsValid && numericValue < 0,
                [props.className ?? ""]: true,
            })
        }
    />;
}