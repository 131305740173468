import { AssetClassDataModel } from "../../../../dataModels/assetClassDataModel";
import { sumValue } from "utils/ArrayUtils";
import { createValueModel, ValueModel } from "../../IasWorkspaceTypes";
import { ModelPortfolioViewModel } from "./ModelPortfolioViewModel";
import { createSegmentGroup, SegmentGroupViewModel } from "./SegmentGroupViewModel";
import { createSegment, SegmentViewModel } from "./SegmentViewModel";
import { SegmentDataModel } from "dataModels/segmentDataModel";

export interface AssetClassTableViewModel extends ValueModel {
    readonly assetClass: AssetClassDataModel;
    readonly segmentGroups: SegmentGroupViewModel[];
    readonly segments: SegmentViewModel[];
    readonly segmentForClassificationActions?: SegmentDataModel;
    allSegments: () => SegmentViewModel[];
    containsChanges: () => boolean;
}

export const createAssetClassTable = (assetClass: AssetClassDataModel, modelPortfolio: ModelPortfolioViewModel, segmentForClassificationActions?: SegmentDataModel): AssetClassTableViewModel => {
    const segmentGroups = assetClass.segmentGroups.map(group => createSegmentGroup(group, assetClass, modelPortfolio));
    const segments = assetClass.segments.map(segment => createSegment(segment, assetClass, modelPortfolio));

    const currentValue = () => sumValue(segmentGroups, segmentGroup => segmentGroup.currentValue()) + sumValue(segments, segment => segment.currentValue());
    const changeValue = () => sumValue(segmentGroups, segmentGroup => segmentGroup.changeValue()) + sumValue(segments, segment => segment.changeValue());

    const allSegments = () => segmentGroups.flatMap(segmentGroup => segmentGroup.segments).concat(segments);

    const containsChanges = () => segmentGroups.some(group => group.containsChanges()) || segments.some(segment => segment.containsChanges());

    return {
        ...createValueModel(currentValue, changeValue),
        assetClass,
        segmentGroups,
        segments,
        segmentForClassificationActions,
        allSegments,
        containsChanges,
    };
};