import { BaseModal } from "components/shared/modal/BaseModal";
import { BaseModalContent } from "components/shared/modal/BaseModalContent";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { NextFooter } from "components/shared/modal/footer/NextFooter";
import { CancelHeader } from "components/shared/modal/header/CancelHeader";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { useMemo, useCallback, ChangeEvent, useState } from "react";
import { SecurityViewModel } from "../view-models/SecurityViewModel";
import styles from "./CommonCustomGroupModal.module.scss";
import { ModalAlternativeButton } from "components/shared/modal/footer/ModalAlternativeButton";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { DataTable, DataTableContent, DataTableBody, DataTableRow, DataTableCell, Checkbox } from "rmwc";
import { hasContent } from "utils/StringUtils";
import classnames from "classnames";
import { xor } from "lodash";

interface CommonCustomGroupModalProps {
    open: boolean;
    onSave: VoidFunction;
    onClose: VoidFunction;
    segmentName: string;
    customGroupSelections: number[];
    originalCustomGroupSelections: number[];
    setCustomGroupSelections: (value: number[]) => void;
    customGroupName: string;
    setCustomGroupName?: (name: string) => void;
    options: SecurityViewModel[];
    title: string;
    nameFieldDisabled: boolean;
}

interface OptionRow {
    security: IasSecurityDataModel;
    isSelectedForCustomGroup: boolean;
    toggleSelectionForCustomGroup: VoidFunction;
}

export function CommonCustomGroupModal(props: CommonCustomGroupModalProps) {
    const { onSave, onClose, segmentName, customGroupSelections, originalCustomGroupSelections, setCustomGroupSelections, customGroupName: name, setCustomGroupName: setName, options, title, nameFieldDisabled, ...nativeProps } = props;

    const [originalForComparison] = useState(originalCustomGroupSelections);

    const optionRows: OptionRow[] = useMemo(() => 
        options.map(option => ({
            security: option.security,
            isSelectedForCustomGroup: customGroupSelections.includes(option.security.id),
            toggleSelectionForCustomGroup: () => {
                const prevSelections = customGroupSelections;
                const newSelections = prevSelections.slice();
                const securityId = option.security.id;
                const prevSelectionIndex = prevSelections.indexOf(securityId);
                if (prevSelectionIndex === -1) {
                    // select by adding to selections
                    newSelections.push(securityId);
                } else {
                    // deselect by removing from selections
                    newSelections.splice(prevSelectionIndex, 1);
                }
                setCustomGroupSelections(newSelections);
            },
        }))
    , [customGroupSelections, options, setCustomGroupSelections]);

    const onSelectAll = useCallback(() => {
        setCustomGroupSelections(optionRows.map(row => row.security.id));
    }, [optionRows, setCustomGroupSelections]);

    const onDeselectAll = useCallback(() => {
        setCustomGroupSelections([]);
    }, [setCustomGroupSelections]);

    const onUserEnterName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const userInput = event.currentTarget.value;
        setName?.(userInput);
    }, [setName]);

    const saveDisabled = useMemo(() => {
        return !(customGroupSelections.length > 0
            && hasContent(name)
            && xor(originalForComparison, customGroupSelections).length > 0);
    }, [customGroupSelections, name, originalForComparison]);

    return <BaseModal
        {...nativeProps}
        renderToPortal
        data-testid="custom-group-modal"
    >
        <BaseModalContent
            width={844}
            minHeight={316}
            className={styles.createCustomGroupModal}
            header={
                <CancelHeader
                    title={title}
                    cancelBtn={
                        <ModalActionButton
                            isDefaultAction={false}
                            onClick={onClose}
                            label="Cancel"
                        />
                    }
                />
            }
            content={
                <div className={styles.modalContents}>
                    <div className={styles.header}>
                        <ValeoTextField
                            label="Group Name"
                            value={name}
                            onInput={onUserEnterName}
                            className={classnames(styles.groupNameField, { [styles.disabled]: nameFieldDisabled })}
                            disabled={nameFieldDisabled}
                            data-testid="name-text-field"
                        />
                        <div className={styles.selectButtons}>
                            <ModalAlternativeButton
                                label="Select All"
                                isDefaultAction={false}
                                onClick={onSelectAll}
                            />
                            <ModalAlternativeButton
                                label="Deselect All"
                                isDefaultAction={false}
                                onClick={onDeselectAll}
                                className={styles.deselectAllButton}
                            />
                        </div>
                    </div>
                    <div className={styles.table}>
                        <DataTable data-testid="custom-group-options">
                            <DataTableContent>
                                <DataTableBody>
                                    <DataTableRow className={styles.segmentRow}>
                                        <DataTableCell>
                                            {segmentName}
                                        </DataTableCell>
                                    </DataTableRow>
                                    {
                                        optionRows.map(row => {
                                            const { security } = row;
                                            const label = `${hasContent(security.tickerSymbol) ? `${security.tickerSymbol.toLocaleUpperCase()}    ` : ""}${security.positionName.toLocaleUpperCase()}`;
                                            return <DataTableRow
                                                key={security.id}
                                                className={styles.securityRow}
                                            >
                                                <DataTableCell>
                                                    <Checkbox
                                                        checked={row.isSelectedForCustomGroup}
                                                        label={label}
                                                        onChange={row.toggleSelectionForCustomGroup}
                                                        className={styles.customGroupCheckbox}
                                                        data-testid={`select-security-${security.positionName}`}
                                                    />
                                                </DataTableCell>
                                            </DataTableRow>;
                                        })
                                    }
                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>
                    </div>
                </div>
            }
            footer={
                <NextFooter
                    nextBtn={
                        <ModalActionButton
                            isDefaultAction={false}
                            onClick={onSave}
                            label="Save"
                            disabled={saveDisabled}
                        />
                    }
                >
                    <div className={styles.helpTextContainer}>
                        <span className={styles.helpText}>Blended Asset Class positions and other Groups are not shown here.</span>
                    </div>
                </NextFooter>
            }
        />
    </BaseModal>;
}