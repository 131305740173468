import { QueryStatus } from "@tanstack/react-query";
import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import styles from "./AddPositionModal.module.scss";
import SegmentSelection from "../../shared/SegmentSelection";
import { ValeoTextField } from "../../shared/ValeoTextField";
import { BlankZerosCurrencyEntry } from "components/numeric-values/BlankZerosCurrencyEntry";
import { NumericTextFieldHooks } from "components/numeric-values/useNumericTextField";
import { AccountSelect, placeholderForNewAccountId } from "./AccountSelect";
import { PositionName } from "./PositionName";
import { usePropWatch } from "utils/usePropWatch";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

export interface AddPositionInfoControlsProps {
    assetTableViewModel: AssetTablesViewModel;
    assetClasses: AssetClassDataModel[];
    ticker: string;
    setTicker: FunctionStringCallback;
    nameResultForTicker: string;
    manualName: string;
    setManualName: FunctionStringCallback;
    accountId: number | null;
    setAccountId: Callback<number | null>;
    currentValue: NumericTextFieldHooks;
    segmentId: number | null;
    setSegmentId: Callback<number | null>;
    isSegmentSelectDisabled: boolean;
    securityStatus: QueryStatus;
    shouldClearControls: boolean;
    setShouldClearControls: BooleanCallback;
    disabled?: boolean;
}

export function AddPositionInfoControls(props: AddPositionInfoControlsProps) {
    const { assetTableViewModel, assetClasses, ticker, setTicker, nameResultForTicker, manualName, setManualName, accountId, setAccountId, currentValue, segmentId, setSegmentId,
        isSegmentSelectDisabled, securityStatus, shouldClearControls, setShouldClearControls } = props;
    const { ias } = assetTableViewModel;
    const disabled = props.disabled ?? false;

    const [tickerInput, setTickerInput] = usePropWatch(ticker);

    const onTickerInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTickerInput(event.currentTarget.value);
    }, [setTickerInput]);

    const onTickerSubmit = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTicker(event.currentTarget.value);
    }, [setTicker]);

    const onAssetClassChange = useCallback((segmentId: number) => {
        setSegmentId(segmentId);
    }, [setSegmentId]);

    useEffect(() => {
        if (shouldClearControls) {
            setTickerInput("");

            setShouldClearControls(false);
        }
    }, [shouldClearControls, setShouldClearControls, setTickerInput]);

    // While a new account is being added, the accountId will be set but will be set to something that is not (yet) in the list
    const isAddingNewAccount = useMemo(() => accountId === placeholderForNewAccountId, [accountId]);
    const accountSelectLabel = useMemo(() => isAddingNewAccount ? "Adding Account..." : "Choose Account", [isAddingNewAccount]);
    const accountSelectDisabled = isAddingNewAccount || (props.disabled ?? false);

    return <div>
        <div className={styles.header}>Position Info</div>
        <div className={styles.topTextFields}>
            <AccountSelect
                label={accountSelectLabel}
                additionalOptionText="Add New Account"
                accounts={ias.accounts}
                accountId={accountId}
                setAccountId={setAccountId}
                disabled={accountSelectDisabled}
            />
            <ValeoTextField
                label="Ticker"
                value={tickerInput}
                onInput={onTickerInput}
                onBlur={onTickerSubmit}
                disabled={disabled}
                className={styles.tickerTextField}
                data-testid="ticker-text-field"
            />
            <div className={styles.currentValue}>
                <BlankZerosCurrencyEntry
                    label="Current $"
                    numericValue={currentValue.value}
                    setNumericValue={currentValue.setValue}
                    display={currentValue.display}
                    setDisplay={currentValue.setDisplay}
                    valueIsValid={currentValue.isValid}
                    setValueIsValid={currentValue.setIsValid}
                    disabled={disabled}
                    className={styles.currentValueTextField}
                    data-testid="current-value-text-field"
                />
                <div className={styles.currentValueInvalidEntry}>{!currentValue.isValid ? "Invalid entry" : ""}</div>
            </div>
            <SegmentSelection
                assetClasses={assetClasses}
                onChange={onAssetClassChange}
                segmentId={segmentId}
                includeBlended={true}
                disabled={isSegmentSelectDisabled || disabled}
            />
        </div>
        <PositionName
            modelPortfolio={assetTableViewModel.modelPortfolio}
            securityTicker={ticker}
            securityStatus={securityStatus}
            nameResultForTicker={nameResultForTicker}
            manualName={manualName}
            setManualName={setManualName}
            disabled={disabled}
        />
    </div>;
}