import { CustomGroupDataModel } from "dataModels/customGroupDataModel";
import { createCustomGroupViewModel } from "./CustomGroupViewModel";
import { SecurityDisplayViewModel } from "./SecurityDisplayViewModel";
import { SecurityViewModel } from "./SecurityViewModel";

export function groupSecurities(securities: SecurityViewModel[], customGroups: CustomGroupDataModel[]): SecurityDisplayViewModel[] {
    const groupedSecurities = securities.reduce<{ securities: SecurityViewModel[], customGroup?: CustomGroupDataModel }[]>((securityGroups, security) => {
        const matchingCustomGroup = customGroups.find(g => g.id === security.security.customGroupId);
        if (matchingCustomGroup) {
            const matchingSecurityGroup = securityGroups.find(({ customGroup }) => customGroup?.id === matchingCustomGroup.id);
            if (matchingSecurityGroup) {
                matchingSecurityGroup.securities.push(security);
            } else {
                securityGroups.push({ securities: [security], customGroup: matchingCustomGroup });
            }
        } else {
            securityGroups.push({ securities: [security] });
        }
        return securityGroups;
    }, []);

    return groupedSecurities.map(({ securities, customGroup }) => {
        if (customGroup) {
            return createCustomGroupViewModel(customGroup, securities);
        }

        return securities[0];
    });
}