import { ValeoSelect } from "components/shared/ValeoSelect";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { ChangeEvent, useCallback, useState } from "react";
import { Tooltip } from "rmwc";
import { AccountForCreation } from "services/accountService";
import { hasContent } from "utils/StringUtils";
import styles from "./AddNewAccount.module.scss";

export interface AddNewAccountProps {
    accountTypes: AccountTypeDataModel[],
    newAccount: AccountForCreation,
    setNewAccount: Callback<AccountForCreation>
    disabled?: boolean;
}

export function AddNewAccount(props: AddNewAccountProps) {
    const {accountTypes, newAccount, setNewAccount} = props;
    const disabled = props.disabled ?? false;

    const [showTooltip, setShowTooltip] = useState(false);

    const onAccountChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        setNewAccount({...newAccount, accountNumber: evt.target.value});
    }, [newAccount, setNewAccount]);
    const onOwnerChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        setNewAccount({...newAccount, owner: evt.target.value});
    }, [newAccount, setNewAccount]);
    const onCustodianChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        setNewAccount({...newAccount, custodian: evt.target.value});
    }, [newAccount, setNewAccount]);

    const onSelectAccountType = useCallback((optionIndex: number) => {
        const accountType = accountTypes[optionIndex];
        setNewAccount({
            ...newAccount,
            typeId: accountType.id,
            accountTypeName: accountType.name,
            taxable: accountType.taxable
        });
    }, [accountTypes, newAccount, setNewAccount]);

    return <div className={styles.accountInfo}>
        <div className={styles.triangle} />
        <div className={styles.grayBackground}>
            <div className={styles.label}>Account Info</div>
            <div className={styles.form}>
                <ValeoTextField
                    label="Account"
                    value={newAccount.accountNumber}
                    onChange={onAccountChange}
                    disabled={disabled}
                    className={styles.account}
                    data-testid="account-number-text-field"
                />
                <ValeoTextField
                    label="Owner"
                    value={newAccount.owner}
                    onChange={onOwnerChange}
                    disabled={disabled}
                    className={styles.owner}
                    data-testid="owner-text-field"
                />
                <ValeoTextField
                    label="Custodian"
                    value={newAccount.custodian}
                    onChange={onCustodianChange}
                    disabled={disabled}
                    className={styles.custodian}
                    data-testid="custodian-text-field"
                />
                <div
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    className={styles.chooseType}
                >
                    <ValeoSelect
                        label="Choose Type"
                        value={newAccount.accountTypeName}
                        options={props.accountTypes.map(accountType => accountType.name)}
                        onSelectIndex={onSelectAccountType}
                        onMouseEnter={() => setShowTooltip(false)}
                        disabled={disabled}
                        className={styles.chooseTypeSelect}
                    />
                    <Tooltip
                        content={newAccount.accountTypeName}
                        open={showTooltip && hasContent(newAccount.accountTypeName)}
                        align="bottom"
                    >
                        <div className={styles.tooltipDiv} />
                    </Tooltip>
                </div>
            </div>
            <div className={styles.helpText}>Purpose for account</div>
        </div>
    </div>;
}
