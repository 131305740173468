import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { DataTable, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableContent } from "rmwc";
import { filterMap, sumValue } from "utils/ArrayUtils";
import NumberDisplay from "../../numeric-values/NumberDisplay";
import { AccountViewModel } from "../asset-table/view-models/AccountViewModel";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import styles from "./ExcessRemainingTable.module.scss";
import { ExcessRemainingTableRow } from "./ExcessRemainingTableRow";

interface ExcessRemainingTableProps {
    viewModel: AssetTablesViewModel;
    iasId: number;
    lastModifiedDate: Date;
    accountTypes: AccountTypeDataModel[];
}

export default function ExcessRemainingTable(props: ExcessRemainingTableProps) {
    const sortAccounts = (a: AccountViewModel, b: AccountViewModel) => {
        if (a.account.taxable === b.account.taxable) {
            return a.account.accountNumber.localeCompare(b.account.accountNumber);
        }

        if (a.account.taxable) {
            return 1;
        }

        return -1;
    };

    const rows = props.viewModel.accounts.sort(sortAccounts).map((account) =>
        <ExcessRemainingTableRow
            account={account}
            key={account.account.id}
            iasId={props.iasId}
            lastModifiedDate={props.lastModifiedDate}
            accountTypes={props.accountTypes}
            iasAccounts={filterMap(props.viewModel.accounts, a => a === account ? null : a.account)}
        />);
    return <DataTable
        className={styles.excessRemainingTable}
        data-testid="excess-remaining-table"
    >
        <DataTableContent>
            <DataTableHead>
                <DataTableRow className={styles.headRow}>
                    <DataTableHeadCell>Excess / (Remaining):</DataTableHeadCell>
                    <DataTableHeadCell colSpan={3}>
                        <NumberDisplay
                            value={sumValue(props.viewModel.accounts, account => account.changeValue())}
                            showZeros
                        />
                    </DataTableHeadCell>
                </DataTableRow>
                <DataTableRow className={styles.columnLabelRow}>
                    <DataTableHeadCell>Account</DataTableHeadCell>
                    <DataTableHeadCell />
                    <DataTableHeadCell>Current</DataTableHeadCell>
                    <DataTableHeadCell>Balance</DataTableHeadCell>
                </DataTableRow>
            </DataTableHead>
            <DataTableBody className={styles.body}>
                {rows}
            </DataTableBody>
        </DataTableContent>
    </DataTable>;
}