import React from "react";
import { ConfigurationResponse } from "services/configurationService";

export const emptyConfiguration: ConfigurationResponse = {
    applicationInsights: {
        enabled: false,
        connectionString: "",
    },
    blackDiamond: {
        clientId: "",
        redirectUri: "",
        authorizeUrl: "",
    }
};

export const ConfigurationContext = React.createContext<ConfigurationResponse>(emptyConfiguration);