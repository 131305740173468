import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SegmentDataModel } from "../../../../dataModels/segmentDataModel";
import { SegmentGroupDataModel } from "../../../../dataModels/segmentGroupDataModel";
import { sumValue } from "utils/ArrayUtils";
import { createValueModel, ValueModel } from "../../IasWorkspaceTypes";
import { ModelPortfolioViewModel } from "./ModelPortfolioViewModel";
import { createSegment, SegmentViewModel } from "./SegmentViewModel";

export interface SegmentGroupViewModel extends ValueModel {
    readonly group: SegmentGroupDataModel;
    readonly assetClass: AssetClassDataModel;
    readonly segments: SegmentViewModel[];
    isEmpty: () => boolean;
    includesSegment: (segment: SegmentDataModel) => boolean;
    includeInDisplay: () => boolean;
    containsChanges: () => boolean;
    containsAccount: (accountId: number) => boolean;
}

export const createSegmentGroup = (group: SegmentGroupDataModel, assetClass: AssetClassDataModel, modelPortfolio: ModelPortfolioViewModel): SegmentGroupViewModel => {
    const segments = group.segments.map(segment => createSegment(segment, assetClass, modelPortfolio, group));

    const isEmpty = () => segments.every(segment => segment.isEmpty());
    const includesSegment = (segment: SegmentDataModel) => segments.some(s => s.segment.id === segment.id);

    const currentValue = () => sumValue(segments, segment => segment.currentValue());
    const changeValue = () => sumValue(segments, segment => segment.changeValue());

    const includeInDisplay = () => !isEmpty() || modelPortfolio.getTargetForSegmentGroup(group.id) > 0;

    const containsChanges = () => segments.some(segment => segment.containsChanges());
    const containsAccount = (accountId: number) => segments.some(segment => segment.containsAccount(accountId));

    return {
        ...createValueModel(currentValue, changeValue),
        group,
        assetClass,
        segments,
        isEmpty,
        includesSegment,
        includeInDisplay,
        containsChanges,
        containsAccount
    };
};