import { EventType } from "constants/eventType";
import { Fragment, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { telemetry } from "services/telemetryService";

interface EventLoggingTooltipContentProps extends PropsWithChildren {
    eventType: EventType;
    requiredDurationToLog?: number;
}

export function EventLoggingTooltipContent(props: EventLoggingTooltipContentProps) {
    const minimumDuration = props.requiredDurationToLog ?? 1_000;

    const getTimeValue = useCallback(() => new Date().getTime(), []);

    // Get the time the component was loaded (i.e. when the tooltip became visible)
    const componentLoadedTime = useMemo(() => getTimeValue(), [getTimeValue]);

    useEffect(() => {
        return () => {
            // When destroying the component, check to see how long the tooltip was present.
            // Only log the tracking event if the component was visible for sufficiently long.
            if ((getTimeValue() - componentLoadedTime) >= minimumDuration) {
                telemetry.trackEvent(props.eventType);
            }
        };
    }, [componentLoadedTime, props.eventType, getTimeValue, minimumDuration]);

    return <Fragment>
        {props.children}
    </Fragment>;
}