import { useEffect } from "react";
import { isConnectionFailure } from "services/ConnectionFailure";
import { populateVisionSelectionsFromIas } from "components/client-page/add-ias-wizard/fastPass/populateVisionSelectionsFromIas";
import { LinearProgressPage } from "./LinearProgressPage";
import { AddIasWizardViewControllerState, PreloadProps } from "../AddIasWizardConstants";
import { ClassifiedVisionPossession } from "components/client-page/createIas";
import { isVisionResponse } from "services/isVisionResponse";
import { IasDataModel } from "dataModels/iasDataModel";

interface LoadFastPassAssetsProps extends PreloadProps {
    setVisionAssets: Callback<ClassifiedVisionPossession[]>;
    setMostRecentlyFinalized: Callback<IasDataModel>;
    setState: Callback<AddIasWizardViewControllerState>;
}

export function LoadFastPassAssets(props: LoadFastPassAssetsProps) {
    const { data: visionData, status: visionDataStatus } = props.visionQuery;
    const { data: mostRecentlyFinalized, status: mostRecentlyFinalizedFullStatus } = props.mostRecentlyFinalizedFullQuery;
    const { data: accountTypes, status: accountTypesStatus } = props.accountTypesQuery;
    const { setVisionAssets, setMostRecentlyFinalized, setState } = props;

    useEffect(() => {
        if (isVisionResponse(visionData) && visionDataStatus === "success" && mostRecentlyFinalizedFullStatus === "success" && accountTypesStatus === "success") {
            setVisionAssets(populateVisionSelectionsFromIas(visionData.categories, mostRecentlyFinalized, accountTypes));
            setMostRecentlyFinalized(mostRecentlyFinalized);
        }
        if (isConnectionFailure(visionData)) {
            setState(AddIasWizardViewControllerState.FastPassVisionConnectionFailed);
        }
    }, [accountTypes, accountTypesStatus, mostRecentlyFinalized, mostRecentlyFinalizedFullStatus, props, setMostRecentlyFinalized, setState, setVisionAssets, visionData, visionDataStatus]);

    return <LinearProgressPage text="Loading Vision Assets..." />;
}
