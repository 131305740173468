import { WizardBaseModalContentProps } from "components/shared/modal/WizardBaseModalContentProps";
import { BaseModalContent } from "../../../shared/modal/BaseModalContent";
import { ModalActionButton } from "../../../shared/modal/footer/ModalActionButton";
import { CancelHeader } from "../../../shared/modal/header/CancelHeader";

export function IasBaseModalContent(props: WizardBaseModalContentProps) {
    const { content, footer, onCancel } = props;

    return <BaseModalContent
        data-testid="add-ias-modal"
        width={1248}
        minHeight={812}
        header={
            <CancelHeader
                title="New IAS"
                cancelBtn={
                    <ModalActionButton
                        isDefaultAction={false}
                        onClick={onCancel}
                    >
                Cancel
                    </ModalActionButton>
                } 
            />
        }
        content={content}
        footer={footer} 
    />;
}
