import NumberDisplay from "components/numeric-values/NumberDisplay";
import { IasDataModel } from "dataModels/iasDataModel";
import { Fragment, useContext, useState } from "react";
import { Button } from "rmwc";
import ChangeValueTextField from "../ChangeValueTextField";
import { AccountPositionViewModel } from "../view-models/AccountPositionViewModel";
import { EditChangeValueDialog } from "./EditChangeValueDialog";
import styles from "./EditChangeTableCell.module.scss";
import { WorkspaceContext } from "contexts/workspaceContext";
import classnames from "classnames";

interface EditChangeTableCellProps {
    viewModel: AccountPositionViewModel;
    ias: IasDataModel;
}

function EditChangeValueDialogButton(props: EditChangeTableCellProps) {
    const context = useContext(WorkspaceContext);
    const [open, setOpen] = useState(false);

    return <Fragment>
        <div className={styles.buttonContainer}>
            <Button
                className={
                    classnames(styles.changeValueButton, {
                        [styles.wide]: !context.sidebarOpen,
                        [styles.ghost]: props.viewModel.position.change === 0,
                    })
                }
                onClick={() => setOpen(true)}
                data-testid="changeCurrentButton"
            >
                <NumberDisplay
                    value={props.viewModel.changeValue()}
                    showZeros
                />
            </Button>
        </div>
        {
            open && <EditChangeValueDialog
                viewModel={props.viewModel}
                ias={props.ias}
                open={open}
                setOpen={setOpen}
            />
        }
    </Fragment>;
}

export default function EditChangeTableCell(props: EditChangeTableCellProps) {
    return props.viewModel.isBlended
        ? <EditChangeValueDialogButton {...props} />
        : <ChangeValueTextField
            ias={props.ias}
            positionId={props.viewModel.position.id}
            accountId={props.viewModel.account.account.id}
            changeValue={props.viewModel.changeValue()}
            currentValue={props.viewModel.currentValue()}
        />;
}