import { useCallback, useMemo } from "react";
import { AddIasStepper } from "./AddIasStepper";
import styles from "./StartingPage.module.scss";
import wizardStyles from "./AddIasWizard.module.scss";
import { MeetingDatePage } from "./pages/MeetingDatePage";
import { isSome } from "utils/isNullOrUndefined";
import { Button } from "rmwc";
import { AddIasWizardProps, AddIasWizardViewControllerState, iasWizardStepNames, MeetingDateProps } from "./AddIasWizardConstants";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

interface StartingPageProps extends AddIasWizardProps, MeetingDateProps {
    setState: Callback<AddIasWizardViewControllerState>
}

export function StartingPage(props: StartingPageProps) {
    const { setOpen, setState, name, setName, date, setDate } = props;
    const fastPassButtonEnabled = useMemo(() => isSome(date) && isSome(props.mostRecentlyFinalized) && isSome(props.client.modelPortfolio ?? props.client.customModelPortfolio),
        [date, props.client.customModelPortfolio, props.client.modelPortfolio, props.mostRecentlyFinalized]);

    const cancel = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onNext = useCallback(() => {
        telemetry.trackEvent(EventType.StandardIasSelected);
        setState(AddIasWizardViewControllerState.Full);
    }, [setState]);

    const setFastPass = useCallback(() => {
        telemetry.trackEvent(EventType.FastPassSelected);
        setState(AddIasWizardViewControllerState.FastPass);
    }, [setState]);

    return <MeetingDatePage
        stepper={
            <AddIasStepper
                stepIndex={0}
                stepNames={iasWizardStepNames}
                pageTitle={<span className={wizardStyles.title}>Select the meeting date and name for this IAS. This can change later.</span>}
            />
        }
        onCancel={cancel}
        onNext={onNext}
        name={name}
        setName={setName}
        date={date}
        setDate={setDate}
        extraFooter={
            fastPassButtonEnabled ? <Button
                onClick={setFastPass}
                className={styles.fastPassButton}
            >New IAS From Defaults</Button> : undefined
        }
    />;
}
