import { PropsWithChildren, ReactNode } from "react";
import { AcceptButtonName, BaseValeoDialog, CommonValeoDialogProps } from "./BaseValeoDialog";

interface ValeoDialogProps extends CommonValeoDialogProps, PropsWithChildren {
    proceedDisabled?: boolean;
    extraAcceptInput?: ReactNode;
}

export function ValeoDialog(props: ValeoDialogProps) {
    const onAccept = () => {
        props.setOpen(false);
        props.onAccept();
    };
    
    return <BaseValeoDialog 
        acceptButtonName={AcceptButtonName.Save} 
        {...props}
        onAccept={onAccept}
        extraAcceptInput={props.extraAcceptInput}
    >
        { props.children }
    </BaseValeoDialog>;
}
