import { useCallback, useMemo } from "react";
import NotesPreview from "components/shared/NotesPreview";
import { IconName } from "iconNames";
import { Tooltip, IconButton, DataTableCell, Icon } from "rmwc";
import { AccountPositionViewModel } from "../view-models/AccountPositionViewModel";
import styles from "./CommentsIconCell.module.scss";
import { EventLoggingTooltipContent } from "components/shared/EventLoggingTooltipContent";
import { EventType } from "constants/eventType";

interface CommentsIconCellProps {
    viewModel: AccountPositionViewModel;
    setCommentDialogOpen: (value: boolean) => void;
    readonlyMode: boolean;
}

function CommentsTooltipContents(props: {viewModel: AccountPositionViewModel}) {
    return <EventLoggingTooltipContent eventType={EventType.CommentIconHover}>
        <div>
            <NotesPreview
                notesType="Client Comment"
                notes={props.viewModel.position.clientComment}
            />
            <NotesPreview
                notesType="Internal Comment"
                notes={props.viewModel.position.internalComment}
            />
        </div>
    </EventLoggingTooltipContent>;
}

export default function CommentsIconCell(props: CommentsIconCellProps) {
    const onIconClick = useCallback(() => props.setCommentDialogOpen(true), [props]);

    const commentsIcon = <Tooltip
        className={styles.commentTooltip}
        content={<CommentsTooltipContents viewModel={props.viewModel} />}
        showArrow
        align="bottomLeft"
    >
        {
            props.readonlyMode ? <Icon
                icon={IconName.Comment}
                className={styles.commentIcon}
                data-testid="comment-icon"
            /> : <IconButton
                icon={IconName.Comment}
                className={styles.commentIcon}
                onClick={onIconClick}
                data-testid="comment-icon"
            />
        }
    </Tooltip>;

    const hasComments = useMemo(() => props.viewModel.position.internalComment !== "" || props.viewModel.position.clientComment !== "", [props.viewModel.position.clientComment, props.viewModel.position.internalComment]);

    return <DataTableCell>
        {hasComments && commentsIcon}
    </DataTableCell>;
}