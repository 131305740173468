import { ImportVisionAssetsContent } from "components/client-page/add-ias-wizard/pages/vision/ImportVisionAssetsContent";
import { BaseModalContent } from "components/shared/modal/BaseModalContent";
import { BackNextFooter } from "components/shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { CancelHeader } from "components/shared/modal/header/CancelHeader";
import { Fragment } from "react";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { VisionCategory, VisionPossession } from "services/visionService";
import { Button } from "rmwc";
import styles from "./VisionResultsPage.module.scss";
import sharedStyles from "./UpdateDataWizardPage.module.scss";
import { isNumber } from "utils/NumberUtils";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import classnames from "classnames";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

interface VisionResultsPageProps {
    onCancel: VoidFunction;
    onNext: VoidFunction;
    onBack?: VoidFunction;
    assetClasses: AssetClassDataModel[];
    visionCategories: VisionCategory[];
    selectedPortfolio: PopulatedPortfolio | null;
    selectedPossessions: Set<VisionPossession>;
    setSelectedPossessions: (possessions: Set<VisionPossession>) => void;
    iasSecurities: IasSecurityDataModel[];
    showVisionResults: boolean;
    setShowVisionResults: (show: boolean) => void;
    hasNewVisionSelections: boolean;
    hasNewBlendedAssetClasses: boolean;
}

export function VisionResultsPage(props: VisionResultsPageProps) {
    const portfolioAccounts: string[] = props.selectedPortfolio ? props.selectedPortfolio.accounts.map(account => account.accountNumber) : [];

    const allPossessionsClassified = Array.from(props.selectedPossessions).every(possession => isNumber(possession.segmentId));

    return <BaseModalContent
        className={classnames({[styles.showOverlay]: !props.showVisionResults})}
        width={1248}
        minHeight={812}
        fixedHeight={props.showVisionResults ? undefined : 812}
        header={
            <CancelHeader
                title="Update Data"
                cancelBtn={
                    <ModalActionButton
                        isDefaultAction={false}
                        onClick={props.onCancel}
                        label="Cancel"
                    />
                }
            />
        }
        content={
            <Fragment>
                {
                    !props.showVisionResults && <div className={styles.overlay}>
                    Vision results have been updated.
                        <Button
                            className={styles.visionButton}
                            onClick={() => props.setShowVisionResults(true)}
                        >Change Vision selections</Button>
                    </div>
                }
                <div className={sharedStyles.title}>Change Vision selections below. Please choose classifications.</div>
                <ImportVisionAssetsContent
                    assetClasses={props.assetClasses}
                    visionCategories={props.visionCategories}
                    portfolioAccounts={portfolioAccounts}
                    selectedPossessions={props.selectedPossessions}
                    setSelectedPossessions={props.setSelectedPossessions}
                    iasSecurities={props.iasSecurities}
                />
            </Fragment>
        }
        footer={
            <BackNextFooter
                nextBtn={
                    <ModalActionButton
                        isDefaultAction={true}
                        onClick={props.onNext}
                        label={props.hasNewVisionSelections || props.hasNewBlendedAssetClasses ? "Next" : "Finish"}
                        disabled={!allPossessionsClassified}
                    />
                }
                backBtn={
                    props.onBack ? <ModalActionButton
                        isDefaultAction={true}
                        onClick={props.onBack}
                        label="Back"
                    /> : <Fragment />
                }
            />
        }
    />;
}