import classnames from "classnames";
import { IndeterminateTristate } from "components/controls/IndeterminateCheckbox";
import NumberDisplay from "components/numeric-values/NumberDisplay";
import SegmentSelection from "components/shared/SegmentSelection";
import { ValeoChip } from "components/shared/ValeoChip";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { useCallback, FormEvent } from "react";
import { DataTableRow, DataTableCell, Checkbox } from "rmwc";
import { VisionPossession } from "services/visionService";
import { normalizeAccountNumber } from "utils/AccountUtils";
import { isNullOrUndefined } from "utils/isNullOrUndefined";
import { isMatchingSecurity } from "utils/securityUtils";
import { hasContent } from "utils/StringUtils";
import styles from "./ImportVisionAssetsContent.module.scss";
import { SelectionProps } from "./VisionAssetsCommonFunctions";

interface VisionPossessionRowProps {
    iasSecurities?: IasSecurityDataModel[],
    possession: VisionPossession,
    portfolioAccounts: string[],
    assetClasses: AssetClassDataModel[]
}

export function VisionPossessionRow(props: VisionPossessionRowProps & SelectionProps) {
    const { addSelections, removeSelections, possession, portfolioAccounts, iasSecurities } = props;

    const security = iasSecurities?.find(s => isMatchingSecurity(s, null, possession.account ?? possession.name));
    const securityIsClassified = security !== undefined;

    const onSegmentSelect = useCallback((segmentId: number) => {
        removeSelections([possession]);
        possession.segmentId = segmentId;
        addSelections([possession]);
    }, [addSelections, possession, removeSelections]);

    const onChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            if (security) {
                onSegmentSelect(security.segmentId);
                return;
            }
            addSelections([possession]);
        } else {
            removeSelections([possession]);
        }
    }, [addSelections, onSegmentSelect, possession, removeSelections, security]);

    const isPortfolioAccount = !isNullOrUndefined(possession.account) && portfolioAccounts.includes(normalizeAccountNumber(possession.account));

    const nameTestId = hasContent(props.possession.account)
        ? `possession-${props.possession.account}`
        : `possession-${props.possession.name}`;
    const nameDisplay = hasContent(props.possession.account)
        ? <div>
            <div className={styles.accountNumber}>{props.possession.account} - {props.possession.name}</div>
            <div className={styles.lightFont}>{`${props.possession.owner} | ${props.possession.location} | ${props.possession.type}`}</div>
        </div>
        : props.possession.name;

    return <DataTableRow
        className={
            classnames({
                "cursor-default": true,
                [styles.bgTransparent]: true,
                [styles.duplicate]: isPortfolioAccount
            })
        }
        data-testid={nameTestId}
    >
        <DataTableCell className={styles.column1}>
            <div className={styles.alignVerticalCenter}>
                <div className={styles.indent2}  />
                <Checkbox
                    className={classnames(styles.checkbox, "clickable")}
                    checked={props.selectedElements.get(possession.id) === IndeterminateTristate.Solid}
                    onChange={onChange}
                    data-testid={`${nameTestId}-checkbox`}
                />
                {nameDisplay}
            </div>
        </DataTableCell>
        <DataTableCell className={classnames(styles.column2, styles.lightFont)}>
            <div>{possession.purpose}</div>
        </DataTableCell>
        <DataTableCell className={styles.column3}>
            <NumberDisplay value={props.possession.endValue} />
        </DataTableCell>
        <DataTableCell className={styles.column4}>
            {isPortfolioAccount && <ValeoChip label="Included in BD" />}
        </DataTableCell>
        <DataTableCell className={styles.column5}>
            {
                props.selectedElements.get(possession.id) === IndeterminateTristate.Solid &&
                <SegmentSelection
                    assetClasses={props.assetClasses}
                    onChange={(segmentId) => onSegmentSelect(segmentId)}
                    segmentId={possession.segmentId}
                    disabled={securityIsClassified}
                    hideFloatingLabel={true}
                    includeBlended={true}
                />
            }
        </DataTableCell>
    </DataTableRow>;
}