import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { PositionDataModel } from "../../../../dataModels/positionDataModel";
import { createValueModel, ValueModel } from "../../IasWorkspaceTypes";
import type { AccountViewModel } from "./AccountViewModel";

export interface AccountPositionViewModel extends ValueModel {
    readonly account: AccountViewModel;
    readonly position: PositionDataModel;
    readonly security: IasSecurityDataModel;
    readonly originalCurrentValue: number;
    readonly currentValueModified: boolean;
    readonly isBlended: boolean;
    readonly segmentId: number;
}

export const createAccountPosition = (account: AccountViewModel, position: PositionDataModel, security: IasSecurityDataModel, segmentId: number, percentage = 100): AccountPositionViewModel => {
    const currentValue = () => (position.overwrittenCurrentValue ?? position.currentValue) * percentage / 100;
    const changeValue = () => position.change * percentage / 100;
    const isBlended = security.blendedFundPercentages.length > 0;

    return {
        ...createValueModel(currentValue, changeValue),
        originalCurrentValue: position.currentValue,
        account,
        position,
        security,
        currentValueModified: position.overwrittenCurrentValue !== null && position.overwrittenCurrentValue !== position.currentValue,
        segmentId,
        isBlended,
    };
};