import { NumericTextField, FormattedNumericTextFieldProps } from "./NumericTextField";
import { formatDisplay } from "utils/FormatNumber";
import { activeDisplayOfCurrencyValue, isCurrencyValueValid, parseCurrencyValueFromDisplay } from "./CurrencyEntry";

export function inactiveDisplayOfBlankZerosCurrencyValue(value: number): string {
    return value === 0
        ? "" // when inactive, format 0 as blank to show label
        : formatDisplay(value, true);
}

export function BlankZerosCurrencyEntry(props: FormattedNumericTextFieldProps) {
    const isValueValid = props.isValueValid ?? isCurrencyValueValid;

    return <NumericTextField
        parseNumericValueFromDisplay={parseCurrencyValueFromDisplay}
        inactiveDisplayOfNumericValue={inactiveDisplayOfBlankZerosCurrencyValue}
        activeDisplayOfNumericValue={activeDisplayOfCurrencyValue}
        {...props}
        isValueValid={isValueValid}
    />;
}