import { ClientDataModel } from "dataModels/clientDataModel";
import { Fragment, useEffect } from "react";
import { PopulatedPortfolio, useRelationshipQuery } from "services/blackDiamondService";
import { isConnectionFailure } from "services/ConnectionFailure";
import { isSome } from "utils/isNullOrUndefined";
import { AddIasWizardViewControllerState } from "../AddIasWizardConstants";
import { PopulatedBlackDiamondPortfolioTemplate } from "../pages/blackDiamond/PopulatedBlackDiamondPortfolioTemplate";
import { LinearProgressPage } from "./LinearProgressPage";

interface LoadBlackDiamondAccountsProps {
    client: ClientDataModel;
    portfolioId: string;
    setState: Callback<AddIasWizardViewControllerState>;
    setPortfolio: Callback<PopulatedPortfolio | null>;
}

export function LoadBlackDiamondAccounts(props: LoadBlackDiamondAccountsProps) {
    const { setState, setPortfolio } = props;
    const { data: relationship, status: relationshipStatus } = useRelationshipQuery(props.client.key);

    useEffect(() => {
        if (relationshipStatus === "success") {
            if (isConnectionFailure(relationship)) {
                setState(AddIasWizardViewControllerState.FastPassBDConnectionFailed);
            }
            if (!isSome(relationship)) {
                setPortfolio(null);
            }
        }
    }, [relationship, relationshipStatus, setPortfolio, setState]);

    if (relationshipStatus === "success" && isSome(relationship) && !isConnectionFailure(relationship)) {
        return <Fragment>
            <PopulatedBlackDiamondPortfolioTemplate
                relationshipId={relationship.id}
                portfolioId={props.portfolioId}
                reportPortfolio={setPortfolio}
                reportConnectionFailure={() => setState(AddIasWizardViewControllerState.FastPassBDConnectionFailed)}
            />
            <LinearProgressPage text="Loading Black Diamond accounts..." />
        </Fragment>;
    }

    return <LinearProgressPage text="Loading Black Diamond accounts..." />;
}
