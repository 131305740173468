import { useMutation, useQueryClient } from "@tanstack/react-query";
import { accountsMatch } from "utils/AccountUtils";
import { useAccountTypeQuery } from "./accountTypeService";
import { deleteWithAuthAndBody, postWithAuth, putWithAuth } from "./apiService";
import { useCommonErrorDetection } from "./dataErrorService";
import { IasModifiedResponseDataModel, iasQueryKey, WritableAccount } from "./iasService";
import { getCachedIas } from "./positionService";

export interface CreateAccountResponse {
    id: number;
    dateModified: Date;
    lastModifiedBy: string;
}

export interface AccountForCreation extends WritableAccount {
    iasId: number,
    accountTypeName: string,
    taxable: boolean,
    lastModifiedDate: Date,
}

const post = async (account: AccountForCreation): Promise<CreateAccountResponse> => {
    return await postWithAuth<CreateAccountResponse>("/account", account);
};

const put = async (accountId: number, info: AccountInformation): Promise<IasModifiedResponseDataModel> => {
    return await putWithAuth(`/account/${accountId}`, info);
};

export interface AccountInformation {
    lastModifiedDate: Date,
    accountNumber?: string,
    owner?: string,
    custodian?: string,
    typeId?: number,
    internalComment?: string,
    clientComment?: string
}

export function useAccountMutation(iasId: number, accountId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    const { data: accountTypes } = useAccountTypeQuery();
    return useMutation((info: AccountInformation) => put(accountId, info), {
        onMutate: async (info) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldAccount = cachedIas.accounts.find(a => a.id === accountId);
            if (oldAccount) {
                const type = accountTypes?.find(accountType => accountType.id === info.typeId);
                const newAccount = {
                    ...oldAccount,
                    ...info,
                    type: type?.name ?? oldAccount.type,
                    taxable: type?.taxable ?? oldAccount.taxable
                };

                const newAccounts = cachedIas.accounts.slice();
                newAccounts[newAccounts.indexOf(oldAccount)] = newAccount;

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    accounts: newAccounts,
                });
            } else {
                throw new Error(`Expected IAS number ${iasId} to include account with id ${accountId} but found none.`);
            }

            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);

            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: { name: response.lastModifiedBy },
            });
        },
    });
}

export function useAccountCreation(iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((account: AccountForCreation) => post(account), {
        onMutate: async (account) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const newAccounts = cachedIas.accounts.slice();
            newAccounts.push({
                ...account,
                id: -Date.now(),
                typeId: account.typeId,
                type: account.accountTypeName,
                taxable: account.taxable,
                positions: [],
                internalComment: "",
                clientComment: ""
            });

            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                accounts: newAccounts,
            });

            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (response, account) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldAccount = cachedIas.accounts.find(a => accountsMatch(a, account));
            if (oldAccount) {
                const newAccount = { ...oldAccount, id: response.id };
                const newAccounts = cachedIas.accounts.slice();
                newAccounts[newAccounts.indexOf(oldAccount)] = newAccount;

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    accounts: newAccounts,
                    dateModified: new Date(response.dateModified),
                    lastModifiedUser: { name: response.lastModifiedBy }
                });
            }
        },
    });
}

export interface DeleteAccountInfo {
    lastModifiedDate: Date
}

async function deleteAccount(accountId: number, info: DeleteAccountInfo): Promise<IasModifiedResponseDataModel> {
    return await deleteWithAuthAndBody(`/account/${accountId}`, info);
}

export function useAccountDeletion(accountId: number, iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: DeleteAccountInfo) => deleteAccount(accountId, info), {
        onMutate: async () => {
            const cachedIas = getCachedIas(queryClient, iasId);

            const oldAccount = cachedIas.accounts.find(account => account.id === accountId);
            if (oldAccount) {
                const newAccounts = cachedIas.accounts.slice();
                newAccounts.splice(newAccounts.indexOf(oldAccount), 1);

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    accounts: newAccounts
                });
            }
        },
        onError: commonErrorDetection,
        onSuccess: (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: { name: response.lastModifiedBy }
            });
        }
    });
}