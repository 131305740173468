import { Fragment, useMemo } from "react";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";
import { SegmentGroupDataModel } from "dataModels/segmentGroupDataModel";
import { isNumber } from "utils/NumberUtils";
import { hasContent, isCaseInsensitiveContentMatch } from "utils/StringUtils";
import styles from "./RecommendedFundsList.module.scss";
import { createRecommendedFundViewModel, RecommendedFundViewModel } from "../asset-table/view-models/RecommendedFundViewModel";
import { SegmentViewModel } from "../asset-table/view-models/SegmentViewModel";
import { RecommendedFundRow } from "./RecommendedFundRow";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";

export interface RecommendedFundsListProps {
    assetTableViewModel: AssetTablesViewModel;
    newPositionTicker: string;
    setNewPositionTicker: FunctionStringCallback;
    recommendedFundSecurities: SecurityLookupDataModel[];
    segmentId: number | null;
}

export function RecommendedFundsList(props: RecommendedFundsListProps) {
    const { assetTableViewModel, newPositionTicker, setNewPositionTicker, recommendedFundSecurities, segmentId } = props;
    const { ias, modelPortfolio } = assetTableViewModel;

    const segment: SegmentViewModel | undefined = useMemo(() => assetTableViewModel.allSegments().find((segment) => segment.segment.id === segmentId), [assetTableViewModel, segmentId]);
    const segmentGroup: SegmentGroupDataModel | undefined = useMemo(() => segment?.group, [segment]);
    const assetClass: AssetClassDataModel | undefined = useMemo(() => segment?.assetClass, [segment]);

    const recommendedFunds: RecommendedFundViewModel[] = useMemo(() => segment
        ? modelPortfolio.recommendedFunds()
            .filter((target) =>
                (isNumber(segmentGroup?.id) && target.segmentGroupId === segmentGroup?.id)
                || (isNumber(segment.segment.id) && target.segmentId === segment.segment.id))
            .map((target) => createRecommendedFundViewModel(target, recommendedFundSecurities))
            .sort((a, b) => a.ticker.localeCompare(b.ticker))
        : []
    , [modelPortfolio, recommendedFundSecurities, segment, segmentGroup?.id]);

    const newPositionFund: RecommendedFundViewModel | undefined = useMemo(() => recommendedFunds
        .find((fund) => isCaseInsensitiveContentMatch(fund.ticker, newPositionTicker))
    , [newPositionTicker, recommendedFunds]);

    const assetClassName = assetClass?.name ?? "";
    const segmentGroupName = segmentGroup?.name;

    return recommendedFunds.length > 0
        ? <div className={styles.recommendedFundsList}>
            <div className={styles.elevation}>
                <div className={styles.label}>Recommended Funds</div>
                <div className={styles.assetClass}>
                    {`${assetClassName} — ${hasContent(segmentGroupName) ? `${segmentGroupName} - ` : ""}${segment?.segment.name ?? ""}`}
                </div>
                {
                    recommendedFunds.map((recommendedFund) => <RecommendedFundRow
                        key={recommendedFund.ticker}
                        ias={ias}
                        ticker={recommendedFund.ticker}
                        name={recommendedFund.name ?? ""}
                        newPositionFund={newPositionFund}
                        setNewPositionTicker={setNewPositionTicker}
                    />)
                }
            </div>
        </div>
        : <Fragment />;
}