import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow, Elevation } from "rmwc";
import AssetClassTableData from "./AssetClassTableData";
import styles from "./AssetClassTable.module.scss";
import sharedStyles from "./SharedStyles.module.scss";
import { useContext } from "react";
import classnames from "classnames";
import NumberDisplay from "../../numeric-values/NumberDisplay";
import { calculatePercentages } from "./Calculations";
import ExpandableToggleButton from "../../shared/ExpandableToggleButton";
import { AssetClassTableViewModel } from "./view-models/AssetClassTableViewModel";
import { AssetClassNames } from "../../../constants/assetClassNames";
import { AssetTablesViewModel } from "./view-models/AssetTablesViewModel";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import VarianceIcon from "./VarianceIcon";
import PercentageDisplay from "../../numeric-values/PercentageDisplay";
import { ExpansionType, useExpansionState } from "services/expansionStateCache";
import { IasDataModel } from "../../../dataModels/iasDataModel";

export interface AssetClassTableProps {
    assetTableViewModel: AssetTablesViewModel;
    assetClass: AssetClassTableViewModel;
    ias: IasDataModel;
}

function headerColorClass(name: string) {
    return classnames({
        [styles.green]: name === AssetClassNames.Cash,
        [styles.red]: name === AssetClassNames.Equities,
        [styles.blue]: name === AssetClassNames.FixedIncome,
        [styles.yellow]: name === AssetClassNames.Alternatives,
    });
}

function summaryColorClass(name: string) {
    return classnames({
        [styles.green_value]: name === AssetClassNames.Cash,
        [styles.red_value]: name === AssetClassNames.Equities,
        [styles.blue_value]: name === AssetClassNames.FixedIncome,
        [styles.yellow_value]: name === AssetClassNames.Alternatives,
    });
}

function varianceColorClass(name: string) {
    switch (name) {
    case AssetClassNames.Cash:
        return "green";
    case AssetClassNames.Equities:
        return "red";
    case AssetClassNames.FixedIncome:
        return "blue";
    case AssetClassNames.Alternatives:
        return "yellow";
    }
    return "";
}

export default function AssetClassTable(props: AssetClassTableProps) {
    const context = useContext(WorkspaceContext);

    const [open, setOpen] = useExpansionState(ExpansionType.AssetClass, props.assetClass.assetClass.id, true);
    const headerColor = headerColorClass(props.assetClass.assetClass.name);
    const summaryColor = summaryColorClass(props.assetClass.assetClass.name);
    const varianceColor = varianceColorClass(props.assetClass.assetClass.name);

    const targetPercentage = props.assetTableViewModel.modelPortfolio.getTargetForAssetClass(props.assetClass.assetClass.id);
    const calculatedPercentages = calculatePercentages(props.assetClass.currentValue(), props.assetTableViewModel.currentValue(), props.assetClass.proposedValue(), props.assetTableViewModel.proposedValue(), targetPercentage);

    return (<Elevation
        z={2}
        wrap
    >
        <DataTable
            className={`${styles.asset_table} ${context.sidebarOpen ? styles.narrow : styles.wide} ${styles.standardTable}`}
            data-id="asset-class-table"
        >
            <DataTableContent>
                <DataTableHead>
                    <DataTableRow
                        className="clickable"
                        onClick={() => setOpen(!open)}
                        data-testid={`${props.assetClass.assetClass.name.toLowerCase()}-table-header-row`}
                    >
                        <DataTableHeadCell className={`${headerColor} ${sharedStyles.indent_0} ${styles.assetTableHeader}`}>
                            <ExpandableToggleButton
                                open={open}
                                setOpen={setOpen}
                                className={styles.expansionButton}
                            />
                            {props.assetClass.assetClass.name}
                        </DataTableHeadCell>
                        <DataTableHeadCell className={headerColor} />
                        <DataTableHeadCell className={`${headerColor} ${sharedStyles.value_column}`}><PercentageDisplay value={calculatedPercentages.current.percentage} /></DataTableHeadCell>
                        <DataTableHeadCell className={`${headerColor} ${sharedStyles.value_column}`} />
                        <DataTableHeadCell className={`${headerColor} ${sharedStyles.value_column}`}><PercentageDisplay value={calculatedPercentages.proposed.percentage} /></DataTableHeadCell>
                        <DataTableHeadCell className={`${headerColor}`}>
                            <div className={sharedStyles.target_and_variance_column}>
                                <div className={classnames(sharedStyles.variance_icon, {[sharedStyles.wide]: !context.sidebarOpen })}>
                                    <VarianceIcon
                                        percentage={calculatedPercentages.proposedVariance.percentage}
                                        value={calculatedPercentages.proposedVariance.value}
                                        class={varianceColor}
                                    />
                                </div>
                                <div className={sharedStyles.value_column}><PercentageDisplay value={calculatedPercentages.target.percentage} /></div>
                            </div>
                        </DataTableHeadCell>
                    </DataTableRow>
                    {
                        props.assetClass.assetClass.name === AssetClassNames.Cash && open &&
                        <DataTableRow>
                            <DataTableCell />
                            <DataTableCell />
                            <DataTableCell className={`${summaryColor} ${sharedStyles.value_column} ${styles.bold}`}>CURRENT</DataTableCell>
                            <DataTableCell className={`${summaryColor} ${sharedStyles.value_column} ${styles.bold}`}>CHANGE</DataTableCell>
                            <DataTableCell className={`${summaryColor} ${sharedStyles.value_column} ${styles.bold}`}>PROPOSED</DataTableCell>
                            <DataTableCell className={`${summaryColor} ${sharedStyles.value_column} ${styles.bold}`}>TARGET</DataTableCell>
                        </DataTableRow>
                    }
                </DataTableHead>
                <DataTableBody>
                    {
                        open && <AssetClassTableData
                            viewModel={props.assetClass}
                            overallValues={props.assetTableViewModel}
                            ias={props.ias}
                            modelPortfolio={props.assetTableViewModel.modelPortfolio}
                            varianceClass={varianceColor}
                            assetClassTarget={targetPercentage}
                        />
                    }
                    <DataTableRow className={styles.totals_row}>
                        <DataTableCell
                            className={`${styles.light_gray} ${summaryColor} ${sharedStyles.value_column} ${styles.total}`}
                            colSpan={3}
                        >
                            <NumberDisplay
                                className={styles.current_totals_content}
                                value={props.assetClass.currentValue()}
                                suppressNegativeStyling={true}
                            />
                        </DataTableCell>
                        <DataTableCell className={`${styles.light_gray} ${summaryColor} ${sharedStyles.value_column} ${styles.total}`}><NumberDisplay
                            value={props.assetClass.changeValue()}
                            suppressNegativeStyling={true}
                        /></DataTableCell>
                        <DataTableCell className={`${styles.light_gray} ${summaryColor} ${sharedStyles.value_column} ${styles.total}`}><NumberDisplay
                            value={props.assetClass.proposedValue()}
                            suppressNegativeStyling={true}
                        /></DataTableCell>
                        <DataTableCell className={`${styles.light_gray} ${summaryColor} ${sharedStyles.value_column} ${styles.total}`}><NumberDisplay
                            value={calculatedPercentages.target.value}
                            suppressNegativeStyling={true}
                        /></DataTableCell>
                    </DataTableRow>
                </DataTableBody>
            </DataTableContent>
        </DataTable>
    </Elevation>);
}