import { useEffect, useState } from "react";

export interface NumericTextFieldHooks {
    value: number,
    setValue: Callback<number>,
    display: string,
    setDisplay: FunctionStringCallback,
    isValid: boolean,
    setIsValid: BooleanCallback,
}

export function useNumericTextField(
    numericValueProp: number,
    inactiveDisplayOfNumericValue: (value: number) => string,
    isNumericValueValid: (value: number) => boolean,
): NumericTextFieldHooks {
    const [value, setValue] = useState(numericValueProp);
    const [display, setDisplay] = useState(inactiveDisplayOfNumericValue(numericValueProp));
    const [isValid, setIsValid] = useState(isNumericValueValid(numericValueProp));

    useEffect(() => {
        setValue(numericValueProp);
        setDisplay(inactiveDisplayOfNumericValue(numericValueProp));
        setIsValid(isNumericValueValid(numericValueProp));
    }, [numericValueProp, inactiveDisplayOfNumericValue, isNumericValueValid]);

    return {
        value, setValue,
        display, setDisplay,
        isValid, setIsValid,
    };
}