import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export default function ScrollToTop(props: PropsWithChildren) {
    const location = useLocation();
    const navigationType = useNavigationType();
    const [onNextRender, setOnNextRender] = useState<Function | null>(null);

    useEffect(() => {
        // Do not scroll to the top if navigating using the back button
        if (navigationType !== "POP") {
            setOnNextRender(() => {
                window.scrollTo(0, 0);
            });
        }
    }, [location, navigationType]);

    useEffect(() => {
        if (onNextRender) {
            onNextRender();
            setOnNextRender(null);
        }
    }, [onNextRender]);

    return <Fragment>
        {props.children}
    </Fragment>;
}