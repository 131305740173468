import classnames from "classnames";
import { MouseEvent, PropsWithChildren, ReactNode } from "react";
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, Typography } from "rmwc";
import { isNumber } from "utils/NumberUtils";
import { LinearProgressOverlay, LinearProgressOverlayProps } from "./LinearProgressOverlay";
import styles from "./ValeoDialog.module.scss";

export enum AcceptButtonName {
    Next = "Next",
    Finish = "Finish",
    Save = "Save"
}

export interface CommonValeoDialogProps {
    title: string;
    width: number;
    fixedHeight?: number;
    minHeight?: number;
    open: boolean;
    setOpen: (open: boolean) => void;
    onOpen?: VoidFunction;
    onAccept: VoidFunction;
    preventDefault?: boolean;
    className?: string;
    testId?: string;
    renderToPortal?: boolean;
}

export interface BaseValeoDialogProps extends PropsWithChildren, CommonValeoDialogProps {
    extraButton?: ReactNode;
    acceptButtonName: AcceptButtonName;
    proceedDisabled?: boolean;
    extraAcceptInput?: ReactNode;
    linearProgressOverlayProps?: LinearProgressOverlayProps;
}

export function BaseValeoDialog(props: BaseValeoDialogProps) {
    const { title, width, fixedHeight, minHeight, open, setOpen, onOpen, proceedDisabled, onAccept, className, renderToPortal, testId, children } = props;

    const dialogInlineStyles = {
        width,
        minWidth: width,
        maxWidth: width,
        ...(isNumber(fixedHeight) && {
            height: fixedHeight,
            minHeight: fixedHeight,
            maxHeight: fixedHeight,
        }),
        ...(isNumber(minHeight) && {
            minHeight
        }),
    };

    const dialogContentInlineStyles: {height?: number, minHeight?: number, maxHeight?: number} = {};
    if (isNumber(fixedHeight)) {
        const contentHeight = fixedHeight - 99;
        dialogContentInlineStyles.height = contentHeight;
        dialogContentInlineStyles.minHeight = contentHeight;
        dialogContentInlineStyles.maxHeight = contentHeight;
    }

    if (isNumber(minHeight)) {
        dialogContentInlineStyles.minHeight = minHeight - 99;
    }

    const onCancel = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen(false);
    };

    const onSave = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onAccept();
    };

    return <Dialog
        open={open}
        onOpen={onOpen}
        className={classnames(styles.dialog, className)}
        preventOutsideDismiss
        renderToPortal={renderToPortal}
    >
        <div
            style={dialogInlineStyles}
            data-testid={testId}
        >
            <DialogTitle className={styles.dialogTitle}>
                <Typography
                    use="headline3"
                    className={styles.title}
                >{title}</Typography>
                <DialogButton
                    isDefaultAction={false}
                    onClick={onCancel}
                    className={`${styles.modalButton} ${styles.cancelButton}`}
                    data-testid="dialog-button-cancel"
                >
                    Cancel
                </DialogButton>
            </DialogTitle>
            <DialogContent style={dialogContentInlineStyles}>
                {children}
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
                <div>
                    {props.extraAcceptInput}
                    <DialogButton
                        isDefaultAction={props.preventDefault !== true}
                        onClick={onSave}
                        disabled={proceedDisabled}
                        className={
                            classnames(styles.modalButton, styles.proceedButton, {
                                [styles.disabledButton]: proceedDisabled,
                            })
                        }
                        data-testid="dialog-button-next"
                    >
                        {props.acceptButtonName}
                    </DialogButton>
                </div>
                {props.extraButton}
            </DialogActions>
        </div>
        {
            props.linearProgressOverlayProps && <LinearProgressOverlay {...props.linearProgressOverlayProps} />
        }
    </Dialog>;
}
