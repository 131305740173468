import styles from "./WorkspaceHeader.module.scss";
import { Button, Checkbox, Typography } from "rmwc";
import { ChangeEvent, Fragment, useCallback, useContext, useMemo, useState } from "react";
import { formatDateTime, formatDateWritten, formatMeetingDate } from "utils/FormatDate";
import { TitleValueCard } from "./TitleValueCard";
import { NotesCard } from "./NotesCard";
import { EditIasModelPortfolioDialog } from "./EditIasModelPortfolioDialog";
import { EditNameDialog } from "./EditNameDialog";
import { ValeoSelect } from "../../shared/ValeoSelect";
import { IasState, IasStates, IasStateText, IasStateTexts, textForIasState } from "constants/iasState";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { ModelPortfolioDataModel, ModelPortfolioHistoricalReturnsDataModel } from "../../../dataModels/modelPortfolioDataModel";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import { useIasMutation } from "services/iasService";
import { hasContent } from "utils/StringUtils";
import { usePropWatch } from "utils/usePropWatch";
import { FinalConfirmationDialog } from "./FinalConfirmationDialog";
import { UpdateDataWizard } from "./update-data-wizard/UpdateDataWizard";
import { ModelPortfolioViewModel } from "../asset-table/view-models/ModelPortfolioViewModel";
import { ClientDataModel } from "dataModels/clientDataModel";
import { downloadPdf } from "utils/downloadPdf";
import { createAssetTables } from "../asset-table/view-models/AssetTablesViewModel";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { FidelityTradeButtons } from "./FidelityTradeButtons";
import { EditReportTitleDialog } from "./EditReportTitleDialog";

export interface WorkspaceHeaderProps {
    ias: IasDataModel,
    modelPortfolio: ModelPortfolioViewModel;
    reportingName: string,
    modelPortfolios: ModelPortfolioDataModel[],
    modelPortfolioReturns: ModelPortfolioHistoricalReturnsDataModel[],
    client: ClientDataModel
}

export default function WorkspaceHeader(props: WorkspaceHeaderProps) {
    const { ias, modelPortfolio, reportingName, modelPortfolios, modelPortfolioReturns, client } = props;

    const format = useMemo(() => formatIas(ias, modelPortfolio, reportingName), [ias, modelPortfolio, reportingName]);

    const [showNameDialog, setShowNameDialog] = useState(false);
    const [showModelPortfolioDialog, setShowModelPortfolioDialog] = useState(false);
    const [showConfirmFinalize, setShowConfirmFinalize] = useState(false);
    const [showRefreshDataDialog, setShowRefreshDataDialog] = useState(false);
    const [showReportTitleDialog, setShowReportTitleDialog] = useState(false);
    const [state, setState] = usePropWatch(format.state());
    const [stateKey, setStateKey] = useState(0);

    const context = useContext(WorkspaceContext);

    const mutation = useIasMutation(ias.id);

    const onFinalizeConfirmed = useCallback(() => {
        setShowConfirmFinalize(false);
        telemetry.trackEvent(EventType.IasStateChange, { from: ias.state, to: IasState.Final });
        mutation.mutate({ lastModifiedDate: ias.dateModified, state: IasState.Final});
    }, [ias.dateModified, mutation, ias.state]);

    const onFinalizeCanceled = useCallback(() => {
        setShowConfirmFinalize(false);
    }, []);

    const onSelectState = useCallback((optionIndex: number) => {
        const selectedState = IasStates[optionIndex];

        switch (selectedState) {
        case IasState.Final: {
            setStateKey(stateKey + 1);
            setShowConfirmFinalize(true);
            break;
        }
        default:
            setState(textForIasState(selectedState));
            telemetry.trackEvent(EventType.IasStateChange, { from: ias.state, to: selectedState });
            mutation.mutate({ lastModifiedDate: ias.dateModified, state: selectedState});
            break;
        }
    }, [ias.dateModified, mutation, setState, stateKey, ias.state]);

    const onCheckboxChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        mutation.mutate({lastModifiedDate: ias.dateModified, showAltTargets: evt.target.checked});
    }, [ias.dateModified, mutation]);

    const assetTablesViewModel = createAssetTables(ias.assetClasses, modelPortfolios, ias);

    const onDownloadPdf = useCallback(async () => {
        telemetry.trackEvent(EventType.PdfExportWorkspace);
        await downloadPdf({
            ias,
            client,
            modelPortfolio,
            assetClasses: ias.assetClasses,
            assetTable: assetTablesViewModel,
            modelPortfolios,
            modelPortfolioReturns,
        });
    }, [client, ias, modelPortfolio, modelPortfolioReturns, modelPortfolios, assetTablesViewModel]);

    return <Fragment>
        <div className={styles.headerText}>
            <Typography
                use="headline4"
            >Meeting Date: </Typography><Typography use="body1">{format.meetingDate()}</Typography>
            <Typography
                use="headline4"
                className={styles.leftPad32}
            >Last Modified: </Typography><Typography use="body1">{format.dateModified()}</Typography>
            <Typography
                use="headline4"
                className={styles.leftPad32}
            >Last Edited By: </Typography><Typography use="body1">{format.lastModified()}</Typography>
        </div>
        <div className={styles.flex}>
            <div className={styles.leftGroup}>
                <div>
                    <div className={styles.reportTitleCard}>
                        <TitleValueCard
                            title="Report Title"
                            value={ias.reportTitle}
                            maxLines={2}
                            onClick={() => setShowReportTitleDialog(true)}
                            editable={!context.readonlyMode}
                        />
                    </div>
                    <div className={styles.nameCard}>
                        <TitleValueCard
                            title="IAS Name"
                            value={format.title()}
                            maxLines={2}
                            onClick={() => setShowNameDialog(true)}
                            editable={!context.readonlyMode}
                        />
                    </div>
                    <div className={styles.modelPortfolioCard}>
                        <TitleValueCard
                            title="Model Portfolio"
                            value={format.modelPortfolioName()}
                            maxLines={1}
                            onClick={() => setShowModelPortfolioDialog(true)}
                            editable={!context.readonlyMode}
                            className="clickable"
                        />
                    </div>
                </div>
                <div className={styles.notesCardContainer}>
                    <NotesCard
                        valeoNotes={format.valeoNotes()}
                        clientNotes={format.clientNotes()}
                        ias={ias}
                    />
                </div>
            </div>
            <div
                className={styles.rightGroup}
                data-testid="rightGroup"
            >
                {
                    context.readonlyMode ?
                        <div className={styles.final}>{IasStateText.Final}</div> :
                        <ValeoSelect
                            key={stateKey}
                            label="State"
                            options={IasStateTexts}
                            value={state}
                            onSelectIndex={onSelectState}
                            className={styles.selectMenu}
                        />
                }
                {
                    context.readonlyMode || <Button
                        label="update data"
                        trailingIcon="keyboard_arrow_right"
                        className={styles.whbutton}
                        onClick={() => setShowRefreshDataDialog(true)}
                        data-testid="updateDataButton"
                    />
                }
                <div className={styles.importDate} >
                    <Typography use="headline4">{context.readonlyMode ? "Last Update: " : "Last: "}</Typography><Typography use="body1">{format.dateRefreshed()}</Typography>
                </div>
                <Button
                    label="export to pdf"
                    trailingIcon="keyboard_arrow_right"
                    className={styles.whbutton}
                    onClick={onDownloadPdf}
                />
                <Checkbox
                    label="Show Alt Targets"
                    className={styles.showAltTargets}
                    onChange={onCheckboxChange}
                    checked={ias.showAltTargets}
                    disabled={context.readonlyMode}
                />
                <FidelityTradeButtons
                    client={client}
                    ias={ias}
                />
            </div>
        </div>
        {
            !context.readonlyMode && <EditNameDialog
                open={showNameDialog}
                setOpen={setShowNameDialog}
                initialName={ias.title}
                ias={ias}
            />
        }
        {
            showModelPortfolioDialog && <EditIasModelPortfolioDialog
                open={showModelPortfolioDialog}
                setOpen={setShowModelPortfolioDialog}
                modelPortfolios={modelPortfolios}
                ias={ias}
                readonly={context.readonlyMode}
                totalIasValue={assetTablesViewModel.currentValue()}
            />
        }
        {
            showConfirmFinalize && <FinalConfirmationDialog
                onConfirm={onFinalizeConfirmed}
                onCancel={onFinalizeCanceled}
                ias={ias}
            />
        }
        {
            showRefreshDataDialog && <UpdateDataWizard
                close={() => setShowRefreshDataDialog(false)}
                clientKey={client.key}
                ias={ias}
            />
        }
        {
            !context.readonlyMode && <EditReportTitleDialog
                open={showReportTitleDialog}
                setOpen={setShowReportTitleDialog}
                initialTitle={ias.reportTitle}
                ias={ias}
            />
        }
    </Fragment>;
}

const formatIas = (ias: IasDataModel, modelPortfolio: ModelPortfolioViewModel, reportingName: string) => {
    return {
        state: () => hasContent(ias.state) ? textForIasState(ias.state) : "Loading...",
        reportingName: () => reportingName,
        meetingDate: () => formatMeetingDate(ias.meetingDate),
        dateModified: () => formatDateTime(ias.dateModified),
        lastModified: () => ias.lastModifiedUser.name,
        title: () => ias.title,
        clientNotes: () => ias.clientNotes,
        valeoNotes: () => ias.valeoNotes,
        modelPortfolioName: () => modelPortfolio.name(),
        dateRefreshed: () => formatDateWritten(ias.dateRefreshed)
    };
};
