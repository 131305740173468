import { DataErrorDialog } from "components/shared/DataErrorDialog";
import { Fragment, PropsWithChildren } from "react";
import { Topbar } from "../topbar/Topbar";
import styles from "./Layout.module.scss";

interface LayoutProps extends PropsWithChildren {
    clientKey?: string;
    clientReportingName?: string;
    clientId?: number;
}

export default function Layout(props: LayoutProps) {
    return <Fragment>
        <Topbar
            clientKey={props.clientKey ?? ""}
            clientReportingName={props.clientReportingName ?? ""}
            clientId={props.clientId ?? -1}
        />
        <div className={styles.content}>
            {props.children}
        </div>
        <DataErrorDialog />
    </Fragment>;
}