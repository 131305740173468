import classnames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { Tooltip, FormattedOption } from "rmwc";
import { ValeoSelect } from "./ValeoSelect";
import { AssetClassNames } from "../../constants/assetClassNames";
import { AssetClassDataModel } from "../../dataModels/assetClassDataModel";
import { SegmentDataModel } from "../../dataModels/segmentDataModel";
import { SegmentGroupDataModel } from "../../dataModels/segmentGroupDataModel";
import { convertToMap } from "utils/MapUtils";
import styles from "./SegmentSelection.module.scss";
import { isNumber } from "utils/NumberUtils";
import { hasContent } from "utils/StringUtils";
import { Segment } from "constants/segment";

interface SegmentSelectionProps {
    assetClasses: AssetClassDataModel[];
    segmentId?: number | null;
    onChange?: (segmentId: number) => void;
    includeBlended?: boolean;
    disabled?: boolean;
    "data-testid"?: string;
    hideFloatingLabel?: boolean;
    excludeSegmentIds?: number[];
}

interface AssetClassFormattedOptions extends FormattedOption {
    options: ExtendedFormattedOption[];
}

interface ExtendedFormattedOption extends Omit<FormattedOption, "value"> {
    segmentid: number;
    segmentgroupid?: number;
    value: string;
}

const createAssetClassOptions = (assetClass: AssetClassDataModel, withoutSegments: number[]): AssetClassFormattedOptions => {
    const innerOptions: ExtendedFormattedOption[] = [];

    if (assetClass.name === AssetClassNames.Cash) {
        const matchingSegment = assetClass.segments.find(segment => segment.name === AssetClassNames.Cash);
        if (matchingSegment && !withoutSegments.includes(matchingSegment.id)) {
            innerOptions.push(createSegmentOption(matchingSegment));
        }
    } else {
        assetClass.segmentGroups.forEach(group => {
            group.segments.forEach(segment => {
                if(!withoutSegments.includes(segment.id)) {
                    innerOptions.push(createGroupSegmentOption(group, segment));
                }
            });
        });

        assetClass.segments.forEach(segment => {
            if(!withoutSegments.includes(segment.id)) {
                innerOptions.push(createSegmentOption(segment));
            }
        });
    }

    return {
        label: <span>{assetClass.name}</span>,
        options: innerOptions,
    };
};

const createGroupSegmentOption = (group: SegmentGroupDataModel, segment: SegmentDataModel): ExtendedFormattedOption => {
    return {
        label: `${group.name} - ${segment.name}`,
        value: `${group.name} - ${segment.name}`,
        segmentid: segment.id,
        segmentgroupid: group.id,
    };
};

const createSegmentOption = (segment: SegmentDataModel): ExtendedFormattedOption => {
    return {
        label: segment.name,
        value: segment.name,
        segmentid: segment.id
    };
};

export default function SegmentSelection(props: SegmentSelectionProps) {
    const { assetClasses, onChange } = props;
    const includeBlended = props.includeBlended ?? false;
    const testId = hasContent(props["data-testid"]) ? props["data-testid"] : "choose-asset-class";

    const options = useMemo(() => {
        const options = assetClasses.map(assetClass => createAssetClassOptions(assetClass, props.excludeSegmentIds ?? []));

        return includeBlended
            ? options.concat({
                label: <span>Other</span>,
                options: [{
                    label: <div className={styles.blendedOption}>
                        <div className={styles.blendedOptionHeader}>Blended Asset Class</div>
                        {"\n" /* Newline is only visible in selection (not menu items). It pushes helper text out of sight so that selection only shows header text. */}
                        <div className={styles.blendedOptionHelper}>You will be able to define this later.</div>
                    </div>,
                    value: "Blended Asset Class",
                    segmentid: Segment.Blended,
                }],
            })
            : options;
    }, [assetClasses, includeBlended, props.excludeSegmentIds]);

    const idToValue = useMemo(() => {
        const flattenedSegmentOptions = options.flatMap(option => option.options);
        return convertToMap(flattenedSegmentOptions, option => [option.segmentid, option.value]);
    }, [options]);

    const selectedSegment = useMemo(() => isNumber(props.segmentId) ? (idToValue.get(props.segmentId) ?? null) : null, [idToValue, props.segmentId]);

    const onSelectSegment = useCallback((optionIndex: number) => {
        const flattenedSegmentOptions = options.flatMap(option => option.options);
        const selectedOption = flattenedSegmentOptions[optionIndex];
        const selectedId = selectedOption.segmentid;
        onChange?.(selectedId);
    }, [onChange, options]);

    const [showTooltip, setShowTooltip] = useState(false);

    return <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className={styles.selectDiv}
    >
        <ValeoSelect
            label="Choose Asset Class"
            value={selectedSegment}
            onSelectIndex={onSelectSegment}
            data-testid={testId}
            options={options}
            className={
                classnames(styles.select, {
                    [styles.fixBorderOverFloatingLabelBug]: hasContent(selectedSegment) && props.hideFloatingLabel !== true,
                    [styles.includesBlended]: includeBlended
                })
            }
            onMouseEnter={()=>setShowTooltip(false)}
            disabled={props.disabled}
            hideFloatingLabel={props.hideFloatingLabel}
        />
        <Tooltip
            content={selectedSegment ?? <div />}
            open={showTooltip && hasContent(selectedSegment)}
            align="bottom"
        >
            <div className={styles.tooltipDiv} />
        </Tooltip>
    </div>;
}