import { useQuery } from "@tanstack/react-query";
import { ModelPortfolioDataModel, ModelPortfolioHistoricalReturnsDataModel } from "../dataModels/modelPortfolioDataModel";
import { getWithAuth } from "./apiService";
import { useCommonErrorDetection } from "./dataErrorService";

export const modelPortfolioQueryKey = () => ["modelPortfolioList"];
export const modelPortfolioReturnsQueryKey = () => ["modelPortfolioReturnsList"];

const getList = async (): Promise<ModelPortfolioDataModel[]> => {
    const modelPortfolios: ModelPortfolioDataModel[] = await getWithAuth("/modelPortfolio");
    return modelPortfolios.sort((a, b) => a.equitiesTarget - b.equitiesTarget);
};

export function useModelPortfolioQuery() {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(modelPortfolioQueryKey(), () => getList(), { 
        onError: commonErrorDetection
    });
}

const getReturnsList = async (): Promise<ModelPortfolioHistoricalReturnsDataModel[]> => {
    const historicalReturns: ModelPortfolioHistoricalReturnsDataModel[] = await getWithAuth("/modelPortfolio/historicalReturns");
    return historicalReturns;
};

export function useModelPortfolioReturnsQuery() {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(modelPortfolioReturnsQueryKey(), () => getReturnsList(), { 
        onError: commonErrorDetection
    });
}