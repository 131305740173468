import NumberDisplay from "components/numeric-values/NumberDisplay";
import AccountInfo from "components/shared/AccountInfo";
import { IconName } from "iconNames";
import { useCallback, useContext, useState } from "react";
import { DataTableRow, DataTableCell, IconButton, Tooltip, Typography, MenuSurfaceAnchor, Icon } from "rmwc";
import { AccountViewModel } from "../asset-table/view-models/AccountViewModel";
import styles from "./ExcessRemainingTableRow.module.scss";
import { usePositionsMutation } from "services/positionService";
import { EditAccountModal } from "./EditAccountModal";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import { useAccountDeletion } from "services/accountService";
import { AccountDataModel } from "dataModels/accountDataModel";
import { hasContent } from "utils/StringUtils";
import { AccountCommentModal } from "./AccountCommentModal";
import NotesPreview from "components/shared/NotesPreview";
import { AccountMenu } from "./AccountMenu";
import classnames from "classnames";
import { WorkspaceContext } from "contexts/workspaceContext";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { EventLoggingTooltipContent } from "components/shared/EventLoggingTooltipContent";

interface ExcessRemainingTableRowProps {
    account: AccountViewModel;
    iasId: number;
    lastModifiedDate: Date;
    accountTypes: AccountTypeDataModel[];
    iasAccounts: AccountDataModel[];
}

function TaxableTooltipContents(props: {label: string}) {
    return <EventLoggingTooltipContent eventType={EventType.TaxableStatusIconHover}>
        <Typography use="headline6">{props.label}</Typography>
    </EventLoggingTooltipContent>;
}

function NotesTooltipContents(props: {account: AccountDataModel}) {
    return <EventLoggingTooltipContent eventType={EventType.CommentIconHover}>
        <div>
            <NotesPreview
                notesType="Client Comment"
                notes={props.account.clientComment}
            />
            <NotesPreview
                notesType="Internal Comment"
                notes={props.account.internalComment}
            />
        </div>
    </EventLoggingTooltipContent>;
}

export function ExcessRemainingTableRow(props: ExcessRemainingTableRowProps) {
    const context = useContext(WorkspaceContext);
    const mutation = usePositionsMutation(props.account.account.id);
    const deleteAccount = useAccountDeletion(props.account.account.id, props.iasId);

    const [menuOpen, setMenuOpen] = useState(false);
    const [kebabVisible, setKebabVisible] = useState(false);
    const [sellAllModalVisible, setSellAllModalVisible] = useState(false);
    const [editAccountModalVisible, setEditAccountModalVisible] = useState(false);
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] = useState(false);
    const [commentModalVisible, setCommentModalVisible] = useState(false);

    const taxableTooltip = <Tooltip
        content={<TaxableTooltipContents label="Taxable" />}
        align="bottom"
        className="sidebar-tooltip"
    >
        <img
            src="/taxable.svg"
            alt="taxable"
            className={styles.taxableIcon}
        />
    </Tooltip>;

    const taxAdvantagedTooltip = <Tooltip
        content={<TaxableTooltipContents label="Tax-Advantaged" />}
        align="bottom"
        className="sidebar-tooltip"
    >
        <img
            src="/tax-advantaged.svg"
            alt="tax-advantaged"
            className={styles.taxableIcon}
        />
    </Tooltip>;

    const sellAll = () => {
        const positions = props.account.account.positions.map((position) => {
            return {
                id: position.id,
                change: -(position.overwrittenCurrentValue ?? position.currentValue)
            };
        });
        const info = {
            iasId: props.iasId,
            lastModifiedDate: props.lastModifiedDate,
            positions
        };
        telemetry.trackEvent(EventType.SellAll);
        mutation.mutate(info);
        setSellAllModalVisible(false);
    };

    const sellAllConfirmationTitle = <span>
        Sell all holdings in this account?:
        <div className={styles.modalAccountNumber}>
            {props.account.account.accountNumber}
        </div>
    </span>;
    const sellAllConfirmationBody = <div>This action can not be undone. The Change column will reflect this action in the asset classes with this account.</div>;

    const onDelete = () => {
        setDeleteAccountModalVisible(false);
        telemetry.trackEvent(EventType.DeleteAccount);
        deleteAccount.mutate({lastModifiedDate: props.lastModifiedDate});
    };

    const hasComments = hasContent(props.account.account.internalComment) || hasContent(props.account.account.clientComment);

    const openCommentModal = useCallback(() => setCommentModalVisible(true), []);
    const openEditAccountModal = useCallback(() => setEditAccountModalVisible(true), []);
    const openSellAllModal = useCallback(() => setSellAllModalVisible(true), []);

    return <DataTableRow className={styles.row}>
        <DataTableCell>
            <div className={styles.account}>
                <div className={styles.accountNumber}>{props.account.account.accountNumber} </div>
                <div className={styles.accountInfo}><AccountInfo account={props.account.account} /></div>
            </div>
        </DataTableCell>
        <DataTableCell className={styles.taxableAndMenuCell}>
            <div className={styles.taxableAndMenu}>
                <div className={styles.taxableAndComments}>
                    {props.account.account.taxable ? taxableTooltip : taxAdvantagedTooltip}
                    {
                        hasComments &&
                        <Tooltip
                            className={classnames(styles.commentTooltip, "sidebar-tooltip")}
                            content={<NotesTooltipContents account={props.account.account} />}
                            showArrow
                            align="bottomLeft"
                        >
                            {
                                context.readonlyMode ? <Icon
                                    icon={IconName.Comment}
                                    className={styles.commentIcon}
                                    data-testid="comment-icon"
                                /> : <IconButton
                                    icon={IconName.Comment}
                                    className={styles.commentIcon}
                                    onClick={openCommentModal}
                                    data-testid="comment-icon"
                                />
                            }
                        </Tooltip>
                    }
                </div>
                <MenuSurfaceAnchor>
                    <AccountMenu
                        onEditAccountClick={openEditAccountModal}
                        onSellAllClick={openSellAllModal}
                        onCommentClick={openCommentModal}
                        setDeleteAccountModalVisible={setDeleteAccountModalVisible}
                        setKebabVisible={setKebabVisible}
                        hasComments={hasComments}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                    />
                    {
                        !context.readonlyMode && <IconButton
                            icon={IconName.MoreVert}
                            onClick={() => setMenuOpen(true)}
                            className={classnames(styles.kebab, {[styles.visible]: kebabVisible })}
                        />
                    }
                </MenuSurfaceAnchor>
                {
                    sellAllModalVisible && 
                    <ConfirmationDialog
                        title={sellAllConfirmationTitle}
                        content={sellAllConfirmationBody}
                        onConfirm={sellAll}
                        onCancel={() => setSellAllModalVisible(false)}
                        confirmButton="Sell all"
                        useWarningStyling
                        width={448}
                    />
                }
                {
                    editAccountModalVisible && <EditAccountModal
                        accountData={props.account.account}
                        open={editAccountModalVisible}
                        setOpen={setEditAccountModalVisible}
                        accountTypes={props.accountTypes}
                        iasId={props.iasId}
                        lastModifiedDate={props.lastModifiedDate}
                        iasAccounts={props.iasAccounts}
                    />
                }
                {
                    deleteAccountModalVisible && <ConfirmationDialog
                        title="Delete this account?"
                        confirmButton="Delete"
                        width={448}
                        useWarningStyling
                        onCancel={() => setDeleteAccountModalVisible(false)}
                        onConfirm={onDelete}
                        content={<div>This will delete the account and any associated positions from the current workspace. You can not undo this action.</div>}
                    />
                }
                {
                    commentModalVisible && <AccountCommentModal
                        account={props.account.account}
                        setOpen={setCommentModalVisible}
                        iasId={props.iasId}
                        lastModifiedDate={props.lastModifiedDate}
                    />
                }
            </div>
        </DataTableCell>
        <DataTableCell><NumberDisplay
            value={props.account.currentValue()}
            showZeros
        /></DataTableCell>
        <DataTableCell><NumberDisplay
            value={props.account.changeValue()}
            showZeros
        /></DataTableCell>
    </DataTableRow >;
}