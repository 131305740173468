import { Typography } from "rmwc";
import { BaseModalHeader } from "./BaseModalHeader";
import styles from "./CancelHeader.module.scss";

export interface CancelHeaderProps {
    title: string;
    cancelBtn: JSX.Element;
}

export function CancelHeader(props: CancelHeaderProps) {
    const { title, cancelBtn } = props;

    return <BaseModalHeader>
        <Typography
            use="headline3"
            className={styles.title}
        >{title}</Typography>
        <div
            className={styles.cancelBtn}
            data-testid="dialog-button-cancel"
        >
            {cancelBtn}
        </div>
    </BaseModalHeader>;
}