import { Checkbox } from "rmwc";
import styles from "./FilterModal.module.scss";
import { BaseModal } from "components/shared/modal/BaseModal";
import { BaseModalContent } from "components/shared/modal/BaseModalContent";
import { CancelHeader } from "components/shared/modal/header/CancelHeader";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { NextFooter } from "components/shared/modal/footer/NextFooter";
import { ModalAlternativeButton } from "components/shared/modal/footer/ModalAlternativeButton";
import { ClientStatus } from "dataModels/clientStatusDataModel";
import { useCallback, useMemo, useState } from "react";
import { storeClientStatusFilters } from "services/clientStatusStorageService";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

interface FilterModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    clientStatusFilters: ClientStatusFilter[];
    setClientStatusFilters: (statusFilters: ClientStatusFilter[]) => void;
    setStatusIdsInStorage: (statusIds: number[]) => void;
}

export interface ClientStatusFilter {
    status: ClientStatus;
    shown: boolean;
}

export function FilterModal(props: FilterModalProps) {
    const [clientFilters, setClientFilters] = useState(props.clientStatusFilters);

    const onSelectAll = useCallback(() => {
        const newStatusFilters = props.clientStatusFilters.slice();
        newStatusFilters.forEach(filter => filter.shown = true);
        setClientFilters(newStatusFilters);
    }, [props.clientStatusFilters]);

    const onDeselectAll = useCallback(() => {
        const newStatusFilters = props.clientStatusFilters.slice();
        newStatusFilters.forEach(filter => filter.shown = false);
        setClientFilters(newStatusFilters);
    }, [props.clientStatusFilters]);

    const saveDisabled = useMemo(() => !clientFilters.some(filter => filter.shown), [clientFilters]);

    const changeSelection = useCallback((statusId: number) => {
        const newStatusFilters = props.clientStatusFilters.slice();
        const status = newStatusFilters.find(filter => filter.status.id === statusId);
        if(status) {
            status.shown = !status.shown;
        }
        setClientFilters(newStatusFilters);
    }, [props.clientStatusFilters]);

    const onSearch = useCallback(() => {
        telemetry.trackEvent(EventType.StatusFiltersChanged);
        const statusIds = clientFilters.filter(filter => filter.shown).map(filter => filter.status.id);
        storeClientStatusFilters(statusIds);
        props.setStatusIdsInStorage(statusIds);
        props.setClientStatusFilters(clientFilters);
        props.setOpen(false);
    }, [clientFilters, props]);

    const halfLength = Math.ceil(clientFilters.length / 2);
    const firstHalfClientFilters = useMemo(() => clientFilters.slice(0, halfLength), [clientFilters, halfLength]);
    const secondHalfClientFilters = useMemo(() => clientFilters.slice(halfLength), [clientFilters, halfLength]);

    function FilterCheckbox({filter}: {filter: ClientStatusFilter}) {
        return <Checkbox
            className={styles.statusCheckbox}
            checked={filter.shown}
            onChange={() => changeSelection(filter.status.id)}
            data-testid={`${filter.status.name}-checkbox`}
        >{filter.status.name}</Checkbox>;
    }

    return <BaseModal
        open={props.open}
    >
        <BaseModalContent
            width={496}
            minHeight={316}
            className={styles.createCustomGroupModal}
            header={
                <CancelHeader
                    title="Status Filter"
                    cancelBtn={
                        <ModalActionButton
                            isDefaultAction={false}
                            onClick={() => props.setOpen(false)}
                            label="Cancel"
                        />
                    }
                />
            }
            content={
                <div className={styles.dialogContent}>
                    <span className={styles.header}>Display clients with a status of:</span>
                    <div className={styles.selectButtons}>
                        <ModalAlternativeButton
                            label="Select All"
                            isDefaultAction={false}
                            onClick={onSelectAll}
                            data-testid="select-all-button"
                        />
                        <ModalAlternativeButton
                            label="Deselect All"
                            isDefaultAction={false}
                            onClick={onDeselectAll}
                            className={styles.deselectAllButton}
                            data-testid="deselect-all-button"
                        />
                    </div>
                    <div className={styles.statusList}>
                        <div className={styles.column}>
                            {
                                firstHalfClientFilters.map(filter => <FilterCheckbox
                                    filter={filter}
                                    key={filter.status.id}
                                />)
                            }
                        </div>
                        <div className={styles.column}>
                            {
                                secondHalfClientFilters.map(filter => <FilterCheckbox
                                    filter={filter}
                                    key={filter.status.id}
                                />)
                            }
                        </div>
                    </div>
                </div>
            }
            footer={
                <NextFooter
                    nextBtn={
                        <ModalActionButton
                            isDefaultAction={false}
                            onClick={onSearch}
                            label="Search"
                            disabled={saveDisabled}
                            data-testid="search-button"
                        />
                    }
                />
            }
        />
    </BaseModal>;
}