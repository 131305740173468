import { useNavigate } from "react-router-dom";
import { postIas, useIasQuery, WritableAccount, WritableCustomGroup, WritableIas, WritablePosition, WritableSecurity } from "services/iasService";
import { Fragment, useCallback, useEffect, useState } from "react";
import { IasDataModel } from "dataModels/iasDataModel";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { getCustomSegmentGroupNames, getCustomSegmentNames } from "utils/CustomSegmentNameUtils";

interface CopyIasProps {
    id: number,
    meetingId: number,
}

export default function CopyIas(props: CopyIasProps) {
    const { id, meetingId } = props;

    const { status: queryStatus, data: ias } = useIasQuery(id);

    const navigate = useNavigate();

    const [hasCreatedNewIas, setHasCreatedNewIas] = useState(false);

    const copyIas = useCallback((ias: IasDataModel) => {
        const customGroups: WritableCustomGroup[] = ias.customGroups.map(customGroup => ({
            tempId: customGroup.id,
            name: customGroup.name,
            segmentId: customGroup.segmentId,
        }));
        const securities: WritableSecurity[] = ias.securities.map(security => {
            return {
                tempId: security.id,
                tickerSymbol: security.tickerSymbol,
                positionName: security.positionName,
                segmentId: security.segmentId,
                customGroupId: security.customGroupId,
                blendedFundPercentages: security.blendedFundPercentages.slice()
            };
        });
        const accounts: WritableAccount[] = ias.accounts.map(account => {
            return {
                accountNumber: account.accountNumber,
                owner: account.owner,
                custodian: account.custodian,
                originalCustodian: account.originalCustodian,
                typeId: account.typeId,
                dataSource: account.dataSource,
                internalComment: account.internalComment,
                clientComment: account.clientComment,
                positions: account.positions.map(position => {
                    const newPosition: WritablePosition = {
                        currentValue: position.currentValue,
                        overwrittenCurrentValue: position.overwrittenCurrentValue,
                        change: position.change,
                        asOfDate: position.asOfDate,
                        dataSource: position.dataSource,
                        securityId: position.securityId,
                        internalComment: position.internalComment,
                        clientComment: position.clientComment,
                        sourceId: position.sourceId,
                        shares: position.shares
                    };
                    return newPosition;
                }),
            };
        });

        const customSegmentNames = getCustomSegmentNames(ias.assetClasses);
        const customSegmentGroupNames = getCustomSegmentGroupNames(ias.assetClasses);

        const newIas: WritableIas = {
            title: "Copy of " + ias.title,
            modelPortfolioId: ias.modelPortfolio?.id,
            modelPortfolioTargetsId: ias.modelPortfolioTargets?.id,
            customModelPortfolio: ias.customModelPortfolio ? { ...ias.customModelPortfolio } : undefined,
            accounts: accounts,
            securities: securities,
            customGroups,
            meetingId,
            valeoNotes: ias.valeoNotes,
            clientNotes: ias.clientNotes,
            cashTarget: ias.cashTarget,
            cashTargetInDollars: ias.cashTargetInDollars,
            alternativesTarget: ias.alternativesTarget,
            alternativesTargetInDollars: ias.alternativesTargetInDollars,
            cashCarveOut: ias.cashCarveOut,
            fixedIncomeCarveOut: ias.fixedIncomeCarveOut,
            equitiesCarveOut: ias.equitiesCarveOut,
            sourcePortfolioId: ias.sourcePortfolioId,
            dateRefreshed: ias.dateRefreshed,
            showAltTargets: ias.showAltTargets,
            customSegmentNames,
            customSegmentGroupNames,
            reportTitle: ias.reportTitle
        };

        telemetry.trackEvent(EventType.CopyIas);

        void postIas(newIas).then(newId => {
            localStorage.setItem("snackbarOpen", "true");
            navigate(`/ias/${newId}`, { replace: false });
        });
    }, [meetingId, navigate]);

    useEffect(() => {
        if (!hasCreatedNewIas && queryStatus === "success") {
            setHasCreatedNewIas(true);
            copyIas(ias);
        }
    }, [copyIas, hasCreatedNewIas, ias, queryStatus]);

    return <Fragment/>;
}