import { DataTableCell, DataTableRow } from "rmwc";
import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import classnames from "classnames";
import { SegmentViewModel } from "./view-models/SegmentViewModel";
import sharedStyles from "./SharedStyles.module.scss";
import NumberDisplay from "../../numeric-values/NumberDisplay";
import { calculatePercentages } from "./Calculations";
import { ValueModel } from "../IasWorkspaceTypes";
import { AssetClassNames } from "../../../constants/assetClassNames";
import { ModelPortfolioViewModel } from "./view-models/ModelPortfolioViewModel";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import { sortSecurities } from "./workspaceSorting";
import VarianceIcon from "./VarianceIcon";
import PercentageDisplay from "../../numeric-values/PercentageDisplay";
import { ExpansionType, useExpansionState } from "services/expansionStateCache";
import ExpandableToggleButton from "../../shared/ExpandableToggleButton";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { SecurityDisplayRow } from "./security-row/SecurityDisplayRow";
import { CreateCustomGroupModal } from "./custom-group/CreateCustomGroupModal";
import { SecurityViewModel } from "./view-models/SecurityViewModel";
import { applyAccountFilter } from "../add-position/AccountSelect";

interface SegmentRowProps {
    viewModel: SegmentViewModel;
    overallValues: ValueModel;
    depth: number;
    ias: IasDataModel;
    assetClass?: string;
    modelPortfolio: ModelPortfolioViewModel;
    varianceClass: string;
    assetClassTarget: number;
}

export default function SegmentRow(props: SegmentRowProps) {
    const context = useContext(WorkspaceContext);

    const sortedSegmentChildren = useMemo(() => sortSecurities(props.viewModel.allSecurityDisplays(), context.sortBy), [context.sortBy, props.viewModel]);

    const segmentChildrenWithChanges = useMemo(() =>
        context.onlyChanges ? props.viewModel.allSecurityDisplaysWithChanges() : props.viewModel.allSecurityDisplays(),
    [context.onlyChanges, props.viewModel]);
    const segmentChildrenFilteredByAccount = useMemo(() =>
        applyAccountFilter(context.filterByAccountId) ? segmentChildrenWithChanges.filter(security => security.containsAccount(context.filterByAccountId)) : segmentChildrenWithChanges,
    [context.filterByAccountId, segmentChildrenWithChanges]);
    const sortedSegmentChildrenToDisplay = useMemo(() => sortSecurities(segmentChildrenFilteredByAccount, context.sortBy), [context.sortBy, segmentChildrenFilteredByAccount]);

    const targetPercentage = props.modelPortfolio.getTargetForSegment(props.viewModel.segment.id);
    const calculatedPercentages = calculatePercentages(props.viewModel.currentValue(), props.overallValues.currentValue(), props.viewModel.proposedValue(), props.overallValues.proposedValue(), targetPercentage);

    const labelStyle = classnames({
        [sharedStyles.redLabel]: props.assetClass === AssetClassNames.Equities,
        [sharedStyles.blueLabel]: props.assetClass === AssetClassNames.FixedIncome,
        [sharedStyles.yellowLabel]: props.assetClass === AssetClassNames.Alternatives,
    });

    const isTopLevel = props.depth === 1;

    const [open, setOpen] = useExpansionState(ExpansionType.Segment, props.viewModel.segment.id, true);

    const depthStyleHeader = sharedStyles[`indent_${props.depth - 1}`];
    const depthStyleFooter = sharedStyles[`indent_${props.depth}`];

    const [initiatingSecurityForGroup, setInitiatingSecurityForGroup] = useState<SecurityViewModel | null>(null);

    const onClickRow = useCallback(() => {
        if (initiatingSecurityForGroup === null) {
            setOpen(!open);
        }
    }, [initiatingSecurityForGroup, open, setOpen]);

    const onCloseCustomGroupModal = useCallback(() => {
        setInitiatingSecurityForGroup(null);
    }, []);

    const onStartCreatingGroup = useCallback((security: SecurityViewModel) => {
        setInitiatingSecurityForGroup(security);
    }, []);

    const segmentName = `${props.viewModel.group ? `${props.viewModel.group.name} - ` : ""}${props.viewModel.segment.name}`;

    return (<Fragment>
        <DataTableRow
            data-testid="segment-row"
            className="clickable"
            onClick={onClickRow}
        >
            <DataTableCell className={classnames(depthStyleHeader, sharedStyles.title_cell, {[sharedStyles.wide]: !context.sidebarOpen })}>
                <div className={sharedStyles.collapsibleGroup}>
                    <ExpandableToggleButton
                        open={open}
                        setOpen={setOpen}
                    />
                    {props.viewModel.segment.name}
                </div>
            </DataTableCell>
            <DataTableCell />
            {
                isTopLevel &&
                <Fragment>
                    <DataTableCell className={sharedStyles.value_column}>
                        <div className={labelStyle}>CURRENT</div>
                        <div className={sharedStyles.percentageDiv}><PercentageDisplay value={calculatedPercentages.current.percentage} /></div>
                    </DataTableCell>
                    <DataTableCell className={sharedStyles.value_column}>
                        <div className={labelStyle}>CHANGE</div>
                        <div className={sharedStyles.percentageDiv} />
                    </DataTableCell>
                    <DataTableCell className={sharedStyles.value_column}>
                        <div className={labelStyle}>PROPOSED</div>
                        <div className={sharedStyles.percentageDiv}><PercentageDisplay value={calculatedPercentages.proposed.percentage} /></div>
                    </DataTableCell>
                    <DataTableCell className={sharedStyles.value_column}>
                        <div className={labelStyle}>TARGET</div>
                        <div className={`${sharedStyles.target_and_variance_column} ${sharedStyles.percentageDiv}`}>
                            <div className={classnames({ [sharedStyles.variance_icon]: true, [sharedStyles.wide]: !context.sidebarOpen })}>
                                <VarianceIcon
                                    percentage={calculatedPercentages.proposedVariance.percentage}
                                    value={calculatedPercentages.proposedVariance.value}
                                    class={props.varianceClass}
                                />
                            </div>
                            <div><PercentageDisplay value={calculatedPercentages.target.percentage} /></div>
                        </div>
                    </DataTableCell>
                </Fragment>
            }
            {
                !isTopLevel &&
                <Fragment>
                    <DataTableCell className={sharedStyles.value_column}>
                        <div className={sharedStyles.medium_weight}><PercentageDisplay value={calculatedPercentages.current.percentage} /></div>
                    </DataTableCell>
                    <DataTableCell />
                    <DataTableCell className={sharedStyles.value_column}>
                        <div className={sharedStyles.medium_weight}><PercentageDisplay value={calculatedPercentages.proposed.percentage} /></div>
                    </DataTableCell>
                    <DataTableCell />
                </Fragment>
            }
            {
                initiatingSecurityForGroup && <CreateCustomGroupModal
                    open={true}
                    onClose={onCloseCustomGroupModal}
                    ias={props.ias}
                    segment={props.viewModel.segment}
                    segmentName={segmentName}
                    segmentChildren={sortedSegmentChildren}
                    initiatingSecurity={initiatingSecurityForGroup}
                />
            }
        </DataTableRow>
        {
            open && sortedSegmentChildrenToDisplay.map((child) => <SecurityDisplayRow
                key={child.key}
                viewModel={child}
                depth={props.depth}
                ias={props.ias}
                assetClassTarget={props.assetClassTarget}
                modelPortfolio={props.modelPortfolio}
                overallValues={props.overallValues}
                onStartCreatingGroup={onStartCreatingGroup}
                segmentName={segmentName}
                segmentChildren={sortedSegmentChildren}
            />)
        }
        <DataTableRow>
            <DataTableCell className={classnames(depthStyleFooter, sharedStyles.title_cell, {[sharedStyles.wide]: !context.sidebarOpen })}>
                {props.viewModel.segment.name} Total
            </DataTableCell>
            <DataTableCell />
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}><NumberDisplay value={props.viewModel.currentValue()} /></DataTableCell>
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}><NumberDisplay value={props.viewModel.changeValue()} /></DataTableCell>
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}><NumberDisplay value={props.viewModel.proposedValue()} /></DataTableCell>
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}>{isTopLevel && <NumberDisplay value={calculatedPercentages.target.value} />}</DataTableCell>
        </DataTableRow>
    </Fragment>);
}