import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow, Elevation, IconButton } from "rmwc";
import ClientTableRow from "./ClientTableRow";
import styles from "./ClientTable.module.scss";
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { IconName } from "iconNames";
import { isWhiteSpace } from "utils/StringUtils";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { ClientSearchResult } from "dataModels/clientDataModel";
import { useClientListSearch } from "services/clientService";
import { ClientTablePagination } from "./ClientTablePagination";
import { createCancellationTokenProducer } from "utils/CancellationToken";
import { ClientStatus } from "dataModels/clientStatusDataModel";
import { isNumber } from "utils/NumberUtils";
import { FilterModal } from "./FilterModal";
import { filterMap } from "utils/ArrayUtils";

interface ClientTableProps {
    initialResult: ClientSearchResult;
    clientStatuses: ClientStatus[];
    shownStatusIds: number[];
    setStatusIdsInStorage: (statusIds: number[]) => void;
    search: string;
    setSearch: (search: string) => void;
}

export default function ClientTable(props: ClientTableProps) {
    const [page, setPage] = useState(1);
    const [searchResults, setSearchResults] = useState(props.initialResult);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [clientStatusFilters, setClientStatusFilters] = useState(props.clientStatuses.map(status => ({status, shown: props.shownStatusIds.includes(status.id)})));
    const cancellationTokenProducer = useMemo(() => createCancellationTokenProducer(), []);

    const clientListSearch = useClientListSearch(cancellationTokenProducer);

    const clientStatusMap = useMemo(() => props.clientStatuses.reduce((map: Record<number, string>, status) => {
        map[status.id] = status.name;
        return map;
    }, {}), [props.clientStatuses]);

    const tableBody = useMemo(() => searchResults.clients.map((client) => <ClientTableRow
        key={client.id}
        client={client}
        status={isNumber(client.statusId) ? clientStatusMap[client.statusId] : undefined}
    />), [clientStatusMap, searchResults.clients]);

    const performSearchForPage = useCallback(async (statusIds: number[], searchTerm: string, pageToUse: number) => {
        const result = await clientListSearch(statusIds, searchTerm, pageToUse);
        if (result) {
            setSearchResults(result);
        }
    }, [clientListSearch]);

    useEffect(() => {
        const currentStatusIds = filterMap(clientStatusFilters, filter => filter.shown ? filter.status.id : null);
        void performSearchForPage(currentStatusIds, props.search, page);
    }, [clientStatusFilters, page, performSearchForPage, props.search]);

    const onSearchChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        props.setSearch(evt.target.value);
    }, [props]);

    const paginate = useCallback(async (pageToUse: number) => {
        setPage(pageToUse);
    }, []);

    const clearSearch = useCallback(async () => {
        props.setSearch("");
        setPage(1);
    }, [props]);

    const onStatusFilterClicked = useCallback(() => {
        setShowFilterModal(true);
    }, []);

    return <Fragment>
        <Elevation
            z={2}
            wrap
        >
            <DataTable
                className={styles.clientTable}
                data-testid="client-table"
            >
                <DataTableContent>
                    <DataTableHead>
                        <DataTableRow>
                            <DataTableHeadCell
                                className={styles.tableHeader}
                                colSpan={3}
                            >Client List</DataTableHeadCell>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableCell
                                colSpan={3}
                                className={styles.searchCell}
                            >
                                <ValeoTextField
                                    label="Search"
                                    outlined
                                    value={props.search}
                                    onChange={onSearchChange}
                                    trailingIcon={
                                        !isWhiteSpace(props.search) && {
                                            icon: IconName.Close,
                                            onClick: clearSearch
                                        }
                                    }
                                    data-testid="client-table-search"
                                    className={styles.search}
                                />
                                <IconButton
                                    icon={IconName.FilterAlt}
                                    onClick={onStatusFilterClicked}
                                    className={styles.filterIcon}
                                    data-testid="filter-icon"
                                />
                            </DataTableCell>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableCell className={styles.clientKey}>Code</DataTableCell>
                            <DataTableCell className={styles.clientName}>Name</DataTableCell>
                            <DataTableCell className={styles.status}>Status</DataTableCell>
                        </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                        {tableBody}
                        <ClientTablePagination
                            results={searchResults}
                            retrievePage={paginate}
                        />
                    </DataTableBody>
                </DataTableContent>
            </DataTable>
        </Elevation>
        {
            showFilterModal && <FilterModal
                open={showFilterModal}
                setOpen={setShowFilterModal}
                clientStatusFilters={clientStatusFilters}
                setClientStatusFilters={setClientStatusFilters}
                setStatusIdsInStorage={props.setStatusIdsInStorage}
            />
        }
    </Fragment>;
}