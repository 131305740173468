import { isSome } from "utils/isNullOrUndefined";

export interface ConnectionFailure {
    isConnectionFailure: true;
}

export function isConnectionFailure(t: object | null | undefined): t is ConnectionFailure {
    return isSome(t) && "isConnectionFailure" in t && typeof(t.isConnectionFailure) === "boolean" && t.isConnectionFailure;
}

export function createConnectionFailure(): ConnectionFailure {
    return {
        isConnectionFailure: true
    };
}