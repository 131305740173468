import { DataTableCell, DataTableRow } from "rmwc";
import MeetingGroup from "./MeetingGroup";
import { MeetingDataModel } from "../../dataModels/meetingDataModel";
import { Fragment, useState } from "react";
import depth from "./Depth.module.scss";
import styles from "./YearGroup.module.scss";
import ExpandableToggleButton from "../shared/ExpandableToggleButton";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

interface YearGroupProps {
    year: number;
    meetings: MeetingDataModel[];
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    depth: number;
    clientId: number;
    allMeetings: MeetingDataModel[];
}

export default function YearGroup(props: YearGroupProps) {
    const year = props.year;
    const meetings = props.meetings;

    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <DataTableRow
                className="clickable"
                onClick={() => setOpen(!open)}
            >
                <DataTableCell className={`${styles.yearCell} ${depth[`depth${props.depth}`]}`}>
                    <ExpandableToggleButton
                        open={open}
                        setOpen={setOpen}
                    />
                    {year}
                </DataTableCell>
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
            </DataTableRow>
            {
                open && meetings.map((meeting) =>
                    <MeetingGroup
                        key={meeting.id}
                        meeting={meeting}
                        modelPortfolios={props.modelPortfolios}
                        assetClasses={props.assetClasses}
                        depth={2}
                        open={false}
                        clientId={props.clientId}
                        allMeetings={props.allMeetings}
                    />
                )
            }
        </Fragment>
    );
}