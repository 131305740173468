import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { NetworkError } from "./NetworkError";
import { telemetry } from "./telemetryService";

const dataErrorKey = ["DataError"];

export interface DataError {
    readonly loadError: boolean,
    readonly saveError: boolean,
    readonly externalChangeError: boolean,
    readonly forbiddenError: boolean
}

const defaultDataError: DataError = {
    loadError: false,
    saveError: false,
    externalChangeError: false,
    forbiddenError: false
};

export const useClearError = () => {
    const queryClient = useQueryClient();
    return useCallback(() => {
        queryClient.removeQueries(dataErrorKey);
    }, [queryClient]);
};

export const useUnknownSaveErrorMutator = () => {
    const queryClient = useQueryClient();
    return useCallback((value: boolean) => {
        const current = queryClient.getQueryData<DataError>(dataErrorKey) ?? defaultDataError;
        const newValue = {...current, saveError: value};
        queryClient.setQueryData<DataError>(dataErrorKey, newValue);
    }, [queryClient]);
};

export const useExternalChangeDetectedMutator = () => {
    const queryClient = useQueryClient();
    return useCallback((value: boolean) => {
        const current = queryClient.getQueryData<DataError>(dataErrorKey) ?? defaultDataError;
        const newValue = {...current, externalChangeError: value};
        queryClient.setQueryData<DataError>(dataErrorKey, newValue);
    }, [queryClient]);
};

export const useLoadErrorMutator = () => {
    const queryClient = useQueryClient();
    return useCallback((value: boolean) => {
        const current = queryClient.getQueryData<DataError>(dataErrorKey) ?? defaultDataError;
        const newValue = {...current, loadError: value};
        queryClient.setQueryData<DataError>(dataErrorKey, newValue);
    }, [queryClient]);
};

export const useForbiddenErrorMutator = () => {
    const queryClient = useQueryClient();
    return useCallback((value: boolean) => {
        const current = queryClient.getQueryData<DataError>(dataErrorKey) ?? defaultDataError;
        const newValue = {...current, forbiddenError: value};
        queryClient.setQueryData<DataError>(dataErrorKey, newValue);
    }, [queryClient]);
};

export const useDataErrorQuery = () => {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(dataErrorKey, () => Promise.resolve({} as DataError), {
        onError: commonErrorDetection
    });
};

export const useCommonErrorDetection = (isMutator: boolean = true) => {
    const externalChangeDetectedMutator = useExternalChangeDetectedMutator();
    const unknownSaveErrorMutator = useUnknownSaveErrorMutator();
    const loadErrorMutator = useLoadErrorMutator();
    const forbiddenError = useForbiddenErrorMutator();
    
    return useCallback((error: unknown) => {
        telemetry.trackException(error as Error);

        if (error instanceof NetworkError && error.errorCode === 406) {
            externalChangeDetectedMutator(true);
        } else if (error instanceof NetworkError && error.errorCode === 403) {
            forbiddenError(true);
        } else if (isMutator) {
            unknownSaveErrorMutator(true);
        } else {
            loadErrorMutator(true);
        }
    }, [externalChangeDetectedMutator, forbiddenError, isMutator, loadErrorMutator, unknownSaveErrorMutator]);
};
