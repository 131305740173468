import { useQuery } from "@tanstack/react-query";
import { useCommonErrorDetection } from "./dataErrorService";
import { getWithAuth } from "./apiService";
import { ClientStatus } from "dataModels/clientStatusDataModel";

async function get(): Promise<ClientStatus[]> {
    return await getWithAuth<ClientStatus[]>("/clientStatus");
}

export function useClientStatusesQuery () {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(["clientStatus"], () => get(), {
        onError: commonErrorDetection
    });
}