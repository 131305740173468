import { Fragment, useEffect } from "react";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";
import { useSecurityQuery } from "services/securityService";

export interface SecurityTemplateProps {
    ticker: string;
    notifySecurity?: (name: SecurityLookupDataModel) => void;
}

export function SecurityTemplate(props: SecurityTemplateProps) {
    const { ticker, notifySecurity } = props;

    const { data } = useSecurityQuery(ticker);

    useEffect(() => {
        if (data) {
            notifySecurity?.(data);
        }
    }, [data, notifySecurity]);

    return <Fragment />;
}