import { ValeoSelect } from "components/shared/ValeoSelect";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { AccountType } from "constants/accountType";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { DataTableRow, DataTableCell, Tooltip } from "rmwc";
import { hasContent } from "utils/StringUtils";
import type { PossessionRowProps, SelectionProps } from "./MoreVisionInfoPage";
import styles from "./VisionHoldingRow.module.scss";

export function VisionHoldingRow(props: PossessionRowProps & SelectionProps) {
    const { possession, addSelections, removeSelections, accountTypes } = props;
    const accountHasType = hasContent(possession.accountType?.name);
    const testId = `holding-${possession.name}`;

    const holdingAccountTypeIds = useMemo(() => [
        AccountType.Alternative_Taxable,
        AccountType.Alternative_Tax_Adv,
        AccountType.Direct_Taxable,
        AccountType.Direct_Tax_Adv
    ], []);

    const chooseAccountOptions = useMemo(() => accountTypes
        .filter(type => holdingAccountTypeIds.includes(type.id))
        .map(type => type.name)
    , [accountTypes, holdingAccountTypeIds]);

    const onSelectAccount = useCallback((optionIndex: number) => {
        removeSelections([possession]);

        const account = chooseAccountOptions[optionIndex];
        possession.accountType = accountTypes.find(accountType => accountType.name === account);

        addSelections([possession]);
    }, [accountTypes, addSelections, chooseAccountOptions, possession, removeSelections]);

    const onOwnerChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        removeSelections([possession]);
        possession.owner = evt.target.value;
        addSelections([possession]);
    }, [addSelections, possession, removeSelections]);

    const onCustodianChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        removeSelections([possession]);
        possession.custodian = evt.target.value;
        addSelections([possession]);
    }, [addSelections, possession, removeSelections]);

    const [showTooltip, setShowTooltip] = useState(false);

    return <DataTableRow
        className={styles.possessionRow}
        data-testid={testId}
    >
        <DataTableCell className={styles.holdingName}>
            {possession.name}
        </DataTableCell>
        <DataTableCell className={styles.chooseAccount}>
            <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <ValeoSelect
                    label="Choose Account"
                    options={chooseAccountOptions}
                    value={possession.accountType?.name ?? ""}
                    onSelectIndex={onSelectAccount}
                    onMouseEnter={() => setShowTooltip(false)}
                    hideFloatingLabel={true}
                    data-testid={`${testId}-choose-account`}
                />
                <Tooltip
                    content={possession.accountType?.name ?? <div />}
                    open={showTooltip && accountHasType}
                    align="bottom"
                >
                    <div className={styles.tooltipDiv} />
                </Tooltip>
            </div>
        </DataTableCell>
        <DataTableCell className={styles.textField}>
            {
                accountHasType && <ValeoTextField
                    value={possession.owner}
                    label="Owner"
                    onChange={onOwnerChange}
                    data-testid={`${testId}-owner`}
                />
            }
        </DataTableCell>
        <DataTableCell className={styles.textField}>
            {
                accountHasType && <ValeoTextField
                    value={possession.custodian ?? ""}
                    label="Custodian"
                    onChange={onCustodianChange}
                    data-testid={`${testId}-custodian`}
                />
            }
        </DataTableCell>
        <DataTableCell
            className={styles.accountType}
            data-testid={`${testId}-account-type`}
        >
            {accountHasType && possession.accountType?.name}
        </DataTableCell>
    </DataTableRow>;
}