import { LinearProgressOverlay } from "components/shared/LinearProgressOverlay";
import { BackNextFooter } from "components/shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateIasParams, useCreateIasMutator } from "../createIasMutator";
import { IasBaseModalContent } from "./pages/IasBaseModalContent";

export interface AddIasPageProps {
    stepper: JSX.Element;
    createIasParams: CreateIasParams
}

export function AddIasPage(props: AddIasPageProps) {
    const {mutate, status, data} = useCreateIasMutator();
    const navigate = useNavigate();

    useEffect(() => {
        if (status === "idle") {
            mutate(props.createIasParams);
        }
    }, [mutate, props.createIasParams, status]);

    useEffect(() => {
        if (status === "success") {
            navigate(`/ias/${data}`, {replace: false});
        }
    }, [data, navigate, status]);

    return <Fragment>
        <IasBaseModalContent
            content={
                <Fragment>
                    {props.stepper}
                </Fragment>
            }
            footer={
                <BackNextFooter
                    backBtn={
                        <ModalActionButton
                            label="Back"
                            disabled
                        />
                    }
                    nextBtn={
                        <ModalActionButton
                            label="Finish"
                            disabled
                        />
                    }
                />
            }
            onCancel={() => {}}
        />
        <LinearProgressOverlay text="Creating New IAS..." />
    </Fragment>;
}