export const fromDateOnlyString = (date: string): Date => {
    const [ year, month, day ] = date.split("-").map(part => parseInt(part));
    return new Date(year, month - 1, day);
};

export function convertToUTC(date: Date) {
    const utcAdjustedDate = new Date(date);
    utcAdjustedDate.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return utcAdjustedDate;
}

export function convertToLocal(date: Date) {
    const localDate = new Date(date);
    localDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return localDate;
}

export function areDatesOnlyEqual(a: Date, b: Date) {
    return a.getUTCDate() === b.getUTCDate() && a.getUTCMonth() === b.getUTCMonth() && a.getUTCFullYear() === b.getUTCFullYear();
}