import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomSegmentGroupName } from "dataModels/customSegmentGroupName";
import { CustomSegmentName } from "dataModels/customSegmentName";
import { SegmentDataModel } from "dataModels/segmentDataModel";
import { SegmentGroupDataModel } from "dataModels/segmentGroupDataModel";
import { cloneDeep, xorWith } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { hasContent } from "utils/StringUtils";

export interface EditCustomSegmentNamesProps {
    setCustomSegmentName: (segmentId: number, customName: string) => void;
    revertCustomSegmentName: (segmentId: number) => void;
    formatCustomSegmentNames: VoidFunction;
    setCustomSegmentGroupName: (segmentGroupId: number, customName: string) => void;
    revertCustomSegmentGroupName: (segmentGroupId: number) => void;
    formatCustomSegmentGroupNames: VoidFunction;
}

interface UseEditCustomSegmentNames {
    assetClasses: AssetClassDataModel[];
    customSegmentNames: CustomSegmentName[];
    customSegmentNamesAreValid: boolean;
    customSegmentNamesAreModified: boolean;
    editCustomSegmentNamesProps: EditCustomSegmentNamesProps;
    customSegmentGroupNames: CustomSegmentGroupName[];
    customSegmentGroupNamesAreValid: boolean;
    customSegmentGroupNamesAreModified: boolean;
}

export function useEditCustomSegmentNames(assetClassesProp: AssetClassDataModel[]): UseEditCustomSegmentNames {
    const [assetClasses, setAssetClasses] = useState(cloneDeep(assetClassesProp));

    const allSegments = useMemo(() => assetClasses.flatMap(ac => ac.segments), [assetClasses]);
    const allSegmentGroups = useMemo(() => assetClasses.flatMap(ac => ac.segmentGroups), [assetClasses]);

    const customSegmentNames: CustomSegmentName[] = useMemo(() =>
        allSegments.reduce<CustomSegmentName[]>((acc, segment) => {
            if (segment.name !== segment.originalName) {
                acc.push({
                    segmentId: segment.id,
                    name: segment.name,
                });
            }
            return acc;
        }, [])
    , [allSegments]);

    const customSegmentGroupNames: CustomSegmentGroupName[] = useMemo(() =>
        allSegmentGroups.reduce<CustomSegmentGroupName[]>((acc, segmentGroup) => {
            if (segmentGroup.name !== segmentGroup.originalName) {
                acc.push({
                    segmentGroupId: segmentGroup.id,
                    name: segmentGroup.name,
                });
            }
            return acc;
        }, [])
    , [allSegmentGroups]);

    const [originalCustomSegmentNames] = useState(customSegmentNames);
    const [originalCustomSegmentGroupNames] = useState(customSegmentGroupNames);

    const customSegmentNamesAreValid = useMemo(() =>
        customSegmentNames.every(csn => hasContent(csn.name))
    , [customSegmentNames]);

    const customSegmentGroupNamesAreValid = useMemo(() =>
        customSegmentGroupNames.every(csgn => hasContent(csgn.name))
    , [customSegmentGroupNames]);

    const customSegmentNamesAreModified = useMemo(() =>
        xorWith(
            customSegmentNames,
            originalCustomSegmentNames,
            (a, b) => a.name === b.name && a.segmentId === b.segmentId,
        ).length > 0
    , [customSegmentNames, originalCustomSegmentNames]);

    const customSegmentGroupNamesAreModified = useMemo(() =>
        xorWith(
            customSegmentGroupNames,
            originalCustomSegmentGroupNames,
            (a, b) => a.name === b.name && a.segmentGroupId === b.segmentGroupId,
        ).length > 0
    , [customSegmentGroupNames, originalCustomSegmentGroupNames]);

    const setSegment = useCallback((segmentId: number, newSegment: SegmentDataModel) => {
        const newAssetClasses = assetClasses.slice();
        const assetClass = newAssetClasses.find(ac => ac.segments.some(s => s.id === segmentId));
        if (assetClass) {
            const newSegments = assetClass.segments.slice();
            const segmentIndex = newSegments.findIndex(s => s.id === segmentId);
            if (segmentIndex !== -1) {
                newSegments[segmentIndex] = newSegment;
            }
            assetClass.segments = newSegments;
        }
        setAssetClasses(newAssetClasses);
    }, [assetClasses]);

    const setSegmentGroup = useCallback((segmentGroupId: number, newSegmentGroup: SegmentGroupDataModel) => {
        const newAssetClasses = assetClasses.slice();
        const assetClass = newAssetClasses.find(ac => ac.segmentGroups.some(sg => sg.id === segmentGroupId));
        if (assetClass) {
            const newSegmentGroups = assetClass.segmentGroups.slice();
            const segmentGroupIndex = newSegmentGroups.findIndex(sg => sg.id === segmentGroupId);
            if (segmentGroupIndex !== -1) {
                newSegmentGroups[segmentGroupIndex] = newSegmentGroup;
            }
            assetClass.segmentGroups = newSegmentGroups;
        }
        setAssetClasses(newAssetClasses);
    }, [assetClasses]);

    const setCustomSegmentName = useCallback((segmentId: number, customName: string) => {
        const existingSegment = allSegments.find(s => s.id === segmentId);
        if (existingSegment) {
            setSegment(segmentId, {
                ...existingSegment,
                name: customName,
            });
        }
    }, [allSegments, setSegment]);

    const setCustomSegmentGroupName = useCallback((segmentGroupId: number, customName: string) => {
        const existingSegmentGroup = allSegmentGroups.find(sg => sg.id === segmentGroupId);
        if (existingSegmentGroup) {
            setSegmentGroup(segmentGroupId, {
                ...existingSegmentGroup,
                name: customName,
            });
        }
    }, [allSegmentGroups, setSegmentGroup]);

    const revertCustomSegmentName = useCallback((segmentId: number) => {
        const existingSegment = allSegments.find(s => s.id === segmentId);
        if (existingSegment) {
            setSegment(segmentId, {
                ...existingSegment,
                name: existingSegment.originalName,
            });
        }
    }, [allSegments, setSegment]);

    const revertCustomSegmentGroupName = useCallback((segmentGroupId: number) => {
        const existingSegmentGroup = allSegmentGroups.find(sg => sg.id === segmentGroupId);
        if (existingSegmentGroup) {
            setSegmentGroup(segmentGroupId, {
                ...existingSegmentGroup,
                name: existingSegmentGroup.originalName,
            });
        }
    }, [allSegmentGroups, setSegmentGroup]);

    const formatCustomSegmentNames = useCallback(() => {
        customSegmentNames.forEach(({ segmentId, name }) => {
            const trimmed = name.trim();
            if (trimmed !== name) {
                setCustomSegmentName(segmentId, trimmed);
            }
        });
    }, [customSegmentNames, setCustomSegmentName]);

    const formatCustomSegmentGroupNames = useCallback(() => {
        customSegmentGroupNames.forEach(({ segmentGroupId, name }) => {
            const trimmed = name.trim();
            if (trimmed !== name) {
                setCustomSegmentGroupName(segmentGroupId, trimmed);
            }
        });
    }, [customSegmentGroupNames, setCustomSegmentGroupName]);

    return {
        assetClasses,
        customSegmentNames,
        customSegmentNamesAreValid,
        customSegmentNamesAreModified,
        editCustomSegmentNamesProps: {
            setCustomSegmentName,
            revertCustomSegmentName,
            formatCustomSegmentNames,
            setCustomSegmentGroupName,
            revertCustomSegmentGroupName,
            formatCustomSegmentGroupNames,
        },
        customSegmentGroupNames,
        customSegmentGroupNamesAreValid,
        customSegmentGroupNamesAreModified,
    };
}