import { ValeoSelect } from "components/shared/ValeoSelect";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { DataTableRow, DataTableCell, Tooltip } from "rmwc";
import { hasContent } from "utils/StringUtils";
import type { PossessionRowProps, SelectionProps } from "./MoreVisionInfoPage";
import styles from "./VisionAccountRow.module.scss";

export function VisionAccountRow(props: PossessionRowProps & SelectionProps) {
    const { possession, addSelections, removeSelections, accountTypes } = props;
    const testId = `account-${possession.account}`;

    const accountTypeNames = useMemo(() => accountTypes.map(accountType => accountType.name), [accountTypes]);

    if (possession.custodian === undefined) {
        possession.custodian = possession.location;
    }

    const onOwnerChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        removeSelections([possession]);
        possession.owner = evt.target.value;
        addSelections([possession]);
    }, [addSelections, possession, removeSelections]);

    const onCustodianChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        removeSelections([possession]);
        possession.custodian = evt.target.value;
        addSelections([possession]);
    }, [addSelections, possession, removeSelections]);

    const onSelectType = useCallback((optionIndex: number) => {
        removeSelections([possession]);
        possession.accountType = accountTypes[optionIndex];
        addSelections([possession]);
    }, [accountTypes, addSelections, possession, removeSelections]);

    const [showTooltip, setShowTooltip] = useState(false);

    return <DataTableRow
        className={styles.possessionRow}
        data-testid={testId}
    >
        <DataTableCell className={styles.accountInfo}>
            <div className={styles.account}>{possession.name}</div>
            <div className={styles.owner}>{possession.owner}</div>
        </DataTableCell>
        <DataTableCell className={styles.accountNumber}>{possession.account}</DataTableCell>
        <DataTableCell className={styles.textField}>
            <ValeoTextField
                value={possession.owner}
                label="Owner"
                onChange={onOwnerChange}
                data-testid={`${testId}-owner`}
            />
        </DataTableCell>
        <DataTableCell className={styles.textField}>
            <ValeoTextField
                value={possession.custodian ?? ""}
                label="Custodian"
                onChange={onCustodianChange}
                data-testid={`${testId}-custodian`}
            />
        </DataTableCell>
        <DataTableCell className={styles.chooseType}>
            <div
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <ValeoSelect
                    label="Choose Type"
                    value={possession.accountType?.name ?? ""}
                    options={accountTypeNames}
                    onSelectIndex={onSelectType}
                    onMouseEnter={() => setShowTooltip(false)}
                    hideFloatingLabel={true}
                    data-testid={`${testId}-choose-type`}
                />
                <Tooltip
                    content={possession.accountType?.name ?? <div />}
                    open={showTooltip && hasContent(possession.accountType?.name)}
                    align="bottom"
                >
                    <div className={styles.tooltipDiv} />
                </Tooltip>
            </div>
        </DataTableCell>
    </DataTableRow>;
}