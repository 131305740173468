import classnames from "classnames";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { FormattedOption } from "rmwc";
import { ValeoSelect } from "../ValeoSelect";
import sharedStyles from "./EditModelPortfolioControls.module.scss";
import styles from "./EditModelPortfolioControlsForCustomPortfolio.module.scss";
import { ValeoTextField } from "../ValeoTextField";
import { ModelPortfolioSelect } from "./ModelPortfolioSelect";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomModelPortfolioDataModel, ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";
import { hasContent, toTestId } from "utils/StringUtils";
import { createModelPortfolioViewModel, ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";

export interface EditModelPortfolioControlsForCustomPortfolioProps {
    modelPortfolio: ModelPortfolioViewModel;
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    customModelPortfolio: CustomModelPortfolioDataModel;
    patchTargets: (targetsUpdates: Partial<TargetsDataModel>) => void;
    patchCustomModelPortfolio: (cmpUpdates: Partial<CustomModelPortfolioDataModel>) => void;
    readonly?: boolean;
}

export function EditModelPortfolioControlsForCustomPortfolio(props: EditModelPortfolioControlsForCustomPortfolioProps) {
    const { assetClasses, patchCustomModelPortfolio } = props;

    const [resetBaseSelectCounter, setResetBaseSelectCounter] = useState(0);

    const setCustomName = useCallback((event: FormEvent<HTMLInputElement>) => {
        patchCustomModelPortfolio({ name: event.currentTarget.value });
    }, [patchCustomModelPortfolio]);

    const baseModelPortfolioName = useMemo(() => props.modelPortfolios.find(mp => mp.id === props.customModelPortfolio.baseModelPortfolioId)?.name ?? "", 
        [props.modelPortfolios, props.customModelPortfolio.baseModelPortfolioId]);

    const baseIds = useMemo(() => props.modelPortfolios.map(({ id }) => id), [props.modelPortfolios]);

    const onSelectBase = useCallback((optionIndex: number) => {
        const baseModelPortfolioId = optionIndex < baseIds.length
            ? baseIds[optionIndex]
            : null;
        if ((baseModelPortfolioId) === null) {
            setResetBaseSelectCounter((value) => value + 1);
            patchCustomModelPortfolio({ baseModelPortfolioId });
        } else {
            const baseModelPortfolio = props.modelPortfolios.find((mp) => mp.id === baseModelPortfolioId);
            if (baseModelPortfolio) {
                const baseViewModel = createModelPortfolioViewModel({ modelPortfolio: baseModelPortfolio }, props.modelPortfolios, assetClasses);
                patchCustomModelPortfolio({
                    baseModelPortfolioId,
                    customSegmentTargets: assetClasses.flatMap((ac) => ac.segments.map(segment => ({
                        targetPercentage: baseViewModel.getTargetForSegment(segment.id),
                        segmentId: segment.id,
                    }))),
                    customSegmentGroupTargets: assetClasses.flatMap((ac) => ac.segmentGroups.map(group => ({
                        targetPercentage: baseViewModel.getTargetForSegmentGroup(group.id),
                        segmentGroupId: group.id,
                    }))),
                });
            }
        }
    }, [baseIds, patchCustomModelPortfolio, props.modelPortfolios, assetClasses]);

    const baseOptions: FormattedOption[] = useMemo(() =>
        props.modelPortfolios.map(({ name }, i) => ({
            label: <div data-testid={`base-${toTestId(name)}`}>{name}</div>,
            value: name,
        })).concat({
            label: <div
                className={styles.noBaseOption}
                data-testid="no-base"
            >No Base</div>,
            value: "No Base",
        })
    , [props.modelPortfolios]);

    return <div className={sharedStyles.elevation}>
        <div className={sharedStyles.controlsContainer}>
            <div className={sharedStyles.leftControls}>
                <div className={sharedStyles.leftControlsTopLevel}>
                    <ModelPortfolioSelect
                        modelPortfolio={props.modelPortfolio}
                        modelPortfolios={props.modelPortfolios}
                        patchTargets={props.patchTargets}
                        readonly={props.readonly}
                    />
                    <div className={styles.leftMargin16}>
                        <ValeoSelect
                            key={resetBaseSelectCounter} // key hack better achieves desired "clean reset" than creative formatting of selected option, and selection of No Base should be infrequent enough to allow for this forced re-mounting
                            data-testid="cmp-base"
                            label="Choose Base (Optional)"
                            options={baseOptions}
                            value={baseModelPortfolioName}
                            onSelectIndex={onSelectBase}
                            disabled={props.readonly}
                            className={
                                classnames(styles.select, {
                                    [styles.nonEmptyBase]: hasContent(baseModelPortfolioName)})
                            }
                        />
                    </div>
                    <ValeoTextField
                        data-testid="cmp-name"
                        label="Name (Optional)"
                        value={props.customModelPortfolio.name}
                        disabled={props.readonly}
                        className={
                            classnames(styles.customName, styles.leftMargin16, {
                                [styles.nonEmptyName]: hasContent(props.customModelPortfolio.name)
                            })
                        }
                        onInput={setCustomName}
                        maxLength={30}
                    />
                </div>
                <div className={sharedStyles.leftControlsHelpText}>This portfolio will not show recommended fund suggestions.</div>
            </div>
        </div>
    </div>;
}
