import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function usePropWatch<T>(prop: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
    const [value, setValue] = useState(prop);

    useEffect(() => {
        setValue(prop);
    }, [prop]);

    return [value, setValue];
}