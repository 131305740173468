import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { AccountDataModel } from "dataModels/accountDataModel";
import { IasDataModel } from "../dataModels/iasDataModel";
import { deleteWithAuthAndBody, postWithAuth, putWithAuth } from "./apiService";
import { IasModifiedResponseDataModel, iasQueryKey, WritablePosition } from "./iasService";
import { useCommonErrorDetection } from "./dataErrorService";
import { PositionDataModel } from "dataModels/positionDataModel";

export interface AccountPosition extends WritablePosition {
    accountId: number;
    lastModifiedDate: Date;
}

export interface CreatePositionResponse {
    id: number;
    dateModified: Date;
    lastModifiedBy: string;
    securityId: number;
}

const post = async (accountPosition: AccountPosition): Promise<CreatePositionResponse> => {
    return await postWithAuth("/position", accountPosition);
};

export function usePositionCreation(iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((accountPosition: AccountPosition) => post(accountPosition), {
        onMutate: async (accountPosition) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldAccount = getCachedAccount(cachedIas, accountPosition.accountId);

            const newPositions = oldAccount.positions.slice();
            newPositions.push({ ...accountPosition, id: -Date.now() });

            const newAccount = { ...oldAccount, positions: newPositions };

            const newAccounts = cachedIas.accounts.slice();
            newAccounts[newAccounts.indexOf(oldAccount)] = newAccount;

            const newSecurites = cachedIas.securities.slice();
            if (accountPosition.newSecurity)
            {
                const newSecurity = {
                    id: accountPosition.newSecurity.tempId,
                    tickerSymbol: accountPosition.newSecurity.tickerSymbol,
                    positionName: accountPosition.newSecurity.positionName,
                    segmentId: accountPosition.newSecurity.segmentId,
                    blendedFundPercentages: accountPosition.newSecurity.blendedFundPercentages,
                };
                newSecurites.push(newSecurity);
            }

            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                accounts: newAccounts,
                securities: newSecurites,
            });

            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (response, {accountId, securityId, newSecurity }) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldAccount = getCachedAccount(cachedIas, accountId);

            const newSecurites = cachedIas.securities.slice();
            let oldPosition: PositionDataModel | undefined;
            if (newSecurity) {
                const oldSecurity = newSecurites.find(security => security.id === newSecurity.tempId);
                if (oldSecurity) {
                    const newItem = { ...oldSecurity, id: response.securityId };
                    newSecurites[newSecurites.indexOf(oldSecurity)] = newItem;

                    oldPosition = oldAccount.positions.find(position => position.securityId === newSecurity.tempId);
                }
            } else {
                oldPosition = oldAccount.positions.find(position => position.securityId === securityId);
            }

            if (oldPosition) {
                const newPosition = { ...oldPosition, id: response.id, securityId: response.securityId };

                const newPositions = oldAccount.positions.slice();
                newPositions[newPositions.indexOf(oldPosition)] = newPosition;

                const newAccount = { ...oldAccount, positions: newPositions };

                const newAccounts = cachedIas.accounts.slice();
                newAccounts[newAccounts.indexOf(oldAccount)] = newAccount;

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    accounts: newAccounts,
                    securities: newSecurites,
                    dateModified: new Date(response.dateModified),
                    lastModifiedUser: { name: response.lastModifiedBy },
                });
            } else {
                throw new Error(`Expected account number ${accountId} to include position with securityId "${securityId}".`);
            }
        },
    });
}

const put = async (positionId: number, info: PositionInformation): Promise<IasModifiedResponseDataModel> => {
    return await putWithAuth(`/position/${positionId}`, info);
};

const putPositions = async(info: MultiplePositionInformation): Promise<IasModifiedResponseDataModel> => {
    return await putWithAuth("/position", info);
};
 
export interface PositionInformation {
    overwrittenCurrentValue?: number,
    change?: number,
    internalComment?: string,
    clientComment?: string,
    lastModifiedDate: Date
}

export function usePositionMutation(positionId: number, accountId: number, iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: PositionInformation) => put(positionId, info), {
        onMutate: async (info) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldAccount = getCachedAccount(cachedIas, accountId);

            const oldPosition = oldAccount.positions.find(position => position.id === positionId);
            if (oldPosition) {
                const newPosition = { ...oldPosition, ...info };

                const newPositions = oldAccount.positions.slice();
                newPositions[newPositions.indexOf(oldPosition)] = newPosition;

                const newAccount = { ...oldAccount, positions: newPositions };

                const newAccounts = cachedIas.accounts.slice();
                newAccounts[newAccounts.indexOf(oldAccount)] = newAccount;

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    accounts: newAccounts,
                });
            } else {
                throw new Error(`Expected account number ${accountId} to include position with id ${positionId} but found none.`);
            }

            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);

            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: { name: response.lastModifiedBy },
            });
        },
    });
}

export function getCachedIas(queryClient: QueryClient, iasId: number): IasDataModel {
    const cachedIas = queryClient.getQueryData<IasDataModel>(iasQueryKey(iasId));
    if (cachedIas) {
        return cachedIas;
    } else {
        throw new Error(`Expected QueryProvider ['ias', ${iasId}] cache item but found none.`);
    }
}

function getCachedAccount(ias: IasDataModel, accountId: number): AccountDataModel {
    const cachedAccount = ias.accounts.find(account => account.id === accountId);
    if (cachedAccount) {
        return cachedAccount;
    } else {
        throw new Error(`Expected QueryProvider ['ias', ${ias.id}] cache item to include account with id ${accountId} but found none.`);
    }
}
interface PositionInfoWithId {
    id: number;
    change?: number;
    internalComment?: string;
    clientComment?: string;
}

export interface MultiplePositionInformation {
    iasId: number;
    lastModifiedDate: Date;
    positions: PositionInfoWithId[];
}

export function usePositionsMutation(accountId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: MultiplePositionInformation) => putPositions(info), {
        onMutate: async (info) => {
            const cachedIas = queryClient.getQueryData<IasDataModel>(iasQueryKey(info.iasId));
            if (cachedIas) {
                const oldAccount = cachedIas.accounts.find(account => account.id === accountId);
                if (oldAccount) {
                    const newPositions = oldAccount.positions.slice();
                    info.positions.forEach((position) => {
                        const index = newPositions.findIndex(p => p.id === position.id);
                        newPositions[index] = {...newPositions[index], ...position}; 
                    });
                    const newAccount = {...oldAccount, positions: newPositions};
                    const newAccounts = cachedIas.accounts.slice();
                    newAccounts[newAccounts.indexOf(oldAccount)] = newAccount;
                    queryClient.setQueryData(iasQueryKey(info.iasId), {...cachedIas, accounts: newAccounts});
                } else {
                    throw new Error(`Expected QueryProvider ['ias', ${info.iasId}] cache item to include account with id ${accountId} but found none.`);
                }
            } else {
                throw new Error(`Expected QueryProvider ['ias', ${info.iasId}] cache item but found none.`);
            }
            return { cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (data, info) => {
            const cached = queryClient.getQueryData<IasDataModel>(iasQueryKey(info.iasId));
            if (cached) {
                queryClient.setQueryData(iasQueryKey(info.iasId), { ...cached, dateModified: new Date(data.dateModified), lastModifiedUser: {name: data.lastModifiedBy} });
            }
        },
    });
}

export interface DeletePositionInformation {
    lastModifiedDate: Date;
}

const deletePosition = async(positionId: number, info: DeletePositionInformation): Promise<IasModifiedResponseDataModel> => {
    return await deleteWithAuthAndBody(`/position/${positionId}`, info);
};

export function usePositionDeletion(positionId: number, accountId: number, iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: DeletePositionInformation) => deletePosition(positionId, info), {
        onMutate: async () => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldAccount = getCachedAccount(cachedIas, accountId);

            const oldPosition = oldAccount.positions.find(position => position.id === positionId);
            if (oldPosition) {
                const newPositions = oldAccount.positions.slice();
                newPositions.splice(newPositions.indexOf(oldPosition), 1);

                const newAccount = { ...oldAccount, positions: newPositions };

                const newAccounts = cachedIas.accounts.slice();
                newAccounts[newAccounts.indexOf(oldAccount)] = newAccount;

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    accounts: newAccounts,
                });
            } else {
                throw new Error(`Expected account number ${accountId} to include position with id ${positionId} but found none.`);
            }

            return { cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);

            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: { name: response.lastModifiedBy },
            });
        },
    });
}