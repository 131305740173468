import { TargetsDataModel } from "../dataModels/targetsDataModel";

export enum ModelPortfolioChangeType {
    None = "None",
    Values = "Values",
    TargetsOnly = "TargetsOnly",
}

export function modelHasChanged(targets: TargetsDataModel, originalTargets: TargetsDataModel): ModelPortfolioChangeType {
    if (modelHasValueChange(targets, originalTargets)) {
        return ModelPortfolioChangeType.Values;
    }
    if (targetsOnlyChange(targets, originalTargets)) {
        return ModelPortfolioChangeType.TargetsOnly;
    }
    return ModelPortfolioChangeType.None;
}

function modelHasValueChange(targets: TargetsDataModel, originalTargets: TargetsDataModel): boolean {
    const carveOutsUsed = targets.alternativesTarget !== 0;

    const modelHasChanged = targets.modelPortfolio?.id !== originalTargets.modelPortfolio?.id 
        || targets.cashTarget !== originalTargets.cashTarget
        || targets.cashTargetInDollars !== originalTargets.cashTargetInDollars
        || targets.alternativesTarget !== originalTargets.alternativesTarget
        || targets.alternativesTargetInDollars !== originalTargets.alternativesTargetInDollars
        || (carveOutsUsed && (targets.cashCarveOut !== originalTargets.cashCarveOut
            || targets.fixedIncomeCarveOut !== originalTargets.fixedIncomeCarveOut
            || targets.equitiesCarveOut !== originalTargets.equitiesCarveOut));
    
    return modelHasChanged;
}

function targetsOnlyChange(targets: TargetsDataModel, originalTargets: TargetsDataModel): boolean {
    return !modelHasValueChange(targets, originalTargets) && targets.modelPortfolioTargets?.id !== originalTargets.modelPortfolioTargets?.id;
}