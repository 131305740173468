import { AssetClassNames } from "constants/assetClassNames";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SegmentDataModel } from "../dataModels/segmentDataModel";
import { AssetClassViewModel, createAssetClassViewModel } from "./AssetClassViewModel";

export interface AssetClassesViewModel {
    readonly cash: AssetClassViewModel;
    readonly fixedIncome: AssetClassViewModel;
    readonly equities: AssetClassViewModel;
    readonly alternatives: AssetClassViewModel;
    allSegments: () => SegmentDataModel[];
}

export function createAssetClassesViewModel(assetClasses: AssetClassDataModel[]): AssetClassesViewModel {
    const cash = createAssetClassViewModel(assetClasses.find(ac => ac.name === AssetClassNames.Cash) ?? generateEmptyAssetClass(AssetClassNames.Cash));
    const fixedIncome = createAssetClassViewModel(assetClasses.find(ac => ac.name === AssetClassNames.FixedIncome) ?? generateEmptyAssetClass(AssetClassNames.FixedIncome));
    const equities = createAssetClassViewModel(assetClasses.find(ac => ac.name === AssetClassNames.Equities) ?? generateEmptyAssetClass(AssetClassNames.Equities));
    const alternatives = createAssetClassViewModel(assetClasses.find(ac => ac.name === AssetClassNames.Alternatives) ?? generateEmptyAssetClass(AssetClassNames.Alternatives));

    return {
        cash,
        fixedIncome,
        equities,
        alternatives,
        allSegments: () => [cash, fixedIncome, equities, alternatives].flatMap((assetClass) => assetClass.allSegments()),
    };
}

export const generateEmptyAssetClass = (name: string): AssetClassDataModel => ({
    id: -1,
    name: name,
    segments: [],
    segmentGroups: [],
});