import { Fragment, ReactNode, useEffect, useState } from "react";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomModelPortfolioDataModel, ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";
import { ValeoDialog } from "../ValeoDialog";
import { EditModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/EditModelPortfolioViewModel";
import { ModelPortfolioChangeType } from "utils/TargetsUtils";
import { EditCustomSegmentNamesProps } from "../custom-segment-name/useEditCustomSegmentNames";
import { CustomModelPortfolioEditor } from "./CustomModelPortfolioEditor";
import { ModelPortfolioEditor } from "./ModelPortfolioEditor";

interface EditModelPortfolioDialogProps extends EditCustomSegmentNamesProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onAccept: VoidFunction;
    isSaveEnabled: boolean;
    setIsSaveEnabled: (enabled: boolean) => void;
    modelPortfolio: EditModelPortfolioViewModel;
    patchTargets: (targetsUpdates: Partial<TargetsDataModel>) => void;
    patchCustomModelPortfolio: (cmpUpdates: Partial<CustomModelPortfolioDataModel>) => void;
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    extraAcceptInput?: ReactNode;
    readonly: boolean;
    setChangeType?: (value: ModelPortfolioChangeType) => void;
    customSegmentNamesAreValid: boolean;
    customSegmentNamesAreModified: boolean;
    assumedTotalPortfolioValue: number;
    setPortfolioValue?: (value: number) => void;
    customSegmentGroupNamesAreValid: boolean;
    customSegmentGroupNamesAreModified: boolean;
}

export function EditModelPortfolioDialog(props: EditModelPortfolioDialogProps) {
    const { isSaveEnabled, setIsSaveEnabled, patchTargets, patchCustomModelPortfolio, setChangeType,
        customSegmentNamesAreValid, customSegmentNamesAreModified, customSegmentGroupNamesAreValid, customSegmentGroupNamesAreModified } = props;

    const [inputIsValid, setInputIsValid] = useState(true);
    const [evenMoreExtraAcceptInput, setEvenMoreExtraAcceptInput] = useState<JSX.Element | null>();

    useEffect(() => {
        if (props.readonly) {
            setIsSaveEnabled(false);
            setChangeType?.(ModelPortfolioChangeType.None);
        }
        else if (!inputIsValid || !customSegmentNamesAreValid || !customSegmentGroupNamesAreValid) {
            setIsSaveEnabled(false);
            setChangeType?.(ModelPortfolioChangeType.None);
        }
        else if (customSegmentNamesAreModified || customSegmentGroupNamesAreModified) {
            setIsSaveEnabled(true);
            setChangeType?.(ModelPortfolioChangeType.Values);
        }
        else {
            const changeType = props.modelPortfolio.hasChanged();
            setIsSaveEnabled(changeType !== ModelPortfolioChangeType.None);
            setChangeType?.(changeType);
        }
    }, [inputIsValid, props.modelPortfolio, props.readonly, setIsSaveEnabled, setChangeType, customSegmentNamesAreModified, customSegmentNamesAreValid, customSegmentGroupNamesAreValid, customSegmentGroupNamesAreModified]);
    
    const customModelPortfolio = props.modelPortfolio.customModelPortfolioInUse();
    const editor =  customModelPortfolio ?
        <CustomModelPortfolioEditor
            modelPortfolio={props.modelPortfolio}
            setEvenMoreExtraAcceptInput={setEvenMoreExtraAcceptInput}
            assetClasses={props.assetClasses}
            modelPortfolios={props.modelPortfolios}
            setInputValid={setInputIsValid}
            patchTargets={patchTargets}
            patchCustomModelPortfolio={patchCustomModelPortfolio}
            readonly={props.readonly}
            setCustomSegmentName={props.setCustomSegmentName}
            revertCustomSegmentName={props.revertCustomSegmentName}
            formatCustomSegmentNames={props.formatCustomSegmentNames}
            customModelPortfolio={customModelPortfolio}
            setCustomSegmentGroupName={props.setCustomSegmentGroupName}
            revertCustomSegmentGroupName={props.revertCustomSegmentGroupName}
            formatCustomSegmentGroupNames={props.formatCustomSegmentGroupNames}
        />
        :
        <ModelPortfolioEditor
            modelPortfolio={props.modelPortfolio}
            setEvenMoreExtraAcceptInput={setEvenMoreExtraAcceptInput}
            assetClasses={props.assetClasses}
            modelPortfolios={props.modelPortfolios}
            setInputValid={setInputIsValid}
            patchTargets={patchTargets}
            patchCustomModelPortfolio={patchCustomModelPortfolio}
            readonly={props.readonly}
            setCustomSegmentName={props.setCustomSegmentName}
            revertCustomSegmentName={props.revertCustomSegmentName}
            formatCustomSegmentNames={props.formatCustomSegmentNames}
            assumedTotalPortfolioValue={props.assumedTotalPortfolioValue}
            setPortfolioValue={props.setPortfolioValue}
            setCustomSegmentGroupName={props.setCustomSegmentGroupName}
            revertCustomSegmentGroupName={props.revertCustomSegmentGroupName}
            formatCustomSegmentGroupNames={props.formatCustomSegmentGroupNames}
        />;

    return <ValeoDialog
        title="Model Portfolio"
        width={1248}
        open={props.open}
        setOpen={props.setOpen}
        proceedDisabled={!isSaveEnabled}
        onAccept={props.onAccept}
        testId="edit-model-dialog"
        extraAcceptInput={<Fragment>{evenMoreExtraAcceptInput}{props.extraAcceptInput}</Fragment>}
        preventDefault
    >
        {editor}
    </ValeoDialog >;
}
