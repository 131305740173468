import { Fragment } from "react";
import { useDataErrorQuery, useExternalChangeDetectedMutator } from "services/dataErrorService";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { NoAccessOverlay } from "./NoAccessOverlay";
import styles from "./DataErrorDialog.module.scss";

export function DataErrorDialog() {
    const { data: dataError } = useDataErrorQuery();
    const externalChangeDetectedMutator = useExternalChangeDetectedMutator();
    if (dataError?.forbiddenError === true) {
        return <NoAccessOverlay />;
    }

    if (dataError?.loadError === true) {
        return <ConfirmationDialog 
            title="The page needs to refresh." 
            confirmButton="REFRESH"
            onConfirm={() => window.location.reload()} 
            content={<div>The system is having trouble loading data. Please refresh to try again.</div>}
            className={styles.errorDialog}
        />;
    }
    
    if (dataError?.saveError === true) {
        return <ConfirmationDialog 
            title="The page needs to refresh." 
            confirmButton="REFRESH"
            onConfirm={() => window.location.reload()} 
            content={<div>The system is having trouble saving data. Please refresh to try again.</div>}
            className={styles.errorDialog}
        />; 
    }

    if (dataError?.externalChangeError === true) {
        return <ConfirmationDialog 
            title={"You Must Refresh Due to Another Editor's Change"} 
            confirmButton="REFRESH"
            onConfirm={() => window.location.reload()} 
            onCancel={() => externalChangeDetectedMutator(false)} 
            content={
                <div>
                    <p>Another editor made a change to this IAS version since you have been viewing it. You must refresh the page in order to view the changes or to be able to make any changes.</p>
                    <p>Please refresh this IAS version now.</p>
                </div>
            }
            className={styles.errorDialog}
        /> ;
    }

    return <Fragment />;
}