import { inRange } from "lodash";
import { staticConfiguration } from "./configurationService";
import authorizationService from "./authorizationService";
import { CancellationError, CancellationToken } from "utils/CancellationToken";
import { NetworkError } from "./NetworkError";
import { isSome } from "utils/isNullOrUndefined";

export interface RequestOptions extends RequestInit {
    cancellationToken?: CancellationToken;
}

const fetchWithAuthBase = async (url: string, options?: RequestOptions): Promise<Response> => {
    const fullUrl = `${staticConfiguration.apiUrl()}/api/v1${url}`;
    const updatedOptions = { ...options };
    const controller = new AbortController();
    const signal = controller.signal;

    if (staticConfiguration.useMsal()) {
        const token = await authorizationService.getToken();
        updatedOptions.headers = {
            ...updatedOptions.headers,
            Authorization: `Bearer ${token}`,
        };
    }

    options?.cancellationToken?.register(() => controller.abort());

    try {
        const response = await fetch(fullUrl, { ...updatedOptions, signal });

        if (options?.cancellationToken?.canceled() ?? false) {
            throw new CancellationError();
        }

        if (!inRange(response.status, 200, 300)) {
            throw new NetworkError(`Request ${options?.method ?? "GET"} ${url} failed with status code ${response.status}`, response.status);
        }

        return response;
    } catch (err) {
        translateCancellationError(err, options?.cancellationToken);
    }
};

const fetchWithAuth = async <T>(url: string, options?: RequestOptions): Promise<T> => {
    try {
        const response = await fetchWithAuthBase(url, options);

        return await response.json() as T;
    } catch (err) {
        translateCancellationError(err, options?.cancellationToken);
    }
};

function translateCancellationError(err: unknown, cancellationToken: CancellationToken | undefined): never {
    if (isSome(cancellationToken) && err instanceof DOMException && err.name === "AbortError" && cancellationToken.canceled()) {
        throw new CancellationError();
    }
    throw err;
}

const setupJsonHeaders = (data: unknown, options?: RequestOptions) => {
    const updatedOptions = {
        ...options,
        method: "PUT",
        body: JSON.stringify(data)
    };
    updatedOptions.headers = {
        ...options?.headers,
        "Content-Type": "application/json",
    };
    return updatedOptions;
};

export const getWithAuth = <T>(url: string, options?: RequestOptions) => fetchWithAuth<T>(url, options);

export const putWithAuth = <T>(url: string, data: unknown, options?: RequestOptions) => {
    const updatedOptions = setupJsonHeaders(data, options);
    updatedOptions.method = "PUT";

    return fetchWithAuth<T>(url, updatedOptions);
};

export const putWithAuthAndDiscardResponse = async (url: string, data: unknown, options?: RequestOptions): Promise<void> => {
    const updatedOptions = setupJsonHeaders(data, options);
    updatedOptions.method = "PUT";

    await fetchWithAuthBase(url, updatedOptions);
};

export const postWithAuth = <T>(url: string, data: unknown, options?: RequestOptions) => {
    const updatedOptions = setupJsonHeaders(data, options);
    updatedOptions.method = "POST";

    return fetchWithAuth<T>(url, updatedOptions);
};

export const deleteWithAuth = <T>(url: string, options?: RequestOptions) => {
    const updatedOptions = { ...options };
    updatedOptions.method = "DELETE";

    return fetchWithAuth<T>(url, updatedOptions);
};

export const deleteWithAuthAndBody = <T>(url: string, data: unknown, options?: RequestOptions) => {
    const updatedOptions = setupJsonHeaders(data, options);
    updatedOptions.method = "DELETE";

    return fetchWithAuth<T>(url, updatedOptions);
};