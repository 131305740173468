import { QueryStatus, useQueryClient } from "@tanstack/react-query";
import { PopulatedBlackDiamondPortfolioTemplate } from "components/client-page/add-ias-wizard/pages/blackDiamond/PopulatedBlackDiamondPortfolioTemplate";
import { LinearProgressOverlay } from "components/shared/LinearProgressOverlay";
import { useEffect, Fragment, useState, useCallback } from "react";
import { clearClientQueries, PopulatedPortfolio, useRelationshipQuery } from "services/blackDiamondService";
import { isConnectionFailure } from "services/ConnectionFailure";
import { hasContent } from "utils/StringUtils";
import { useBlackDiamondAuthentication } from "../BlackDiamondAuthenticationTemplate";
import { BDAuthProgress } from "../BDAuthProgress";

enum LoadingText {
    BLACK_DIAMOND = "Updating Black Diamond accounts...",
    VISION = "Updating Vision assets...",
}

interface LoadingPageProps {
    clientKey: string,
    sourcePortfolioId: string | undefined,
    visionStatus: QueryStatus,
    populatedPortfolio: PopulatedPortfolio | undefined,
    setPopulatedPortfolio: Callback<PopulatedPortfolio | undefined>,
    onSuccess: VoidFunction;
    reportConnectionFailure: VoidFunction;
}

export function LoadingPage(props: LoadingPageProps) {
    const { clientKey, visionStatus, sourcePortfolioId, populatedPortfolio, setPopulatedPortfolio, onSuccess, reportConnectionFailure } = props;

    const [hasClearedBDQueries, setHasClearedBDQueries] = useState(false);
    const [loadingText, setLoadingText] = useState(LoadingText.BLACK_DIAMOND);

    const authProgress = useBlackDiamondAuthentication();
    const { status: relationshipStatus, data: relationship } = useRelationshipQuery(clientKey, authProgress === BDAuthProgress.Success);
    const queryClient = useQueryClient();

    const reportPortfolio = useCallback((portfolio: PopulatedPortfolio) => {
        setPopulatedPortfolio(portfolio);
        setLoadingText(LoadingText.VISION);
    }, [setPopulatedPortfolio]);

    useEffect(() => {
        clearClientQueries(queryClient, clientKey);
        setHasClearedBDQueries(true);
    }, [queryClient, clientKey]);

    useEffect(() => {
        if (authProgress === BDAuthProgress.Failed) {
            reportConnectionFailure();
        }
    }, [authProgress, reportConnectionFailure]);

    useEffect(() => {
        if (relationshipStatus === "success" && visionStatus === "success" && !isConnectionFailure(relationship)) {
            if (!relationship || !hasContent(sourcePortfolioId) || populatedPortfolio) {
                onSuccess();
            }
        }
        if (isConnectionFailure(relationship)) {
            reportConnectionFailure();
        }
    }, [onSuccess, populatedPortfolio, relationship, relationshipStatus, reportConnectionFailure, sourcePortfolioId, visionStatus]);

    return <Fragment>
        <LinearProgressOverlay
            text={loadingText}
            fullscreen
        />
        {
            hasContent(sourcePortfolioId) && hasClearedBDQueries && relationship && !isConnectionFailure(relationship) && loadingText === LoadingText.BLACK_DIAMOND &&
            <PopulatedBlackDiamondPortfolioTemplate
                portfolioId={sourcePortfolioId}
                relationshipId={relationship.id}
                reportPortfolio={reportPortfolio}
                reportConnectionFailure={reportConnectionFailure}
            />
        }
    </Fragment>;
}