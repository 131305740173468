import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import { IasDataModel } from "dataModels/iasDataModel";
import { usePositionDeletion } from "services/positionService";
import { AccountPositionViewModel } from "../view-models/AccountPositionViewModel";

interface DeleteConfirmationProps {
    viewModel: AccountPositionViewModel;
    ias: IasDataModel;
    deleteConfirmationOpen: boolean;
    setDeleteConfirmationOpen: BooleanCallback;
}

export default function DeleteConfirmation(props: DeleteConfirmationProps) {
    const deleteFunction = usePositionDeletion(props.viewModel.position.id, props.viewModel.account.account.id, props.ias.id);

    const deleteConfirmed = () => {
        deleteFunction.mutate({ lastModifiedDate: props.ias.dateModified });
    };

    return <ConfirmationDialog
        title="Delete position for this account?"
        content={<div>This will delete the account association with this position. You can not undo this action.</div>}
        onConfirm={() => deleteConfirmed()}
        onCancel={() => props.setDeleteConfirmationOpen(false)}
        confirmButton="Delete"
        useWarningStyling
        width={448}
    />;
}