import { NumericTextField, FormattedNumericTextFieldProps } from "./NumericTextField";
import { isNumericDecimal, isWhiteSpace } from "utils/StringUtils";
import { isNumber } from "utils/NumberUtils";

function parsePercentageValueFromDisplay(text: string): number {
    return isWhiteSpace(text)
        ? 0 // interpret blank/whitespace input as 0
        : isNumericDecimal(text)
            ? parseFloat(text)
            : NaN;
}

export function inactiveDisplayOfPercentageValue(value: number): string {
    return value.toString();
}

function activeDisplayOfPercentageValue(value: number): string {
    return value === 0
        ? "" // when active, format 0 as blank to accept user input
        : value.toString();
}

export function isPercentageValueValid(value: number): boolean {
    return isNumber(value) && value >= 0 && value <= 100;
}

export function PercentageEntry(props: FormattedNumericTextFieldProps) {
    const isValueValid = props.isValueValid ?? isPercentageValueValid;

    return <NumericTextField
        parseNumericValueFromDisplay={parsePercentageValueFromDisplay}
        inactiveDisplayOfNumericValue={inactiveDisplayOfPercentageValue}
        activeDisplayOfNumericValue={activeDisplayOfPercentageValue}
        suffix="%"
        {...props}
        isValueValid={isValueValid}
    />;
}