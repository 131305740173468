import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BlendedFundSegmentPercentage } from "dataModels/blendedFundSegmentPercentage";
import { IasDataModel } from "dataModels/iasDataModel";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";
import { postWithAuth, putWithAuth } from "./apiService";
import { useCommonErrorDetection } from "./dataErrorService";
import { IasModifiedResponseDataModel, iasQueryKey } from "./iasService";

export const securityQueryKey = (ticker: string) => ["security", ticker];

interface SecuritySearchResponse {
    security: SecurityLookupDataModel | null;
}

export const searchForSecurity = async (ticker: string): Promise<SecurityLookupDataModel | null> => {
    const result = await postWithAuth<SecuritySearchResponse>(
        "/security/search",
        {
            ticker: ticker.toUpperCase(),
        },
    );

    return result.security;
};

export function useSecurityQuery(ticker: string, enabled: boolean = true) {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(securityQueryKey(ticker), () => searchForSecurity(ticker), {
        retry: false,
        enabled,
        onError: commonErrorDetection
    });
}

export interface SecurityInformation {
    positionName?: string;
    segmentId?: number;
    blendedFundPercentages?: BlendedFundSegmentPercentage[];
    lastModifiedDate: Date;
}

const put = async (securityId: number, info: SecurityInformation): Promise<IasModifiedResponseDataModel> => {
    const {
        positionName,
        ...directlyMappingFields
    } = info;
    return await putWithAuth(`/security/${securityId}`, {
        ...directlyMappingFields,
        name: positionName,
    });
};

export function useSecurityMutation(securityId: number, iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();

    return useMutation((info: SecurityInformation) => put(securityId, (info)), {
        onMutate: async (info) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldSecurity = cachedIas.securities.find(security => security.id === securityId);
            if (oldSecurity) {
                const newSecurity: IasSecurityDataModel = {
                    ...oldSecurity,
                    ...info,
                };

                const newSecurities = cachedIas.securities.slice();
                newSecurities[newSecurities.indexOf(oldSecurity)] = newSecurity;

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    securities: newSecurities,
                });
            } else {
                throw new Error(`Expected QueryProvider ['ias', ${iasId}] cache item to include security with id ${securityId} but found none.`);
            }

            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: async (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);

            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: { name: response.lastModifiedBy },
            });
        },
    });
}

export function getCachedIas(queryClient: QueryClient, iasId: number): IasDataModel {
    const cachedIas = queryClient.getQueryData<IasDataModel>(iasQueryKey(iasId));
    if (cachedIas) {
        return cachedIas;
    } else {
        throw new Error(`Expected QueryProvider ['ias', ${iasId}] cache item but found none.`);
    }
}