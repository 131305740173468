import { DataTable, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow, Elevation } from "rmwc";
import styles from "./AssetClassTable.module.scss";
import sharedStyles from "./SharedStyles.module.scss";
import { useContext } from "react";
import NumberDisplay from "../../numeric-values/NumberDisplay";
import { AssetTablesViewModel } from "./view-models/AssetTablesViewModel";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import classnames from "classnames";

export interface TotalsTableProps {
    viewModel: AssetTablesViewModel;
}

export default function TotalsTable(props: TotalsTableProps) {
    const context = useContext(WorkspaceContext);

    const headerColor = styles.gray;

    const totalsClasses = classnames(headerColor, sharedStyles.indent_1, styles.assetTableHeader, {
        [sharedStyles.wide]: !context.sidebarOpen,
    });

    return (<Elevation
        z={2}
        wrap
    >
        <DataTable className={`${styles.asset_table} ${styles.totalsTable} ${context.sidebarOpen ? styles.narrow : styles.wide}`}>
            <DataTableContent>
                <DataTableHead>
                    <DataTableRow data-testid="totals-table-header-row">
                        <DataTableHeadCell className={totalsClasses}>
                            Total
                        </DataTableHeadCell>
                        <DataTableHeadCell className={headerColor} />
                        <DataTableHeadCell className={`${headerColor} ${sharedStyles.value_column}`}>
                            <NumberDisplay
                                className={styles.current_totals_content}
                                value={props.viewModel.currentValue()}
                            />
                        </DataTableHeadCell>
                        <DataTableHeadCell className={`${headerColor} ${sharedStyles.value_column}`}>
                            <NumberDisplay value={props.viewModel.changeValue()} />
                        </DataTableHeadCell>
                        <DataTableHeadCell className={`${headerColor} ${sharedStyles.value_column}`}>
                            <NumberDisplay value={props.viewModel.proposedValue()} />
                        </DataTableHeadCell>
                        <DataTableHeadCell className={`${headerColor}`} />
                    </DataTableRow>
                </DataTableHead>
            </DataTableContent>
        </DataTable>
    </Elevation>);
}