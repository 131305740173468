import classnames from "classnames";
import PercentageDisplay from "components/numeric-values/PercentageDisplay";
import { inactiveDisplayOfPercentageValue, isPercentageValueValid, PercentageEntry } from "components/numeric-values/PercentageEntry";
import { useNumericTextField } from "components/numeric-values/useNumericTextField";
import { SegmentDataModel } from "dataModels/segmentDataModel";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { DataTableCell } from "rmwc";
import { isSome } from "utils/isNullOrUndefined";
import { hasContent, isWhiteSpace, toTestId } from "utils/StringUtils";
import { CustomSegmentNameMenu } from "./CustomSegmentNameMenu";
import { EditCustomSegmentNamesProps } from "../custom-segment-name/useEditCustomSegmentNames";
import { ValeoTextField } from "../ValeoTextField";
import styles from "./ModelPortfolioTargetsTable.module.scss";
import editStyles from "./CustomModelPortfolioTable.module.scss";
import { SegmentGroupDataModel } from "dataModels/segmentGroupDataModel";

export interface ModelPortfolioTableSegmentOrGroupViewModel {
    readonly name: string;
    readonly targetPercentage: number;
    readonly segmentWithCustomizableName: SegmentDataModel | null;
    readonly segmentGroupWithCustomizableName: SegmentGroupDataModel | null;
    onValidChange?: (newTargetPercentage: number) => void;
}

export interface ModelPortfolioTableSegmentOrGroupCellProps extends ModelPortfolioTableSegmentOrGroupViewModel, EditCustomSegmentNamesProps {
    readonly: boolean;
    targetPercentagesAreEditable: boolean;
    setSegmentValidity?: (name: string, isValid: boolean) => void;
}

export function ModelPortfolioTableSegmentOrGroupCell(props: ModelPortfolioTableSegmentOrGroupCellProps) {
    const { setSegmentValidity, name, targetPercentage, segmentWithCustomizableName, segmentGroupWithCustomizableName, onValidChange,
        setCustomSegmentName, revertCustomSegmentName, formatCustomSegmentNames,
        setCustomSegmentGroupName, revertCustomSegmentGroupName, formatCustomSegmentGroupNames } = props;
    const targetPercentagesAreEditable = !props.readonly && props.targetPercentagesAreEditable;
    const segmentNamesAreEditable = !props.readonly;

    const target = useNumericTextField(targetPercentage, inactiveDisplayOfPercentageValue, isPercentageValueValid);

    const [isEditingCustomName, setIsEditingCustomName] = useState(false);

    const startEditingCustomName = useCallback(() => {
        setIsEditingCustomName(true);
    }, []);

    const onCustomNameInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (segmentWithCustomizableName) {
            setCustomSegmentName(segmentWithCustomizableName.id, event.currentTarget.value);
        } else if(segmentGroupWithCustomizableName) {
            setCustomSegmentGroupName(segmentGroupWithCustomizableName.id, event.currentTarget.value);
        }
    }, [segmentGroupWithCustomizableName, segmentWithCustomizableName, setCustomSegmentGroupName, setCustomSegmentName]);

    const onCustomNameBlur = useCallback(() => {
        if (hasContent(name)) {
            setIsEditingCustomName(false);
        }
        formatCustomSegmentNames();
        formatCustomSegmentGroupNames();
    }, [formatCustomSegmentGroupNames, formatCustomSegmentNames, name]);

    const revertCustomName = useCallback(() => {
        if (segmentWithCustomizableName) {
            revertCustomSegmentName(segmentWithCustomizableName.id);
        } else if(segmentGroupWithCustomizableName) {
            revertCustomSegmentGroupName(segmentGroupWithCustomizableName.id);
        }
        setIsEditingCustomName(false);
    }, [segmentWithCustomizableName, segmentGroupWithCustomizableName, revertCustomSegmentName, revertCustomSegmentGroupName]);

    useEffect(() => {
        if (setSegmentValidity) {
            setSegmentValidity(name, target.isValid);
        }
    }, [name, setSegmentValidity, target.isValid]);

    const allowCustomName = segmentNamesAreEditable && isSome(segmentWithCustomizableName ?? segmentGroupWithCustomizableName);
    const allowRevertName = allowCustomName && (segmentWithCustomizableName?.name !== segmentWithCustomizableName?.originalName || segmentGroupWithCustomizableName?.name !== segmentGroupWithCustomizableName?.originalName);

    return <DataTableCell
        className={classnames(styles.kebabCell)}
        data-testid={`${toTestId(name)}-target-cell`}
    >
        {
            isEditingCustomName
                ? <ValeoTextField
                    label=""
                    value={name}
                    onInput={onCustomNameInput}
                    onBlur={onCustomNameBlur}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    invalid={isWhiteSpace(name)}
                    className={styles.customNameTextField}
                    data-testid="custom-name-text-field"
                />
                : <div className={classnames(styles.entryName, { [styles.allowCustomName]: allowCustomName })}>
                    <div className={styles.overflowClamped}>{name}</div>
                </div>
        }
        {
            allowCustomName && <div className={styles.customNameKebab}>
                <CustomSegmentNameMenu
                    editCustomName={startEditingCustomName}
                    revertCustomName={
                        allowRevertName
                            ? revertCustomName
                            : undefined
                    }
                />
            </div>
        }
        <div className={classnames(styles.entryTarget)}>
            {
                targetPercentagesAreEditable
                    ? <PercentageEntry
                        numericValue={target.value} 
                        setNumericValue={target.setValue}
                        display={target.display}
                        setDisplay={target.setDisplay}
                        valueIsValid={target.isValid}
                        setValueIsValid={target.setIsValid}
                        onValidChange={onValidChange}
                        className={editStyles.percentageEntry}
                        data-testid={`${toTestId(name)}-target`}
                    />
                    : <PercentageDisplay
                        value={target.value}
                        precision={1}
                    />
            }
        </div>
    </DataTableCell>;
}