import { useQuery } from "@tanstack/react-query";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { getWithAuth } from "./apiService";
import { useCommonErrorDetection } from "./dataErrorService";

const get = async (): Promise<AccountTypeDataModel[]> => {
    return await getWithAuth<AccountTypeDataModel[]>("/accountType");
};

export function useAccountTypeQuery() {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(["accountTypeList"], () => get(), { 
        onError: commonErrorDetection
    });
}