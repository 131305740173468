import { DataTableCell, DataTableRow } from "rmwc";
import { Fragment, useContext } from "react";
import SegmentRow from "./SegmentRow";
import classnames from "classnames";
import { SegmentGroupViewModel } from "./view-models/SegmentGroupViewModel";
import sharedStyles from "./SharedStyles.module.scss";
import NumberDisplay from "../../numeric-values/NumberDisplay";
import { calculatePercentages } from "./Calculations";
import { ValueModel } from "../IasWorkspaceTypes";
import { AssetClassNames } from "../../../constants/assetClassNames";
import { ModelPortfolioViewModel } from "./view-models/ModelPortfolioViewModel";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import VarianceIcon from "./VarianceIcon";
import PercentageDisplay from "../../numeric-values/PercentageDisplay";
import { ExpansionType, useExpansionState } from "services/expansionStateCache";
import ExpandableToggleButton from "../../shared/ExpandableToggleButton";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { applyAccountFilter } from "../add-position/AccountSelect";

interface SegmentGroupRowProps {
    viewModel: SegmentGroupViewModel;
    overallValues: ValueModel;
    depth: number;
    ias: IasDataModel;
    assetClass?: string;
    modelPortfolio: ModelPortfolioViewModel;
    varianceClass: string;
    assetClassTarget: number;
}

export default function SegmentGroupRow(props: SegmentGroupRowProps) {
    const context = useContext(WorkspaceContext);

    const filteredByAccount = applyAccountFilter(context.filterByAccountId)
        ? props.viewModel.segments.filter(segment => segment.containsAccount(context.filterByAccountId))
        : props.viewModel.segments;
    const filtered = context.onlyChanges ? filteredByAccount.filter(segment => segment.containsChanges()) : filteredByAccount;
    const segmentsToDisplay = filtered.filter(segment => segment.includeInDisplay());

    const targetPercentage = props.modelPortfolio.getTargetForSegmentGroup(props.viewModel.group.id);
    const calculatedPercentages = calculatePercentages(props.viewModel.currentValue(), props.overallValues.currentValue(), props.viewModel.proposedValue(), props.overallValues.proposedValue(), targetPercentage);

    const labelStyle = classnames({
        [sharedStyles.redLabel]: props.assetClass === AssetClassNames.Equities,
        [sharedStyles.blueLabel]: props.assetClass === AssetClassNames.FixedIncome,
        [sharedStyles.yellowLabel]: props.assetClass === AssetClassNames.Alternatives,
    });

    const depthStyleHeader = sharedStyles[`indent_${props.depth - 1}`];
    const depthStyleFooter = sharedStyles[`indent_${props.depth}`];

    const [open, setOpen] = useExpansionState(ExpansionType.SegmentGroup, props.viewModel.group.id, true);

    return (<Fragment>
        <DataTableRow
            data-testid="segment-group-row"
            className="clickable"
            onClick={() => setOpen(!open)}
        >
            <DataTableCell className={classnames(depthStyleHeader, sharedStyles.title_cell, {[sharedStyles.wide]: !context.sidebarOpen })}>
                <div className={sharedStyles.collapsibleGroup}>
                    <ExpandableToggleButton
                        open={open}
                        setOpen={setOpen}
                    />
                    {props.viewModel.group.name}
                </div>
            </DataTableCell>
            <DataTableCell />
            <DataTableCell className={sharedStyles.value_column}>
                <div className={labelStyle}>CURRENT</div>
                <div className={sharedStyles.percentageDiv}><PercentageDisplay value={calculatedPercentages.current.percentage} /></div>
            </DataTableCell>
            <DataTableCell className={sharedStyles.value_column}>
                <div className={labelStyle}>CHANGE</div>
                <div className={sharedStyles.percentageDiv} />
            </DataTableCell>
            <DataTableCell className={sharedStyles.value_column}>
                <div className={labelStyle}>PROPOSED</div>
                <div className={sharedStyles.percentageDiv}><PercentageDisplay value={calculatedPercentages.proposed.percentage} /></div>
            </DataTableCell>
            <DataTableCell className={sharedStyles.value_column}>
                <div className={labelStyle}>TARGET</div>
                <div className={classnames(sharedStyles.target_and_variance_column, sharedStyles.percentageDiv)}>
                    <div className={classnames(sharedStyles.variance_icon, {[sharedStyles.wide]: !context.sidebarOpen })}>
                        <VarianceIcon
                            percentage={calculatedPercentages.proposedVariance.percentage}
                            value={calculatedPercentages.proposedVariance.value}
                            class={props.varianceClass}
                        />
                    </div>
                    <div><PercentageDisplay value={calculatedPercentages.target.percentage} /></div>
                </div>
            </DataTableCell>
        </DataTableRow>
        {
            open && segmentsToDisplay.map(segment => <SegmentRow
                key={segment.segment.id}
                viewModel={segment}
                overallValues={props.overallValues}
                depth={props.depth + 1}
                ias={props.ias}
                modelPortfolio={props.modelPortfolio}
                varianceClass={props.varianceClass}
                assetClassTarget={props.assetClassTarget}
            />)
        }
        <DataTableRow>
            <DataTableCell className={classnames(depthStyleFooter, sharedStyles.title_cell, {[sharedStyles.wide]: !context.sidebarOpen })}>
                {props.viewModel.group.name} Total
            </DataTableCell>
            <DataTableCell />
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}><NumberDisplay value={props.viewModel.currentValue()} /></DataTableCell>
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}><NumberDisplay value={props.viewModel.changeValue()} /></DataTableCell>
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}><NumberDisplay value={props.viewModel.proposedValue()} /></DataTableCell>
            <DataTableCell className={`${sharedStyles.value_column} ${sharedStyles.medium_weight}`}><NumberDisplay value={calculatedPercentages.target.value} /></DataTableCell>
        </DataTableRow>
    </Fragment>);
}