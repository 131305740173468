import { SortBy } from "contexts/workspaceContext";
import { SecurityDisplayViewModel } from "./view-models/SecurityDisplayViewModel";

export function sortSecurities<T extends SecurityDisplayViewModel>(viewModels: T[], sortBy: SortBy): T[] {
    switch (sortBy) {
    case SortBy.PositionName:
        return viewModels.slice().sort((a, b) => a.name.localeCompare(b.name));
    case SortBy.Current:
        return viewModels.slice().sort((a, b) => b.currentValue() - a.currentValue());
    case SortBy.Proposed:
        return viewModels.slice().sort((a, b) => b.proposedValue() - a.proposedValue());
    }
}