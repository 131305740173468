import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow, Elevation } from "rmwc";
import ArchiveGroup from "./ArchiveGroup";
import RecentGroup from "./RecentGroup";
import styles from "./IasTable.module.scss";
import { MeetingDataModel } from "../../dataModels/meetingDataModel";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

interface IasTableProps {
    meetings: MeetingDataModel[];
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    clientId: number;
    allMeetings: MeetingDataModel[];
}

export default function IasTable(props: IasTableProps) {
    const meetings = props.meetings.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const recentMeetings = meetings.slice(0, 2);
    const archivedMeetings = meetings.slice(2);

    return (
        <Elevation
            z={2}
            wrap
        >
            <DataTable className={styles.iasTable}>
                <DataTableContent>
                    <DataTableHead>
                        <DataTableRow>
                            <DataTableHeadCell
                                className={styles.title}
                                colSpan={6}
                            >Investment Allocation Summary</DataTableHeadCell>
                        </DataTableRow>
                        <DataTableRow>
                            <DataTableCell className={styles.meetingDateAndName}>Meeting date & name</DataTableCell>
                            <DataTableCell />
                            <DataTableCell className={styles.head}>State</DataTableCell>
                            <DataTableCell className={styles.head}>Date modified</DataTableCell>
                            <DataTableCell className={styles.head}>Last edited by</DataTableCell>
                            <DataTableCell className={styles.head}>Model portfolio</DataTableCell>
                        </DataTableRow>
                    </DataTableHead>

                    <DataTableBody>
                        <RecentGroup
                            meetings={recentMeetings}
                            modelPortfolios={props.modelPortfolios}
                            assetClasses={props.assetClasses}
                            clientId={props.clientId}
                            allMeetings={props.allMeetings}
                        />
                        <ArchiveGroup
                            meetings={archivedMeetings}
                            modelPortfolios={props.modelPortfolios}
                            assetClasses={props.assetClasses}
                            clientId={props.clientId}
                            allMeetings={props.allMeetings}
                        />
                    </DataTableBody>
                </DataTableContent>
            </DataTable>
        </Elevation>

    );
}