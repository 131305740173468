import { EventType } from "constants/eventType";
import { Fragment, useCallback } from "react";
import { useIasDeletion } from "services/iasService";
import { telemetry } from "services/telemetryService";
import { ConfirmationDialog } from "../shared/ConfirmationDialog";

interface DeleteConfirmationProps {
    id: number;
    clientId: number;
    setShown: (arg0: boolean) => void;
    onRemoved: VoidFunction;
}

export default function DeleteConfirmation(props: DeleteConfirmationProps) {
    const { id, clientId, setShown, onRemoved } = props;

    const deletion = useIasDeletion(id, clientId);

    const handleConfirm = useCallback(() => {
        telemetry.trackEvent(EventType.DeleteIAS);
        deletion.mutate();
        onRemoved();
    }, [deletion, onRemoved]);

    const handleCancel = useCallback(() => {
        setShown(false);
    }, [setShown]);

    return <ConfirmationDialog
        title="Delete this IAS?"
        confirmButton="Delete"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        useWarningStyling
        content={<Fragment />}
    />;
}