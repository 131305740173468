import { noAccountSelectedId } from "components/ias-workspace/add-position/AccountSelect";
import React from "react";

export enum SortBy {
    PositionName = "Position Name",
    Current = "Current",
    Proposed = "Proposed",
}

export interface WorkspaceContextProperties {
    sidebarOpen: boolean;
    onlyChanges: boolean;
    readonlyMode: boolean;
    sortBy: SortBy;
    filterByAccountId: number;
}

export const WorkspaceContext = React.createContext<WorkspaceContextProperties>({
    sidebarOpen: true,
    onlyChanges: false,
    readonlyMode: true,
    sortBy: SortBy.PositionName,
    filterByAccountId: noAccountSelectedId
});