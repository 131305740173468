import { Fragment, useEffect, useState } from "react";
import { useHoldingsQuery } from "services/blackDiamondService";
import { isConnectionFailure } from "services/ConnectionFailure";
import { isSome } from "utils/isNullOrUndefined";
import { BlackDiamondHolding } from "../../../../../dataModels/blackDiamond/holding";

interface HoldingTemplateProps {
    accountId: string;
    report: (accountId: string, holdings: BlackDiamondHolding[]) => void;
    reportConnectionFailure: VoidFunction;
}

export function HoldingTemplate(props: HoldingTemplateProps) {
    const { data: holdings } = useHoldingsQuery(props.accountId);
    const [reported, setReported] = useState(false);
    useEffect(() => {
        if (isSome(holdings) && !reported) {
            if (isConnectionFailure(holdings)) {
                props.reportConnectionFailure();
            }
            else {
                props.report(props.accountId, holdings);
            }
            setReported(true);
        }
    }, [holdings, props, reported]);

    return <Fragment />;
}
