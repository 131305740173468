import classnames from "classnames";
import { useCallback, useContext, useState } from "react";
import { usePositionMutation } from "services/positionService";
import styles from "./ChangeValueTextField.module.scss";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import { CurrencyEntry } from "../../numeric-values/CurrencyEntry";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

interface CurrentValueTextFieldProps {
    ias: IasDataModel;
    positionId: number;
    accountId: number;
    currentValue: number;
    onValidBlur?: VoidFunction;
    "data-testid"?: string;
}

export default function CurrentValueTextField(props: CurrentValueTextFieldProps) {
    const mutation = usePositionMutation(props.positionId, props.accountId, props.ias.id);
    const context = useContext(WorkspaceContext);

    const [value, setValue] = useState(props.currentValue);
    const [display, setDisplay] = useState(`${props.currentValue}`);
    const [isValid, setIsValid] = useState(true);

    const onValidChangeValueChange = useCallback((value: number) => {
        telemetry.trackEvent(EventType.EditCurrentValue);
        mutation.mutate({overwrittenCurrentValue: value, lastModifiedDate: props.ias.dateModified});
    }, [mutation, props.ias.dateModified]);

    return <CurrencyEntry
        className={
            classnames(styles.changeTextField, {
                [styles.wide]: !context.sidebarOpen,
            })
        }
        numericValue={value}
        setNumericValue={setValue}
        display={display}
        setDisplay={setDisplay}
        valueIsValid={isValid}
        setValueIsValid={setIsValid}
        onValidChange={onValidChangeValueChange}
        onValidBlur={props.onValidBlur}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        data-currentvalid={isValid}
        data-testid={props["data-testid"]}
    />;
}