import ClientTable from "../client-table/ClientTable";
import CenteredContent from "../layout/CenteredContent";
import { useClientListQuery } from "services/clientService";
import ClientListLayout from "components/layout/ClientListLayout";
import { Fragment, useState } from "react";
import { LinearProgressOverlay } from "components/shared/LinearProgressOverlay";
import { DataErrorDialog } from "components/shared/DataErrorDialog";
import { useDocumentTitle } from "utils/useDocumentTitle";
import { useClientStatusesQuery } from "services/clientStatusService";
import { combineQueryStatuses } from "utils/ReactQueryUtils";
import { filterMap } from "utils/ArrayUtils";
import { getStoredClientStatusFilters } from "services/clientStatusStorageService";

export default function ClientList() {
    const {status: clientStatusesStatus, data: clientStatuses} = useClientStatusesQuery();
    const [statusIdsInStorage, setStatusIdsInStorage] = useState(getStoredClientStatusFilters());
    const shownStatusIds = statusIdsInStorage ?? filterMap(clientStatuses ?? [], status => status.defaultToShown ? status.id : null);
    const { status: clientListStatus, data: clientList } = useClientListQuery(shownStatusIds);
    const [search, setSearch] = useState("");

    const combinedStatus = combineQueryStatuses(clientListStatus, clientStatusesStatus);

    useDocumentTitle("IAS Client List");

    return <Fragment>
        {
            clientList && clientStatuses && <ClientListLayout
                shownStatusIds={shownStatusIds}
                clientList={clientList}
            >
                <CenteredContent>
                    <ClientTable
                        initialResult={clientList}
                        clientStatuses={clientStatuses}
                        shownStatusIds={shownStatusIds}
                        setStatusIdsInStorage={setStatusIdsInStorage}
                        search={search}
                        setSearch={setSearch}
                    />
                </CenteredContent>
            </ClientListLayout>
        }
        {
            combinedStatus === "loading" &&
            <LinearProgressOverlay
                text="Loading client list..."
                fullscreen
            />
        }
        <DataErrorDialog />
    </Fragment>;
}