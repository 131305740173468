export function calculateUnroundedPercentage(numerator: number, denominator: number): number {
    if (denominator === 0 || isNaN(denominator) || isNaN(numerator)) {
        return 0;
    }

    return numerator / denominator * 100;
}

export function valueForPercentage(overallValue: number, percentage: number) {
    return Math.round(overallValue * percentage / 100);
}

export function calculateRoundedPercentage(numerator: number, denominator: number): number {
    return Math.round(calculateUnroundedPercentage(numerator, denominator));
}