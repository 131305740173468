import classnames from "classnames";
import styles from "./ValeoChip.module.scss";

interface ValeoChipProps {
    label: string;
    className?: string;
}

export function ValeoChip(props: ValeoChipProps) {
    const { label, className } = props;

    return <div className={classnames(styles.chip, className)}>
        <span className={styles.contents}>{label}</span>
    </div>;
}