import { useCallback, useEffect, useMemo, useState } from "react";
import WorkspaceHeader from "./workspace-header/WorkspaceHeader";
import RightSidebar from "./right-sidebar/RightSidebar";
import { useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import CenteredContent from "../layout/CenteredContent";
import styles from "./IasWorkspace.module.scss";
import { createAssetTables } from "./asset-table/view-models/AssetTablesViewModel";
import AssetTableTools from "./asset-table-tools/AssetTableTools";
import { SecurityTemplate } from "./asset-table/SecurityTemplate";
import { IasState, isFinal } from "constants/iasState";
import { useIasQuery } from "services/iasService";
import { useModelPortfolioQuery, useModelPortfolioReturnsQuery } from "services/modelPortfolioService";
import { SortBy, WorkspaceContext, WorkspaceContextProperties } from "../../contexts/workspaceContext";
import { clearExpansionStateCache } from "services/expansionStateCache";
import { IconButton, Snackbar, SnackbarAction } from "rmwc";
import { useClientQuery } from "services/clientService";
import { IconName } from "../../iconNames";
import { AddPosition } from "./add-position/AddPosition";
import TotalsTable from "./asset-table/TotalsTable";
import { LinearProgressOverlay } from "components/shared/LinearProgressOverlay";
import { combineQueryStatuses } from "utils/ReactQueryUtils";
import { IasDataModel } from "dataModels/iasDataModel";
import { ClientDataModel } from "dataModels/clientDataModel";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";
import { useAccountTypeQuery } from "services/accountTypeService";
import { useDocumentTitle } from "utils/useDocumentTitle";
import { hasContent } from "utils/StringUtils";
import AssetClassTable from "./asset-table/AssetClassTable";
import { RecommendedFundsFullList } from "./recommended-funds/RecommendedFundsFullList";
import { noAccountSelectedId } from "./add-position/AccountSelect";

function IasWorkspace() {
    const { id } = useParams();
    const iasId = parseInt(id ?? "-1");

    const { status: iasStatus, data: iasData } = useIasQuery(iasId);
    const { status: clientStatus, data: clientData } = useClientQuery(iasData?.clientId ?? null);
    const { status: modelPortfolioStatus, data: modelPortfoliosData } = useModelPortfolioQuery();
    const { status: modelPortfolioReturnsStatus, data: modelPortfolioReturnsData } = useModelPortfolioReturnsQuery();
    const { status: accountTypeStatus, data: accountTypes } = useAccountTypeQuery();

    const [addPositionModalOpen, setAddPositionModalOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [onlyChanges, setOnlyChanges] = useState(false);
    const [sortBy, setSortBy] = useState(SortBy.PositionName);
    const [filterByAccountId, setFilterByAccountId] = useState<number>(noAccountSelectedId);
    const [snackbarOpen] = useState(localStorage.getItem("snackbarOpen") === "true");

    const contextProperties: WorkspaceContextProperties = {
        sidebarOpen: sidebarOpen,
        onlyChanges: onlyChanges,
        readonlyMode: true,
        sortBy: sortBy,
        filterByAccountId: filterByAccountId
    };

    useDocumentTitle(hasContent(clientData?.key) ? `IAS ${clientData?.key} - ${clientData?.reportingName}` : "IAS");

    useEffect(() => {
        return () => {
            clearExpansionStateCache();
        };
    }, []);

    const onClickFab = useCallback(() => {
        setAddPositionModalOpen(true);
    }, []);

    const modelPortfolios = useMemo(() => modelPortfoliosData ?? [], [modelPortfoliosData]);
    const modelPortfolioReturns = useMemo(() => modelPortfolioReturnsData ?? [], [modelPortfolioReturnsData]);
    const ias = useMemo(() => iasData ?? defaultIasDataModel(), [iasData]);
    const client = useMemo(() => clientData ?? defaultClient(), [clientData]);

    const combinedStatus = combineQueryStatuses(iasStatus, clientStatus, modelPortfolioStatus, modelPortfolioReturnsStatus, accountTypeStatus);

    contextProperties.readonlyMode = isFinal(ias.state);

    const assetTableViewModel = useMemo(() => createAssetTables(ias.assetClasses, modelPortfolios, ias), [ias, modelPortfolios]);
    const { modelPortfolio } = assetTableViewModel;

    const recommendedFundSecurities: SecurityLookupDataModel[] = useMemo(() => [], []);

    const notifySecurity = useCallback((security: SecurityLookupDataModel) => {
        recommendedFundSecurities.push(security);
    }, [recommendedFundSecurities]);

    return <WorkspaceContext.Provider value={contextProperties}>
        <Layout
            clientKey={client.key}
            clientReportingName={client.reportingName}
            clientId={client.id}
        >
            <CenteredContent>
                <div className={styles.fabContainer}>
                    {
                        !contextProperties.readonlyMode && <IconButton
                            icon={IconName.Add}
                            onClick={onClickFab}
                            className={styles.fab}
                            data-testid="add-position-fab"
                        />
                    }
                </div>
                <div>
                    <WorkspaceHeader
                        ias={ias}
                        modelPortfolio={modelPortfolio}
                        reportingName={client.reportingName}
                        modelPortfolios={modelPortfolios}
                        modelPortfolioReturns={modelPortfolioReturns}
                        client={client}
                    />
                    <AssetTableTools
                        setOnlyChanges={setOnlyChanges}
                        setSortBy={setSortBy}
                        setFilterByAccountId={setFilterByAccountId}
                        viewModel={assetTableViewModel}
                        accounts={ias.accounts}
                    />
                    <div className={styles.assetTableContainer}>
                        <div 
                            className={styles.assetClassTables}
                            data-testid="asset-class-tables"
                        >
                            <AssetClassTable
                                assetTableViewModel={assetTableViewModel}
                                assetClass={assetTableViewModel.cash}
                                ias={ias}
                            />
                            <AssetClassTable
                                assetTableViewModel={assetTableViewModel}
                                assetClass={assetTableViewModel.fixedIncome}
                                ias={ias}
                            />
                            <AssetClassTable
                                assetTableViewModel={assetTableViewModel}
                                assetClass={assetTableViewModel.equities}
                                ias={ias}
                            />
                            <AssetClassTable
                                assetTableViewModel={assetTableViewModel}
                                assetClass={assetTableViewModel.alternatives}
                                ias={ias}
                            />
                            <TotalsTable viewModel={assetTableViewModel} />
                            <RecommendedFundsFullList viewModel={assetTableViewModel} />
                        </div>
                        <RightSidebar
                            viewModel={assetTableViewModel}
                            setSidebarOpen={setSidebarOpen}
                            iasId={ias.id}
                            lastModifiedDate={ias.dateModified}
                            accountTypes={accountTypes ?? []}
                        />
                    </div>
                </div>
                {
                    addPositionModalOpen && <AddPosition
                        open={addPositionModalOpen}
                        setOpen={setAddPositionModalOpen}
                        assetTableViewModel={assetTableViewModel}
                        recommendedFundSecurities={recommendedFundSecurities}
                        assetClasses={ias.assetClasses}
                        accountTypes={accountTypes ?? []}
                    />
                }
                {
                    snackbarOpen && <Snackbar
                        className={styles.snackbar}
                        message="You are now viewing a draft copy."
                        onOpen={()=>localStorage.setItem("snackbarOpen", "false")}
                        dismissesOnAction
                        open={snackbarOpen}
                        action={<SnackbarAction label="Dismiss"/>}
                    />
                }
            </CenteredContent>
        </Layout>
        {
            combinedStatus === "loading" &&
            <LinearProgressOverlay
                text="Loading workspace..."
                fullscreen
            />
        }
        {
            modelPortfolio.recommendedFunds().map((target) => <SecurityTemplate
                key={target.ticker}
                ticker={target.ticker}
                notifySecurity={notifySecurity}
            />)
        }
    </WorkspaceContext.Provider>;
}

function defaultClient() {
    const client: ClientDataModel = {
        id: 0,
        key: "",
        meetings: [],
        assetClasses: [],
        reportingName: ""
    };
    return client;
}

function defaultIasDataModel() {
    const ias: IasDataModel = {
        id: 0,
        title: "",
        state: IasState.Draft,
        accounts: [],
        securities: [],
        customGroups: [],
        valeoNotes: "",
        clientNotes: "",
        clientId: 0,
        cashTarget: 0,
        cashTargetInDollars: 0,
        alternativesTarget: 0,
        alternativesTargetInDollars: 0,
        cashCarveOut: 0,
        fixedIncomeCarveOut: 0,
        equitiesCarveOut: 0,
        dateModified: new Date(),
        meetingDate: new Date(),
        lastModifiedUser: {name: "", identity: ""},
        dateRefreshed: new Date(),
        showAltTargets: false,
        assetClasses: [],
        reportTitle: ""
    };
    return ias;
}

export default IasWorkspace;
