import { QueryStatus } from "@tanstack/react-query";
import { NumericTextFieldHooks } from "components/numeric-values/useNumericTextField";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { Fragment, useState } from "react";
import { AccountForCreation } from "services/accountService";
import { AddNewAccount } from "./AddNewAccount";
import { AddPositionInfoControls } from "./AddPositionInfoControls";
import { BlendedFundControls, BlendedFundPercentage } from "../../shared/blended-fund/BlendedFundControls";
import { noAccountSelectedId } from "./AccountSelect";
import sharedStyles from "./AddPositionModal.module.scss";
import styles from "./AddBlendedPositionControls.module.scss";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { hasContent } from "utils/StringUtils";

export interface AddBlendedPositionControlsProps {
    assetTableViewModel: AssetTablesViewModel;
    assetClasses: AssetClassDataModel[];
    ticker: string;
    setTicker: FunctionStringCallback;
    nameResultForTicker: string;
    manualName: string;
    setManualName: FunctionStringCallback;
    accountId: number | null;
    setAccountId: Callback<number | null>;
    currentValue: NumericTextFieldHooks;
    segmentId: number | null;
    setSegmentId: Callback<number | null>;
    isSegmentSelectDisabled: boolean;
    securityStatus: QueryStatus;
    shouldClearControls: boolean;
    setShouldClearControls: BooleanCallback;
    accountTypes: AccountTypeDataModel[];
    newAccount: AccountForCreation;
    setNewAccount: Callback<AccountForCreation>;
    percentages: BlendedFundPercentage[];
    setPercentages: (percentages: BlendedFundPercentage[]) => void;
    setIsValid: (isValid: boolean) => void;
    setErrorText: (errorText: string) => void;
}

export function AddBlendedPositionControls(props: AddBlendedPositionControlsProps) {
    const {
        assetTableViewModel,
        assetClasses,
        ticker, setTicker,
        nameResultForTicker,
        manualName, setManualName,
        accountId, setAccountId,
        currentValue,
        segmentId, setSegmentId,
        isSegmentSelectDisabled,
        securityStatus,
        shouldClearControls, setShouldClearControls,
        accountTypes,
        newAccount, setNewAccount,
        percentages, setPercentages,
        setIsValid,
    } = props;
    const name = hasContent(nameResultForTicker) ? nameResultForTicker : manualName;

    const [percentageAssigned, setPercentageAssigned] = useState(0);

    return <Fragment>
        <AddPositionInfoControls
            assetTableViewModel={assetTableViewModel}
            assetClasses={assetClasses}
            ticker={ticker}
            setTicker={setTicker}
            nameResultForTicker={nameResultForTicker}
            manualName={manualName}
            setManualName={setManualName}
            accountId={accountId}
            setAccountId={setAccountId}
            currentValue={currentValue}
            segmentId={segmentId}
            setSegmentId={setSegmentId}
            isSegmentSelectDisabled={isSegmentSelectDisabled}
            securityStatus={securityStatus}
            shouldClearControls={shouldClearControls}
            setShouldClearControls={setShouldClearControls}
            disabled
        />
        {
            accountId === noAccountSelectedId && <AddNewAccount
                accountTypes={accountTypes}
                newAccount={newAccount}
                setNewAccount={setNewAccount}
                disabled
            />
        }
        <div className={styles.blendedControlsContainer}>
            <div className={sharedStyles.header}>Blended Asset Class</div>
            <BlendedFundControls
                securityTicker={ticker}
                securityName={name}
                assetClasses={assetClasses}
                percentages={percentages}
                setPercentages={setPercentages}
                percentageAssigned={percentageAssigned}
                setPercentageAssigned={setPercentageAssigned}
                setIsValid={setIsValid}
            />
        </div>
    </Fragment>;
}