export enum IconName {
    Add = "add",
    ArrowBack = "arrow_back",
    ArrowForward = "arrow_forward",
    Assignment = "assignment",
    Close = "close",
    Comment = "comment",
    Error = "error",
    ExpandLess = "expand_less",
    ExpandMore = "expand_more",
    FirstPage = "first_page",
    LastPage = "last_page",
    Menu = "menu",
    MoreVert = "more_vert",
    ChevronRight = "chevron_right",
    ChevronLeft = "chevron_left",
    Person = "person",
    Info = "info",
    FilterAlt = "filter_alt"
}