import { BDHoldingsClassificationTable } from "components/client-page/add-ias-wizard/pages/blackDiamond/BDHoldingsClassificationTable";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { NextFooter } from "components/shared/modal/footer/NextFooter";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { BlackDiamondHolding } from "dataModels/blackDiamond/holding";
import { Fragment, useMemo } from "react";
import { isNumber } from "utils/NumberUtils";
import styles from "./BDHoldingsClassificationPage.module.scss";
import { UpdateDataWizardBaseModalContent } from "./UpdateDataWizardBaseModalContent";
import sharedStyles from "./UpdateDataWizardPage.module.scss";
import { PopulatedAccount } from "services/blackDiamondService";

interface BDHoldingClassificationPageProps {
    blackDiamondAccounts: PopulatedAccount[];
    unclassifiedHoldings: BlackDiamondHolding[];
    setUnclassifiedHoldings: Callback<BlackDiamondHolding[]>;
    assetClasses: AssetClassDataModel[];
    clientHasVisionData: boolean;
    hasNewBlendedAssetClasses: boolean;
    onCancel: ()=>void;
    onNext: ()=>void;
}

export function BDHoldingClassificationPage(props: BDHoldingClassificationPageProps) {
    const hasUnclassifiedHoldings = useMemo(() => props.unclassifiedHoldings.some(holding => !isNumber(holding.segmentId)), [props.unclassifiedHoldings]);

    return <UpdateDataWizardBaseModalContent
        onCancel={props.onCancel}
        content={
            <Fragment>
                <div className={sharedStyles.title}>Black Diamond results have been updated. Please choose classifications.</div>
                <BDHoldingsClassificationTable
                    blackDiamondAccounts={props.blackDiamondAccounts}
                    holdingsToClassify={props.unclassifiedHoldings}
                    setHoldingsToClassify={props.setUnclassifiedHoldings}
                    assetClasses={props.assetClasses}
                />
            </Fragment>
        }
        footer={
            <NextFooter
                nextBtn={
                    <ModalActionButton
                        isDefaultAction={true}
                        onClick={props.onNext}
                        disabled={hasUnclassifiedHoldings}
                        label={props.clientHasVisionData || props.hasNewBlendedAssetClasses ? "Next" : "Finish"}
                    />
                }
            >
                {
                    hasUnclassifiedHoldings && <div className={styles.helpTextContainer}>
                        <span className={styles.helpText}>All assets/entities above need to be classified to continue.</span>
                    </div>
                }
            </NextFooter>
        }
    />;
}