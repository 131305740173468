import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertToLocal, convertToUTC } from "utils/DateUtils";
import styles from "./ValeoDatePicker.module.scss";

interface ValeoDatePickerProps {
    date?: Date;
    setDate: Callback<Date>;
    className?: string;
}

export function ValeoDatePicker(props: ValeoDatePickerProps) {

    return (
        <div className={`${styles.datePicker} ${props.className}`}>
            <DatePicker
                selected={props.date ? convertToUTC(props.date) : undefined}
                onChange={(date: Date) => props.setDate(convertToLocal(date))}
                inline
                openToDate={props.date}
                fixedHeight
                disabledKeyboardNavigation
                formatWeekDay={nameOfDay => nameOfDay[0]}
            />
        </div>
    );
}