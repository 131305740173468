import { EventLoggingTooltipContent } from "components/shared/EventLoggingTooltipContent";
import { EventType } from "constants/eventType";
import { Tooltip, Typography } from "rmwc";
import NumberDisplay from "../../numeric-values/NumberDisplay";
import PercentageDisplay from "../../numeric-values/PercentageDisplay";
import styles from "./VarianceIcon.module.scss";

export interface VarianceIconProps {
    percentage: number;
    value: number;
    class: string;
}

function VarianceIconTooltipContents(props: VarianceIconProps) {
    return <EventLoggingTooltipContent eventType={EventType.VarianceIconHover}>
        <Typography use="headline6">
            <PercentageDisplay
                className={styles.percentage_value}
                value={props.percentage}
                precision={1}
            />
            <NumberDisplay
                value={props.value}
                suppressNegativeStyling={true}
            />
        </Typography>
    </EventLoggingTooltipContent>;
}

export default function VarianceIcon(props: VarianceIconProps) {
    // The SVG is inline rather than in an icon file to allow CSS styling over the colors (with the 'currentColor' fill)

    return (<Tooltip
        content={<VarianceIconTooltipContents {...props} />}
        align="bottom"
        showArrow={true}
    >
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="variance-icon"
            className={styles[props.class]}
        >
            <circle
                cx="12"
                cy="12"
                r="9"
                fill="currentColor"
            />
            <path
                d="M12 6L17.1962 15H6.80385L12 6Z"
                fill="white"
            />
        </svg>
    </Tooltip>);
}