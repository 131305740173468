import { useBlackDiamondAuthentication } from "components/ias-workspace/workspace-header/BlackDiamondAuthenticationTemplate";
import { BDAuthProgress } from "components/ias-workspace/workspace-header/BDAuthProgress";
import { useEffect } from "react";
import { LinearProgressPage } from "./LinearProgressPage";

interface LogIntoBlackDiamondProps {
    setAuthProgress: Callback<BDAuthProgress>;
}

export function LogIntoBlackDiamond(props: LogIntoBlackDiamondProps) {
    const { setAuthProgress } = props;
    const bdAuthProgress = useBlackDiamondAuthentication();

    useEffect(() => {
        setAuthProgress(bdAuthProgress);
    }, [bdAuthProgress, setAuthProgress]);

    return <LinearProgressPage text="Finish signing into Black Diamond." />;
}
