import { round } from "lodash";

interface PercentageDisplayProps {
    value: number;
    precision?: number;
    className?: string;
}

export default function PercentageDisplay(props: PercentageDisplayProps) {
    const precision = props.precision ?? 0;
    const value = round(props.value, precision);

    return (
        <span className={props.className}>{value}%</span>
    );
}