import { Typography } from "rmwc";
import { hasContent } from "utils/StringUtils";
import styles from "./NotesPreview.module.scss";

interface NotesPreviewProps {
    notesType: string;
    notes: string;
}

export default function NotesPreview(props: NotesPreviewProps) {
    const { notesType, notes } = props;

    return <div>
        <Typography use="headline5">{notesType}:</Typography>
        <Typography
            use="headline6"
            className={styles.notesPreview}
        >{hasContent(notes) ? notes : "None"}</Typography>
    </div>;
}
