import { FormEvent, Fragment } from "react";
import styles from "./MeetingDatePage.module.scss";
import { ValeoDatePicker } from "../../../shared/ValeoDatePicker";
import { ValeoTextField } from "../../../shared/ValeoTextField";
import { NextFooter } from "../../../shared/modal/footer/NextFooter";
import { ModalActionButton } from "../../../shared/modal/footer/ModalActionButton";
import { IasBaseModalContent } from "./IasBaseModalContent";

export interface MeetingDatePageProps {
    stepper: JSX.Element;
    onCancel: VoidFunction;
    onNext: VoidFunction;
    name: string;
    setName: FunctionStringCallback;
    date?: Date;
    setDate: Callback<Date | undefined>;
    extraFooter?: JSX.Element;
}

export function MeetingDatePage(props: MeetingDatePageProps) {
    const { stepper, onCancel, onNext, name, setName, date, setDate } = props;

    const onTextFieldChange = (event: FormEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
    };

    return <IasBaseModalContent
        onCancel={onCancel}
        content={
            <Fragment>
                {stepper}
                <div className={styles.content}>
                    <div className={styles.dateAndName}>
                        <ValeoDatePicker
                            date={date}
                            setDate={setDate}
                        />
                        <ValeoTextField
                            label="Save Name As (Optional)"
                            value={name}
                            onInput={onTextFieldChange}
                            className={styles.nameTextField}
                            maxLength={60}
                        />
                    </div>
                </div>
            </Fragment>
        }
        footer={
            <NextFooter
                nextBtn={
                    <ModalActionButton
                        label="Next"
                        isDefaultAction={true}
                        onClick={onNext}
                        disabled={!date}
                    />
                }
            >
                { props.extraFooter }
            </NextFooter>
        }
    />;
}