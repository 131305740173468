import { Fragment } from "react";
import { VisionCategory, VisionPossession } from "services/visionService";
import { LinearProgressOverlay } from "../../../../shared/LinearProgressOverlay";
import { BackNextFooter } from "../../../../shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "../../../../shared/modal/footer/ModalActionButton";
import { IasBaseModalContent } from "../IasBaseModalContent";
import { ImportVisionAssetsContent } from "./ImportVisionAssetsContent";
import { PopulatedPortfolio } from "services/blackDiamondService";
import styles from "./ImportVisionAssetsPage.module.scss";
import contentStyles from "./ImportVisionAssetsContent.module.scss";
import { isNumber } from "utils/NumberUtils";
import { normalizeAccountNumber } from "utils/AccountUtils";
import { QueryStatus } from "@tanstack/react-query";
import classnames from "classnames";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

export interface ImportVisionPageProps {
    stepper: JSX.Element;
    onCancel: VoidFunction;
    onBack: VoidFunction;
    onNext: VoidFunction;
    assetClasses: AssetClassDataModel[];
    visionCategories: VisionCategory[];
    visionConnectionFailure: boolean;
    visionStatus: QueryStatus;
    selectedPortfolio: PopulatedPortfolio | null;
    selectedPossessions: Set<VisionPossession>;
    setSelectedPossessions: (possessions: Set<VisionPossession>) => void;
    hasBlendedAssetClasses: boolean;
}

export function ImportVisionAssetsPage(props: ImportVisionPageProps) {
    if (props.visionStatus === "success") {
        if (props.visionConnectionFailure) {
            return <ImportVisionAssetsPageConnectionFailure {...props} />;
        }
        return <ImportVisionAssetsPageContent {...props} />;
    }
    return <ImportVisionAssetsPageLoading {...props} />;
}

function ImportVisionAssetsPageLoading(props: ImportVisionPageProps) {
    const { onCancel, onBack } = props;
    return <Fragment>
        <IasBaseModalContent
            onCancel={onCancel}
            content={
                <div >
                    {props.stepper}
                </div>
            }
            footer={
                <BackNextFooter
                    backBtn={
                        <ModalActionButton
                            label="Back"
                            onClick={onBack}
                        />
                    }
                    nextBtn={
                        <div>
                            <ModalActionButton
                                label="Finish"
                                isDefaultAction={true}
                                disabled={true}
                                className={styles.nextButton}
                            />
                        </div>
                    }
                />
            }
        />
        <LinearProgressOverlay
            text="Loading Vision assets..."
            onCancel={onBack}
        />
    </Fragment>;
}

function ImportVisionAssetsPageConnectionFailure(props: ImportVisionPageProps) {
    const { onCancel, onBack, hasBlendedAssetClasses, onNext } = props;
    return <IasBaseModalContent
        onCancel={onCancel}
        content={
            <div >
                {props.stepper}
                <div className={classnames(contentStyles.noAssets, styles.connectionFailure)}>
                    <div className={styles.redText}>Vision failed to connect.</div>
                    <div>Continue without these assets or try again later.</div>
                </div>
            </div>
        }
        footer={
            <BackNextFooter
                backBtn={
                    <ModalActionButton
                        label="Back"
                        onClick={onBack}
                    />
                }
                nextBtn={
                    <div>
                        <ModalActionButton
                            label={hasBlendedAssetClasses ? "Next" : "Finish"}
                            isDefaultAction={true}
                            onClick={onNext}
                            className={styles.nextButton}
                        />
                    </div>
                }
            />
        }
    />;
}

function ImportVisionAssetsPageContent(props: ImportVisionPageProps) {
    const { onCancel, stepper, onBack, onNext, assetClasses, visionCategories, selectedPossessions, setSelectedPossessions, hasBlendedAssetClasses } = props;

    const allPossessionsClassified = Array.from(selectedPossessions).every(possession => isNumber(possession.segmentId));

    const portfolioAccounts: string[] = props.selectedPortfolio ? props.selectedPortfolio.accounts.map(account => normalizeAccountNumber(account.accountNumber)) : [];

    return <IasBaseModalContent
        onCancel={onCancel}
        content={
            <div >
                {stepper}
                <ImportVisionAssetsContent
                    assetClasses={assetClasses}
                    visionCategories={visionCategories}
                    portfolioAccounts={portfolioAccounts}
                    selectedPossessions={selectedPossessions}
                    setSelectedPossessions={setSelectedPossessions}
                />
            </div>
        }
        footer={
            <BackNextFooter
                backBtn={
                    <ModalActionButton
                        label="Back"
                        onClick={onBack}
                    />
                }
                nextBtn={
                    <div>
                        {
                            !allPossessionsClassified && <span className={styles.helpText}>
                                    All assets/entities above need a selection to continue.
                            </span>
                        }
                        <ModalActionButton
                            label={(selectedPossessions.size > 0 || hasBlendedAssetClasses) ? "Next" : "Finish"}
                            isDefaultAction={true}
                            onClick={onNext}
                            disabled={!allPossessionsClassified}
                            className={styles.nextButton}
                        />
                    </div>
                }
            />
        }
    />;
}