import { DataTableCell, DataTableRow, Icon, IconButton, Menu, MenuItem, MenuSurfaceAnchor, Tooltip } from "rmwc";
import { MinimalIasDataModel } from "../../dataModels/iasDataModel";
import styles from "./IasRow.module.scss";
import NotesPreview from "../shared/NotesPreview";
import depth from "./Depth.module.scss";
import { useCallback, useState, MouseEventHandler, MouseEvent, useMemo } from "react";
import classnames from "classnames";
import { IasState, isFinal, textForIasState } from "constants/iasState";
import { formatDateNumbers } from "utils/FormatDate";
import CopyIas from "./CopyIas";
import DeleteConfirmation from "./DeleteConfirmation";
import { IconName } from "../../iconNames";
import { useKeyboardAwareNavigate } from "utils/UseKeyboardAwareNavigate";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { createModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { PdfDownloader } from "./PdfDownloader";
import { EventLoggingTooltipContent } from "components/shared/EventLoggingTooltipContent";
import { EventType } from "constants/eventType";
import { telemetry } from "services/telemetryService";

interface IasRowProps {
    meetingId: number;
    ias: MinimalIasDataModel;
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    depth: number;
    clientId: number;
}

function NotesTooltipContents(props: {ias: MinimalIasDataModel}) {
    return <EventLoggingTooltipContent eventType={EventType.NoteIconHover}>
        <div>
            <NotesPreview
                notesType="Client Notes"
                notes={props.ias.clientNotes}
            />
            <NotesPreview
                notesType="Internal Notes"
                notes={props.ias.valeoNotes}
            />
        </div>
    </EventLoggingTooltipContent>;
}

export default function IasRow(props: IasRowProps) {
    const { ias, modelPortfolios, assetClasses } = props;

    const modelPortfolio = useMemo(() => createModelPortfolioViewModel(ias, modelPortfolios, assetClasses), [assetClasses, ias, modelPortfolios]);

    const stateStyle = classnames({
        [styles.draft]: ias.state === IasState.Draft,
        [styles.review]: ias.state === IasState.ForReview,
        [styles.final]: ias.state === IasState.Final,
    });

    const state = textForIasState(ias.state);

    const [menuOpen, setMenuOpen] = useState(false);
    const [kebabVisible, setKebabVisible] = useState(false);
    const [isCopying, setIsCopying] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [pdfDownload, setPdfDownload] = useState(false);

    const downloadComplete = useCallback(() => setPdfDownload(false), []);

    const navigate = useKeyboardAwareNavigate();

    const handleRowClick = useCallback((event: MouseEvent) => {
        if (showDeleteConfirmation) {
            return;
        }

        if (!((event.target as Element).attributes.getNamedItem("role")?.value === "button")) {
            navigate(event, `/ias/${ias.id}`);
        }
    }, [ias.id, showDeleteConfirmation, navigate]);

    const handleKebabClick: MouseEventHandler = useCallback(() => {
        setMenuOpen(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (!menuOpen) {
            setKebabVisible(false);
        }
    }, [menuOpen]);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
        setKebabVisible(false);
    }, []);

    const handleDelete = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        setMenuOpen(false);
        setShowDeleteConfirmation(true);
    }, []);

    const notesIcon = useMemo(() => <Tooltip
        className={styles.notesTooltip}
        content={<NotesTooltipContents ias={ias} />}
        showArrow
        align="bottomLeft"
    >
        <Icon
            icon={IconName.Assignment}
            className={styles.notesIcon}
        />
    </Tooltip>, [ias]);

    const makeACopy = useCallback(async (event: MouseEvent) => {
        event.stopPropagation();
        setIsCopying(true);
    }, []);

    const exportToPdf = useCallback(async (event: MouseEvent) => {
        event.stopPropagation();
        telemetry.trackEvent(EventType.PdfExportClientProfile);
        setMenuOpen(false);
        setPdfDownload(true);
    }, []);

    const onRemoved = useCallback(() => {
        setShowDeleteConfirmation(false);
    }, []);

    return <DataTableRow
        key={ias.id}
        onClick={handleRowClick}
        onMouseEnter={() => setKebabVisible(true)}
        onMouseLeave={handleMouseLeave}
        className="clickable"
    >
        <DataTableCell className={classnames(depth[`depth${props.depth}`], styles.wrap)}>{ias.title}</DataTableCell>
        <DataTableCell>{!(ias.valeoNotes === "" && ias.clientNotes === "") && notesIcon}</DataTableCell>
        <DataTableCell className={stateStyle}>{state}</DataTableCell>
        <DataTableCell>{formatDateNumbers(new Date(ias.dateModified))}</DataTableCell>
        <DataTableCell className={styles.wrap}>{ias.lastModifiedUser.name}</DataTableCell>
        <DataTableCell className={styles.wrap}>
            <MenuSurfaceAnchor className={styles.menuAnchor}>
                <div className={styles.modelPortfolio}>{modelPortfolio.name()}</div>
                {
                    kebabVisible &&
                    <IconButton
                        icon={IconName.MoreVert}
                        onClick={handleKebabClick}
                    />
                }
                <Menu
                    anchorCorner="bottomLeft"
                    open={menuOpen}
                    onClose={handleMenuClose}
                    className={styles.menu}
                >
                    <MenuItem
                        className={styles.menuItem}
                        onClick={makeACopy}
                    >Make a Copy</MenuItem>
                    <MenuItem
                        className={styles.menuItem}
                        onClick={exportToPdf}
                    >Export to PDF</MenuItem>
                    {
                        !isFinal(ias.state) && <MenuItem
                            className={styles.red}
                            onClick={handleDelete}
                        >Delete</MenuItem>
                    }
                </Menu>
            </MenuSurfaceAnchor>
            {
                isCopying && <CopyIas
                    id={props.ias.id}
                    meetingId={props.meetingId}
                />
            }
            {
                showDeleteConfirmation && <DeleteConfirmation
                    id={props.ias.id}
                    clientId={props.clientId}
                    setShown={setShowDeleteConfirmation}
                    onRemoved={onRemoved}
                />
            }
            {
                pdfDownload && <PdfDownloader
                    clientId={props.clientId}
                    iasId={props.ias.id}
                    downloadComplete={downloadComplete}
                />
            }
        </DataTableCell>
    </DataTableRow>;
}
