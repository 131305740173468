import { round } from "lodash";
import { isNumericInteger } from "./StringUtils";

const numberFormat = new Intl.NumberFormat("en-US");

export function formatDisplay(value: number, showZero = false) : string {
    if (value === 0 && !showZero) {
        return "-";
    }

    const baseValue = numberFormat.format(Math.abs(value));
    if (value < 0) {
        return `(${baseValue})`;
    }

    return baseValue;
}

export function formatRoundedDisplay(value: number, showZero = false) : string {
    return formatDisplay(Math.round(value), showZero);
}

export function formatRoundedDisplayForPdf(value: number, showZero = false): string {
    return value < 0 ? formatRoundedDisplay(value, showZero) : formatRoundedDisplay(value, showZero)+ "\u00a0";
}

export function formatPercentage(value: number, useParenthesesForNegative = true, precision = 1) : string {
    if (value < 0 && useParenthesesForNegative) {
        return `(${(round(-value, precision)).toFixed(precision)}%)`;
    }

    return `${round(value, precision).toFixed(precision)}%`;
}

export function formatPercentageForPdf(value: number, useParenthesesForNegative = true, precision = 1): string {
    return value < 0 ? formatPercentage(value, useParenthesesForNegative, precision) : formatPercentage(value, useParenthesesForNegative, precision) + "\u00a0";
}

export function parseDisplay(display: string, showZero = false) : number | null {
    if (display === "-" && !showZero) {
        return 0;
    }

    let normalizedDisplay = display.replaceAll(",", "");
    if (/\(.+\)/.test(normalizedDisplay)) {
        normalizedDisplay = normalizedDisplay.replace("(", "-").slice(0, -1);
    }

    if (isNumericInteger(normalizedDisplay)) {
        return parseInt(normalizedDisplay);
    }

    return null;
}