import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";
import { isNumber } from "utils/NumberUtils";
import { hasContent } from "utils/StringUtils";

export function modelPortfolioName(targets: TargetsDataModel, modelPortfolios: ModelPortfolioDataModel[]): string {
    if (targets.customModelPortfolio && !targets.modelPortfolio) {
        if (hasContent(targets.customModelPortfolio.name))
        {
            return `${targets.customModelPortfolio.name}*`;
        }
        return `${isNumber(targets.customModelPortfolio.baseModelPortfolioId) ? modelPortfolios.find(mp => mp.id === targets.customModelPortfolio?.baseModelPortfolioId)?.name : "Custom"}*`;
    }
    return targets.modelPortfolio?.name ?? "";
}