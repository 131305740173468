import classnames from "classnames";
import { ClientSearchResult } from "dataModels/clientDataModel";
import { IconName } from "iconNames";
import { useCallback } from "react";
import { DataTableCell, DataTableRow, IconButton } from "rmwc";
import { formatDisplay } from "utils/FormatNumber";
import styles from "./ClientTablePagination.module.scss";

interface ClientTablePaginationProps {
    results: ClientSearchResult;
    retrievePage: (page: number) => void;
}

export function ClientTablePagination(props: ClientTablePaginationProps) {
    const { results, retrievePage } = props;

    const onFirstPage = useCallback(() => {
        retrievePage(1);
    }, [retrievePage]);
    const onPreviousPage = useCallback(() => {
        retrievePage(results.pagination.page > 1 ? results.pagination.page - 1 : 1);
    }, [results.pagination.page, retrievePage]);
    const onNextPage = useCallback(() => {
        retrievePage(results.pagination.page < results.pagination.pageCount ? results.pagination.page + 1 : results.pagination.pageCount);
    }, [results.pagination.page, results.pagination.pageCount, retrievePage]);
    const onLastPage = useCallback(() => {
        retrievePage(results.pagination.pageCount);
    }, [results.pagination.pageCount, retrievePage]);

    const resultStart = (results.pagination.page - 1) * results.pagination.pageSize + 1;
    const resultEnd = resultStart + results.clients.length - 1;

    const resultsMessage = results.pagination.totalCount === 0
        ? "No Results Found"
        : `${formatDisplay(resultStart, true)} - ${formatDisplay(resultEnd, true)}  of  ${formatDisplay(results.pagination.totalCount, true)}`;

    const leftDisabled = resultStart === 1;
    const rightDisabled = resultEnd === results.pagination.totalCount;

    return <DataTableRow className={styles.paginationRow}>
        <DataTableCell colSpan={3}>
            <div className={styles.paginationContent}>
                <div className={styles.resultsMessage}>{resultsMessage}</div>
                <IconButton
                    icon={IconName.FirstPage}
                    data-testid="client-table-pagination-first"
                    onClick={onFirstPage}
                    disabled={leftDisabled}
                    className={classnames(styles.firstButton, {[styles.disabled]: leftDisabled})}
                />
                <IconButton
                    icon={IconName.ChevronLeft}
                    data-testid="client-table-pagination-previous"
                    onClick={onPreviousPage}
                    disabled={leftDisabled}
                    className={classnames({[styles.disabled]: leftDisabled})}
                />
                <IconButton
                    icon={IconName.ChevronRight}
                    data-testid="client-table-pagination-next"
                    onClick={onNextPage}
                    disabled={rightDisabled}
                    className={classnames({[styles.disabled]: rightDisabled})}
                />
                <IconButton
                    icon={IconName.LastPage}
                    data-testid="client-table-pagination-last"
                    onClick={onLastPage}
                    disabled={rightDisabled}
                    className={classnames(styles.lastButton, {[styles.disabled]: rightDisabled})}
                />
            </div>
        </ DataTableCell>
    </DataTableRow>;
}