import classnames from "classnames";
import { Fragment, useCallback, useContext, useRef, useState } from "react";
import { usePositionMutation } from "services/positionService";
import styles from "./ChangeValueTextField.module.scss";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import { CurrencyEntry, inactiveDisplayOfCurrencyValue, isCurrencyValueValid } from "../../numeric-values/CurrencyEntry";
import { Snackbar, SnackbarAction } from "rmwc";
import { IconName } from "../../../iconNames";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { useNumericTextField } from "components/numeric-values/useNumericTextField";

interface ChangeValueTextFieldProps {
    ias: IasDataModel;
    positionId: number;
    accountId: number;
    changeValue: number;
    currentValue: number;
}

export default function ChangeValueTextField(props: ChangeValueTextFieldProps) {
    const mutation = usePositionMutation(props.positionId, props.accountId, props.ias.id);
    const context = useContext(WorkspaceContext);
    const changeValue = useNumericTextField(props.changeValue, inactiveDisplayOfCurrencyValue, isCurrencyValueValid);
    const [showExcessSellMessage, setShowExcessSellMessage] = useState(false);
    const snackbarRef = useRef<HTMLDivElement>(null);

    const onValidChangeValueChange = useCallback(() => {
        mutation.mutate({change: changeValue.value, lastModifiedDate: props.ias.dateModified});

        if ((props.currentValue + changeValue.value) < 0) {
            setShowExcessSellMessage(true);
        }
    }, [changeValue.value, mutation, props.currentValue, props.ias.dateModified]);

    const correctIconColor = () => {
        // The generate icon in the Snackbar component has a hard-coded semi-opaque white color set.
        // This change resets the element style to remove the color and allow a fall through to CSS color styling.
        const icon = snackbarRef.current?.getElementsByTagName("i")[0];
        icon?.setAttribute("style", "fill: currentcolor; margin-left: 1rem;");
    };

    return (
        <Fragment>
            <CurrencyEntry
                className={
                    classnames(styles.changeTextField, {
                        [styles.wide]: !context.sidebarOpen,
                    })
                }
                numericValue={changeValue.value}
                setNumericValue={changeValue.setValue}
                display={changeValue.display}
                setDisplay={changeValue.setDisplay}
                valueIsValid={changeValue.isValid}
                setValueIsValid={changeValue.setIsValid}
                onValidChange={onValidChangeValueChange}
                data-changevalid={changeValue.isValid}
            />
            {
                showExcessSellMessage && <Snackbar
                    ref={snackbarRef}
                    className={styles.excessSellSnackbar}
                    icon={IconName.Error}
                    message="You are selling more than the current value holding."
                    dismissesOnAction
                    open={showExcessSellMessage}
                    action={<SnackbarAction label="Dismiss"/>}
                    onClose={() => setShowExcessSellMessage(false)}
                    onOpen={correctIconColor}
                />
            }
        </Fragment>
    );
}