import { Fragment, useCallback, useEffect, useState } from "react";
import { PopulatedAccount, PopulatedPortfolio, useAccountsQuery } from "services/blackDiamondService";
import { BlackDiamondHolding } from "dataModels/blackDiamond/holding";
import { HoldingTemplate } from "./HoldingTemplate";
import { isConnectionFailure } from "services/ConnectionFailure";
import { isSome } from "utils/isNullOrUndefined";

interface PopulatedBlackDiamondPortfolioTemplateProps {
    relationshipId: string;
    portfolioId: string;
    reportPortfolio: (portfolio: PopulatedPortfolio) => void;
    reportConnectionFailure: VoidFunction;
}

export function PopulatedBlackDiamondPortfolioTemplate(props: PopulatedBlackDiamondPortfolioTemplateProps) {
    const { data: accounts } = useAccountsQuery(props.relationshipId, props.portfolioId);

    const [holdingsMemo, setHoldingsMemo] = useState(() => new Map<string, BlackDiamondHolding[]>());

    useEffect(() => {
        if (isConnectionFailure(accounts)) {
            props.reportConnectionFailure();
        }
        else if (holdingsMemo.size === accounts?.length) {
            const portfolio: PopulatedPortfolio = {
                id: props.portfolioId,
                accounts: accounts.map(account => {
                    const thisAccount: PopulatedAccount = {
                        holdings: holdingsMemo.get(account.id) ?? [],
                        ...account
                    };
                    return thisAccount;
                })
            };
            props.reportPortfolio(portfolio);
        }
    }, [accounts, holdingsMemo, props]);

    const holdingsReport = useCallback((accountId: string, holdings: BlackDiamondHolding[]) => {
        holdingsMemo.set(accountId, holdings);
        setHoldingsMemo(new Map(holdingsMemo));
    }, [holdingsMemo]);

    const holdingsTemplates = ((isSome(accounts) && !isConnectionFailure(accounts)) ? accounts : []).map(account => <HoldingTemplate
        accountId={account.id}
        report={holdingsReport}
        key={account.id}
        reportConnectionFailure={props.reportConnectionFailure}
    />);

    return <Fragment>{holdingsTemplates}</Fragment>;
}
