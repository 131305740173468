import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { AddIasPage } from "./AddIasPage";
import { AddIasStepper } from "./AddIasStepper";
import styles from "./AddIasWizard.module.scss";
import { MeetingDatePage } from "./pages/MeetingDatePage";
import { ModelPortfolioPage } from "./pages/ModelPortfolioPage";
import { BDPortfoliosPage } from "./pages/blackDiamond/BDPortfoliosPage";
import { BDHoldingsClassificationPage } from "./pages/blackDiamond/BDHoldingsClassificationPage";
import { TargetsDataModel } from "../../../dataModels/targetsDataModel";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { BlackDiamondHolding } from "../../../dataModels/blackDiamond/holding";
import { ImportVisionAssetsPage } from "./pages/vision/ImportVisionAssetsPage";
import { uniqBy } from "lodash";
import { VisionCategory, VisionPossession } from "services/visionService";
import { isVisionResponse } from "services/isVisionResponse";
import { isNumber } from "utils/NumberUtils";
import { PopulatedPortfolioSelection } from "./pages/blackDiamond/PortfolioSelection";
import { MoreVisionInfoPage } from "./pages/vision/MoreVisionInfoPage/MoreVisionInfoPage";
import { CustomModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { createEditModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/EditModelPortfolioViewModel";
import { Segment } from "constants/segment";
import { BlendedAssetClassesPage, BlendDefinition } from "./pages/blended/BlendedAssetClassesPage";
import { ClassifiedBlackDiamondHolding, ClassifiedVisionPossession } from "../createIas";
import { usePropWatch } from "utils/usePropWatch";
import { filterMap } from "utils/ArrayUtils";
import { BlendedFundSegmentPercentage } from "dataModels/blendedFundSegmentPercentage";
import { DataSource } from "constants/dataSource";
import { IasBaseModalContent } from "./pages/IasBaseModalContent";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { combineQueryStatuses } from "utils/ReactQueryUtils";
import { isConnectionFailure } from "services/ConnectionFailure";
import { AddIasWizardProps, iasWizardStepNames, MeetingDateProps, PreloadProps } from "./AddIasWizardConstants";
import { PositionDataModel } from "dataModels/positionDataModel";
import { AccountDataModel } from "dataModels/accountDataModel";
import { useEditCustomSegmentNames } from "components/shared/custom-segment-name/useEditCustomSegmentNames";
import { createAssetTables } from "components/ias-workspace/asset-table/view-models/AssetTablesViewModel";

enum WizardPage {
    MeetingDate,
    ModelPortfolio,
    BDPortfolio,
    BDHoldingsClassification,
    Vision,
    MoreVisionInfo,
    BlendedAssetClasses,
    AddIas,
    TRIGGER_FINISH
}

interface AccountPosition extends PositionDataModel {
    account: AccountDataModel;
}

interface AddIasWizardFullProps extends AddIasWizardProps, MeetingDateProps, PreloadProps {
    scrollToTop: VoidFunction;
}

export function AddIasWizardFull(props: AddIasWizardFullProps) {
    const { setOpen, client, modelPortfolios, scrollToTop, date, setDate, name, setName } = props;

    const { assetClasses, customSegmentNamesAreValid, customSegmentNamesAreModified, editCustomSegmentNamesProps,
        customSegmentGroupNamesAreValid, customSegmentGroupNamesAreModified } = useEditCustomSegmentNames(client.assetClasses);

    const { data: visionData, status: visionDataStatus } = props.visionQuery;
    const { data: mostRecentlyFinalizedFull, status: mostRecentlyFinalizedStatus} = props.mostRecentlyFinalizedFullQuery;
    const { data: unsortedAccountTypes, status: accountTypesStatus } = props.accountTypesQuery;

    const accountTypes = useMemo(() => accountTypesStatus === "success"
        ? unsortedAccountTypes.sort((a, b) => a.name.localeCompare(b.name))
        : []
    , [accountTypesStatus, unsortedAccountTypes]);

    const visionQueryStatuses = [visionDataStatus];
    if (props.mostRecentlyFinalized) {
        visionQueryStatuses.push(mostRecentlyFinalizedStatus);
    }
    const visionStatus = combineQueryStatuses(...visionQueryStatuses);

    const rawVisionCategories: VisionCategory[] = useMemo(() => isVisionResponse(visionData) ? visionData.categories : [], [visionData]);

    const mostRecentlyFinalizedIasVisionPositions: AccountPosition[] = useMemo(() => mostRecentlyFinalizedFull?.accounts.flatMap(account => filterMap(account.positions, position => {
        if (position.dataSource !== DataSource.Vision) {
            return null;
        }
        return {
            ...position,
            account,
        };
    })) ?? [],
    [mostRecentlyFinalizedFull?.accounts]);

    const mostRecentlyFinalizedIasSecurities: IasSecurityDataModel[] = useMemo(() => mostRecentlyFinalizedFull?.securities ?? [],
        [mostRecentlyFinalizedFull?.securities]);

    const initializeVisionData = useCallback((data: VisionCategory[]): [VisionCategory[], VisionPossession[]] => data.reduce<[VisionCategory[], VisionPossession[]]>(([accCategories, accInitialSelections], category) => {
        const initializedCategory = {
            ...category,
            subcategories: category.subcategories.map(subcategory => ({
                ...subcategory,
                possessions: subcategory.possessions.map(possession => {
                    const position = mostRecentlyFinalizedIasVisionPositions.find(p => p.sourceId === possession.id);
                    if (position) {
                        const security = mostRecentlyFinalizedIasSecurities.find(s => s.id === position.securityId);
                        if (security) {
                            const initializedPossession = {
                                ...possession,
                                segmentId: security.segmentId,
                                accountType: accountTypes.find(type => type.id === position.account.typeId),
                                owner: position.account.owner,
                                custodian: position.account.custodian,
                            };
                            accInitialSelections.push(initializedPossession);
                            return initializedPossession;
                        }
                    }
                    return possession;
                }),
            })),
        };
        accCategories.push(initializedCategory);
        return [accCategories, accInitialSelections];
    }, [[], []]),
    [accountTypes, mostRecentlyFinalizedIasSecurities, mostRecentlyFinalizedIasVisionPositions]);

    const [visionCategories, initialVisionSelections]: [VisionCategory[], VisionPossession[]] = useMemo(() => {
        return mostRecentlyFinalizedIasVisionPositions.length > 0
            ? initializeVisionData(rawVisionCategories)
            : [rawVisionCategories, []];
    }, [initializeVisionData, mostRecentlyFinalizedIasVisionPositions.length, rawVisionCategories]);

    const [pageIndex, setPageIndex] = useState(WizardPage.ModelPortfolio);

    const propsTargets: TargetsDataModel = {
        modelPortfolio: client.modelPortfolio,
        customModelPortfolio: client.customModelPortfolio,
        cashTarget: client.cashTarget,
        alternativesTarget: client.alternativesTarget,
        cashCarveOut: client.cashCarveOut,
        fixedIncomeCarveOut: client.fixedIncomeCarveOut,
        equitiesCarveOut: client.equitiesCarveOut,
    };
    const [originalTargets] = useState(propsTargets);
    const [targets, setTargets] = useState(propsTargets);

    const patchTargets = useCallback((targetsUpdates: Partial<TargetsDataModel>) => {
        setTargets((targets: TargetsDataModel) => ({
            ...targets,
            ...targetsUpdates,
        }));
    }, []);

    const patchCustomModelPortfolio = useCallback((cmpUpdates: Partial<CustomModelPortfolioDataModel>) => {
        setTargets((targets: TargetsDataModel) => ({
            ...targets,
            ...(targets.customModelPortfolio && {
                customModelPortfolio: {
                    ...targets.customModelPortfolio,
                    ...cmpUpdates,
                },
            }),
        }));
    }, []);

    const mostRecentIasTotalValue = useMemo(() => {
        if(mostRecentlyFinalizedStatus === "success") {
            const assetClassTables = createAssetTables(mostRecentlyFinalizedFull.assetClasses, modelPortfolios, mostRecentlyFinalizedFull);
            return assetClassTables.currentValue();
        }
        return 0;
    }, [modelPortfolios, mostRecentlyFinalizedFull, mostRecentlyFinalizedStatus]);
    const [portfolioValue, setPortfolioValue] = useState(mostRecentIasTotalValue);

    const [modelPortfolio, setModelPortfolio] = useState(createEditModelPortfolioViewModel(targets, modelPortfolios, assetClasses, originalTargets, portfolioValue));
    useEffect(() => {
        setModelPortfolio(createEditModelPortfolioViewModel(targets, modelPortfolios, assetClasses, originalTargets, portfolioValue));
    }, [assetClasses, targets, modelPortfolios, originalTargets, portfolioValue]);

    const [blackDiamondPortfolio, setBlackDiamondPortfolio] = useState<PopulatedPortfolioSelection>({ value: null, selectionMade: false });
    const [bdHoldingsToClassify, setBDHoldingsToClassify] = useState<BlackDiamondHolding[]>([]);

    const getClassifiedBlackDiamondHoldings = useCallback(() => bdHoldingsToClassify.map(holding => ({
        ...holding,
        blendedFundPercentages: [],
    })), [bdHoldingsToClassify]);
    const [classifiedBlackDiamondHoldings, setClassifiedBlackDiamondHoldings] = usePropWatch<ClassifiedBlackDiamondHolding[]>(getClassifiedBlackDiamondHoldings);

    const blendedBlackDiamondHoldings: ClassifiedBlackDiamondHolding[] = useMemo(() => {
        return classifiedBlackDiamondHoldings.filter(holding => holding.segmentId === Segment.Blended);
    }, [classifiedBlackDiamondHoldings]);

    const getBlackDiamondHoldingBlendDefinitions = useCallback(() => blendedBlackDiamondHoldings.map(holding => ({
        id: holding.assetId,
        dataSource: DataSource.BlackDiamond,
        securityTicker: holding.ticker ?? "",
        securityName: holding.name,
        percentages: holding.blendedFundPercentages,
    })), [blendedBlackDiamondHoldings]);
    const [blackDiamondHoldingBlendDefinitions, setBlackDiamondHoldingBlendDefinitions] = usePropWatch<BlendDefinition[]>(getBlackDiamondHoldingBlendDefinitions);

    const getInitialVisionSelections = useCallback((): Set<VisionPossession> => new Set(initialVisionSelections), [initialVisionSelections]);
    const [visionPossessions, setVisionPossessions] = usePropWatch<Set<VisionPossession>>(getInitialVisionSelections);

    const getClassifiedVisionPossessions = useCallback(() => Array.from(visionPossessions).map(possession => {
        const matchingPosition = mostRecentlyFinalizedIasVisionPositions.find(p => p.sourceId === possession.id);
        const blendedFundPercentages = mostRecentlyFinalizedIasSecurities.find(s => s.id === matchingPosition?.securityId)?.blendedFundPercentages ?? [];
        return {
            ...possession,
            blendedFundPercentages,
        };
    }), [mostRecentlyFinalizedIasSecurities, mostRecentlyFinalizedIasVisionPositions, visionPossessions]);
    const [classifiedVisionPossessions, setClassifiedVisionPossessions] = usePropWatch<ClassifiedVisionPossession[]>(getClassifiedVisionPossessions);

    const blendedVisionPossessions: ClassifiedVisionPossession[] = useMemo(() => {
        return classifiedVisionPossessions.filter(possession => possession.segmentId === Segment.Blended);
    }, [classifiedVisionPossessions]);

    const getVisionPossessionBlendDefinitions = useCallback(() => blendedVisionPossessions.map(possession => ({
        id: possession.id,
        dataSource: DataSource.Vision,
        securityTicker: "",
        securityName: possession.account ?? possession.name,
        percentages: possession.blendedFundPercentages,
    })), [blendedVisionPossessions]);
    const [visionPossessionBlendDefinitions, setVisionPossessionBlendDefinitions] = usePropWatch<BlendDefinition[]>(getVisionPossessionBlendDefinitions);

    const hasBlendedAssetClasses: boolean = useMemo(() => {
        return blendedBlackDiamondHoldings.length > 0 || blendedVisionPossessions.length > 0;
    }, [blendedBlackDiamondHoldings.length, blendedVisionPossessions.length]);

    const blendDefinitions = useMemo(() => blackDiamondHoldingBlendDefinitions.concat(visionPossessionBlendDefinitions),
        [blackDiamondHoldingBlendDefinitions, visionPossessionBlendDefinitions]);

    const setBlendDefinitions = useCallback((blendDefinitions: BlendDefinition[]) => {
        const blackDiamondBlendDefinitions = blendDefinitions.filter(blend => blend.dataSource === DataSource.BlackDiamond);
        setBlackDiamondHoldingBlendDefinitions(blackDiamondBlendDefinitions);
        setClassifiedBlackDiamondHoldings((existingHoldings) => existingHoldings.map(existingHolding => {
            const matchingDefinition = blackDiamondBlendDefinitions.find(blend => blend.id === existingHolding.assetId);
            return {
                ...existingHolding,
                blendedFundPercentages: matchingDefinition
                    ? filterMap(matchingDefinition.percentages, percentage => isNumber(percentage.segmentId) ? percentage : null) as BlendedFundSegmentPercentage[]
                    : existingHolding.blendedFundPercentages,
            };
        }));

        const visionBlendDefinitions = blendDefinitions.filter(blend => blend.dataSource === DataSource.Vision);
        setVisionPossessionBlendDefinitions(visionBlendDefinitions);
        setClassifiedVisionPossessions((existingHoldings) => existingHoldings.map(existingHolding => {
            const matchingDefinition = visionBlendDefinitions.find(blend => blend.id === existingHolding.id);
            return {
                ...existingHolding,
                blendedFundPercentages: matchingDefinition
                    ? filterMap(matchingDefinition.percentages, percentage => isNumber(percentage.segmentId) ? percentage : null) as BlendedFundSegmentPercentage[]
                    : existingHolding.blendedFundPercentages,
            };
        }));
    }, [setBlackDiamondHoldingBlendDefinitions, setClassifiedBlackDiamondHoldings, setClassifiedVisionPossessions, setVisionPossessionBlendDefinitions]);

    const pageIndexWhenBlendedIsNext: WizardPage = useMemo(() => {
        return hasBlendedAssetClasses
            ? WizardPage.BlendedAssetClasses
            : WizardPage.TRIGGER_FINISH;
    }, [hasBlendedAssetClasses]);

    useEffect(() => {
        if (blackDiamondPortfolio.selectionMade) {
            const holdings = blackDiamondPortfolio.value?.accounts.flatMap(account => account.holdings.filter(holding => !isNumber(holding.segmentId))) ?? [];
            setBDHoldingsToClassify(uniqBy(holdings, "name"));
        } else {
            setBDHoldingsToClassify([]);
        }
    }, [blackDiamondPortfolio]);

    const cancel = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const onBack = useCallback(() => {
        scrollToTop();
        setPageIndex((pageIndex: number) => {
            switch (pageIndex) {
            case WizardPage.Vision:
                return WizardPage.BDPortfolio;
            case WizardPage.BlendedAssetClasses:
                return WizardPage.Vision;
            default:
                return pageIndex - 1;
            }
        });
    }, [scrollToTop]);

    const onNext = useCallback(() => {
        scrollToTop();
        setPageIndex((pageIndex: number) => {
            switch (pageIndex) {
            case WizardPage.Vision:
                return visionPossessions.size > 0
                    ? WizardPage.MoreVisionInfo
                    : pageIndexWhenBlendedIsNext;
            case WizardPage.MoreVisionInfo:
                return pageIndexWhenBlendedIsNext;
            default:
                return pageIndex + 1;
            }
        });
    }, [pageIndexWhenBlendedIsNext, scrollToTop, visionPossessions.size]);

    const onBDPortfoliosNext = useCallback((portfolio: PopulatedPortfolio | null) => {
        scrollToTop();
        const holdingsToClassify = portfolio?.accounts.flatMap(account => account.holdings.filter(holding => !isNumber(holding.segmentId))) ?? [];

        setPageIndex(holdingsToClassify.length > 0
            ? WizardPage.BDHoldingsClassification
            : WizardPage.Vision);
    }, [scrollToTop]);

    const displayPage = useCallback((index: number) => {
        const stepNamesOnThisPage = [...iasWizardStepNames];
        if (hasBlendedAssetClasses) {
            stepNamesOnThisPage.push("Blended Asset Classes");
        }

        const pagesFns = new Map<number, () => JSX.Element>([
            [WizardPage.MeetingDate, () => <MeetingDatePage
                stepper={
                    <AddIasStepper
                        stepIndex={0}
                        stepNames={stepNamesOnThisPage}
                        pageTitle={<span className={styles.title}>Select the meeting date and name for this IAS. This can change later.</span>}
                    />
                }
                onCancel={cancel}
                onNext={onNext}
                name={name}
                setName={setName}
                date={date}
                setDate={setDate}
            />,
            ],
            [WizardPage.ModelPortfolio, () => <ModelPortfolioPage
                stepper={
                    <AddIasStepper
                        stepIndex={1}
                        stepNames={stepNamesOnThisPage}
                        pageTitle={<span className={styles.title}>Confirm which model portfolio will apply to this IAS.</span>}
                    />
                }
                onCancel={cancel}
                onBack={onBack}
                onNext={onNext}
                client={client}
                modelPortfolio={modelPortfolio}
                targets={targets}
                patchTargets={patchTargets}
                patchCustomModelPortfolio={patchCustomModelPortfolio}
                assetClasses={assetClasses}
                customSegmentNamesAreModified={customSegmentNamesAreModified}
                customSegmentNamesAreValid={customSegmentNamesAreValid}
                assumedTotalPortfolioValue={mostRecentIasTotalValue}
                setPortfolioValue={mostRecentIasTotalValue === 0 ? setPortfolioValue : undefined}
                customSegmentGroupNamesAreModified={customSegmentGroupNamesAreModified}
                customSegmentGroupNamesAreValid={customSegmentGroupNamesAreValid}
                {...editCustomSegmentNamesProps}
            />,
            ],
            [WizardPage.BDPortfolio, () => <BDPortfoliosPage
                stepper={
                    <AddIasStepper
                        stepIndex={2}
                        stepNames={stepNamesOnThisPage}
                        pageTitle={<span className={styles.title}>Choose a Black Diamond Portfolio to import.</span>}
                    />
                }
                onCancel={cancel}
                onBack={onBack}
                onNext={onBDPortfoliosNext}
                client={client}
                populatedPortfolio={blackDiamondPortfolio}
                setPopulatedPortfolio={setBlackDiamondPortfolio}
            />,
            ],
            [WizardPage.BDHoldingsClassification, () => <BDHoldingsClassificationPage
                stepper={
                    <AddIasStepper
                        stepIndex={2}
                        stepNames={stepNamesOnThisPage}
                        pageTitle={
                            <Fragment>
                                <span className={styles.grayTitle}>Choose a Black Diamond Portfolio to import.</span>
                                <span className={styles.title}> Some additional information is needed. Please choose classifications.</span>
                            </Fragment>
                        }
                    />
                }
                onCancel={cancel}
                onBack={onBack}
                onNext={onNext}
                blackDiamondAccounts={blackDiamondPortfolio.value?.accounts ?? []}
                assetClasses={assetClasses}
                holdingsToClassify={bdHoldingsToClassify}
                setHoldingsToClassify={setBDHoldingsToClassify}
            />,
            ],
            [WizardPage.Vision, () => <ImportVisionAssetsPage
                stepper={
                    <AddIasStepper
                        stepIndex={3}
                        stepNames={stepNamesOnThisPage}
                        pageTitle={<span className={styles.title}>Choose Vision assets to import.</span>}
                    />
                }
                onCancel={cancel}
                onBack={onBack}
                onNext={onNext}
                assetClasses={assetClasses}
                visionCategories={visionCategories}
                visionConnectionFailure={isConnectionFailure(visionData)}
                visionStatus={visionStatus}
                selectedPortfolio={blackDiamondPortfolio.value}
                selectedPossessions={visionPossessions}
                setSelectedPossessions={setVisionPossessions}
                hasBlendedAssetClasses={hasBlendedAssetClasses}
            />,
            ],
            [WizardPage.MoreVisionInfo, () => <MoreVisionInfoPage
                stepper={
                    <AddIasStepper
                        stepIndex={3}
                        stepNames={stepNamesOnThisPage}
                        pageTitle={
                            <Fragment>
                                <span className={styles.grayTitle}>Choose Vision assets to import. </span>
                                <span className={styles.title}>Some additional information is needed.</span>
                            </Fragment>
                        }
                    />
                }
                onCancel={cancel}
                onNext={onNext}
                onBack={onBack}
                selectedPossessions={visionPossessions}
                setSelectedPossessions={setVisionPossessions}
                hasBlendedAssetClasses={hasBlendedAssetClasses}
            />,
            ],
            [WizardPage.BlendedAssetClasses, () => <BlendedAssetClassesPage
                WizardBaseModalContent={IasBaseModalContent}
                stepper={
                    <AddIasStepper
                        stepIndex={4}
                        stepNames={stepNamesOnThisPage}
                        pageTitle={
                            <Fragment>
                                <span className={styles.title}>Define the Blended Asset Classes.</span>
                            </Fragment>
                        }
                    />
                }
                onCancel={cancel}
                onBack={onBack}
                onNext={onNext}
                assetClasses={assetClasses}
                blendDefinitions={blendDefinitions}
                setBlendDefinitions={setBlendDefinitions}
            />,
            ],
        ]);

        const pageFn = pagesFns.get(index);

        if (pageFn) {
            return pageFn();
        }

        return <AddIasPage
            stepper={
                <AddIasStepper
                    stepIndex={3}
                    stepNames={stepNamesOnThisPage}
                    pageTitle={
                        <Fragment>
                            <span className={styles.grayTitle}>Choose Vision assets to import. </span>
                            <span className={styles.title}>Some additional information is needed.</span>
                        </Fragment>
                    }
                />
            }
            createIasParams={
                {
                    date: date ?? new Date(),
                    title: name,
                    modelPortfolio,
                    client: client,
                    portfolio: blackDiamondPortfolio.value,
                    classifiedHoldings: classifiedBlackDiamondHoldings,
                    visionAssets: classifiedVisionPossessions,
                    mostRecentlyFinalized: mostRecentlyFinalizedFull,
                    assetClasses: assetClasses
                }
            }
        />;
    }, [assetClasses, bdHoldingsToClassify, blackDiamondPortfolio, blendDefinitions, cancel, classifiedBlackDiamondHoldings, classifiedVisionPossessions, client, customSegmentGroupNamesAreModified, customSegmentGroupNamesAreValid, customSegmentNamesAreModified, customSegmentNamesAreValid, date, editCustomSegmentNamesProps, hasBlendedAssetClasses, modelPortfolio, mostRecentIasTotalValue, mostRecentlyFinalizedFull, name, onBDPortfoliosNext, onBack, onNext, patchCustomModelPortfolio, patchTargets, setBlendDefinitions, setDate, setName, setVisionPossessions, targets, visionCategories, visionData, visionPossessions, visionStatus]);

    return displayPage(pageIndex);
}
