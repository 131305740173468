import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import { TradeDataExceptionReason } from "services/tradeDataService";
import styles from "./TradeDataExceptionDialog.module.scss";

interface TradeDataExclusionDialogProps {
    onConfirm: VoidFunction;
    exceptionReasons: TradeDataExceptionReason[];
}

export function TradeDataExceptionDialog(props: TradeDataExclusionDialogProps) {
    const undeterminable = props.exceptionReasons.filter(reason => reason === TradeDataExceptionReason.UndeterminableSharePrice).length;
    const zeroShares = props.exceptionReasons.filter(reason => reason === TradeDataExceptionReason.ZeroSharesTrade).length;

    const content = <div>
        <span>There were trades excluded in the download due to these reasons:</span>
        <ul>
            {undeterminable !== 0 && <li>Has a position(s) with an undeterminable share price</li>}
            {zeroShares !== 0 && <li>Has a position(s) yielding a zero share trade</li>}
        </ul>
    </div>;

    return <ConfirmationDialog
        title="There are some excluded Fidelity trades in the download."
        confirmButton="OKAY"
        onConfirm={props.onConfirm}
        content={content}
        className={styles.dialog}
    />;
}