import { QueryClient, useQuery } from "@tanstack/react-query";
import { postWithAuth } from "./apiService";
import { v4 as uuidv4 } from "uuid";
import authorizationService from "./authorizationService";
import { staticConfiguration } from "./configurationService";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { useCommonErrorDetection } from "./dataErrorService";
import { ConnectionFailure, createConnectionFailure } from "./ConnectionFailure";
import { isString } from "utils/StringUtils";
import { createTokenRevokedError, TokenRevokedError } from "./TokenRevokedError";

export interface VisionPossession {
    id: number;
    name: string;
    type: string;
    owner: string;
    endValue: number;
    purpose: string | null;
    endDate: string;
    location?: string;
    account?: string;
    segmentId?: number;
    accountType?: AccountTypeDataModel;
    custodian?: string;
}

export interface VisionSubcategory {
    uniqueId: string;
    name: string;
    possessions: VisionPossession[];
}

export interface VisionCategory {
    uniqueId: string;
    name: string;
    subcategories: VisionSubcategory[];
}

export interface VisionResponse {
    categories: VisionCategory[];
}

interface VisionTransport extends VisionResponse {
    error?: string;
}

const post = async (clientKey: string): Promise<VisionResponse | ConnectionFailure | TokenRevokedError> => {
    const result = await postWithAuth<VisionTransport>("/vision", {
        clientKey,
        refreshToken: staticConfiguration.useMsal() ? await authorizationService.getRefreshToken() : ""
    });

    if (isString(result.error)) {
        if (result.error === "Token Revoked") return createTokenRevokedError();
        return createConnectionFailure();
    }

    for(const category of result.categories) {
        category.uniqueId = uuidv4();
        for (const subcategory of category.subcategories) {
            subcategory.uniqueId = uuidv4();
        }
    }
    return {
        categories: result.categories
    };
};

export const useVisionData = (clientKey: string) => {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(["vision", clientKey], () => post(clientKey), {
        onError: commonErrorDetection
    });
};

export const clearVisionQueries = (queryClient: QueryClient) => {
    queryClient.removeQueries(["vision"]);
};
