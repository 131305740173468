import { DialogButtonProps } from "rmwc";
import { hasContent, toTestId } from "utils/StringUtils";

export interface ModalButtonProps extends DialogButtonProps, React.HTMLProps<HTMLElement> {
    label?: string;
    "data-testid"?: string;
}

export function modalButtonTestId(props: ModalButtonProps): string | undefined {
    if (hasContent(props["data-testid"])) {
        return props["data-testid"];
    } else {
        const basis = hasContent(props.label)
            ? props.label
            : props.children?.toString() ?? "";
        if (hasContent(basis)) {
            return `modal-button-${toTestId(basis)}`;
        }
    }
    return undefined;
}