import { BackNextFooter } from "components/shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { Fragment } from "react";
import { VisionPossession } from "services/visionService";
import { hasContent } from "utils/StringUtils";
import { IasBaseModalContent } from "../../IasBaseModalContent";
import styles from "./MoreVisionInfoPage.module.scss";
import { VisionTable } from "./VisionTable";

interface MoreVisionInfoPageProps {
    stepper: JSX.Element;
    onCancel: VoidFunction;
    onBack: VoidFunction;
    onNext: VoidFunction;
    selectedPossessions: Set<VisionPossession>;
    setSelectedPossessions: (possessions: Set<VisionPossession>) => void;
    hasBlendedAssetClasses: boolean;
}

export interface PossessionRowProps {
    possession: VisionPossession,
    accountTypes: AccountTypeDataModel[]
}

export interface SelectionProps {
    addSelections: (possessions: VisionPossession[]) => void;
    removeSelections: (possessions: VisionPossession[]) => void;
}

export function MoreVisionInfoPage(props: MoreVisionInfoPageProps) {
    const { stepper, onCancel, onBack, onNext, selectedPossessions, setSelectedPossessions, hasBlendedAssetClasses } = props;

    const allPossessionsComplete = Array.from(selectedPossessions).every(possession =>
        hasContent(possession.custodian) && hasContent(possession.owner) && hasContent(possession.accountType?.name)
    );

    const visionTable = <VisionTable
        selectedPossessions={selectedPossessions}
        setSelectedPossessions={setSelectedPossessions}
    />;

    return <IasBaseModalContent
        onCancel={onCancel}
        content={
            <Fragment>
                {stepper}
                {visionTable}
            </Fragment>
        }
        footer={
            <BackNextFooter
                backBtn={<ModalActionButton onClick={onBack}>Back</ModalActionButton>}
                nextBtn={
                    <div>
                        {
                            !allPossessionsComplete && <span className={styles.helpText}>
                                All assets/entities above need to be complete to continue.
                            </span>
                        }
                        <ModalActionButton
                            label={hasBlendedAssetClasses ? "Next" : "Finish"}
                            onClick={onNext}
                            disabled={!allPossessionsComplete}
                            className={styles.nextButton}
                        />
                    </div>
                }
            />
        }
    />;
}