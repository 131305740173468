import classnames from "classnames";
import { formatDisplay } from "utils/FormatNumber";
import styles from "./NumberDisplay.module.scss";

interface NumberDisplayProps {
    value: number;
    suppressNegativeStyling?: boolean;
    showZeros?: boolean;
    className?: string;
}

export default function NumberDisplay(props: NumberDisplayProps) {
    const value = Math.round(props.value);
    const suppressNegativeStyling = props.suppressNegativeStyling ?? false;
    const isNegative = value < 0;
    const className = classnames(props.className, { [styles.negative]: !suppressNegativeStyling && isNegative });

    return (
        <span className={className}>{formatDisplay(value, props.showZeros)}</span>
    );
}