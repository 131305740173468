import { ValeoDialog } from "../../../shared/ValeoDialog";
import { AccountPositionViewModel } from "../view-models/AccountPositionViewModel";
import styles from "./EditChangeValueDialog.module.scss";
import { Fragment, useCallback, useMemo, useState } from "react";
import { usePositionMutation } from "services/positionService";
import { IasDataModel } from "../../../../dataModels/iasDataModel";
import { inactiveDisplayOfCurrencyValue, CurrencyEntry } from "../../../numeric-values/CurrencyEntry";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SegmentDataModel } from "dataModels/segmentDataModel";
import NumberDisplay from "components/numeric-values/NumberDisplay";
import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableRow } from "rmwc";
import AccountInfo from "components/shared/AccountInfo";
import classnames from "classnames";

interface EditChangeValueDialogProps {
    viewModel: AccountPositionViewModel;
    open: boolean;
    setOpen: BooleanCallback;
    ias: IasDataModel;
}

interface AssetClassToShow {
    assetClass: AssetClassDataModel;
    segmentPercentages: SegmentPercentage[];
}

interface SegmentPercentage {
    segment: SegmentDataModel;
    displayName: string;
    percentage: number;
}

export function EditChangeValueDialog(props: EditChangeValueDialogProps) {
    const position = props.viewModel.position;
    const account = props.viewModel.account.account;
    const security = props.viewModel.security;

    const assetClasses = props.ias.assetClasses;

    const currentValue = position.overwrittenCurrentValue ?? position.currentValue;

    const [changeValue, setChangeValue] = useState(position.change);
    const [display, setDisplay] = useState(inactiveDisplayOfCurrencyValue(changeValue));
    const [isValid, setIsValid] = useState(true);

    const mutator = usePositionMutation(position.id, account.id, props.ias.id);

    const setChangeValueIfValid = useCallback((value: number) => {
        // This needs to explicitly check for a NaN result since there isn't a guarantee that the paired value of isValid will be updated yet.
        if (!isNaN(value)) {
            setChangeValue(value);
        }
    }, [setChangeValue]);

    const changeValueChanged = useMemo(() => changeValue !== position.change, [changeValue, position.change]);

    const acceptChange = () => {
        if (changeValueChanged) {
            mutator.mutate({
                change: changeValue,
                lastModifiedDate: props.ias.dateModified
            });
        }
    };

    const assetClassesToShow: AssetClassToShow[] = [];

    const storeMatchingSegment = (segment: SegmentDataModel, percentages: SegmentPercentage[], prefix = "") => {
        const match = security.blendedFundPercentages.find(blend => blend.segmentId === segment.id);
        if (match) {
            percentages.push({
                segment,
                displayName: `${prefix}${segment.name}`,
                percentage: match.percentage,
            });
        }
    };

    assetClasses.forEach(assetClass => {
        const segmentPercentages: SegmentPercentage[] = [];

        assetClass.segmentGroups.forEach(group => {
            group.segments.forEach(segment => storeMatchingSegment(segment, segmentPercentages, `${group.name} - `));
        });
        assetClass.segments.forEach(segment => storeMatchingSegment(segment, segmentPercentages));

        if (segmentPercentages.length > 0) {
            assetClassesToShow.push({
                assetClass,
                segmentPercentages,
            });
        }
    });

    return (
        <ValeoDialog
            title="Add Blended Change"
            width={1248}
            open={props.open}
            setOpen={props.setOpen}
            proceedDisabled={!changeValueChanged || !isValid}
            onAccept={acceptChange}
            renderToPortal
        >
            <div>
                <div className={styles.securityInformation}>
                    <span className={styles.ticker}>
                        {props.viewModel.security.tickerSymbol}
                    </span>
                    {props.viewModel.security.positionName}
                </div>

                <div className={styles.elevation}>
                    <div className={styles.informationColumn}>
                        <div className={styles.accountNumber}>
                            {props.viewModel.account.account.accountNumber}
                        </div>
                        <div className={styles.accountDetails}>
                            <AccountInfo
                                account={props.viewModel.account.account}
                                className={styles.whiteSpaceNoWrap}
                            />
                        </div>
                    </div>
                    <div className={styles.valueColumn}>
                        <div className={styles.header}>
                            Current
                        </div>
                        <div className={styles.displayValue}>
                            <NumberDisplay
                                value={currentValue}
                                className={styles.displayValueText}
                            />
                        </div>
                    </div>
                    <div className={styles.valueColumn}>
                        <div className={styles.header}>
                            Change
                        </div>
                        <CurrencyEntry
                            className={styles.textboxControl}
                            numericValue={changeValue}
                            setNumericValue={setChangeValueIfValid}
                            display={display}
                            setDisplay={setDisplay}
                            valueIsValid={isValid}
                            setValueIsValid={setIsValid}
                        />
                    </div>
                    <div className={styles.valueColumn}>
                        <div className={styles.header}>
                            Proposed
                        </div>
                        <div className={styles.displayValue}>
                            <NumberDisplay value={currentValue + changeValue} />
                        </div>
                    </div>
                </div>

                <DataTable className={styles.table}>
                    <DataTableContent>
                        <DataTableBody>
                            {
                                assetClassesToShow.map(assetClass => <Fragment
                                    key={assetClass.assetClass.id}
                                >
                                    <DataTableRow className={styles.assetClassRow}>
                                        <DataTableCell>
                                            {assetClass.assetClass.name}
                                        </DataTableCell>
                                        <DataTableCell />
                                        <DataTableCell />
                                        <DataTableCell />
                                        <DataTableCell />
                                    </DataTableRow>
                                    {
                                        assetClass.segmentPercentages.map(segmentPercentage => <DataTableRow
                                            className={
                                                classnames(styles.segmentRow, {
                                                    [styles.originRow]: segmentPercentage.segment.id === props.viewModel.segmentId,
                                                })
                                            }
                                            data-testid="blended-segment-portion"
                                            key={segmentPercentage.segment.id}
                                        >
                                            <DataTableCell>
                                                {segmentPercentage.displayName}
                                            </DataTableCell>
                                            <DataTableCell>
                                                <NumberDisplay value={currentValue * segmentPercentage.percentage / 100} />
                                            </DataTableCell>
                                            <DataTableCell>
                                                <NumberDisplay value={changeValue * segmentPercentage.percentage / 100} />
                                            </DataTableCell>
                                            <DataTableCell>
                                                <NumberDisplay value={(currentValue + changeValue) * segmentPercentage.percentage / 100} />
                                            </DataTableCell>
                                            <DataTableCell />
                                        </DataTableRow>)
                                    }
                                </Fragment>)
                            }
                        </DataTableBody>
                    </DataTableContent>
                </DataTable>
            </div>
        </ValeoDialog>
    );
}