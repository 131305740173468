import classnames from "classnames";
import { WorkspaceContext } from "contexts/workspaceContext";
import { IconName } from "iconNames";
import { useCallback, useContext, useState } from "react";
import { MenuSurfaceAnchor, Menu, MenuItem, IconButton } from "rmwc";
import styles from "./SecurityMenu.module.scss";

interface CustomGroupRowMenuProps {
    openEditGroupNameAssetClassModal: VoidFunction;
    onEditGroup: VoidFunction;
    openUngroupConfirmation: VoidFunction;
}

export function CustomGroupRowMenu(props: CustomGroupRowMenuProps) {
    const context = useContext(WorkspaceContext);
    const [kebabVisible, setKebabVisible] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
        setKebabVisible(false);
    }, []);

    const handleKebabClick = () => {
        setMenuOpen((menuOpen) => !menuOpen);
    };

    return <MenuSurfaceAnchor className={styles.securityMenu}>
        <Menu
            anchorCorner="bottomLeft"
            open={menuOpen}
            onOpen={() => setKebabVisible(true)}
            onClose={handleMenuClose}
            className={classnames(styles.menu, { [styles.sidebarOpen]: context.sidebarOpen })}
        >

            <MenuItem
                onClick={props.openEditGroupNameAssetClassModal}
                className={styles.menuItem}
            >
                Edit Name/Asset Class
            </MenuItem>
            <MenuItem
                onClick={props.onEditGroup}
                className={styles.menuItem}
            >
                Edit Group...
            </MenuItem>
            <MenuItem
                onClick={props.openUngroupConfirmation}
                className={classnames(styles.menuItem, styles.red)}
            >
                Ungroup
            </MenuItem>
        </Menu>
        {
            !context.readonlyMode && <IconButton
                icon={IconName.MoreVert}
                onClick={handleKebabClick}
                className={classnames({ [styles.visible]: kebabVisible })}
            />
        }
    </MenuSurfaceAnchor>;
}