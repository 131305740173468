import { useCallback, useContext, useMemo, useState } from "react";
import { BaseModalProps } from "components/shared/modal/BaseModal";
import { useCustomGroupCreation } from "services/customGroupService";
import { SegmentDataModel } from "dataModels/segmentDataModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { isSecurityViewModel, SecurityDisplayViewModel } from "../view-models/SecurityDisplayViewModel";
import { CommonCustomGroupModal } from "./CommonCustomGroupModal";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { SecurityViewModel } from "../view-models/SecurityViewModel";
import { sortSecurities } from "../workspaceSorting";
import { WorkspaceContext } from "contexts/workspaceContext";

export interface CreateCustomGroupModalProps extends BaseModalProps {
    open: boolean;
    onClose: VoidFunction;
    ias: IasDataModel;
    segment: SegmentDataModel;
    segmentName: string;
    segmentChildren: SecurityDisplayViewModel[];
    initiatingSecurity: SecurityViewModel | null;
}

export function CreateCustomGroupModal(props: CreateCustomGroupModalProps) {
    const { open, onClose, ias, segment, segmentName, segmentChildren, initiatingSecurity, ...nativeProps } = props;

    const context = useContext(WorkspaceContext);

    const { mutate: createCustomGroup } = useCustomGroupCreation(ias.id, segment.id);

    const [name, setName] = useState("");
    const [customGroupSelections, setCustomGroupSelections] = useState<number[]>(initiatingSecurity ? [initiatingSecurity.security.id] : []);

    const firstSelectedOption: number | undefined = useMemo(() => customGroupSelections.at(0), [customGroupSelections]);

    const options = useMemo(() => {
        const unsortedOptions = segmentChildren
            .filter(isSecurityViewModel)
            .filter(child => !child.isBlended && !child.isInCustomGroup);
        return sortSecurities(unsortedOptions, context.sortBy);
    }, [context.sortBy, segmentChildren]);

    const onSave = useCallback(() => {
        telemetry.trackEvent(EventType.CustomGroupCreated);

        // each security in a custom group has an ID that will never be found in another custom group, so use ID of first selected security
        // save validation prevents default
        const tempId = firstSelectedOption ?? -1;
        createCustomGroup({
            tempId,
            name,
            securityIds: customGroupSelections,
            lastModifiedDate: ias.dateModified,
        });
        onClose();
    }, [createCustomGroup, customGroupSelections, firstSelectedOption, ias.dateModified, name, onClose]);

    return <CommonCustomGroupModal
        open={open}
        onClose={onClose}
        segmentName={segmentName}
        title="Create Group"
        nameFieldDisabled={false}
        onSave={onSave}
        customGroupSelections={customGroupSelections}
        originalCustomGroupSelections={[]}
        setCustomGroupSelections={setCustomGroupSelections}
        customGroupName={name}
        setCustomGroupName={setName}
        options={options}
        {...nativeProps}
    />;
}