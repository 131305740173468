import { Tab, TabBar, TabBarOnActivateEventT } from "rmwc";
import styles from "./ValeoToggle.module.scss";

interface ValeoToggleProps {
    onActivate: (evt: TabBarOnActivateEventT) => void;
    tabOneLabel: string;
    tabTwoLabel: string;
}

export function ValeoToggle(props: ValeoToggleProps) {
    return(
        <TabBar
            className={styles.toggle}
            onActivate={props.onActivate}
        >
            <Tab
                focusOnActivate={false}
                className={`${styles.tab} ${styles.tabOne}`}
            >
                {props.tabOneLabel}
            </Tab>
            <Tab
                focusOnActivate={false}
                className={`${styles.tab} ${styles.tabTwo}`}
            >
                {props.tabTwoLabel}
            </Tab>
        </TabBar>
    );
}