import LinearStepper from "../../shared/LinearStepper";
import styles from "./AddIasStepper.module.scss";

export interface AddIasStepperProps {
    stepIndex: number;
    stepNames: readonly string[];
    pageTitle: JSX.Element;
}

export function AddIasStepper(props: AddIasStepperProps) {
    const { stepIndex, stepNames, pageTitle } = props;

    return <div className={styles.elevation}>
        <LinearStepper
            stepNames={stepNames}
            stepIndex={stepIndex}
            className={styles.stepper}
        />
        <div className={styles.title}>
            {pageTitle}
        </div>
    </div>;
}