import { ClassifiedBlackDiamondHolding, ClassifiedVisionPossession } from "components/client-page/createIas";
import { ClientDataModel } from "dataModels/clientDataModel";
import { IasDataModel, MinimalIasDataModel } from "dataModels/iasDataModel";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { useMemo, useState } from "react";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { isSome } from "utils/isNullOrUndefined";
import { PreloadProps, AddIasWizardViewControllerState } from "../AddIasWizardConstants";
import { LoadFastPassAssets } from "./LoadFastPassAssets";
import { CreateIas } from "./CreateIas";
import { BlackDiamondWorkflow } from "./BlackDiamondWorkflow";

interface FastPassProps extends PreloadProps {
    client: ClientDataModel;
    date: Date;
    name: string;
    modelPortfolios: ModelPortfolioDataModel[];
    setState: Callback<AddIasWizardViewControllerState>;
    mostRecentlyFinalizedMinimal: MinimalIasDataModel;
    onCancel: VoidFunction;
}

export function FastPass(props: FastPassProps) {
    const [ portfolio, setPortfolio ] = useState<PopulatedPortfolio | null>(null);
    const sourcePortfolioId = useMemo(() => props.mostRecentlyFinalizedMinimal.sourcePortfolioId, [props.mostRecentlyFinalizedMinimal.sourcePortfolioId]);
    const [ bdHoldings, setBdHoldings ] = useState<ClassifiedBlackDiamondHolding[] | null>(() => isSome(sourcePortfolioId) ? null : []);
    const [ visionAssets, setVisionAssets ] = useState<ClassifiedVisionPossession[] | null>(null);
    const [mostRecentlyFinalizedIas, setMostRecentlyFinalizedIas] = useState<IasDataModel>();

    if (!isSome(bdHoldings) && isSome(sourcePortfolioId)) {
        return <BlackDiamondWorkflow
            {...props}
            sourcePortfolioId={sourcePortfolioId}
            report={setBdHoldings}
            portfolio={portfolio}
            setPortfolio={setPortfolio}
            bdHoldings={bdHoldings}
            setBdHoldings={setBdHoldings}
        />;
    }

    if (!isSome(visionAssets) || !isSome(mostRecentlyFinalizedIas)) {
        return <LoadFastPassAssets
            setVisionAssets={setVisionAssets}
            visionQuery={props.visionQuery}
            setMostRecentlyFinalized={setMostRecentlyFinalizedIas}
            mostRecentlyFinalizedFullQuery={props.mostRecentlyFinalizedFullQuery}
            accountTypesQuery={props.accountTypesQuery}
            setState={props.setState}
        />;
    }

    return <CreateIas
        blackDiamondHoldings={bdHoldings ?? []}
        visionAssets={visionAssets}
        mostRecentlyFinalized={mostRecentlyFinalizedIas}
        date={props.date}
        name={props.name}
        client={props.client}
        modelPortfolios={props.modelPortfolios}
        portfolio={portfolio}
    />;
}
