import { AccountDataModel } from "../../../../dataModels/accountDataModel";
import { sumValue } from "utils/ArrayUtils";
import { createValueModel, ValueModel } from "../../IasWorkspaceTypes";
import type { AssetClassTableViewModel } from "./AssetClassTableViewModel";

export interface AccountViewModel extends ValueModel {
    readonly account: AccountDataModel;
}

export const createAccount = (account: AccountDataModel, assetClasses: AssetClassTableViewModel[]): AccountViewModel => {
    const getPositionsForAccount = () => assetClasses
        .flatMap(assetClass => assetClass.allSegments())
        .flatMap(segment => segment.allSecurities())
        .flatMap(security => security.accountPositions)
        .filter(accountPosition => accountPosition.account.account === account);
  
    const currentValue = () => sumValue(getPositionsForAccount(), accountPosition => accountPosition.currentValue());
    const changeValue = () => sumValue(getPositionsForAccount(), accountPosition => accountPosition.changeValue());

    return {
        ...createValueModel(currentValue, changeValue),
        account,
    };
};