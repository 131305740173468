import classnames from "classnames";
import { IconButton } from "rmwc";
import { IconName } from "../../iconNames";
import styles from "./ExpandableToggleButton.module.scss";

interface ExpandableToggleButtonProps {
    open: boolean,
    setOpen: (value: boolean) => void,
    className?: string
}

export default function ExpandableToggleButton(props: ExpandableToggleButtonProps) {
    const classes = classnames(styles.button, props.className);
    return (
        <IconButton
            icon={props.open ? IconName.ExpandLess : IconName.ExpandMore}
            className={classes}
            onClick={() => props.setOpen(!props.open)}
        />
    );
}