import { QueryClient, useQuery } from "@tanstack/react-query";
import { CancellationToken } from "utils/CancellationToken";
import Timeout from "await-timeout";
import { getWithAuth, postWithAuth } from "./apiService";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { BlackDiamondAccount } from "dataModels/blackDiamond/account";
import { BlackDiamondHolding } from "dataModels/blackDiamond/holding";
import { BlackDiamondRelationship } from "dataModels/blackDiamond/relationship";
import { addQueryParams } from "utils/AddQueryParams";
import { BlackDiamondConfiguration } from "./configurationService";
import { addSeconds } from "date-fns";
import { isString } from "utils/StringUtils";
import { useCommonErrorDetection } from "./dataErrorService";
import { ConnectionFailure, createConnectionFailure } from "./ConnectionFailure";

enum AuthDestination {
    Token,
    Refresh
}

interface SessionStorageItem {
    clear: VoidFunction;
    set: (value: string) => void;
    get: () => string | null;
}

interface SessionStorage {
    removeItem: (key: string) => void;
    setItem: (key: string, value: string) => void;
    getItem: (key: string) => string | null;
}

const sessionStorage = () : SessionStorage => window.opener?.sessionStorage ?? window.sessionStorage;

let authInFlight = false;

const createSessionStorageItem = (key: string): SessionStorageItem => {
    return {
        clear: () => sessionStorage().removeItem(key),
        set: (value: string) => sessionStorage().setItem(key, value),
        get: () => sessionStorage().getItem(key)
    };
};

const blackDiamondSessionStorage = {
    state: createSessionStorageItem("black-diamond-state"),
    code: createSessionStorageItem("black-diamond-code")
};

interface AuthToken {
    token: string;
    expiration: Date;
    refresh_token: string;
}

interface AuthTokenDataModel {
    access_token: string;
    expires_in: number;
    refresh_token: string;
}

let authToken: AuthToken | null = null;

export const expireToken = () => authToken = null;

export const isAuthorized = (): boolean => {
    return authToken !== null && new Date() < addSeconds(authToken.expiration, -30);
};

export const waitForAuthorizationAsync = async (configuration: BlackDiamondConfiguration, cancellationToken: CancellationToken): Promise<boolean> =>  {
    if (isAuthorized()) {
        return true;
    }

    authInFlight = false;
    authToken = null;

    const code = await getAuthCodeAsync(configuration, cancellationToken);

    if (!isString(code)) {
        return false;
    }

    authToken = await getOrRefreshAuthTokenInternal(code, AuthDestination.Token);
    if (!authToken) {
        return false;
    }
    return true;
};

export const setAuthorizeResponse = (code: string, state: string) => {
    blackDiamondSessionStorage.code.set(code);
    blackDiamondSessionStorage.state.set(state);
};

const generateRandomState = (n: number): string => {
    const validChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const randomBytes = new Uint8Array(n);

    return Array.from(window.crypto.getRandomValues(randomBytes))
        .map(byte => validChars[byte % validChars.length])
        .join("");
};

const getAuthCodeAsync = async (configuration: BlackDiamondConfiguration, cancelAuthorizationFlow: CancellationToken): Promise<string | null> => {
    blackDiamondSessionStorage.code.clear();
    blackDiamondSessionStorage.state.clear();

    const state = generateRandomState(40);

    const window = openLoginWindow(configuration, state);

    while (!cancelAuthorizationFlow.canceled()) {
        const state = blackDiamondSessionStorage.state.get();
        const code = blackDiamondSessionStorage.code.get();
        if (isString(state) && isString(code)) {
            break;
        }
        if (window?.closed ?? true) {
            return null;
        }
        await Timeout.set(250);
    }
    window?.close();

    if (cancelAuthorizationFlow.canceled()) {
        return null;
    }

    if (blackDiamondSessionStorage.state.get() !== state) {
        console.error("CSRF Error");
        return null;
    }

    const code = blackDiamondSessionStorage.code.get();
    blackDiamondSessionStorage.code.clear();
    blackDiamondSessionStorage.state.clear();
    return code;
};

function openLoginWindow(configuration: BlackDiamondConfiguration, state: string) {
    const fullUrl = addQueryParams(configuration.authorizeUrl, {
        response_type: "code",
        client_id: configuration.clientId,
        state: state,
        scope: "offline_access api",
        redirect_uri: configuration.redirectUri
    });

    return window.open(fullUrl,
        "Log in to Black Diamond",
        "width=600,height=700");
}

export const relationshipQueryKey = (clientKeys: string[]) => ["blackDiamondRelationship"].concat(clientKeys);

interface GetRelationshipResponse {
    relationship: BlackDiamondRelationship | null;
    error: string | null;
}

export const getRelationship = async (clientKey: string): Promise<BlackDiamondRelationship | null | ConnectionFailure> => {
    const bdAuthToken = authToken;

    if (!bdAuthToken) {
        throw new Error("Unauthorized");
    }

    const result = await postWithAuth<GetRelationshipResponse>(
        "/bd/relationship/search",
        {
            clientName: clientKey,
        },
        {
            headers: {
                "bdAuthToken": bdAuthToken.token,
            },
        },
    );

    if (isString(result.error)) {
        return createConnectionFailure();
    }

    return result.relationship;
};

export function useRelationshipQuery(clientKey: string, enabled: boolean = true) {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(relationshipQueryKey([clientKey]), () => getRelationship(clientKey), {
        retry: false,
        enabled,
        onError: commonErrorDetection
    });
}

export const accountsQueryKey = (portfolioIds: string[]) => ["blackDiamondAccounts"].concat(portfolioIds);

export interface BlackDiamondAccountResponse extends Omit<BlackDiamondAccount, "type" | "typeId"> {
    accountType: AccountTypeDataModel;
}

interface GetAccountsResponse {
    accounts: BlackDiamondAccountResponse[];
    error: string | null;
}

export const getAccounts = async (relationshipId: string, portfolioId: string): Promise<BlackDiamondAccount[] | ConnectionFailure> => {
    const bdAuthToken = authToken;

    if (!bdAuthToken) {
        throw new Error("Unauthorized");
    }

    const result = await getWithAuth<GetAccountsResponse>(
        `/bd/accounts?relationshipId=${relationshipId}&portfolioId=${portfolioId}`,
        {
            headers: {
                "bdAuthToken": bdAuthToken.token,
            },
        },
    );

    if (isString(result.error)) {
        return createConnectionFailure();
    }

    return result.accounts.map((result) => ({
        ...result,
        type: result.accountType.name,
        typeId: result.accountType.id,
    }));
};

export function useAccountsQuery(relationshipId: string, portfolioId: string) {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(accountsQueryKey([portfolioId]), () => getAccounts(relationshipId, portfolioId), {
        retry: false,
        onError: commonErrorDetection
    });
}

export const holdingsQueryKey = (accountIds: string[]) => ["blackDiamondHoldings"].concat(accountIds);

interface GetHoldingsResponse {
    holdings: BlackDiamondHolding[];
    error: string | null;
}

export const getHoldings = async (accountId: string): Promise<BlackDiamondHolding[] | ConnectionFailure> => {
    const bdAuthToken = authToken;

    if (!bdAuthToken) {
        throw new Error("Unauthorized");
    }

    const result = await getWithAuth<GetHoldingsResponse>(
        `/bd/holdings?accountId=${accountId}`,
        {
            headers: {
                "bdAuthToken": bdAuthToken.token,
            },
        },
    );

    if (isString(result.error)) {
        return createConnectionFailure();
    }

    return result.holdings;
};

export function useHoldingsQuery(accountId: string) {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(holdingsQueryKey([accountId]), () => getHoldings(accountId), {
        retry: false,
        onError: commonErrorDetection
    });
}

export function clearClientQueries(queryClient: QueryClient, clientKey: string) {
    queryClient.removeQueries(relationshipQueryKey([clientKey]));
    queryClient.removeQueries(accountsQueryKey([]));
    queryClient.removeQueries(holdingsQueryKey([]));
}

export interface PopulatedAccount extends BlackDiamondAccount {
    holdings: BlackDiamondHolding[];
}

export interface PopulatedPortfolio {
    readonly id: string;
    readonly accounts: PopulatedAccount[];
}

export const refreshAuthToken = async (): Promise<void> => {
    if (authInFlight) {
        console.error("refresh_token already in flight.");
        return;
    }

    const token = authToken;

    if (!token) {
        console.error("attempting to refresh a null token.");
        return;
    }

    authToken = await getOrRefreshAuthTokenInternal(token.refresh_token, AuthDestination.Refresh);
};

export const getOrRefreshAuthTokenInternal = async (codeOrRefreshToken: string, destination: AuthDestination): Promise<AuthToken | null> => {
    authInFlight = true;
    try {
        const url = destination === AuthDestination.Token ? "/bd/token" : "/bd/token/refresh";
        const result = await postWithAuth<AuthTokenDataModel>(url, codeOrRefreshToken);

        return {
            token: result.access_token,
            expiration: addSeconds(new Date(), Math.max(0, result.expires_in)),
            refresh_token: result.refresh_token
        };
    } catch {
        return null;
    }
    finally {
        authInFlight = false;
    }
};