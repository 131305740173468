import { PropsWithChildren } from "react";
import { DialogTitle } from "rmwc";
import styles from "./BaseModalHeader.module.scss";

export interface BaseModalHeaderProps extends PropsWithChildren {}

export function BaseModalHeader(props: BaseModalHeaderProps) {
    return <DialogTitle className={styles.dialogTitle}>
        {props.children}
    </DialogTitle>;
}