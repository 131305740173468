import classnames from "classnames";
import { FormEvent, useState } from "react";
import styles from "./EditNameDialog.module.scss";
import { ValeoDialog } from "../../shared/ValeoDialog";
import { useIasMutation } from "services/iasService";
import { ValeoTextField } from "../../shared/ValeoTextField";
import { IasDataModel } from "../../../dataModels/iasDataModel";

export interface EditNameDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    initialName: string;
    ias: IasDataModel;
}

export function EditNameDialog(props: EditNameDialogProps) {
    const { open, setOpen, initialName, ias } = props;
    const mutation = useIasMutation(ias.id);
    const [name, setName] = useState(initialName);

    const onAccept = () => {
        if (name !== initialName) {
            mutation.mutate({ lastModifiedDate: ias.dateModified, title: name });
        }
    };

    const onOpen = () => {
        setName(initialName);
    };

    const onTextFieldChange = (event: FormEvent<HTMLInputElement>) => setName(event.currentTarget.value);

    const textFieldClassNames = classnames(styles.nameTextField, {
        [styles.nonEmptyName]: name.length > 0
    });

    return <ValeoDialog
        title="IAS Name"
        width={448}
        fixedHeight={203}
        open={open}
        setOpen={setOpen}
        onOpen={onOpen}
        proceedDisabled={name === initialName}
        onAccept={onAccept}
        testId="edit-name-dialog"
    >
        <div className={styles.content}>
            <ValeoTextField
                label="Name"
                value={name}
                onInput={onTextFieldChange}
                className={textFieldClassNames}
                maxLength={60}
            />
        </div>
    </ValeoDialog>;
}