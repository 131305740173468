import classnames from "classnames";
import { Card, Elevation, Tooltip, Typography } from "rmwc";
import { createRef, CSSProperties, MouseEventHandler, useEffect, useState } from "react";
import styles from "./TitleValueCard.module.scss";

interface TitleValueCardProps {
    title: string;
    value: string;
    maxLines: number;
    editable: boolean;
    onClick?: MouseEventHandler<HTMLElement>;
    className?: string;
}

export function TitleValueCard(props: TitleValueCardProps) {
    const { title, value, maxLines, onClick, editable } = props;
    const lineClamp = {
        "WebkitLineClamp": maxLines
    } as CSSProperties;

    const classNames = classnames(props.className, styles.cardElevation, styles.important, {
        clickable: editable,
    });

    const [isEllipsisActive, setIsEllipsisActive] = useState(false);
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        const element = ref.current;
        setIsEllipsisActive(
            element
                ? element.clientHeight < element.scrollHeight
                : false
        );
    }, [ref]);

    const valueTooltip = <Typography use="headline6">{value}</Typography>;
    const valueText = <div
        className={styles.valueBox}
        style={lineClamp}
        ref={ref}
    >
        <span
            className={styles.value}
            data-testid="title-value-card-value"
        >{value}</span>
    </div>;

    return (<Elevation
        z={2}
        className={classNames}
    >
        <Card
            className={styles.card}
            onClick={onClick}
            data-testid={`Title Value Card ${title}`}
        >
            <div>
                <Typography
                    use="caption"
                    className={styles.title}
                >{title}</Typography>
                {
                    editable && <img
                        src="/edit-and-create.svg"
                        alt="Edit and create."
                        width={24}
                        height={24}
                        className={styles.floatRight}
                    />
                }
            </div>
            {
                isEllipsisActive &&
                <Tooltip
                    content={valueTooltip}
                    align="bottom"
                >
                    {valueText}
                </Tooltip>
            }
            {!isEllipsisActive && valueText}
        </Card>
    </Elevation>);
}
