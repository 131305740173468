import { BaseModal } from "components/shared/modal/BaseModal";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { LoadingPage } from "./LoadingPage";
import { isNumber } from "utils/NumberUtils";
import { differenceWith, uniqBy } from "lodash";
import { BDHoldingClassificationPage } from "./BDHoldingsClassificationPage";
import { BlackDiamondHolding } from "dataModels/blackDiamond/holding";
import { VisionResultsPage } from "./VisionResultsPage";
import { clearVisionQueries, useVisionData, VisionCategory, VisionPossession } from "services/visionService";
import { isVisionResponse } from "services/isVisionResponse";
import { useQueryClient } from "@tanstack/react-query";
import { UpdateDataPage } from "./UpdateDataPage";
import { isMatchingSecurity } from "utils/securityUtils";
import { DataSource } from "constants/dataSource";
import { filterMap } from "utils/ArrayUtils";
import { UpdateMoreVisionInfo } from "./UpdateMoreVisionInfo";
import { ClassifiedBlackDiamondHolding, ClassifiedVisionPossession } from "components/client-page/createIas";
import { usePropWatch } from "utils/usePropWatch";
import { Segment } from "constants/segment";
import { BlendDefinition, BlendedAssetClassesPage } from "components/client-page/add-ias-wizard/pages/blended/BlendedAssetClassesPage";
import { BlendedFundSegmentPercentage } from "dataModels/blendedFundSegmentPercentage";
import sharedStyles from "./UpdateDataWizardPage.module.scss";
import { UpdateDataWizardBaseModalContent } from "./UpdateDataWizardBaseModalContent";
import { isConnectionFailure } from "services/ConnectionFailure";
import type { UpdateDataWizardProps } from "./UpdateDataWizard";
import { isTokenRevokedError } from "services/TokenRevokedError";

enum WizardPage {
    Loading,
    BDHoldingsClassification,
    VisionResults,
    MoreVisionInfo,
    BlendedAssetClasses,
    TRIGGER_FINISH
}

interface UpdateDataWizardInternalProps extends UpdateDataWizardProps {
    reportVisionConnectionFailed: VoidFunction;
    reportTokenRevokedError: VoidFunction;
    reportBDConnectionFailed: VoidFunction;
}

export function UpdateDataWizardContents(props: UpdateDataWizardInternalProps) {
    const { close, clientKey, ias } = props;
    const { assetClasses } = ias;

    const queryClient = useQueryClient();
    const { status: visionStatus, data: visionData } = useVisionData(clientKey);
    const [pageIndex, setPageIndex] = useState(WizardPage.Loading);
    const [visionQueriesCleared, setVisionQueriesCleared] = useState(false);

    const visionCategories: VisionCategory[] = useMemo(() => isVisionResponse(visionData) ? visionData.categories : [], [visionData]);

    useEffect(() => {
        if (isConnectionFailure(visionData)) {
            props.reportVisionConnectionFailed();
        }

        if (isTokenRevokedError(visionData)) {
            props.reportTokenRevokedError();
        }
    }, [props, visionData]);

    const [populatedPortfolio, setPopulatedPortfolio] = useState<PopulatedPortfolio>();

    const [showVisionResults, setShowVisionResults] = useState(false);

    useEffect(() => {
        clearVisionQueries(queryClient);
        setVisionQueriesCleared(true);
    }, [clientKey, queryClient]);

    const newBDHoldings = useMemo(() => populatedPortfolio?.accounts.flatMap(bdAccount => {
        if (!ias.accounts.some(account => account.accountNumber === bdAccount.accountNumber)) {
            return bdAccount.holdings;
        } else {
            return bdAccount.holdings.filter(h => !ias.accounts.some(a => a.positions.some(p => p.sourceId === h.assetId)));
        }
    }) ?? [], [populatedPortfolio?.accounts, ias.accounts]);

    const [unclassifiedNewBlackDiamondHoldings, setUnclassifiedNewBlackDiamondHoldings] = useState<BlackDiamondHolding[]>([]);

    const getClassifiedNewBlackDiamondHoldings = useCallback(() => unclassifiedNewBlackDiamondHoldings.map(holding => ({
        ...holding,
        blendedFundPercentages: [],
    })), [unclassifiedNewBlackDiamondHoldings]);
    const [classifiedNewBlackDiamondHoldings, setClassifiedNewBlackDiamondHoldings] = usePropWatch<ClassifiedBlackDiamondHolding[]>(getClassifiedNewBlackDiamondHoldings);

    const blendedNewBlackDiamondHoldings: ClassifiedBlackDiamondHolding[] = useMemo(() => {
        return classifiedNewBlackDiamondHoldings.filter(holding => holding.segmentId === Segment.Blended);
    }, [classifiedNewBlackDiamondHoldings]);

    const getNewBlackDiamondHoldingBlendDefinitions = useCallback(() => blendedNewBlackDiamondHoldings.map(holding => ({
        id: holding.assetId,
        dataSource: DataSource.BlackDiamond,
        securityTicker: holding.ticker ?? "",
        securityName: holding.name,
        percentages: holding.blendedFundPercentages,
    })), [blendedNewBlackDiamondHoldings]);
    const [newBlackDiamondHoldingBlendDefinitions, setNewBlackDiamondHoldingBlendDefinitions] = usePropWatch<BlendDefinition[]>(getNewBlackDiamondHoldingBlendDefinitions);

    const [selectedVisionPossessions, setSelectedVisionPossessions] = useState<Set<VisionPossession>>(new Set<VisionPossession>());
    const [originalSelectedVisionPossessions, originalSetSelectedVisionPossessions] = useState<Set<VisionPossession>>(new Set<VisionPossession>());
    const [newVisionSelections, setNewVisionSelections] = useState<Set<VisionPossession>>(new Set());
    const clientHasVisionData = useMemo(() => visionCategories.length > 0, [visionCategories]);

    const hasNewVisionSelections = useMemo(() => newVisionSelections.size > 0, [newVisionSelections.size]);

    const classifiedSelectedVisionPossessions = useMemo(() => Array.from(selectedVisionPossessions).map(possession => ({
        ...possession,
        blendedFundPercentages: [],
    })), [selectedVisionPossessions]);

    const getClassifiedNewVisionPossessions = useCallback(() => Array.from(newVisionSelections).map(possession => ({
        ...possession,
        blendedFundPercentages: [],
    })), [newVisionSelections]);
    const [classifiedNewVisionPossessions, setClassifiedNewVisionPossessions] = usePropWatch<ClassifiedVisionPossession[]>(getClassifiedNewVisionPossessions);

    const blendedNewVisionPossessions: ClassifiedVisionPossession[] = useMemo(() => {
        return classifiedNewVisionPossessions.filter(possession => possession.segmentId === Segment.Blended);
    }, [classifiedNewVisionPossessions]);

    const getNewVisionPossessionBlendDefinitions = useCallback(() => blendedNewVisionPossessions.map(possession => ({
        id: possession.id,
        dataSource: DataSource.Vision,
        securityTicker: "",
        securityName: possession.account ?? possession.name,
        percentages: possession.blendedFundPercentages,
    })), [blendedNewVisionPossessions]);
    const [newVisionPossessionBlendDefinitions, setNewVisionPossessionBlendDefinitions] = usePropWatch<BlendDefinition[]>(getNewVisionPossessionBlendDefinitions);

    const hasNewBlendedAssetClasses: boolean = useMemo(() => {
        return blendedNewBlackDiamondHoldings.length > 0 || blendedNewVisionPossessions.length > 0;
    }, [blendedNewBlackDiamondHoldings.length, blendedNewVisionPossessions.length]);

    const newBlendDefinitions = useMemo(() =>
        newBlackDiamondHoldingBlendDefinitions.concat(newVisionPossessionBlendDefinitions)
    , [newBlackDiamondHoldingBlendDefinitions, newVisionPossessionBlendDefinitions]);

    const setNewBlendDefinitions = useCallback((blendDefinitions: BlendDefinition[]) => {
        const blackDiamondBlendDefinitions = blendDefinitions.filter(blend => blend.dataSource === DataSource.BlackDiamond);
        setNewBlackDiamondHoldingBlendDefinitions(blackDiamondBlendDefinitions);
        setClassifiedNewBlackDiamondHoldings((existingHoldings) => existingHoldings.map(existingHolding => {
            const matchingDefinition = blackDiamondBlendDefinitions.find(blend => blend.id === existingHolding.assetId);
            return {
                ...existingHolding,
                blendedFundPercentages: matchingDefinition
                    ? filterMap(matchingDefinition.percentages, percentage => isNumber(percentage.segmentId) ? percentage : null) as BlendedFundSegmentPercentage[]
                    : existingHolding.blendedFundPercentages,
            };
        }));

        const visionBlendDefinitions = blendDefinitions.filter(blend => blend.dataSource === DataSource.Vision);
        setNewVisionPossessionBlendDefinitions(visionBlendDefinitions);
        setClassifiedNewVisionPossessions((existingHoldings) => existingHoldings.map(existingHolding => {
            const matchingDefinition = visionBlendDefinitions.find(blend => blend.id === existingHolding.id);
            return {
                ...existingHolding,
                blendedFundPercentages: matchingDefinition
                    ? filterMap(matchingDefinition.percentages, percentage => isNumber(percentage.segmentId) ? percentage : null) as BlendedFundSegmentPercentage[]
                    : existingHolding.blendedFundPercentages,
            };
        }));
    }, [setNewBlackDiamondHoldingBlendDefinitions, setClassifiedNewBlackDiamondHoldings, setClassifiedNewVisionPossessions, setNewVisionPossessionBlendDefinitions]);

    const pageIndexWhenBlendedIsNext: WizardPage = useMemo(() => {
        return hasNewBlendedAssetClasses
            ? WizardPage.BlendedAssetClasses
            : WizardPage.TRIGGER_FINISH;
    }, [hasNewBlendedAssetClasses]);

    const pageIndexWhenVisionIsNext: WizardPage = useMemo(() => {
        return clientHasVisionData
            ? WizardPage.VisionResults
            : pageIndexWhenBlendedIsNext;
    }, [clientHasVisionData, pageIndexWhenBlendedIsNext]);

    useEffect(() => {
        const holdings = newBDHoldings.filter(h =>
            !isNumber(h.segmentId) &&
            !ias.securities.some(s => isMatchingSecurity(s, h.ticker, h.name)));
        setUnclassifiedNewBlackDiamondHoldings(uniqBy(holdings, "name"));
    }, [ias.securities, newBDHoldings]);

    useEffect(() => {
        const visionPositions = ias.accounts.flatMap(a => a.positions.filter(p => p.dataSource === DataSource.Vision));
        const visionPossessions = visionCategories.flatMap(c => c.subcategories.flatMap(s => filterMap(s.possessions, possession => {
            const position = visionPositions.find(p => p.sourceId === possession.id);
            const security = ias.securities.find(s => s.id === position?.securityId);
            if(position === undefined) {
                return null;
            }
            return {
                ...possession,
                segmentId: security?.segmentId,
                blendedFundPercentages: [],
            };
        })));
        setSelectedVisionPossessions(new Set<VisionPossession>(visionPossessions));
        originalSetSelectedVisionPossessions(new Set<VisionPossession>(visionPossessions));
    }, [ias.accounts, ias.securities, visionCategories]);

    const modalRef = createRef<HTMLElement>();

    const scrollToTop = useCallback(() => modalRef.current?.scroll(0, 0), [modalRef]);

    const onBDNext = useCallback(() => {
        scrollToTop();
        setPageIndex(pageIndexWhenVisionIsNext);
    }, [pageIndexWhenVisionIsNext, scrollToTop]);

    useEffect(() => {
        setNewVisionSelections(new Set(differenceWith(Array.from(selectedVisionPossessions), ias.accounts.flatMap(a => a.positions), (a, b) => a.id === b.sourceId && b.dataSource === DataSource.Vision)));
    }, [ias.accounts, selectedVisionPossessions]);

    const onVisionBack = useCallback(() => {
        scrollToTop();
        setPageIndex(WizardPage.BDHoldingsClassification);
    }, [scrollToTop]);

    const onVisionNext = useCallback(() => {
        scrollToTop();
        setPageIndex(newVisionSelections.size > 0
            ? WizardPage.MoreVisionInfo
            : pageIndexWhenBlendedIsNext
        );
    }, [newVisionSelections.size, pageIndexWhenBlendedIsNext, scrollToTop]);

    const onMoreVisionInfoBack = useCallback(() => {
        scrollToTop();
        setPageIndex(WizardPage.VisionResults);
    }, [scrollToTop]);

    const onMoreVisionInfoNext = useCallback(() => {
        scrollToTop();
        setPageIndex(pageIndexWhenBlendedIsNext);
    }, [pageIndexWhenBlendedIsNext, scrollToTop]);

    const onLoadingSuccess = useCallback(() => {
        if (visionQueriesCleared) {
            scrollToTop();
            const holdingsToClassify = newBDHoldings.some(h =>
                !isNumber(h.segmentId) &&
                !ias.securities.some(s => isMatchingSecurity(s, h.ticker, h.name)));
            setPageIndex(holdingsToClassify
                ? WizardPage.BDHoldingsClassification
                : pageIndexWhenVisionIsNext
            );
        }
    }, [ias.securities, newBDHoldings, pageIndexWhenVisionIsNext, scrollToTop, visionQueriesCleared]);

    const onBlendedBack = useCallback(() => {
        scrollToTop();
        setPageIndex(clientHasVisionData
            ? WizardPage.VisionResults
            : WizardPage.BDHoldingsClassification
        );
    }, [clientHasVisionData, scrollToTop]);

    const onBlendedNext = useCallback(() => {
        scrollToTop();
        setPageIndex(WizardPage.TRIGGER_FINISH);
    }, [scrollToTop]);

    const displayPage = useCallback((index: number) => {
        switch (index) {
        case WizardPage.Loading:
            return <LoadingPage
                clientKey={clientKey}
                sourcePortfolioId={ias.sourcePortfolioId}
                visionStatus={visionStatus}
                populatedPortfolio={populatedPortfolio}
                setPopulatedPortfolio={setPopulatedPortfolio}
                onSuccess={onLoadingSuccess}
                reportConnectionFailure={props.reportBDConnectionFailed}
            />;
        case WizardPage.BDHoldingsClassification:
            return <BDHoldingClassificationPage
                blackDiamondAccounts={populatedPortfolio?.accounts ?? []}
                unclassifiedHoldings={unclassifiedNewBlackDiamondHoldings}
                setUnclassifiedHoldings={setUnclassifiedNewBlackDiamondHoldings}
                assetClasses={assetClasses}
                clientHasVisionData={clientHasVisionData}
                hasNewBlendedAssetClasses={hasNewBlendedAssetClasses}
                onCancel={close}
                onNext={onBDNext}
            />;
        case WizardPage.VisionResults:
            return <VisionResultsPage
                onCancel={close}
                onNext={onVisionNext}
                onBack={unclassifiedNewBlackDiamondHoldings.length > 0 ? onVisionBack : undefined}
                assetClasses={assetClasses}
                visionCategories={visionCategories}
                selectedPortfolio={populatedPortfolio ?? null}
                selectedPossessions={selectedVisionPossessions}
                setSelectedPossessions={setSelectedVisionPossessions}
                iasSecurities={ias.securities}
                showVisionResults={showVisionResults}
                setShowVisionResults={setShowVisionResults}
                hasNewVisionSelections={hasNewVisionSelections}
                hasNewBlendedAssetClasses={hasNewBlendedAssetClasses}
            />;
        case WizardPage.MoreVisionInfo:
            return <UpdateMoreVisionInfo
                onCancel={close}
                onBack={onMoreVisionInfoBack}
                onNext={onMoreVisionInfoNext}
                selectedPossessions={newVisionSelections}
                setSelectedPossessions={setNewVisionSelections}
                hasNewBlendedAssetClasses={hasNewBlendedAssetClasses}
            />;
        case WizardPage.BlendedAssetClasses:
            return <BlendedAssetClassesPage
                WizardBaseModalContent={UpdateDataWizardBaseModalContent}
                stepper={
                    <div className={sharedStyles.title}>Define the Blended Asset Classes.</div>
                }
                onCancel={close}
                onBack={onBlendedBack}
                onNext={onBlendedNext}
                assetClasses={assetClasses}
                blendDefinitions={newBlendDefinitions}
                setBlendDefinitions={setNewBlendDefinitions}
            />;
        default:
            return <UpdateDataPage
                populatedPortfolio={populatedPortfolio}
                classifiedHoldings={classifiedNewBlackDiamondHoldings}
                newVisionPossessions={classifiedNewVisionPossessions}
                visionPossessions={Array.from(classifiedSelectedVisionPossessions)}
                originalVisionPossessions={Array.from(originalSelectedVisionPossessions)}
                ias={ias}
            />;
        }
    }, [assetClasses, classifiedNewBlackDiamondHoldings, classifiedNewVisionPossessions, classifiedSelectedVisionPossessions, clientHasVisionData, clientKey, close, hasNewBlendedAssetClasses, hasNewVisionSelections, ias, newBlendDefinitions, newVisionSelections, onBDNext, onBlendedBack, onBlendedNext, onLoadingSuccess, onMoreVisionInfoBack, onMoreVisionInfoNext, onVisionBack, onVisionNext, populatedPortfolio, props.reportBDConnectionFailed, selectedVisionPossessions, setNewBlendDefinitions, showVisionResults, unclassifiedNewBlackDiamondHoldings, visionCategories, visionStatus, originalSelectedVisionPossessions]);

    return <BaseModal
        open
        ref={modalRef}
        data-testid="update-data-modal"
    >
        {displayPage(pageIndex)}
    </BaseModal>;
}