import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { isSome } from "utils/isNullOrUndefined";
import { hasContent } from "utils/StringUtils";
import { getWithAuth, postWithAuth } from "./apiService";
import { ConnectionFailure, createConnectionFailure, isConnectionFailure } from "./ConnectionFailure";
import { useCommonErrorDetection } from "./dataErrorService";
import { iasQueryKey } from "./iasService";
import { getCachedIas } from "./positionService";

export enum TradeDataExceptionReason {
    UndeterminableSharePrice = "UndeterminableSharePrice",
    ZeroSharesTrade = "ZeroSharesTrade"
}

export interface TradeDataException {
    ticker: string;
    reason: TradeDataExceptionReason;
}

interface TradeData {
    trades: string;
    exceptions: TradeDataException[];
}

export function useCreateGetTradeData(iasId: number): () => Promise<TradeData | null> {
    const commonErrorDetection = useCommonErrorDetection();
    return useCallback(async () => {
        try
        {
            return await getWithAuth<TradeData>(`/tradedata/${iasId}`);
        }
        catch (e)
        {
            commonErrorDetection(e);
            return null;
        }
    }, [commonErrorDetection, iasId]);
}

interface PostTradeDataWireResponse {
    tradeTime: string | null;
    exceptions: TradeDataException[] | null;
    error: string | null;
}

interface UploadTradeDataResponse {
    tradeTime: Date;
    exceptions: TradeDataException[];
}

export function isTradeResponse(item: UploadTradeDataResponse | ConnectionFailure | null | undefined): item is UploadTradeDataResponse {
    return isSome(item) && !isConnectionFailure(item);
}

async function postTradeDataAsync(iasId: number): Promise<UploadTradeDataResponse | ConnectionFailure> {
    const response = await postWithAuth<PostTradeDataWireResponse>(`/tradedata/${iasId}`, {});

    if (hasContent(response.error) || !isSome(response.tradeTime) || !isSome(response.exceptions)) {
        return createConnectionFailure();
    }

    return {
        exceptions: response.exceptions,
        tradeTime: new Date(response.tradeTime)
    };
}

export function useUploadTradeDataMutator(iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation(["uploadTradeData", iasId], () => postTradeDataAsync(iasId), {
        onError: commonErrorDetection,
        onMutate: () => updateTradeTime(queryClient, iasId, new Date()),
        onSuccess: (response) => updateTradeTime(queryClient, iasId, isTradeResponse(response) ? response.tradeTime : null)
    });
}

function updateTradeTime(queryClient: QueryClient, iasId: number, date: Date | null) {
    const cachedIas = getCachedIas(queryClient, iasId);
    if (isSome(cachedIas)) {
        queryClient.setQueryData(iasQueryKey(iasId), {
            ...cachedIas,
            fidelityTradesSentTimestamp: date
        });
    }
}