import { BaseModal } from "components/shared/modal/BaseModal";
import { BaseModalContent } from "components/shared/modal/BaseModalContent";
import { BaseModalFooter } from "components/shared/modal/footer/BaseModalFooter";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { CancelHeader } from "components/shared/modal/header/CancelHeader";
import { ValeoSelect } from "components/shared/ValeoSelect";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { DataSource } from "constants/dataSource";
import { AccountDataModel } from "dataModels/accountDataModel";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { isEmpty } from "lodash";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { AccountInformation, useAccountMutation } from "services/accountService";
import { accountsMatch } from "utils/AccountUtils";
import { isWhiteSpace } from "utils/StringUtils";
import styles from "./EditAccountModal.module.scss";

interface EditAccountModalProps {
    accountData: AccountDataModel,
    open: boolean,
    setOpen: BooleanCallback,
    accountTypes: AccountTypeDataModel[],
    iasId: number,
    lastModifiedDate: Date,
    iasAccounts: AccountDataModel[]
}

export function EditAccountModal(props: EditAccountModalProps) {
    const { accountData, open, setOpen, accountTypes, iasId, lastModifiedDate, iasAccounts } = props;

    const [originalAccount] = useState(accountData);
    const [account, setAccount] = useState(accountData);
    const [showContent, setShowContent] = useState(false);
    const mutator = useAccountMutation(iasId, accountData.id);

    const accountHasChanged = useMemo(() => !accountsMatch(account, originalAccount), [account, originalAccount]);

    const invalidFields = useMemo(() => isWhiteSpace(account.accountNumber)
        || isWhiteSpace(account.owner)
        || isWhiteSpace(account.custodian)
    , [account.accountNumber, account.custodian, account.owner]);

    const accountAlreadyExists = useMemo(() => iasAccounts.some(a => accountsMatch(a, account)), [account, iasAccounts]);

    const onAccountNumberChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        setAccount({ ...account, accountNumber: evt.target.value });
    }, [account]);

    const onOwnerChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        setAccount({ ...account, owner: evt.target.value });
    }, [account]);

    const onCustodianChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
        setAccount({ ...account, custodian: evt.target.value });
    }, [account]);

    const onSelectAccountType = useCallback((optionIndex: number) => {
        const accountType = accountTypes[optionIndex];
        setAccount({ ...account, typeId: accountType.id, type: accountType.name, taxable: accountType.taxable });
    }, [account, accountTypes]);

    const isBlackDiamondAccount = account.dataSource === DataSource.BlackDiamond;

    const onSave = useCallback(() => {
        const mutations: Omit<AccountInformation, "lastModifiedDate"> = {};

        if (account.accountNumber !== originalAccount.accountNumber) {
            mutations.accountNumber = account.accountNumber;
        }
        if (account.owner !== originalAccount.owner) {
            mutations.owner = account.owner;
        }
        if (account.custodian !== originalAccount.custodian) {
            mutations.custodian = account.custodian;
        }
        if (account.typeId !== originalAccount.typeId) {
            mutations.typeId = account.typeId;
        }

        if (!isEmpty(mutations)) {
            mutator.mutate({ lastModifiedDate, ...mutations });
        }

        setOpen(false);
    }, [account.accountNumber, account.custodian, account.owner, account.typeId, lastModifiedDate, mutator, originalAccount.accountNumber, originalAccount.custodian, originalAccount.owner, originalAccount.typeId, setOpen]);

    const editAccountInfo = <div className={styles.modalContent}>
        <div className={styles.accountInfo}>Account Info</div>
        <div className={styles.form}>
            <ValeoTextField
                label="Account"
                value={account.accountNumber}
                onChange={onAccountNumberChange}
                className={styles.account}
                disabled={isBlackDiamondAccount}
            />
            <ValeoTextField
                label="Owner"
                value={account.owner}
                onChange={onOwnerChange}
                className={styles.owner}
            />
            <ValeoTextField
                label="Custodian"
                value={account.custodian}
                onChange={onCustodianChange}
                className={styles.custodian}
            />
            <ValeoSelect
                label="Choose Type"
                options={accountTypes.map(accountType => accountType.name)}
                value={account.type}
                onSelectIndex={onSelectAccountType}
                className={styles.typeSelect}
            />
        </div>
        <div className={styles.optionalText}>
            {isBlackDiamondAccount && <div className={styles.helpText}>You can not edit BD account names.</div>}
        </div>
    </div>;

    return <BaseModal
        open={open}
        onOpen={() => setShowContent(true)}
        onClose={() => setShowContent(false)}
    >
        <BaseModalContent
            width={844}
            header={
                <CancelHeader
                    title="Edit Account"
                    cancelBtn={
                        <ModalActionButton
                            isDefaultAction={false}
                            onClick={() => setOpen(false)}
                        >
                    Cancel
                        </ModalActionButton>
                    }
                />
            }
            content={showContent ? editAccountInfo : <div />}
            footer={
                <BaseModalFooter>
                    <div className={styles.footerFiller} />
                    <div>
                        {accountAlreadyExists && <div className={styles.duplicateAccount}>This account info already exists.</div>}
                    </div>
                    <ModalActionButton
                        isDefaultAction={false}
                        disabled={!accountHasChanged || invalidFields || accountAlreadyExists}
                        onClick={onSave}
                        className={styles.saveButton}
                    >
                    Save
                    </ModalActionButton>
                </BaseModalFooter>
            }
        />
    </BaseModal>;
}