import { useCallback, useEffect, useMemo, useState } from "react";
import { usePositionCreation } from "services/positionService";
import { isNumber } from "utils/NumberUtils";
import { inactiveDisplayOfBlankZerosCurrencyValue } from "components/numeric-values/BlankZerosCurrencyEntry";
import { isCurrencyValueValid } from "components/numeric-values/CurrencyEntry";
import { useNumericTextField } from "components/numeric-values/useNumericTextField";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";
import { AddPositionModal } from "./AddPositionModal";
import { useSecurityQuery } from "services/securityService";
import { hasContent, isCaseInsensitiveContentMatch } from "utils/StringUtils";
import { AccountForCreation, useAccountCreation } from "services/accountService";
import { noAccountSelectedId } from "./AccountSelect";
import { DataSource } from "constants/dataSource";
import { WritableSecurity } from "services/iasService";
import { AssetClassNames } from "constants/assetClassNames";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { BlendedFundPercentage } from "../../shared/blended-fund/BlendedFundControls";
import { Segment } from "constants/segment";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { PositionAddedResponse } from "./AddPositionCommon";

export interface AddPositionProps {
    open: boolean;
    setOpen: BooleanCallback;
    assetTableViewModel: AssetTablesViewModel;
    assetClasses: AssetClassDataModel[];
    recommendedFundSecurities: SecurityLookupDataModel[];
    accountTypes: AccountTypeDataModel[];
}

export function AddPosition(props: AddPositionProps) {
    const { open, setOpen, assetTableViewModel, assetClasses, recommendedFundSecurities, accountTypes } = props;
    const { ias } = assetTableViewModel;
    const lastModifiedDate = ias.dateModified;

    const [ticker, internalSetTicker] = useState<string>("");
    const setTicker = useCallback((value: string) => internalSetTicker(value.trim()), []) as FunctionStringCallback;
    const [manualName, setManualName] = useState("");
    const [accountId, setAccountId] = useState<number | null>(null);
    const currentValue = useNumericTextField(0, inactiveDisplayOfBlankZerosCurrencyValue, isCurrencyValueValid);
    const [segmentId, setSegmentId] = useState<number | null>(null);
    const [newAccount, setNewAccount] = useState<AccountForCreation>({
        iasId: ias.id,
        lastModifiedDate: lastModifiedDate,
        accountNumber: "",
        owner: "",
        custodian: "",
        originalCustodian: undefined,
        dataSource: DataSource.Manual,
        typeId: 0,
        accountTypeName: "",
        taxable: true,
        positions: [],
        internalComment: "",
        clientComment: ""
    });
    const [blendedFundPercentages, setBlendedFundPercentages] = useState<BlendedFundPercentage[]>([]);

    const { mutateAsync: createPosition } = usePositionCreation(ias.id);
    const { mutateAsync: createAccount } = useAccountCreation(ias.id);

    const { existingSecurity, matchedByName } = useMemo(() => {
        if (hasContent(ticker)) {
            const found = ias.securities.find((security) => isCaseInsensitiveContentMatch(security.tickerSymbol, ticker));
            if (found) {
                return { existingSecurity: found, matchedByName: false};
            }
        }
        if (hasContent(manualName)) {
            const found = ias.securities.find((security) => !hasContent(security.tickerSymbol) && isCaseInsensitiveContentMatch(security.positionName, manualName));
            if (found) {
                return { existingSecurity: found, matchedByName: true };
            }
        }
        return { existingSecurity: undefined, matchedByName: false };
    }, [ias.securities, manualName, ticker]);

    const { data: security, status: securityStatus } = useSecurityQuery(ticker.toUpperCase(), hasContent(ticker) && !existingSecurity);

    useEffect(() => {
        if (ticker !== ticker.toUpperCase()) {
            setTicker(ticker.toUpperCase());
        }
    }, [setTicker, ticker]);

    const cashSegments = useMemo(() => props.assetTableViewModel.cash.segments, [props.assetTableViewModel]);
    const actualCashSegment = useMemo(() => cashSegments.find(segment => segment.segment.name === AssetClassNames.Cash), [cashSegments]);
    const cashSegmentId = actualCashSegment?.segment.id ?? null;
    const otherCashSegmentIds = useMemo(() => cashSegments.filter(other => other !== actualCashSegment).map(segment => segment.segment.id), [cashSegments, actualCashSegment]);

    useEffect(() => {
        let matchedId = existingSecurity?.segmentId ?? security?.segmentId ?? null;

        // For matched securities with cash-like segments, use the cash segment specifically (it is the only one in the dropdown)
        if (isNumber(matchedId) && otherCashSegmentIds.includes(matchedId)) {
            matchedId = cashSegmentId;
        }

        setSegmentId(matchedId);
    }, [existingSecurity?.segmentId, security?.segmentId, cashSegmentId, otherCashSegmentIds]);

    const nameResultForTicker = useMemo(() => (!matchedByName ? existingSecurity?.positionName : undefined) ?? security?.name ?? "",
        [existingSecurity?.positionName, matchedByName, security?.name]);
    
    const addingNewAccount = useMemo(() => accountId === noAccountSelectedId, [accountId]);

    const addPosition = useCallback(async (): Promise<PositionAddedResponse> => {
        const name = hasContent(nameResultForTicker) ? nameResultForTicker : manualName;
        if (isNumber(segmentId)) {
            const securityId = existingSecurity ? existingSecurity.id : -Date.now();
            const newPosition = {
                currentValue: currentValue.value,
                overwrittenCurrentValue: null,
                change: 0,
                asOfDate: new Date().toISOString(),
                dataSource: DataSource.Manual,
                internalComment: "",
                clientComment: "",
                securityId,
                sourceId: null,
            };
            const newSecurity: WritableSecurity | null = existingSecurity ? null : {
                tempId: securityId,
                tickerSymbol: ticker,
                positionName: name.trim(),
                segmentId: segmentId,
                blendedFundPercentages: blendedFundPercentages.map(blend => {
                    const segmentId = blend.segmentId ?? -1; // save validation prevents default
                    return {
                        segmentId,
                        percentage: blend.percentage,
                    };
                }),
            };
            if (segmentId === Segment.Blended) {
                telemetry.trackEvent(EventType.BlendedFundUsed);
            }
            if (addingNewAccount) {
                const accountResponse = await createAccount({
                    ...newAccount,
                    lastModifiedDate
                });
                const positionResponse = await createPosition({
                    ...newPosition,
                    accountId: accountResponse.id,
                    newSecurity,
                    lastModifiedDate: accountResponse.dateModified
                });

                return {
                    accountId: accountResponse.id,
                    positionId: positionResponse.id,
                };
            } else if (isNumber(accountId)) {
                const positionResponse = await createPosition({...newPosition, accountId, newSecurity, lastModifiedDate});
                return {
                    accountId,
                    positionId: positionResponse.id,
                };
            }
        }

        // It should not be possible to actually make it down to this line. Any place that invokes
        // 'addPosition' will already have guards in place ensuring there is a segment selected.
        throw new Error("Adding a position without a segment is not supported");
    }, [nameResultForTicker, manualName, segmentId, existingSecurity, currentValue.value, ticker, blendedFundPercentages, addingNewAccount, accountId, createAccount, newAccount, lastModifiedDate, createPosition]);

    useEffect(() => {
        if (hasContent(nameResultForTicker)) {
            setManualName("");
        }
    }, [nameResultForTicker]);

    return <AddPositionModal
        open={open}
        setOpen={setOpen}
        assetTableViewModel={assetTableViewModel}
        assetClasses={assetClasses}
        recommendedFundSecurities={recommendedFundSecurities}
        accountTypes={accountTypes}
        ticker={ticker}
        setTicker={setTicker}
        securityStatus={existingSecurity ? "success" : securityStatus}
        nameResultForTicker={nameResultForTicker}
        manualName={manualName}
        setManualName={setManualName}
        accountId={accountId}
        setAccountId={setAccountId}
        currentValue={currentValue}
        segmentId={segmentId}
        setSegmentId={setSegmentId}
        isSegmentSelectDisabled={!!existingSecurity}
        addPosition={addPosition}
        newAccount={newAccount}
        setNewAccount={setNewAccount}
        percentages={blendedFundPercentages}
        setPercentages={setBlendedFundPercentages}
        addingNewAccount={addingNewAccount}
    />;
}