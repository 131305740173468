import { sumValue } from "utils/ArrayUtils";
import { SecurityDisplayViewModel, SecurityDisplay } from "./SecurityDisplayViewModel";
import { SecurityViewModel } from "./SecurityViewModel";
import { createValueModel } from "../../IasWorkspaceTypes";
import { CustomGroupDataModel } from "dataModels/customGroupDataModel";

export interface CustomGroupViewModel extends SecurityDisplayViewModel {
    securities: SecurityViewModel[];
    customGroup: CustomGroupDataModel;
    allSecuritiesWithChanges: () => SecurityViewModel[];
}

export function createCustomGroupViewModel(customGroup: CustomGroupDataModel, securities: SecurityViewModel[]): CustomGroupViewModel {
    const currentValue = () => sumValue(securities, security => security.currentValue());
    const changeValue = () => sumValue(securities, security => security.changeValue());
    const containsChanges = () => securities.some(security => security.containsChanges());
    const containsAccount = (accountId: number) => securities.some(security => security.containsAccount(accountId));

    return {
        securityDisplay: SecurityDisplay.CustomGroup,
        name: customGroup.name,
        segmentId: customGroup.segmentId,
        key: `custom-group-${customGroup.id}`,
        containsChanges,
        containsAccount,
        ...createValueModel(currentValue, changeValue),
        securities,
        customGroup,
        allSecuritiesWithChanges: () => securities.filter(security => security.containsChanges()),
    };
}