export type ProposalMode = "Proposed"|"Current";

export interface ValueModel {
    currentValue: () => number;
    proposedValue: () => number;
    changeValue: () => number;
}

export function createValueModel(currentValue: () => number, changeValue: () => number): ValueModel {
    const proposedValue = () => currentValue() + changeValue();
    return {
        currentValue,
        changeValue,
        proposedValue
    };
}

export interface ValueModeSelector {
    getValue: (model: ValueModel) => number,
    name: string
}

export const ProposedSelector: ValueModeSelector = {
    getValue: (model) => model.proposedValue(),
    name: "Proposed"
};

export const CurrentSelector: ValueModeSelector = {
    getValue: (model) => model.currentValue(),
    name: "Current"
};