import { IasDataModel } from "dataModels/iasDataModel";
import { CustomGroupRow } from "./CustomGroupRow";
import { SecurityRow } from "./SecurityRow";
import { ModelPortfolioViewModel } from "../view-models/ModelPortfolioViewModel";
import { CustomGroupViewModel } from "../view-models/CustomGroupViewModel";
import { isCustomGroupViewModel, SecurityDisplayViewModel } from "../view-models/SecurityDisplayViewModel";
import { SecurityViewModel } from "../view-models/SecurityViewModel";
import { ValueModel } from "../../IasWorkspaceTypes";

interface SecurityDisplayRowProps {
    viewModel: SecurityDisplayViewModel;
    depth: number;
    ias: IasDataModel;
    overallValues: ValueModel;
    assetClassTarget: number;
    modelPortfolio: ModelPortfolioViewModel;
    onStartCreatingGroup?: (security: SecurityViewModel) => void;
    segmentName: string;
    segmentChildren: SecurityDisplayViewModel[];
}

export function SecurityDisplayRow(props: SecurityDisplayRowProps) {
    const { viewModel } = props;

    if (isCustomGroupViewModel(viewModel)) {
        return <CustomGroupRow
            {...props}
            viewModel={props.viewModel as CustomGroupViewModel}
        />;
    }

    return <SecurityRow
        {...props}
        viewModel={props.viewModel as SecurityViewModel}
    />;
}