import { LinearProgressOverlay } from "components/shared/LinearProgressOverlay";
import { Fragment } from "react";
import { IasBaseModalContent } from "../pages/IasBaseModalContent";

export function LinearProgressPage(props: { text: string; }) {
    return <Fragment>
        <IasBaseModalContent
            content={<Fragment />}
            footer={<Fragment />}
            onCancel={() => { }}
        />
        <LinearProgressOverlay text={props.text} />
    </Fragment>;
}
