import { CustomModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { Fragment } from "react";
import type { BaseModelPortfolioEditorProps } from "./BaseModelPortfolioEditor";
import { CustomModelPortfolioTable } from "./CustomModelPortfolioTable";
import { EditModelPortfolioControlsForCustomPortfolio } from "./EditModelPortfolioControlsForCustomPortfolio";

export interface CustomModelPortfolioEditorProps extends BaseModelPortfolioEditorProps {
    customModelPortfolio: CustomModelPortfolioDataModel;
}

export function CustomModelPortfolioEditor(props: CustomModelPortfolioEditorProps) {
    return <Fragment>
        <EditModelPortfolioControlsForCustomPortfolio
            modelPortfolio={props.modelPortfolio}
            customModelPortfolio={props.customModelPortfolio}
            modelPortfolios={props.modelPortfolios}
            assetClasses={props.assetClasses}
            patchTargets={props.patchTargets}
            patchCustomModelPortfolio={props.patchCustomModelPortfolio}
            readonly={props.readonly}
        />
        <CustomModelPortfolioTable
            modelPortfolio={props.modelPortfolio}
            assetClasses={props.assetClasses}
            customModelPortfolio={props.customModelPortfolio}
            setCustomModelPortfolio={props.patchCustomModelPortfolio}
            setEvenMoreExtraAcceptInput={props.setEvenMoreExtraAcceptInput}
            setInputValid={props.setInputValid}
            readonly={props.readonly}
            key={props.customModelPortfolio.baseModelPortfolioId}
            setCustomSegmentName={props.setCustomSegmentName}
            revertCustomSegmentName={props.revertCustomSegmentName}
            formatCustomSegmentNames={props.formatCustomSegmentNames}
            setCustomSegmentGroupName={props.setCustomSegmentGroupName}
            revertCustomSegmentGroupName={props.revertCustomSegmentGroupName}
            formatCustomSegmentGroupNames={props.formatCustomSegmentGroupNames}
        />
    </Fragment>;
}
