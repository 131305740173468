import { PropsWithChildren } from "react";
import styles from "./FullScreenOverlay.module.scss";

export interface FullScreenOverlayProps extends PropsWithChildren {
    text?: string;
}

export const FullScreenOverlay = (props: FullScreenOverlayProps) => {
    return <div className={styles.fullScreenOverlay}>
        <div className={styles.centeredContent}>
            {
                props.text !== undefined && <div
                    className={styles.text}
                    data-testid="full-screen-overlay-title"
                >{props.text}</div>
            }
            {props.children}
        </div>
    </div>;
};
