import { hasContent } from "./StringUtils";

export const isMatchingSecurity = (security: { tickerSymbol: string, positionName: string }, ticker: string | null, positionName: string) => {
    if (hasContent(security.tickerSymbol)) {
        return security.tickerSymbol === ticker;
    } else {
        if (hasContent(ticker)) {
            return false;
        }

        return security.positionName === positionName;
    }
};