import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { Fragment, useCallback, useMemo } from "react";
import { DataTable, DataTableContent, DataTableBody, DataTableRow, DataTableCell } from "rmwc";
import { useAccountTypeQuery } from "services/accountTypeService";
import { VisionPossession } from "services/visionService";
import { hasContent } from "utils/StringUtils";
import type { SelectionProps } from "./MoreVisionInfoPage";
import { VisionAccountRow } from "./VisionAccountRow";
import { VisionHoldingRow } from "./VisionHoldingRow";
import styles from "./VisionTable.module.scss";

interface VisionTableProps {
    selectedPossessions: Set<VisionPossession>;
    setSelectedPossessions: (possessions: Set<VisionPossession>) => void;
}

export function VisionTable(props: VisionTableProps) {
    const {selectedPossessions, setSelectedPossessions} = props;

    const possessions: VisionPossession[] = useMemo(() => Array.from(selectedPossessions), [selectedPossessions]);

    const addSelections = useCallback((possessions: VisionPossession[]) => {
        const newSet = new Set(selectedPossessions);
        possessions.forEach(possession => newSet.add(possession));
        setSelectedPossessions(newSet);
    }, [selectedPossessions, setSelectedPossessions]);

    const removeSelections = useCallback((possessions: VisionPossession[]) => {
        const newSet = new Set(selectedPossessions);
        possessions.forEach(possession => newSet.delete(possession));
        setSelectedPossessions(newSet);
    }, [selectedPossessions, setSelectedPossessions]);

    const selectionProps: SelectionProps = useMemo(() => ({
        addSelections,
        removeSelections,
    }), [addSelections, removeSelections]);

    const holdings: VisionPossession[] = useMemo(() => possessions.filter(possession => !hasContent(possession.account)), [possessions]);
    const accounts: VisionPossession[] = useMemo(() => possessions.filter(possession => hasContent(possession.account)), [possessions]);

    const { data, status } = useAccountTypeQuery();

    const accountTypes: AccountTypeDataModel[] = useMemo(() => status === "success"
        ? data.sort((a, b) => a.name.localeCompare(b.name))
        : []
    , [data, status]);

    return <DataTable className={styles.table}>
        <DataTableContent>
            <DataTableBody>
                {
                    holdings.length > 0 && <Fragment>
                        <DataTableRow className={styles.columnHeaders}>
                            <DataTableCell className={styles.sectionTitle}>Holding Info</DataTableCell>
                            <DataTableCell>Account</DataTableCell>
                            <DataTableCell className={styles.noPadding}>Owner</DataTableCell>
                            <DataTableCell className={styles.noPadding}>Custodian</DataTableCell>
                            <DataTableCell className={styles.noPadding}>Type</DataTableCell>
                        </DataTableRow>
                        {
                            holdings.map(possession =>
                                <VisionHoldingRow
                                    {...selectionProps}
                                    possession={possession}
                                    accountTypes={accountTypes}
                                    key={possession.id}
                                />)
                        }
                    </Fragment>
                }
                {
                    accounts.length > 0 && <Fragment>
                        <DataTableRow className={styles.columnHeaders}>
                            <DataTableCell className={styles.sectionTitle}>Account Info</DataTableCell>
                            <DataTableCell>Account</DataTableCell>
                            <DataTableCell className={styles.noPadding}>Owner</DataTableCell>
                            <DataTableCell className={styles.noPadding}>Custodian</DataTableCell>
                            <DataTableCell className={styles.noPadding}>Type</DataTableCell>
                        </DataTableRow>
                        {
                            accounts.map(possession =>
                                <VisionAccountRow
                                    {...selectionProps}
                                    possession={possession}
                                    accountTypes={accountTypes}
                                    key={possession.id}
                                />)
                        }
                    </Fragment>
                }
            </DataTableBody>
        </DataTableContent>
    </DataTable>;
}