import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import classnames from "classnames";
import { WorkspaceContext } from "contexts/workspaceContext";
import { IasDataModel } from "dataModels/iasDataModel";
import { IconName } from "iconNames";
import { MenuSurfaceAnchor, Menu, MenuItem, IconButton } from "rmwc";
import { EditPositionCommentDialog } from "../EditPositionCommentDialog";
import { AccountPositionViewModel } from "../view-models/AccountPositionViewModel";
import DeleteConfirmation from "./DeleteConfirmation";
import styles from "./AccountPositionMenu.module.scss";
import { EditCurrentValueDialog } from "./EditCurrentValueDialog";
import { EditChangeValueDialog } from "./EditChangeValueDialog";

interface AccountPositionMenuProps {
    viewModel: AccountPositionViewModel;
    ias: IasDataModel;
    revertCurrentValue: VoidFunction;
    setEditCurrentValue: BooleanCallback;
    commentDialogOpen: boolean;
    setCommentDialogOpen: BooleanCallback;
    menuButtonClassName?: string;
}

export default function AccountPositionMenu(props: AccountPositionMenuProps) {
    const context = useContext(WorkspaceContext);
    const [open, setOpen] = useState(false);

    const { viewModel, setEditCurrentValue } = props;

    const [menuOpen, setMenuOpen] = useState(false);
    const [kebabVisible, setKebabVisible] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [editCurrentValueBlendedOpen, setEditCurrentValueBlendedOpen] = useState(false);

    const handleMenuClose = () => {
        setMenuOpen(false);
        setKebabVisible(false);
    };

    const handleKebabClick = () => {
        setMenuOpen(true);
    };

    const handleEditCurrentClick = useCallback(() => {
        if (viewModel.isBlended) {
            setEditCurrentValueBlendedOpen(true);
        } else {
            setEditCurrentValue(true);
        }
    }, [viewModel.isBlended, setEditCurrentValue]);

    const onAddBlendedChangeClick = useCallback(() => {
        setOpen(true);
    }, []);

    return <Fragment>
        <MenuSurfaceAnchor>
            <Menu
                anchorCorner="bottomLeft"
                open={menuOpen}
                onOpen={() => setKebabVisible(true)}
                onClose={handleMenuClose}
                className={classnames(styles.menu, {[styles.sidebarOpen]: context.sidebarOpen, [styles.blended]: viewModel.isBlended})}
            >
                <MenuItems
                    handleEditCurrentClick={handleEditCurrentClick}
                    onAddBlendedChangeClick={onAddBlendedChangeClick}
                    revertCurrentValue={props.revertCurrentValue}
                    setCommentDialogOpen={props.setCommentDialogOpen}
                    setDeleteConfirmationOpen={setDeleteConfirmationOpen}
                    viewModel={viewModel}
                />
            </Menu>
            {
                !context.readonlyMode && <IconButton
                    icon={IconName.MoreVert}
                    onClick={handleKebabClick}
                    className={classnames({ [props.menuButtonClassName ?? ""]: kebabVisible })}
                />
            }
        </MenuSurfaceAnchor>
        {
            props.commentDialogOpen && <EditPositionCommentDialog
                viewModel={viewModel}
                open={props.commentDialogOpen}
                setOpen={props.setCommentDialogOpen}
                ias={props.ias}
            />
        }
        {
            deleteConfirmationOpen && <DeleteConfirmation
                viewModel={viewModel}
                ias={props.ias}
                deleteConfirmationOpen={deleteConfirmationOpen}
                setDeleteConfirmationOpen={setDeleteConfirmationOpen}
            />
        }
        {
            editCurrentValueBlendedOpen && <EditCurrentValueDialog
                viewModel={viewModel}
                ias={props.ias}
                open={editCurrentValueBlendedOpen}
                setOpen={setEditCurrentValueBlendedOpen}
            />
        }
        {
            open && <EditChangeValueDialog
                viewModel={props.viewModel}
                ias={props.ias}
                open={open}
                setOpen={setOpen}
            />
        }
    </Fragment>;
}

interface MenuItemsProps {
    viewModel: AccountPositionViewModel;
    revertCurrentValue: VoidFunction;
    setCommentDialogOpen: BooleanCallback;
    onAddBlendedChangeClick: VoidFunction;
    handleEditCurrentClick: VoidFunction;
    setDeleteConfirmationOpen: (open: boolean) => void;
}

function MenuItems(props: MenuItemsProps) {
    const { viewModel } = props;
    const hasComments = useMemo(() => viewModel.position.internalComment !== "" || viewModel.position.clientComment !== "", [viewModel.position.clientComment, viewModel.position.internalComment]);

    return <Fragment>
        {
            viewModel.isBlended && <MenuItem
                className={styles.menuItem}
                onClick={props.onAddBlendedChangeClick}
            >Add Blended Change</MenuItem>
        }
        <MenuItem
            className={styles.menuItem}
            onClick={props.handleEditCurrentClick}
        >Edit {viewModel.isBlended ? "Blended" : ""} Current</MenuItem>
        {
            viewModel.currentValueModified && <MenuItem
                className={styles.menuItem}
                onClick={props.revertCurrentValue}
            >Revert {viewModel.isBlended ? "Blended" : ""} Current</MenuItem>
        }
        <MenuItem
            className={styles.menuItem}
            onClick={() => props.setCommentDialogOpen(true)}
        >{hasComments ? "Edit Comment" : "Add Comment"}</MenuItem>
        <MenuItem
            className={classnames(styles.menuItem, styles.deleteMenuItem)}
            onClick={() => props.setDeleteConfirmationOpen(true)}
        >Delete</MenuItem>
    </Fragment>;
}