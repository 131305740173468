import classnames from "classnames";
import NumberDisplay from "components/numeric-values/NumberDisplay";
import ExpandableToggleButton from "components/shared/ExpandableToggleButton";
import { WorkspaceContext } from "contexts/workspaceContext";
import { IasDataModel } from "dataModels/iasDataModel";
import { ExpansionType, useExpansionState } from "services/expansionStateCache";
import { Fragment, useCallback, useContext, MouseEvent, useState, useMemo } from "react";
import { DataTableRow, DataTableCell } from "rmwc";
import { SecurityRow } from "./SecurityRow";
import sharedStyles from "../SharedStyles.module.scss";
import securityStyles from "./SecurityRow.module.scss";
import { ModelPortfolioViewModel } from "../view-models/ModelPortfolioViewModel";
import { CustomGroupViewModel } from "../view-models/CustomGroupViewModel";
import { ValueModel } from "../../IasWorkspaceTypes";
import { IconName } from "iconNames";
import { useCustomGroupDeletion } from "services/customGroupService";
import { CustomGroupRowMenu } from "./CustomGroupRowMenu";
import { SecurityDisplayViewModel } from "../view-models/SecurityDisplayViewModel";
import { EditCustomGroupContentsModal } from "../custom-group/EditCustomGroupContentsModal";
import { EditCustomGroupNameAssetClassModal } from "./EditCustomGroupNameAssetClassModal";
import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { sortSecurities } from "../workspaceSorting";
import { applyAccountFilter } from "components/ias-workspace/add-position/AccountSelect";

interface CustomGroupRowProps {
    viewModel: CustomGroupViewModel;
    depth: number;
    ias: IasDataModel;
    overallValues: ValueModel;
    assetClassTarget: number;
    modelPortfolio: ModelPortfolioViewModel;
    segmentName: string;
    segmentChildren: SecurityDisplayViewModel[];
}

export function CustomGroupRow(props: CustomGroupRowProps) {
    const { viewModel, depth, ias, overallValues, assetClassTarget, modelPortfolio, segmentName, segmentChildren } = props;

    const context = useContext(WorkspaceContext);

    const securitiesToDisplay = useMemo(() => context.onlyChanges
        ? sortSecurities(viewModel.allSecuritiesWithChanges(), context.sortBy)
        : sortSecurities(viewModel.securities, context.sortBy)
    , [context.onlyChanges, context.sortBy, viewModel]);

    const securitiesFilteredByAccount = useMemo(() =>
        applyAccountFilter(context.filterByAccountId) ? securitiesToDisplay.filter(security => security.containsAccount(context.filterByAccountId)) : securitiesToDisplay,
    [context.filterByAccountId, securitiesToDisplay]);

    const deleteCustomGroup = useCustomGroupDeletion(viewModel.customGroup.id, ias.id);
    const [open, setOpen] = useExpansionState(ExpansionType.CustomGroup, viewModel.key, false, viewModel.securities.map(s => [ExpansionType.Security, s.key, false]));
    const [editingGroupContents, setEditingGroupContents] = useState(false);

    const [isEditingGroupNameAssetClass, setIsEditingGroupNameAssetClass] = useState(false);
    const [isConfirmingUngroup, setIsConfirmingUngroup] = useState(false);

    const onClickRow = useCallback((evt: MouseEvent<HTMLElement>) => {
        const isKebab = evt.target instanceof HTMLButtonElement && evt.target.textContent === IconName.MoreVert;
        const isMenuOption = evt.target instanceof HTMLLIElement;
        if (!isEditingGroupNameAssetClass && !editingGroupContents && !isKebab && !isMenuOption) {
            setOpen(!open);
        }
    }, [editingGroupContents, isEditingGroupNameAssetClass, open, setOpen]);

    const openEditGroupNameAssetClassModal = useCallback(() => {
        setIsEditingGroupNameAssetClass(true);
    }, []);

    const openUngroupConfirmation = useCallback(() => {
        setIsConfirmingUngroup(true);
    }, []);

    const closeUngroupConfirmation = useCallback(() => {
        setIsConfirmingUngroup(false);
    }, []);

    const onUngroup = useCallback(() => {
        telemetry.trackEvent(EventType.CustomGroupUngrouped);
        deleteCustomGroup.mutate({lastModifiedDate: ias.dateModified});
    }, [deleteCustomGroup, ias.dateModified]);

    const onEditGroupContents = useCallback(() => {
        setEditingGroupContents(true);
    }, []);

    const onCloseEditGroupContentsModal = useCallback(() => {
        setEditingGroupContents(false);
    }, []);

    const depthStyle = sharedStyles[`indent_${props.depth}`];
    return <Fragment>
        <DataTableRow
            data-testid={`custom-group-row-${viewModel.name}`}
            className={classnames(securityStyles.row, "clickable")}
            onClick={onClickRow}
        >
            <DataTableCell className={classnames(depthStyle, {[sharedStyles.wide]: !context.sidebarOpen })}>
                <div className={classnames(securityStyles.securityName, sharedStyles.collapsibleGroup)}>
                    <ExpandableToggleButton
                        open={open}
                        setOpen={setOpen}
                    />
                    <div>
                        {viewModel.name.toLocaleUpperCase()}
                    </div>
                </div>
            </DataTableCell>
            <DataTableCell />
            <DataTableCell className={classnames(sharedStyles.value_column, securityStyles.securityRow)}>
                <NumberDisplay value={viewModel.currentValue()} />
            </DataTableCell>
            <DataTableCell className={classnames(sharedStyles.value_column, securityStyles.securityRow)}>
                <NumberDisplay value={viewModel.changeValue()} />
            </DataTableCell>
            <DataTableCell className={classnames(sharedStyles.value_column, securityStyles.securityRow)}>
                <NumberDisplay value={viewModel.proposedValue()} />
            </DataTableCell>
            <DataTableCell className={classnames(securityStyles.securityRow, securityStyles.kebabCell)} >
                <CustomGroupRowMenu
                    openEditGroupNameAssetClassModal={openEditGroupNameAssetClassModal}
                    openUngroupConfirmation={openUngroupConfirmation}
                    onEditGroup={onEditGroupContents}
                />
                {
                    isEditingGroupNameAssetClass && <EditCustomGroupNameAssetClassModal
                        open={isEditingGroupNameAssetClass}
                        setOpen={setIsEditingGroupNameAssetClass}
                        ias={props.ias}
                        customGroup={props.viewModel}
                    />
                }
                {
                    editingGroupContents && <EditCustomGroupContentsModal
                        open={editingGroupContents}
                        onClose={onCloseEditGroupContentsModal}
                        segmentName={segmentName}
                        segmentChildren={segmentChildren}
                        ias={ias}
                        customGroup={viewModel}
                    />
                } 
                {
                    isConfirmingUngroup && <ConfirmationDialog
                        title={`Ungroup ${viewModel.name}?`}
                        confirmButton="Ungroup"
                        onConfirm={onUngroup}
                        onCancel={closeUngroupConfirmation}
                        content={<Fragment />}
                        width={640}
                        useWarningStyling
                    />
                }
            </DataTableCell>
        </DataTableRow>
        {
            open && securitiesFilteredByAccount.map(security => <SecurityRow
                key={security.key}
                viewModel={security}
                depth={depth + 1}
                ias={ias}
                assetClassTarget={assetClassTarget}
                modelPortfolio={modelPortfolio}
                overallValues={overallValues}
            />)
        }
    </Fragment>;
}