import { ClassifiedBlackDiamondHolding } from "components/client-page/createIas";
import { DataSource } from "constants/dataSource";
import { Segment } from "constants/segment";
import { BlackDiamondHolding } from "dataModels/blackDiamond/holding";
import { BlendedFundSegmentPercentage } from "dataModels/blendedFundSegmentPercentage";
import { ClientDataModel } from "dataModels/clientDataModel";
import { isNumber } from "lodash";
import { useCallback, useMemo, useState, useEffect } from "react";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { AddIasWizardViewControllerState } from "../AddIasWizardConstants";
import { BDHoldingsClassificationPage } from "../pages/blackDiamond/BDHoldingsClassificationPage";
import { BlendDefinition, BlendedAssetClassesPage } from "../pages/blended/BlendedAssetClassesPage";
import { IasBaseModalContent } from "../pages/IasBaseModalContent";
import styles from "./BlackDiamondClassification.module.scss";

interface BlackDiamondClassificationProps {
    portfolio: PopulatedPortfolio;
    setClassifiedBlackDiamondHoldings: Callback<ClassifiedBlackDiamondHolding[]>;
    setState: Callback<AddIasWizardViewControllerState>;
    onCancel: VoidFunction;
    client: ClientDataModel;
}

export function BlackDiamondClassification(props: BlackDiamondClassificationProps) {
    const { portfolio, setClassifiedBlackDiamondHoldings } = props;
    const [ page, setPage ] = useState<"Classify" | "Blend">("Classify");
    const allHoldings = useMemo(() => portfolio.accounts.flatMap(account => account.holdings).map<ClassifiedBlackDiamondHolding>(holding => ({
        ...holding,
        blendedFundPercentages: [],
    })), [portfolio.accounts]);

    const [ holdingsToClassify, setHoldingsToClassify ] = useState(() => allHoldings.filter(holding => !isNumber(holding.segmentId)));
    const holdingsThatDontNeedClassification = useMemo(() => allHoldings.filter(holding => isNumber(holding.segmentId)), [allHoldings]);

    const report = useCallback(() => setClassifiedBlackDiamondHoldings(holdingsToClassify.concat(holdingsThatDontNeedClassification)),
        [holdingsThatDontNeedClassification, holdingsToClassify, setClassifiedBlackDiamondHoldings]);

    const setHoldings = useCallback((newHoldings: BlackDiamondHolding[]) => {
        setHoldingsToClassify(holdingsToClassify.map(holding => {
            const found = newHoldings.find(nh => nh.assetId === holding.assetId);
            if (!found) return holding;
            return {
                ...found,
                blendedFundPercentages: holding.blendedFundPercentages
            };
        }));
    }, [holdingsToClassify]);

    useEffect(() => {
        if (holdingsToClassify.length === 0) {
            report();
        }
    }, [holdingsToClassify.length, report]);

    const setBlendDefinitions = useCallback((newBlendDefinitions: BlendDefinition[]) => {
        const temp = holdingsToClassify.map(holding => {
            const found = newBlendDefinitions.find(bd => bd.id === holding.assetId);
            if (!found) return holding;
            return {
                ...holding,
                blendedFundPercentages: found.percentages.map<BlendedFundSegmentPercentage>(p =>({
                    segmentId: p.segmentId ?? 0,
                    percentage: p.percentage
                }))
            };
        });
        setHoldingsToClassify(temp);
    }, [holdingsToClassify]);

    const blendDefinitions = useMemo<BlendDefinition[]>(() => holdingsToClassify.filter(holding => holding.segmentId === Segment.Blended).map(holding => {
        return {
            dataSource: DataSource.BlackDiamond,
            id: holding.assetId,
            percentages: holding.blendedFundPercentages.slice(),
            securityName: holding.name,
            securityTicker: holding.ticker ?? ""
        };
    }), [holdingsToClassify]);

    const blendedPageActive = useMemo(() => holdingsToClassify.some(ch => ch.segmentId === Segment.Blended), [holdingsToClassify]);

    const onClassifyNext = useCallback(() => {
        if (blendedPageActive) {
            setPage("Blend");
        } else {
            report();
        }
    }, [blendedPageActive, report]);

    if (page === "Classify") {
        return <BDHoldingsClassificationPage
            stepper={<div className={styles.title}>Some additional Black Diamond classification is needed.</div>}
            onCancel={props.onCancel}
            onBack={() => props.setState(AddIasWizardViewControllerState.StartingPage)}
            onNext={onClassifyNext}
            blackDiamondAccounts={portfolio.accounts}
            assetClasses={props.client.assetClasses}
            holdingsToClassify={holdingsToClassify}
            setHoldingsToClassify={setHoldings}
            overrideNextButtonLabel={blendedPageActive ? "Next": "Finish"}
        />;
    }
    else {
        return <BlendedAssetClassesPage
            WizardBaseModalContent={IasBaseModalContent}
            stepper={<div className={styles.title}>Define the Blended Asset Classes.</div>}
            onCancel={props.onCancel}
            onBack={() => setPage("Classify")}
            onNext={report}
            assetClasses={props.client.assetClasses}
            blendDefinitions={blendDefinitions}
            setBlendDefinitions={setBlendDefinitions}
        />;
    }
}
