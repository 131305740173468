import { createCustomModelPortfolioViewModel } from "components/shared/modelPortfolio/view-models/createCustomModelPortfolioViewModel";
import { createStandardModelPortfolioViewModel } from "components/shared/modelPortfolio/view-models/createStandardModelPortfolioViewModel";
import { modelPortfolioName } from "components/shared/modelPortfolio/view-models/modelPortfolioName";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomModelPortfolioDataModel, ModelPortfolioDataModel, RecommendedFundTargetDataModel } from "dataModels/modelPortfolioDataModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";

export interface ModelPortfolioSharedViewModel {
    name: () => string;
    cashTarget: () => number;
    cashTargetInDollars: () => number | undefined;
    alternativesTarget: () => number;
    alternativesTargetInDollars: () => number | undefined;
    cashCarveOut: () => number;
    fixedIncomeCarveOut: () => number;
    equitiesCarveOut: () => number;
    recommendedFunds: () => RecommendedFundTargetDataModel[];
    modelPortfolioId: () => number | undefined;
    modelPortfolio: () => ModelPortfolioDataModel | undefined;
    customModelPortfolioInUse: () => CustomModelPortfolioDataModel | undefined;
    savedCustomModelPortfolio: () => CustomModelPortfolioDataModel | undefined;
}

export interface ModelPortfolioViewModel extends ModelPortfolioSharedViewModel {
    getTargetForAssetClass: (id: number) => number;
    getTargetForSegmentGroup: (id: number) => number;
    getTargetForSegment: (id: number) => number;
    getTargetForCash: () => number;
    getTargetForFixedIncome: () => number;
    getTargetForEquities: () => number;
    getTargetForAlternatives: () => number;
}

export function createModelPortfolioViewModel(targets: TargetsDataModel, modelPortfolios: ModelPortfolioDataModel[], assetClasses: AssetClassDataModel[], totalIasValue?: number): ModelPortfolioViewModel {
    const modelPortfolio = modelPortfolios.find(portfolio => portfolio.id === targets.modelPortfolio?.id);
    const customModelPortfolioInUse = !targets.modelPortfolio
        ? targets.customModelPortfolio
        : undefined;

    function cashTarget() {
        if(targets.cashTargetInDollars === undefined || totalIasValue === undefined) {
            return targets.cashTarget ?? 0;
        }
        if(totalIasValue === 0)
        {
            return 0;
        }
        return (targets.cashTargetInDollars / totalIasValue) * 100;
    }

    function alternativesTarget() {
        if(targets.alternativesTargetInDollars === undefined || totalIasValue === undefined) {
            return targets.alternativesTarget ?? 0;
        }
        if(totalIasValue === 0)
        {
            return 0;
        }
        return (targets.alternativesTargetInDollars / totalIasValue) * 100;
    }

    const shared: ModelPortfolioSharedViewModel = {
        name: () => modelPortfolioName(targets, modelPortfolios),
        cashTarget: () => cashTarget(),
        cashTargetInDollars: () => targets.cashTargetInDollars,
        alternativesTarget: () => alternativesTarget(),
        alternativesTargetInDollars: () => targets.alternativesTargetInDollars,
        cashCarveOut: () => targets.cashCarveOut ?? 0,
        fixedIncomeCarveOut: () => targets.fixedIncomeCarveOut ?? 0,
        equitiesCarveOut: () => targets.equitiesCarveOut ?? 100,
        recommendedFunds: () => modelPortfolio?.modelPortfolioTargetSet?.recommendedFundTargets ?? [],
        modelPortfolioId: () => modelPortfolio?.id,
        modelPortfolio: () => modelPortfolio,
        customModelPortfolioInUse: () => customModelPortfolioInUse,
        savedCustomModelPortfolio: () => targets.customModelPortfolio,
    };

    if (customModelPortfolioInUse) {
        return createCustomModelPortfolioViewModel(shared, customModelPortfolioInUse, assetClasses);
    }

    if (modelPortfolio) {
        return createStandardModelPortfolioViewModel(shared, modelPortfolio, assetClasses, targets.modelPortfolioTargets);
    }

    return {
        ...shared,
        getTargetForSegment: () => 0,
        getTargetForSegmentGroup: () => 0,
        getTargetForAssetClass: () => 0,
        getTargetForCash: () => 0,
        getTargetForFixedIncome: () => 0,
        getTargetForEquities: () => 0,
        getTargetForAlternatives: () => 0,
    };
}