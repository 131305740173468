import { ClassifiedBlackDiamondHolding, ClassifiedVisionPossession } from "components/client-page/createIas";
import { createModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { ClientDataModel } from "dataModels/clientDataModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { Fragment, useMemo } from "react";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { AddIasPage } from "../AddIasPage";

interface CreateIasProps {
    blackDiamondHoldings: ClassifiedBlackDiamondHolding[];
    visionAssets: ClassifiedVisionPossession[];
    mostRecentlyFinalized: IasDataModel;
    date: Date;
    name: string;
    client: ClientDataModel;
    modelPortfolios: ModelPortfolioDataModel[];
    portfolio: PopulatedPortfolio | null;
}

export function CreateIas(props: CreateIasProps) {
    const { client, name } = props;
    const mpvm = useMemo(() => createModelPortfolioViewModel(client, props.modelPortfolios, client.assetClasses), [client, props.modelPortfolios]);
    return <AddIasPage
        stepper={<Fragment />}
        createIasParams={
            {
                date: props.date,
                title: name,
                modelPortfolio: mpvm,
                client: client,
                portfolio: props.portfolio,
                classifiedHoldings: props.blackDiamondHoldings,
                visionAssets: props.visionAssets,
                mostRecentlyFinalized: props.mostRecentlyFinalized,
                assetClasses: client.assetClasses
            }
        }
    />;
}
