import ExpandableToggleButton from "components/shared/ExpandableToggleButton";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { useCallback, Fragment } from "react";
import { useExpansionState, ExpansionType } from "services/expansionStateCache";
import { VisionCategory, VisionSubcategory } from "services/visionService";
import { SelectionProps, isCategory, isSubcategory } from "./VisionAssetsCommonFunctions";
import { VisionContainerRow } from "./VisionContainerRow";
import { VisionPossessionRow } from "./VisionPossessionRow";

interface VisionContainerBlockProps {
    container: VisionCategory | VisionSubcategory,
    defaultOpen: boolean,
    portfolioAccounts: string[],
    assetClasses: AssetClassDataModel[],
    iasSecurities?: IasSecurityDataModel[],
}

export function VisionContainerBlock(props: VisionContainerBlockProps & SelectionProps) {
    const [open, setOpen] = useExpansionState(ExpansionType.VisionRow, props.container.uniqueId, props.defaultOpen);

    const onClick = useCallback(() => setOpen(!open), [open, setOpen]);

    return <Fragment>
        <VisionContainerRow
            onClick={onClick}
            {...props}
            container={props.container}
            toggleButton={
                <ExpandableToggleButton
                    open={open}
                    setOpen={setOpen}
                />
            }
        />
        {
            open && isCategory(props.container) && props.container.subcategories.map(subcategory => <VisionContainerBlock
                {...props}
                container={subcategory}
                key={subcategory.uniqueId}
                defaultOpen={props.defaultOpen}
            />)
        }
        {
            open && isSubcategory(props.container) && props.container.possessions.map(posession => <VisionPossessionRow
                {...props}
                possession={posession}
                key={posession.id}
                assetClasses={props.assetClasses}
            />)
        }
    </Fragment>;
}