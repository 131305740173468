import { isString } from "utils/StringUtils";

const getEnv = (variableName: string): string => {
    const result = process.env[variableName];
    if (!isString(result)) {
        console.error(`Configuration variable ${variableName} not found.`);
    }
    return result ?? "";
};

export const staticConfiguration = {
    apiUrl: () => getEnv("REACT_APP_API_URL"),
    useMsal: () => process.env.REACT_APP_USE_MSAL === "true",
    oauth: {
        clientId: () => getEnv("REACT_APP_OAUTH_CLIENT_ID"),
        scopes: () => getEnv("REACT_APP_OAUTH_SCOPES"),
        redirectUri: () => getEnv("REACT_APP_OAUTH_REDIRECT_URI"),
        tenantAuthorityUri: () => `https://login.windows.net/${getEnv("REACT_APP_OAUTH_TENANT_ID")}`
    },
    includeControlsPage: () => process.env.REACT_APP_INCLUDE_CONTROLS_PAGE === "true",
    zendesk: {
        enabled: () => process.env.REACT_APP_ZENDESK_ENABLED === "true",
        key: () => getEnv("REACT_APP_ZENDESK_KEY"),
    }
};

export interface ApplicationInsightsConfiguration {
    enabled: boolean;
    connectionString: string;
}

export interface BlackDiamondConfiguration {
    clientId: string;
    redirectUri: string;
    authorizeUrl: string;
}

export interface ConfigurationResponse {
    applicationInsights: ApplicationInsightsConfiguration;
    blackDiamond: BlackDiamondConfiguration;
}