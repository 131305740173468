import { PropsWithChildren } from "react";
import { BaseModalFooter } from "./BaseModalFooter";
import styles from "./NextFooter.module.scss";

export interface NextFooterProps extends PropsWithChildren {
    nextBtn: JSX.Element;
}

export function NextFooter(props: NextFooterProps) {
    const { nextBtn, children } = props;

    return <BaseModalFooter className={styles.dialogActions}>
        {children}
        <div data-testid="dialog-button-next">
            {nextBtn}
        </div>
    </BaseModalFooter>;
}