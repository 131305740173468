import { useCallback, useState } from "react";
import sharedStyles from "./UpdateDataWizardPage.module.scss";
import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import { UpdateDataWizardContents } from "./UpdateDataWizardContents";
import { IasDataModel } from "dataModels/iasDataModel";
import { TokenRevokedDialog } from "components/shared/TokenRevokedDialog";

export interface UpdateDataWizardProps {
    close: VoidFunction;
    clientKey: string;
    ias: IasDataModel;
}

export function UpdateDataWizard(props: UpdateDataWizardProps) {
    const { close } = props;
    const [visionConnectionFailed, setVisionConnectionFailed] = useState(false);
    const [bdConnectionFailed, setBDConnectionFailed] = useState(false);
    const [tokenRevoked, setTokenRevoked] = useState(false);

    const reportVisionConnectionFailed = useCallback(() => setVisionConnectionFailed(true), []);
    const reportBDConnectionFailed = useCallback(() => setBDConnectionFailed(true), []);
    const reportTokenRevokedError = useCallback(() => setTokenRevoked(true), []);

    if (tokenRevoked) {
        return <TokenRevokedDialog />;
    }

    if (visionConnectionFailed || bdConnectionFailed) {
        const system = bdConnectionFailed ? "Black Diamond" : "Vision";
        return <ConfirmationDialog
            title={<span className={sharedStyles.redWarning}>{system} failed to connect. No data was updated.</span>}
            confirmButton="Okay"
            onConfirm={close}
            content={`The system can not connect to ${system} at this time. Please try again later.`}
        />;
    }

    return <UpdateDataWizardContents
        {...props}
        reportBDConnectionFailed={reportBDConnectionFailed}
        reportVisionConnectionFailed={reportVisionConnectionFailed}
        reportTokenRevokedError={reportTokenRevokedError}
    />;
}
