import classnames from "classnames";
import { IconButton, LinearProgress } from "rmwc";
import { IconName } from "../../iconNames";
import styles from "./LinearProgressOverlay.module.scss";

export interface LinearProgressOverlayProps {
    text: string;
    onCancel?: VoidFunction;
    fullscreen?: boolean;
    className?: string;
}

export const LinearProgressOverlay = (props: LinearProgressOverlayProps) => {
    return <div className={classnames(styles.linearProgressOverlay, {[styles.fullscreen]: props.fullscreen}, props.className)}>
        {
            props.onCancel &&
            <div className={styles.exit}>
                <IconButton
                    icon={IconName.Close}
                    onClick={props.onCancel}
                    className={styles.closeButton}
                    width={28}
                    height={28}
                    data-testid="linear-progress-cancel"
                />
            </div>
        }
        <div className={styles.centeredContent}>
            <div
                className={styles.text}
                data-testid="linear-progress-title"
            >{props.text}</div>
            <div><LinearProgress
                className={styles.linearProgress}
                data-testid="linear-progress"
            /></div>
        </div>
    </div>;
};
