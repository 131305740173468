import classnames from "classnames";
import { PropsWithChildren } from "react";
import { DialogActions } from "rmwc";
import styles from "./BaseModalFooter.module.scss";

export interface BaseModalFooterProps extends PropsWithChildren {
    className?: string;
}

export function BaseModalFooter(props: BaseModalFooterProps) {
    return <DialogActions className={classnames(styles.dialogActions, props.className)}>
        {props.children}
    </DialogActions>;
}