import { useState } from "react";
import { isSome } from "utils/isNullOrUndefined";
import { AddIasWizardProps, AddIasWizardViewControllerState, PreloadProps } from "./AddIasWizardConstants";
import { AddIasWizardFull } from "./AddIasWizardFull";
import { FastPass } from "./fastPass/FastPass";
import { StartingPage } from "./StartingPage";

interface AddIasWizardViewControllerProps extends AddIasWizardProps, PreloadProps {
    state: AddIasWizardViewControllerState;
    setState: Callback<AddIasWizardViewControllerState>
    scrollToTop: VoidFunction;
}

export function AddIasWizardViewController(props: AddIasWizardViewControllerProps) {
    const [name, setName] = useState("");
    const [date, setDate] = useState<Date>();

    switch (props.state) {
    case AddIasWizardViewControllerState.StartingPage:
        return <StartingPage
            {...props}
            name={name}
            setName={setName}
            date={date}
            setDate={setDate}
        />;
    case AddIasWizardViewControllerState.FastPass:
        if (!isSome(props.mostRecentlyFinalized)) {
            throw new Error("Cannot enter fast pass because mostRecentlyFinalized is null.");
        }
        return <FastPass
            mostRecentlyFinalizedMinimal={props.mostRecentlyFinalized}
            onCancel={() => props.setOpen(false)}
            {...props}
            date={date ?? new Date()}
            name={name}
        />;
    case AddIasWizardViewControllerState.Full:
        return <AddIasWizardFull
            {...props}
            name={name}
            setName={setName}
            date={date}
            setDate={setDate}
        />;
    default:
        throw new Error(`Unknown AddIasWizardViewControllerState: ${props.state}`);
    }
}
