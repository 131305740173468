import classnames from "classnames";
import { FormEvent, useState } from "react";
import styles from "./EditReportTitleDialog.module.scss";
import { ValeoDialog } from "../../shared/ValeoDialog";
import { useIasMutation } from "services/iasService";
import { ValeoTextField } from "../../shared/ValeoTextField";
import { IasDataModel } from "../../../dataModels/iasDataModel";

export interface EditReportTitleDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    initialTitle: string;
    ias: IasDataModel;
}

export function EditReportTitleDialog(props: EditReportTitleDialogProps) {
    const { open, setOpen, initialTitle, ias } = props;
    const mutation = useIasMutation(ias.id);
    const [title, setTitle] = useState(initialTitle);

    const onAccept = () => {
        if (title !== initialTitle) {
            mutation.mutate({ lastModifiedDate: ias.dateModified, reportTitle: title });
        }
    };

    const onOpen = () => {
        setTitle(initialTitle);
    };

    const onTextFieldChange = (event: FormEvent<HTMLInputElement>) => setTitle(event.currentTarget.value);

    const textFieldClassNames = classnames(styles.titleTextField, {
        [styles.nonEmptyName]: title.length > 0
    });

    return <ValeoDialog
        title="Report Title"
        width={448}
        fixedHeight={203}
        open={open}
        setOpen={setOpen}
        onOpen={onOpen}
        proceedDisabled={title === initialTitle}
        onAccept={onAccept}
        testId="edit-report-title-dialog"
    >
        <div className={styles.content}>
            <ValeoTextField
                label="Report Title"
                value={title}
                onInput={onTextFieldChange}
                className={textFieldClassNames}
                maxLength={60}
            />
        </div>
    </ValeoDialog>;
}