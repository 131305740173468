import { WizardBaseModalContentProps } from "components/shared/modal/WizardBaseModalContentProps";
import { BaseModalContent } from "../../../shared/modal/BaseModalContent";
import { ModalActionButton } from "../../../shared/modal/footer/ModalActionButton";
import { CancelHeader } from "../../../shared/modal/header/CancelHeader";

export function UpdateDataWizardBaseModalContent(props: WizardBaseModalContentProps) {
    const { content, footer, onCancel } = props;

    return <BaseModalContent
        width={1248}
        minHeight={812}
        header={
            <CancelHeader
                title="Update Data"
                cancelBtn={
                    <ModalActionButton
                        isDefaultAction={false}
                        onClick={onCancel}
                        label="Cancel"
                    />
                } 
            />
        }
        content={content}
        footer={footer} 
    />;
}
