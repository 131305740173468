import classnames from "classnames";
import SegmentSelection from "components/shared/SegmentSelection";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { ChangeEvent, Fragment, useCallback, useEffect } from "react";
import { hasContent } from "utils/StringUtils";
import { SecurityViewModel } from "../view-models/SecurityViewModel";
import styles from "./EditSecurityControls.module.scss";

export interface EditSecurityControlsProps {
    security: SecurityViewModel;
    assetClasses: AssetClassDataModel[];
    name: string;
    setName: (name: string) => void;
    segmentId: number;
    setSegmentId: (segmentId: number) => void;
    setIsValid: (isValid: boolean) => void;
    setIsModified: (isModified: boolean) => void;
}

export function EditSecurityControls(props: EditSecurityControlsProps) {
    const { assetClasses, name, setName, segmentId, setSegmentId, setIsValid, setIsModified } = props;
    const { security, isInCustomGroup } = props.security;

    const onNameInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
    }, [setName]);

    const onSegmentChange = useCallback((segmentId: number) => {
        setSegmentId(segmentId);
    }, [setSegmentId]);

    useEffect(() => {
        setIsValid(hasContent(name));
    }, [name, setIsValid]);

    useEffect(() => {
        setIsModified(name !== security.positionName
            || segmentId !== security.segmentId);
    }, [security.positionName, security.segmentId, name, segmentId, setIsModified]);

    return <Fragment>
        <div className={styles.header}>Position Info</div>
        <div className={styles.row}>
            <ValeoTextField
                label="Ticker"
                value={security.tickerSymbol}
                disabled
                className={classnames(styles.tickerTextField, { [styles.nonEmptyTicker]: hasContent(security.tickerSymbol) })}
                data-testid="ticker-text-field"
            />
            <SegmentSelection
                assetClasses={assetClasses}
                onChange={onSegmentChange}
                segmentId={segmentId}
                includeBlended
                disabled={isInCustomGroup}
            />
        </div>
        <div className={styles.row}>
            <ValeoTextField
                label="Name"
                value={name}
                onInput={onNameInput}
                className={classnames(styles.nameTextField, { [styles.nonEmptyName]: hasContent(name) })}
                data-testid="name-text-field"
            />
        </div>
    </Fragment>;
}