import { inRange } from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";
import type { BaseModelPortfolioEditorProps } from "./BaseModelPortfolioEditor";
import EditModelPortfolioControls from "./EditModelPortfolioControls";
import { ModelPortfolioTargetsTable } from "./ModelPortfolioTargetsTable";
import { createModelPortfolioTargetsTableViewModel } from "./view-models/ModelPortfolioTargetsTableViewModel";
import { useNumericTextField } from "components/numeric-values/useNumericTextField";
import { inactiveDisplayOfCurrencyValue, isCurrencyValueValid } from "components/numeric-values/CurrencyEntry";

export interface ModelPortfolioEditorProps extends BaseModelPortfolioEditorProps {
    assumedTotalPortfolioValue: number;
    setPortfolioValue?: (value: number) => void;
}
export function ModelPortfolioEditor(props: ModelPortfolioEditorProps) {
    const viewModel = useMemo(() => createModelPortfolioTargetsTableViewModel(props.modelPortfolio, props.assetClasses), [props.modelPortfolio, props.assetClasses]);

    const [cashTargetInDollarsSelected, setCashTargetInDollarsSelected] = useState(props.modelPortfolio.cashTargetInDollars() !== undefined);
    const [alternativesTargetInDollarsSelected, setAlternativesTargetInDollarsSelected] = useState(props.modelPortfolio.alternativesTargetInDollars() !== undefined);

    const assumedTotalPortfolioValue = useNumericTextField(props.assumedTotalPortfolioValue, inactiveDisplayOfCurrencyValue, isCurrencyValueValid);

    const setTargetsValid = useCallback((valid: boolean) => {
        const calculatedTargetsInRange = viewModel.assetClassesHeaderEntries.every((entry) => inRange(entry.target, 0, 101));
        const assumedPortfolioValueValid = (!cashTargetInDollarsSelected && !alternativesTargetInDollarsSelected)
        || (assumedTotalPortfolioValue.value > 0 && assumedTotalPortfolioValue.isValid);
        props.setInputValid(valid && calculatedTargetsInRange && assumedPortfolioValueValid);
    }, [alternativesTargetInDollarsSelected, assumedTotalPortfolioValue.isValid, assumedTotalPortfolioValue.value,
        cashTargetInDollarsSelected, props, viewModel.assetClassesHeaderEntries]);

    return <Fragment>
        <EditModelPortfolioControls
            modelPortfolio={props.modelPortfolio}
            modelPortfolios={props.modelPortfolios}
            patchTargets={props.patchTargets}
            setValid={setTargetsValid}
            readonly={props.readonly}
            cashTargetInDollarsSelected={cashTargetInDollarsSelected}
            setCashTargetInDollarsSelected={setCashTargetInDollarsSelected}
            alternativesTargetInDollarsSelected={alternativesTargetInDollarsSelected}
            setAlternativesTargetInDollarsSelected={setAlternativesTargetInDollarsSelected}
        />
        <ModelPortfolioTargetsTable
            viewModel={viewModel}
            readonly={props.readonly}
            setCustomSegmentName={props.setCustomSegmentName}
            revertCustomSegmentName={props.revertCustomSegmentName}
            formatCustomSegmentNames={props.formatCustomSegmentNames}
            assumedTotalPortfolioValue={assumedTotalPortfolioValue}
            setPortfolioValue={props.setPortfolioValue}
            targetInDollarsSelected={cashTargetInDollarsSelected || alternativesTargetInDollarsSelected}
            setCustomSegmentGroupName={props.setCustomSegmentGroupName}
            revertCustomSegmentGroupName={props.revertCustomSegmentGroupName}
            formatCustomSegmentGroupNames={props.formatCustomSegmentGroupNames}
        />
    </Fragment>;
}
