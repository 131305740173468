import classnames from "classnames";
import { useCallback, useEffect, useState, FocusEvent, KeyboardEvent } from "react";
import { TextField, TextFieldHTMLProps, TextFieldProps } from "rmwc";
import styles from "./ValeoTextField.module.scss";

export interface ValeoTextFieldProps extends TextFieldProps, TextFieldHTMLProps {
    value: string;
    setValue?: (value: string) => void;
}

export function ValeoTextField(props: ValeoTextFieldProps) {
    const { setValue, onFocus, onKeyDown, ...nativeProps } = props;

    const [escape, setEscape] = useState(false);
    const [revertValue, setRevertValue] = useState("");

    useEffect(() => {
        if (escape) {
            (document.activeElement as HTMLElement).blur();
            setEscape(false);
        }
    }, [escape]);

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        onKeyDown?.(event);

        switch (event.key) {
        case "Enter":
            (event.target as HTMLElement).blur();
            break;
        case "Escape":
            setValue?.(revertValue);
            setEscape(true);
            break;
        }
    }, [revertValue, setValue, onKeyDown]);

    return <TextField
        {...nativeProps}
        outlined
        onFocus={
            (event: FocusEvent<HTMLInputElement>) => {
                onFocus?.(event);

                setRevertValue(event.currentTarget.value);
            }
        }
        onKeyDown={handleKeyDown}
        className={classnames(styles.textField, props.className)}
    />;
}