import styles from "./BlendedAssetClassesPage.module.scss";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { NextFooter } from "components/shared/modal/footer/NextFooter";

export interface BlendedAssetClassDefinitionOverlayFooterProps {
    percentageAssigned: number;
    isBlendValid: boolean;
    isBlendModified: boolean;
    onSave: VoidFunction;
}

export function BlendedAssetClassDefinitionOverlayFooter(props: BlendedAssetClassDefinitionOverlayFooterProps) {
    const { percentageAssigned, isBlendValid, isBlendModified, onSave } = props;

    return <NextFooter
        nextBtn={
            <ModalActionButton
                label="Save"
                isDefaultAction={true}
                onClick={onSave}
                disabled={!(isBlendValid && isBlendModified)}
            />
        }
    >
        {
            percentageAssigned !== 100 && <div className={styles.helpTextContainer}>
                <span className={styles.helpText}>The breakdown must equal 100%.</span>
            </div>
        }
    </NextFooter>;
}