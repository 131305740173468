import { Menu, MenuItem } from "rmwc";
import styles from "./AccountMenu.module.scss";

interface AccountMenuProps {
    onEditAccountClick: VoidFunction;
    onSellAllClick: VoidFunction;
    onCommentClick: VoidFunction;
    setDeleteAccountModalVisible: (visible: boolean) => void;
    setKebabVisible: (visible: boolean) => void;
    menuOpen: boolean;
    setMenuOpen: (open: boolean) => void;
    hasComments: boolean;
}

export function AccountMenu(props: AccountMenuProps) {
    const {onEditAccountClick, onSellAllClick, onCommentClick, setDeleteAccountModalVisible, hasComments, setKebabVisible, setMenuOpen, menuOpen} = props;

    const onMenuClose = () => {
        setMenuOpen(false);
        setKebabVisible(false);
    };

    return <Menu
        open={menuOpen}
        onClose={onMenuClose}
        onOpen={() => setKebabVisible(true)}
        anchorCorner="bottomLeft"
        className={styles.menu}
    >
        <MenuItem onClick={onEditAccountClick}>Edit Account</MenuItem>
        <MenuItem onClick={onSellAllClick}>Sell All</MenuItem>
        <MenuItem onClick={onCommentClick}>
            {hasComments ? "Edit Comment" : "Add Comment"}
        </MenuItem>
        <MenuItem
            onClick={()=>setDeleteAccountModalVisible(true)}
            className={styles.red}
        >Delete</MenuItem>
    </Menu>;
}