import classnames from "classnames";
import { DialogButton } from "rmwc";
import { ModalButtonProps, modalButtonTestId } from "./BaseModalButton";
import styles from "./ModalActionButton.module.scss";

export function ModalActionButton(props: ModalButtonProps) {
    const { className } = props;
    const disabled = props.disabled ?? false;

    return <DialogButton
        {...props}
        className={
            classnames(styles.button, className, {
                [styles.enabled]: !disabled,
                [styles.disabled]: disabled,
            })
        }
        data-testid={modalButtonTestId(props)}
    />;
}