import { DialogContent } from "rmwc";
import { isNumber } from "utils/NumberUtils";

export interface BaseModalContentProps {
    width: number;
    fixedHeight?: number;
    minHeight?: number;
    header: JSX.Element;
    content: JSX.Element;
    footer: JSX.Element;
    className?: string;
    "data-testid"?: string;
}

export function BaseModalContent(props: BaseModalContentProps) {
    const { width, fixedHeight, minHeight, header, content, footer, ...nativeProps } = props;
    const { className } = nativeProps;

    const dialogInlineStyles = {
        width,
        minWidth: width,
        maxWidth: width,
        ...(isNumber(fixedHeight) && {
            height: fixedHeight,
            minHeight: fixedHeight,
            maxHeight: fixedHeight,
        }),
        ...(isNumber(minHeight) && {
            minHeight
        }),
    };

    const dialogContentInlineStyles: {height?: number, minHeight?: number, maxHeight?: number} = {};
    if (isNumber(fixedHeight)) {
        const contentHeight = fixedHeight - 99;
        dialogContentInlineStyles.height = contentHeight;
        dialogContentInlineStyles.minHeight = contentHeight;
        dialogContentInlineStyles.maxHeight = contentHeight;
    } else if (isNumber(minHeight)) {
        const contentHeight = minHeight - 99;
        dialogContentInlineStyles.minHeight = contentHeight;
    }

    return <div
        className={className}
        style={dialogInlineStyles}
        data-testid={props["data-testid"]}
    >
        {header}
        <DialogContent style={dialogContentInlineStyles}>
            {content}
        </DialogContent>
        {footer}
    </div>;
}