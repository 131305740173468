import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { PositionDataModel } from "dataModels/positionDataModel";
import { sumValue } from "utils/ArrayUtils";
import { isNumber } from "utils/NumberUtils";
import { createValueModel } from "../../IasWorkspaceTypes";
import { AccountPositionViewModel, createAccountPosition } from "./AccountPositionViewModel";
import { AccountViewModel } from "./AccountViewModel";
import { SecurityDisplayViewModel, SecurityDisplay } from "./SecurityDisplayViewModel";

export interface SecurityViewModel extends SecurityDisplayViewModel {
    readonly security: IasSecurityDataModel;
    readonly accountPositions: AccountPositionViewModel[];
    readonly isBlended: boolean;
    readonly isInCustomGroup: boolean;
    addAccountPosition: (account: AccountViewModel, position: PositionDataModel) => void;
}

const createSecurityWithPercentage = (security: IasSecurityDataModel, percentage: number, key: string, segmentId: number): SecurityViewModel => {
    const accountPositions: AccountPositionViewModel[] = [];
    const isBlended = security.blendedFundPercentages.length > 0;
    const isInCustomGroup = isNumber(security.customGroupId);

    const currentValue = () => sumValue(accountPositions, accountPosition => accountPosition.currentValue());
    const changeValue = () => sumValue(accountPositions, accountPosition => accountPosition.changeValue());
    const containsChanges = () => accountPositions.some(position => position.changeValue() !== 0);
    const containsAccount = (accountId: number) => accountPositions.some(position => position.account.account.id === accountId);

    const addAccountPosition = (account: AccountViewModel, position: PositionDataModel) => {
        accountPositions.push(createAccountPosition(account, position, security, segmentId, percentage));
    };

    return {
        securityDisplay: SecurityDisplay.Security,
        name: security.positionName,
        segmentId,
        key,
        security,
        accountPositions,
        isBlended,
        isInCustomGroup,
        containsChanges,
        containsAccount,
        ...createValueModel(currentValue, changeValue),
        addAccountPosition,
    };
};

export const createSecurity = (security: IasSecurityDataModel, segmentId: number): SecurityViewModel => {
    return createSecurityWithPercentage(security, 100, security.id.toString(), segmentId);
};

export const createBlendedSecurity = (security: IasSecurityDataModel, percentage: number, segmentId: number): SecurityViewModel => {
    return createSecurityWithPercentage(security, percentage, `${segmentId}|${security.id}`, segmentId);
};