import { sum } from "lodash";
import { ModelPortfolioSharedViewModel, ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { AssetClassNames } from "constants/assetClassNames";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { ModelPortfolioDataModel, ModelPortfolioTargetSet } from "dataModels/modelPortfolioDataModel";
import { filterMap } from "utils/ArrayUtils";

export function createStandardModelPortfolioViewModel(shared: ModelPortfolioSharedViewModel, modelPortfolio: ModelPortfolioDataModel, assetClasses: AssetClassDataModel[], targetSet?: ModelPortfolioTargetSet): ModelPortfolioViewModel {
    let cashAssetClassTarget = shared.cashTarget();
    let fixedIncomeAssetClassTarget = modelPortfolio.fixedIncomeTarget;
    let equitiesAssetClassTarget = modelPortfolio.equitiesTarget;

    fixedIncomeAssetClassTarget -= cashAssetClassTarget;
    if (fixedIncomeAssetClassTarget < 0) {
        equitiesAssetClassTarget += fixedIncomeAssetClassTarget;
        fixedIncomeAssetClassTarget = 0;
    }

    if (shared.alternativesTarget() > 0) {
        cashAssetClassTarget -= shared.alternativesTarget() * shared.cashCarveOut() / 100;
        fixedIncomeAssetClassTarget -= shared.alternativesTarget() * shared.fixedIncomeCarveOut() / 100;
        equitiesAssetClassTarget -= shared.alternativesTarget() * shared.equitiesCarveOut() / 100;
    }

    const recommendedFundTargets = targetSet?.recommendedFundTargets ?? modelPortfolio.modelPortfolioTargetSet?.recommendedFundTargets ?? [];
    const segmentTargets = targetSet?.segmentTargets ?? modelPortfolio.modelPortfolioTargetSet?.segmentTargets ?? [];

    const assetClassTargetsMap = new Map<number, number>(assetClasses.map(assetClass => {
        let baseTarget = 0;

        switch (assetClass.name) {
        case AssetClassNames.Cash:
            baseTarget = cashAssetClassTarget;
            break;
        case AssetClassNames.FixedIncome:
            baseTarget = fixedIncomeAssetClassTarget;
            break;
        case AssetClassNames.Equities:
            baseTarget = equitiesAssetClassTarget;
            break;
        case AssetClassNames.Alternatives:
            baseTarget = shared.alternativesTarget();
            break;
        }

        return [assetClass.id, baseTarget];
    }));

    const groupIds = filterMap(recommendedFundTargets, target => target.segmentGroupId);
    const segmentIds = filterMap(recommendedFundTargets, target => target.segmentId)
        .concat(segmentTargets.map(target => target.segmentId));

    const groupsToAssetClass = new Map<number, number>(groupIds.map(groupId => {
        const assetClass = assetClasses.find(assetClass => assetClass.segmentGroups.some(g => g.id === groupId));
        return [groupId, assetClass?.id ?? -1];
    }));

    const segmentsToAssetClass = new Map<number, number>(segmentIds.map(segmentId => {
        const assetClass = assetClasses.find(assetClass => assetClass.segments.some(s => s.id === segmentId));
        return [segmentId, assetClass?.id ?? -1];
    }));

    const getTargetForAssetClass = (id: number) => assetClassTargetsMap.get(id) ?? 0;
    const getTargetForSegment = (id: number): number => {
        const assetClassId = segmentsToAssetClass.get(id) ?? -1;
        const classTarget = getTargetForAssetClass(assetClassId);

        const recommendedFundPercentage = sum(recommendedFundTargets.filter(target => target.segmentId === id).map(target => target.targetPercentage));
        const directPercentage = segmentTargets.find(segment => segment.segmentId === id)?.targetPercentage ?? 0;

        const segmentPercentage = recommendedFundPercentage + directPercentage;

        return classTarget * (segmentPercentage / 100);
    };
    const getTargetForSegmentGroup = (id: number): number => {
        const assetClassId = groupsToAssetClass.get(id) ?? -1;
        const classTarget = getTargetForAssetClass(assetClassId);

        const groupPercentage = sum(recommendedFundTargets.filter(target => target.segmentGroupId === id).map(target => target.targetPercentage));

        return classTarget * (groupPercentage / 100);
    };

    return {
        ...shared,
        getTargetForSegment,
        getTargetForSegmentGroup,
        getTargetForAssetClass,
        getTargetForCash: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Cash)?.id ?? -1),
        getTargetForFixedIncome: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.FixedIncome)?.id ?? -1),
        getTargetForEquities: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Equities)?.id ?? -1),
        getTargetForAlternatives: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Alternatives)?.id ?? -1),
    };
}