import classnames from "classnames";
import { Fragment, useCallback, useMemo, useState } from "react";
import { BackNextFooter } from "../../../../shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "../../../../shared/modal/footer/ModalActionButton";
import { BlendedAssetClassesTable } from "./BlendedAssetClassesTable";
import { BlendedAssetClassDefinitionOverlay } from "./BlendedAssetClassDefinitionOverlay";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { BlendedFundPercentage } from "components/shared/blended-fund/BlendedFundControls";
import styles from "./BlendedAssetClassesPage.module.scss";
import { DataSource } from "constants/dataSource";
import { usePropWatch } from "utils/usePropWatch";
import { BlendedAssetClassDefinitionOverlayFooter } from "./BlendedAssetClassDefinitionOverlayFooter";
import { WizardBaseModalContentProps } from "components/shared/modal/WizardBaseModalContentProps";

export interface BlendedAssetClassesPageProps {
    WizardBaseModalContent: (props: WizardBaseModalContentProps) => JSX.Element;
    stepper: JSX.Element;
    onCancel: VoidFunction;
    onBack: VoidFunction;
    onNext: VoidFunction;
    assetClasses: AssetClassDataModel[];
    blendDefinitions: BlendDefinition[];
    setBlendDefinitions: (blendDefinitions: BlendDefinition[]) => void;
}

export interface BlendDefinition {
    id: number;
    dataSource: DataSource;
    securityTicker: string;
    securityName: string;
    percentages: BlendedFundPercentage[];
}

export function BlendedAssetClassesPage(props: BlendedAssetClassesPageProps) {
    const { WizardBaseModalContent, onCancel, stepper, onBack, onNext, assetClasses, blendDefinitions, setBlendDefinitions } = props;

    const [activeBlendDefinitionHoldingId, setActiveBlendDefinitionHoldingId] = useState<number | null>(null);
    const [activeBlendDefinitionPercentageAssigned, setActiveBlendDefinitionPercentageAssigned] = useState(0);
    const [isActiveBlendDefinitionValid, setIsActiveBlendDefinitionValid] = useState(false);
    const [isActiveBlendDefinitionModified, setIsActiveBlendDefinitionModified] = useState(false);

    const activeBlendDefinition: BlendDefinition | undefined = useMemo(() => {
        return blendDefinitions.find(blend => blend.id === activeBlendDefinitionHoldingId);
    }, [activeBlendDefinitionHoldingId, blendDefinitions]);

    const [activeBlendDefinitionPercentages, setActiveBlendDefinitionPercentages] = usePropWatch(activeBlendDefinition?.percentages);

    const hasUndefinedBlendedHoldings: boolean = useMemo(() => {
        return blendDefinitions.some(blend => blend.percentages.length === 0);
    }, [blendDefinitions]);

    const startDefiningBlendForHolding = useCallback((id: number) => {
        setActiveBlendDefinitionHoldingId(id);
    }, []);

    const cancelActiveBlendDefinition = useCallback(() => {
        setActiveBlendDefinitionHoldingId(null);
    }, []);

    const saveActiveBlendDefinition = useCallback(() => {
        if (activeBlendDefinitionPercentages) {
            const newBlendDefinitions = blendDefinitions.slice();
            newBlendDefinitions[blendDefinitions.findIndex(blend => blend.id === activeBlendDefinitionHoldingId)].percentages = activeBlendDefinitionPercentages;
            setBlendDefinitions(newBlendDefinitions);
            setActiveBlendDefinitionHoldingId(null);
        }
    }, [activeBlendDefinitionHoldingId, activeBlendDefinitionPercentages, blendDefinitions, setBlendDefinitions]);

    return <Fragment>
        <WizardBaseModalContent
            onCancel={onCancel}
            content={
                <div>
                    {
                        activeBlendDefinition && activeBlendDefinitionPercentages && <BlendedAssetClassDefinitionOverlay
                            key={activeBlendDefinition.id}
                            securityTicker={activeBlendDefinition.securityTicker}
                            securityName={activeBlendDefinition.securityName}
                            assetClasses={assetClasses}
                            percentages={activeBlendDefinitionPercentages}
                            setPercentages={setActiveBlendDefinitionPercentages}
                            percentageAssigned={activeBlendDefinitionPercentageAssigned}
                            setPercentageAssigned={setActiveBlendDefinitionPercentageAssigned}
                            setIsBlendValid={setIsActiveBlendDefinitionValid}
                            setIsBlendModified={setIsActiveBlendDefinitionModified}
                            onCancel={cancelActiveBlendDefinition}
                        />
                    }
                    <div className={classnames({ [styles.blurred]: activeBlendDefinition })}>
                        {
                            activeBlendDefinition && <div className={styles.blur} />
                        }
                        {stepper}
                        {
                            !activeBlendDefinition && <BlendedAssetClassesTable
                                blendDefinitions={blendDefinitions}
                                startDefiningBlend={startDefiningBlendForHolding}
                            />
                        }
                    </div>
                </div>
            }
            footer={
                activeBlendDefinition
                    ? <BlendedAssetClassDefinitionOverlayFooter
                        isBlendValid={isActiveBlendDefinitionValid}
                        isBlendModified={isActiveBlendDefinitionModified}
                        percentageAssigned={activeBlendDefinitionPercentageAssigned}
                        onSave={saveActiveBlendDefinition}
                    />
                    : <BackNextFooter
                        backBtn={
                            <ModalActionButton
                                label="Back"
                                isDefaultAction={true}
                                onClick={onBack}
                            />
                        }
                        nextBtn={
                            <ModalActionButton
                                label="Finish"
                                isDefaultAction={true}
                                onClick={onNext}
                                disabled={hasUndefinedBlendedHoldings}
                            />
                        }
                    >
                        {
                            hasUndefinedBlendedHoldings && <div className={styles.helpTextContainer}>
                                <span className={styles.helpText}>All Blended Asset Classes above need to be defined to continue.</span>
                            </div>
                        }
                    </BackNextFooter>
            }
        />
    </Fragment>;
}