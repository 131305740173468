import { useEffect } from "react";
import { useUserQuery } from "services/userService";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zE: any | undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zESettings: any | undefined;
    }
}

interface ZendeskProps {
    zendeskKey: string;
}

function insertScript(zendeskKey: string) {
    const script = document.createElement("script");
    script.defer = true;

    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    
    document.body.append(script);
}

function initializeSettings() {
    window.zESettings = {
        webWidget: {
            color: {
                launcher: "#1C355C",
                launcherText: "#FFFFFF",
                button: "#1C355C",
                header: "#1C355C",
            },
            contactForm: {
            }
        },
    };
}

function disposeReferences() {
    if (window.zE === undefined) {
        return;
    }

    delete window.zE;
    delete window.zESettings;
}

export function Zendesk(props: ZendeskProps) {
    const { data: user } = useUserQuery();

    useEffect(() => {
        initializeSettings();
        insertScript(props.zendeskKey);

        return disposeReferences;
    }, [props.zendeskKey]);

    useEffect(() => {
        if (user) {
            window.zESettings.webWidget.contactForm.fields = [
                { id: "name", prefill: { "*": user.name } },
                { id: "email", prefill: { "*": user.identity } },
            ];

            if (window.zE !== undefined) {
                window.zE("webWidget", "updateSettings", window.zESettings);
            }
        }
    }, [user]);

    return null;
}