import { Fragment, useCallback, useMemo, useState } from "react";
import { Checkbox, LinearProgress } from "rmwc";
import styles from "./ModelPortfolioPage.module.scss";
import { TargetsDataModel } from "../../../../dataModels/targetsDataModel";
import { useClientCustomModelPortfolioMutation, useClientMutation } from "services/clientService";
import { useModelPortfolioQuery } from "services/modelPortfolioService";
import { BackNextFooter } from "../../../shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "../../../shared/modal/footer/ModalActionButton";
import { IasBaseModalContent } from "./IasBaseModalContent";
import { EditModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/EditModelPortfolioViewModel";
import { CustomModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { ModelPortfolioChangeType } from "utils/TargetsUtils";
import { ClientDataModel } from "dataModels/clientDataModel";
import { EditCustomSegmentNamesProps } from "components/shared/custom-segment-name/useEditCustomSegmentNames";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomModelPortfolioEditor } from "components/shared/modelPortfolio/CustomModelPortfolioEditor";
import { ModelPortfolioEditor } from "components/shared/modelPortfolio/ModelPortfolioEditor";
import { getCustomSegmentGroupNames, getCustomSegmentNames } from "utils/CustomSegmentNameUtils";

export interface ModelPortfolioPageProps extends EditCustomSegmentNamesProps {
    stepper: JSX.Element;
    onCancel: VoidFunction;
    onBack: VoidFunction;
    onNext: VoidFunction;
    client: ClientDataModel;
    modelPortfolio: EditModelPortfolioViewModel;
    targets: TargetsDataModel;
    patchTargets: (targetsUpdates: Partial<TargetsDataModel>) => void;
    patchCustomModelPortfolio: (cmpUpdates: Partial<CustomModelPortfolioDataModel>) => void;
    assetClasses: AssetClassDataModel[];
    customSegmentNamesAreValid: boolean;
    customSegmentNamesAreModified: boolean;
    assumedTotalPortfolioValue: number;
    setPortfolioValue?: (value: number) => void;
    customSegmentGroupNamesAreValid: boolean;
    customSegmentGroupNamesAreModified: boolean;
}

export function ModelPortfolioPage(props: ModelPortfolioPageProps) {
    const { stepper, onCancel, onBack, onNext, client, modelPortfolio, targets, patchTargets,
        patchCustomModelPortfolio, assetClasses, customSegmentNamesAreValid, customSegmentNamesAreModified, customSegmentGroupNamesAreValid, customSegmentGroupNamesAreModified } = props;

    const { status: modelPortfolioStatus, data: modelPortfolios } = useModelPortfolioQuery();
    const [clientMutation] = useState(useClientMutation(client.id));
    const [clientCmpMutation] = useState(useClientCustomModelPortfolioMutation(client.id));

    const [inputIsValid, setInputIsValid] = useState(true);
    const [makeDefaultPortfolio, setMakeDefaultPortfolio] = useState(true);
    const [extraFooter, setExtraFooter] = useState<JSX.Element | null>(null);

    const isNextEnabled: boolean = useMemo(() => {
        return inputIsValid && modelPortfolio.name() !== "" && customSegmentNamesAreValid && customSegmentGroupNamesAreValid;
    }, [customSegmentGroupNamesAreValid, customSegmentNamesAreValid, inputIsValid, modelPortfolio]);

    const shouldDisplayMakeDefaultCheckbox: boolean = useMemo(() => {
        return isNextEnabled && (modelPortfolio.hasChanged() !== ModelPortfolioChangeType.None || customSegmentNamesAreModified || customSegmentGroupNamesAreModified || customSegmentGroupNamesAreModified);
    }, [isNextEnabled, modelPortfolio, customSegmentNamesAreModified, customSegmentGroupNamesAreModified]);

    const onMakeDefaultCheckboxToggle = useCallback(() => {
        setMakeDefaultPortfolio(!makeDefaultPortfolio);
    }, [makeDefaultPortfolio]);

    const customOnNext = useCallback(() => {
        if (shouldDisplayMakeDefaultCheckbox && makeDefaultPortfolio) {
            const customSegmentNamesUpdates = customSegmentNamesAreModified
                ? { customSegmentNames: getCustomSegmentNames(assetClasses) }
                : {};
            const customSegmentGroupNamesUpdates = customSegmentGroupNamesAreModified ? { customSegmentGroupNames: getCustomSegmentGroupNames(assetClasses) } : {};
            const customModelPortfolio = modelPortfolio.customModelPortfolioInUse();
            if (customModelPortfolio) {
                clientCmpMutation.mutate({
                    customModelPortfolio,
                    ...customSegmentNamesUpdates,
                    ...customSegmentGroupNamesUpdates,
                });
            } else {
                clientMutation.mutate({
                    ...targets,
                    ...customSegmentNamesUpdates,
                    ...customSegmentGroupNamesUpdates,
                });
            }
        }

        onNext();
    }, [assetClasses, clientCmpMutation, clientMutation, customSegmentGroupNamesAreModified, customSegmentNamesAreModified, makeDefaultPortfolio, modelPortfolio, onNext, shouldDisplayMakeDefaultCheckbox, targets]);

    const customModelPortfolio = props.modelPortfolio.customModelPortfolioInUse();
    const modelPortfolioEditor =  customModelPortfolio ?
        <CustomModelPortfolioEditor
            modelPortfolio={modelPortfolio}
            setEvenMoreExtraAcceptInput={setExtraFooter}
            modelPortfolios={modelPortfolios ?? []}
            patchTargets={patchTargets}
            patchCustomModelPortfolio={patchCustomModelPortfolio}
            setInputValid={setInputIsValid}
            assetClasses={assetClasses}
            readonly={false}
            setCustomSegmentName={props.setCustomSegmentName}
            revertCustomSegmentName={props.revertCustomSegmentName}
            formatCustomSegmentNames={props.formatCustomSegmentNames}
            customModelPortfolio={customModelPortfolio}
            setCustomSegmentGroupName={props.setCustomSegmentGroupName}
            revertCustomSegmentGroupName={props.revertCustomSegmentGroupName}
            formatCustomSegmentGroupNames={props.formatCustomSegmentGroupNames}
        />
        :
        <ModelPortfolioEditor
            modelPortfolio={modelPortfolio}
            setEvenMoreExtraAcceptInput={setExtraFooter}
            modelPortfolios={modelPortfolios?? []}
            patchTargets={patchTargets}
            patchCustomModelPortfolio={patchCustomModelPortfolio}
            setInputValid={setInputIsValid}
            assetClasses={assetClasses}
            readonly={false}
            setCustomSegmentName={props.setCustomSegmentName}
            revertCustomSegmentName={props.revertCustomSegmentName}
            formatCustomSegmentNames={props.formatCustomSegmentNames}
            assumedTotalPortfolioValue={props.assumedTotalPortfolioValue}
            setPortfolioValue={props.setPortfolioValue}
            setCustomSegmentGroupName={props.setCustomSegmentGroupName}
            revertCustomSegmentGroupName={props.revertCustomSegmentGroupName}
            formatCustomSegmentGroupNames={props.formatCustomSegmentGroupNames}
        />;

    return <IasBaseModalContent
        onCancel={onCancel}
        content={
            <Fragment>
                {stepper}
                {
                    modelPortfolioStatus !== "success"
                        ? <div className={styles.emptyPage}>
                            <LinearProgress
                                className={styles.progress}
                                data-testid="linear-progress"
                            />
                        </div>
                        : <div>
                            {modelPortfolioEditor}
                        </div>
                }
            </Fragment>
        }
        footer={
            <BackNextFooter
                backBtn={
                    <ModalActionButton
                        label="Back"
                        isDefaultAction={true}
                        onClick={onBack}
                    />
                }
                nextBtn={
                    <ModalActionButton
                        label="Next"
                        isDefaultAction={true}
                        onClick={customOnNext}
                        disabled={!isNextEnabled}
                    />
                }
            >
                <div className={styles.checkboxContainer}>
                    { extraFooter }
                    {
                        shouldDisplayMakeDefaultCheckbox && <Checkbox
                            label="Make client default portfolio"
                            checked={makeDefaultPortfolio}
                            onChange={onMakeDefaultCheckboxToggle}
                            className={styles.checkbox}
                        />
                    }
                </div>
            </BackNextFooter>
        }
    />;
}
