import { format } from "date-fns";
import { convertToUTC } from "./DateUtils";

export function formatMeetingDate(date: Date) : string {
    return formatDateWritten(convertToUTC(date));
}

export function formatMeetingDateForPdfName(date: Date) : string {
    const utcAdjustedDate = new Date(date);
    utcAdjustedDate.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return format(utcAdjustedDate, "yyyy-MM-dd");
}

export function formatDateWritten(date: Date) : string {
    return format(date, "MMMM d, y");
}

export function formatDateNumbers (date: Date) : string {
    return format(date, "MM/dd/yyyy");
}

export function formatDateMonthAndYear (date: Date) : string {
    return format(date, "MM/yyyy");
}

export function formatDateTime(date: Date) : string {
    return format(date, "MMMM d, y h:mmaaa");
}