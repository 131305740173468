import classnames from "classnames";
import styles from "./LinearStepper.module.scss";

interface LinearStepperProps {
    stepNames: readonly string[]
    stepIndex: number
    className?: string
}

export default function LinearStepper(props: LinearStepperProps) {
    return <div className={`${styles.linearStepper} ${props.className}`}>
        {
            props.stepNames.map((stepName, index) => {
                const stepDisplayNumber = index+1;
                return <div
                    key={stepDisplayNumber}
                    className={
                        classnames(styles.step, {
                            [styles.currentStep]: props.stepIndex === index,
                            [styles.passedStep]: props.stepIndex > index
                        })
                    }
                    data-testid={`step-${stepDisplayNumber}`}
                >
                    <div className={styles.stepNumber}>
                        {
                            props.stepIndex > index ? <img
                                src="/icon-complete.svg"
                                alt="complete"
                            /> : stepDisplayNumber
                        }
                    </div>
                    <span className={styles.stepName}>{stepName}</span>
                    {
                        stepDisplayNumber < props.stepNames.length && <div
                            className={
                                classnames(styles.connector, {
                                    [styles.fiveSteps]: props.stepNames.length === 5,
                                })
                            }
                        />
                    }
                </div>;
            })
        }
    </div>;
}