import { BaseModalFooter } from "components/shared/modal/footer/BaseModalFooter";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { ModalAlternativeButton } from "components/shared/modal/footer/ModalAlternativeButton";
import { useCallback, useState } from "react";
import { hasContent } from "utils/StringUtils";
import { placeholderForNewAccountId } from "./AccountSelect";
import { PositionAddedCallback } from "./AddPositionCommon";
import styles from "./AddPositionModalFooter.module.scss";

export interface AddPositionModalFooterProps {
    isNewBlend: boolean;
    isEditingBlend: boolean;
    startEditingBlend: VoidFunction;
    stopEditingBlend: VoidFunction;
    isPreBlendValid: boolean;
    preBlendErrorText: string;
    isBlendValid: boolean;
    blendErrorText: string;
    addPosition: PositionAddedCallback;
    clearForm: VoidFunction;
    clearFormExceptAccount: VoidFunction;
    closeModal: VoidFunction;
    addingNewAccount: boolean;
    setAccountId: Callback<number | null>;
}

export function AddPositionModalFooter(props: AddPositionModalFooterProps) {
    const {
        isNewBlend,
        isEditingBlend, startEditingBlend, stopEditingBlend,
        isPreBlendValid,
        preBlendErrorText,
        isBlendValid,
        blendErrorText,
        addPosition,
        clearForm,
        clearFormExceptAccount,
        closeModal,
        addingNewAccount,
        setAccountId,
    } = props;

    const [forceDisableSaveButtons, setForceDisableSaveButtons] = useState(false);

    const saveAndAddNew = useCallback(async () => {
        if (addingNewAccount) {
            setForceDisableSaveButtons(true);

            const addPositionPromise = addPosition();
            clearFormExceptAccount();
            setAccountId(placeholderForNewAccountId); // Use as a flag that something is in the process of being added

            const addedPositionResponse = await addPositionPromise;
            setAccountId(addedPositionResponse.accountId);

            setForceDisableSaveButtons(false);
        } else {
            void addPosition();
            clearFormExceptAccount();
        }
    }, [addPosition, addingNewAccount, clearFormExceptAccount, setAccountId]);

    const saveAndClose = useCallback(async () => {
        void addPosition();
        closeModal();
    }, [addPosition, closeModal]);

    const hasNext = isNewBlend && !isEditingBlend;
    const isNextEnabled = isPreBlendValid;
    const isSaveEnabled = isNewBlend ? isBlendValid : isPreBlendValid;

    return <BaseModalFooter>
        <div>
            {
                isEditingBlend
                    ? <ModalActionButton
                        isDefaultAction={false}
                        onClick={stopEditingBlend}
                    >
                        Back
                    </ModalActionButton>
                    : <ModalAlternativeButton
                        isDefaultAction={false}
                        onClick={clearForm}
                        disabled={forceDisableSaveButtons}
                    >
                        Clear Form
                    </ModalAlternativeButton>
            }
        </div>
        <div className={styles.footerFiller} />
        <div>
            {
                !hasNext && isSaveEnabled && <ModalAlternativeButton
                    isDefaultAction={false}
                    onClick={saveAndAddNew}
                    className={styles.saveAndAddNew}
                    disabled={forceDisableSaveButtons}
                >
                    Save + Add New
                </ModalAlternativeButton>
            }
            <AddPositionModalFooterError
                preBlendErrorText={preBlendErrorText}
                blendErrorText={blendErrorText}
            />
        </div>
        <div>
            <ModalActionButton
                isDefaultAction={false}
                onClick={hasNext ? startEditingBlend : saveAndClose}
                disabled={!(hasNext ? isNextEnabled : (isSaveEnabled && !forceDisableSaveButtons))}
            >
                {hasNext ? "Next" : "Save"}
            </ModalActionButton>
        </div>
    </BaseModalFooter>;
}

interface AddPositionModalFooterErrorProps {
    preBlendErrorText: string;
    blendErrorText: string;
}

function AddPositionModalFooterError(props: AddPositionModalFooterErrorProps) {
    const { preBlendErrorText, blendErrorText } = props;

    return <div className={styles.errorText}>
        {
            hasContent(preBlendErrorText)
                ? preBlendErrorText
                : blendErrorText // can be empty
        }
    </div>;
}