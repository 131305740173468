import { useCallback, useState } from "react";
import { RecommendedFundsTable } from "./RecommendedFundsTable";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { Button, Icon } from "rmwc";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import styles from "./RecommendedFundsFullList.module.scss";
import { IconName } from "iconNames";

interface RecommendedFundsFullListProps {
    viewModel: AssetTablesViewModel;
}

export function RecommendedFundsFullList(props: RecommendedFundsFullListProps) {
    const [tableVisible, setTableVisible] = useState(false);

    const showTable = useCallback(() => {
        telemetry.trackEvent(EventType.RecommendedFundsTableShown);
        setTableVisible(true);
    }, [setTableVisible]);
    const hideTable = useCallback(() => setTableVisible(false), [setTableVisible]);

    return !tableVisible
        ? <HiddenLayout showCallback={showTable} />
        : <ShownLayout
            viewModel={props.viewModel}
            hideCallback={hideTable}
        />;
}

interface ShownLayoutProps {
    viewModel: AssetTablesViewModel;
    hideCallback: VoidFunction;
}

function ShownLayout(props: ShownLayoutProps) {
    return <div>
        <Button
            onClick={props.hideCallback}
            className={styles.recommendedFundsButton}
        >
            Hide Recommended Funds
        </Button>
        {
            props.viewModel.modelPortfolio.customModelPortfolioInUse() !== undefined && <div className={styles.notAvailable}>
                <Icon
                    icon={IconName.Info}
                    className={styles.infoIcon}
                />
            Recommended fund information is not available when using a custom model portfolio.
            </div>
        }
        {props.viewModel.modelPortfolio.customModelPortfolioInUse() === undefined && <RecommendedFundsTable viewModel={props.viewModel} />}
    </div>;
}

interface HiddenLayoutProps {
    showCallback: VoidFunction;
}

function HiddenLayout(props: HiddenLayoutProps) {
    return <div>
        <Button
            onClick={props.showCallback}
            className={styles.recommendedFundsButton}
        >
            Show All Recommended Funds
        </Button>
    </div>;
}