import { isNullOrUndefined } from "./isNullOrUndefined";

export function isString(s: unknown): s is string {
    return typeof s === "string";
}

export function isWhiteSpace(s: string) {
    return s.match(/^\s*$/) !== null;
}

export function hasContent(s: string | null | undefined): s is string {
    return !isNullOrUndefined(s) && !isWhiteSpace(s);
}

export function isNumericInteger(s: string) {
    return s.match(/^-?\d+$/) !== null;
}

export function isNumericDecimal(s: string) {
    return s.match(/^-?\d+(\.\d+)?$|^-?\.\d+$/) !== null;
}

export function isCaseInsensitiveContentMatch(a: string, b: string) {
    return a.trim().toLowerCase() === b.trim().toLowerCase();
}

export function toTestId(s: string) {
    return s.toLocaleLowerCase().replace(/ /g, "-");
}
