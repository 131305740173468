import { Fragment, useMemo, useState } from "react";
import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow, Icon, Tooltip } from "rmwc";
import { ModelPortfolioTableAssetClassHeaderCell } from "./ModelPortfolioTableAssetClassHeaderCell";
import { ModelPortfolioTableSegmentOrGroupCell } from "./ModelPortfolioTableSegmentOrGroupCell";
import { ModelPortfolioTargetsTableViewModel } from "./view-models/ModelPortfolioTargetsTableViewModel";
import { EditCustomSegmentNamesProps } from "../custom-segment-name/useEditCustomSegmentNames";
import styles from "./ModelPortfolioTargetsTable.module.scss";
import { CurrencyEntry } from "components/numeric-values/CurrencyEntry";
import { NumericTextFieldHooks } from "components/numeric-values/useNumericTextField";
import { IconName } from "iconNames";

export interface ModelPortfolioTargetsTableProps extends EditCustomSegmentNamesProps {
    viewModel: ModelPortfolioTargetsTableViewModel;
    readonly: boolean;
    assumedTotalPortfolioValue: NumericTextFieldHooks;
    setPortfolioValue?: (value: number) => void;
    targetInDollarsSelected: boolean;
}

export function ModelPortfolioTargetsTable(props: ModelPortfolioTargetsTableProps) {
    const {
        assetClassesHeaderEntries,
        assetClassesSetsOfRowEntries,
    } = props.viewModel;

    const {setPortfolioValue, assumedTotalPortfolioValue} = props;
    const assetClassesTargetsRow = useMemo(() => assetClassesHeaderEntries.map((entry) =>
        <ModelPortfolioTableAssetClassHeaderCell
            key={entry.name}
            name={entry.name}
            targetPercentage={entry.target}
            segmentWithEditableTargetPercentage={null}
            readonly={props.readonly}
        />
    ), [assetClassesHeaderEntries, props.readonly]);

    const rowCount = useMemo(() => Math.max(
        ...assetClassesSetsOfRowEntries.map((assetClassRowEntries) => assetClassRowEntries.length)
    ), [assetClassesSetsOfRowEntries]);

    const rows = useMemo(() => Array(rowCount).fill(null).map((x, rowIndex) =>
        <DataTableRow key={rowIndex}>
            {
                assetClassesSetsOfRowEntries.map((assetClassRowEntries, assetClassIndex) => {
                    const entry = assetClassRowEntries.at(rowIndex);
                    const key = assetClassesHeaderEntries.at(assetClassIndex)?.name ?? ""; // index range should match array size

                    if (!entry) {
                        return <DataTableCell key={key} />;
                    }

                    return <ModelPortfolioTableSegmentOrGroupCell
                        key={key}
                        {...props}
                        name={entry.name}
                        targetPercentage={entry.target}
                        segmentWithCustomizableName={entry.segmentWithCustomizableName}
                        segmentGroupWithCustomizableName={entry.segmentGroupWithCustomizableName}
                        targetPercentagesAreEditable={false}
                    />;
                })
            }
        </DataTableRow>
    ), [assetClassesHeaderEntries, assetClassesSetsOfRowEntries, props, rowCount]);

    const [portfolioValueInfoShown, setPortfolioValueInfoShown] = useState(false);

    const totalPortfolioValue = useMemo(() => {
        if(!setPortfolioValue) {
            return <Fragment>Target percentages are based on the total portfolio value of ${assumedTotalPortfolioValue.display}
                <Tooltip
                    content={<Fragment>Total portfolio value comes from the client's most recently finalized IAS.</Fragment>}
                    open={portfolioValueInfoShown}
                    align="bottom"
                    showArrow={true}
                >
                    <Icon
                        data-testid="portfolio-value-info-icon"
                        icon={{icon: IconName.Info, strategy: "ligature"}}
                        className={styles.icon}
                        onMouseEnter={() => setPortfolioValueInfoShown(true)}
                        onMouseLeave={() => setPortfolioValueInfoShown(false)}
                    />
                </Tooltip>
            </Fragment>;
        } else {
            return <Fragment>Target percentages assume a total portfolio value of
                <CurrencyEntry
                    display={assumedTotalPortfolioValue.display}
                    setDisplay={assumedTotalPortfolioValue.setDisplay}
                    numericValue={assumedTotalPortfolioValue.value}
                    setNumericValue={assumedTotalPortfolioValue.setValue}
                    valueIsValid={assumedTotalPortfolioValue.isValid}
                    setValueIsValid={assumedTotalPortfolioValue.setIsValid}
                    onValidChange={()=>setPortfolioValue(assumedTotalPortfolioValue.value)}
                    isValueValid={(value: number) => value > 0}
                    prefix="$"
                    align="start"
                    className={styles.totalPortfolioValueTextField}
                    data-testid="total-portfolio-value-textfield"
                /></Fragment>;
        }
    }, [assumedTotalPortfolioValue.display, assumedTotalPortfolioValue.isValid, assumedTotalPortfolioValue.setDisplay, assumedTotalPortfolioValue.setIsValid, assumedTotalPortfolioValue.setValue, assumedTotalPortfolioValue.value, portfolioValueInfoShown, setPortfolioValue]);

    return <DataTable className={styles.table}>
        <DataTableContent>
            <DataTableHead>
                {
                    props.targetInDollarsSelected && <DataTableRow data-testid="total-portfolio-value">
                        <DataTableCell
                            colSpan={4}
                            className={styles.portfolioAssumptionCell}
                        >
                            {totalPortfolioValue}
                        </DataTableCell>
                    </DataTableRow>
                }
                <DataTableRow>
                    <DataTableHeadCell className={styles.targetHeaderCell}>TARGET</DataTableHeadCell>
                    <DataTableHeadCell className={styles.targetHeaderCell}>TARGET</DataTableHeadCell>
                    <DataTableHeadCell className={styles.targetHeaderCell}>TARGET</DataTableHeadCell>
                    <DataTableHeadCell className={styles.targetHeaderCell}>TARGET</DataTableHeadCell>
                </DataTableRow>
                <DataTableRow>
                    {assetClassesTargetsRow}
                </DataTableRow>
            </DataTableHead>
            <DataTableBody>
                {rows}
            </DataTableBody>
        </DataTableContent>
    </DataTable>;
}