type CancellationCallback = VoidFunction;

export interface CancellationToken {
    readonly canceled: () => boolean;
    readonly cancel: VoidFunction;
    readonly register: (callback: CancellationCallback) => void;
}

export const createCancellationToken = (): CancellationToken => {
    const token = {
        canceled: false
    };

    const registry: CancellationCallback[] = [];

    return {
        canceled: () => token.canceled,
        cancel: () => {
            if (!token.canceled) {
                token.canceled = true;
                registry.forEach(callback => callback());
            }
        },
        register: (callback) => registry.push(callback)
    };
};

export interface CancellationTokenProducer {
    token() : CancellationToken;
    cancel(): void;
}

export function createCancellationTokenProducer(): CancellationTokenProducer {
    let cancellationToken: CancellationToken | null = null;

    function token() {
        cancellationToken ??= createCancellationToken();
        return cancellationToken;
    }

    function cancel() {
        cancellationToken?.cancel();
        cancellationToken = null;
    }

    return {
        token,
        cancel,
    };
}

export class CancellationError extends Error {

}