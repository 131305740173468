import { ClassifiedBlackDiamondHolding, ClassifiedVisionPossession } from "components/client-page/createIas";
import { LinearProgressOverlay } from "components/shared/LinearProgressOverlay";
import { AccountType } from "constants/accountType";
import { DataSource } from "constants/dataSource";
import { BlendedFundSegmentPercentage } from "dataModels/blendedFundSegmentPercentage";
import { IasDataModel } from "dataModels/iasDataModel";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { round } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { RefreshAccount, useRefreshIasMutator, WritableSecurity } from "services/iasService";
import { accountsMatch } from "utils/AccountUtils";
import { filterMap } from "utils/ArrayUtils";
import { isNumber } from "utils/NumberUtils";
import { isMatchingSecurity } from "utils/securityUtils";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { VisionPossession } from "services/visionService";
import { xor } from "lodash";
import { Segment } from "constants/segment";

interface UpdateDataPageProps {
    populatedPortfolio: PopulatedPortfolio | undefined;
    classifiedHoldings: ClassifiedBlackDiamondHolding[];
    newVisionPossessions: ClassifiedVisionPossession[];
    visionPossessions: ClassifiedVisionPossession[];
    originalVisionPossessions: VisionPossession[];
    ias: IasDataModel;
}

function haveVisionPossessionsChanged(newVisionPossessions: ClassifiedVisionPossession[], visionPossessions: ClassifiedVisionPossession[], originalVisionPossessions: VisionPossession[]) {
    if (newVisionPossessions.length > 0) {
        return true;
    }

    const originalPossessionIds = originalVisionPossessions.map(possession => possession.id);
    const currentPossessionIds = visionPossessions.map(possession => possession.id);

    return xor(originalPossessionIds, currentPossessionIds).length > 0;
}

export function UpdateDataPage(props: UpdateDataPageProps) {
    const { populatedPortfolio, classifiedHoldings, newVisionPossessions, visionPossessions, ias, originalVisionPossessions } = props;

    const { mutate, status } = useRefreshIasMutator(ias.id);

    const [newSecurities] = useState<WritableSecurity[]>([]);
    const findOrCreateSecurityId = useCallback((ticker: string, positionName: string, segmentId: number, blendedFundPercentages: BlendedFundSegmentPercentage[]) => {
        let security = newSecurities.find(s => isMatchingSecurity(s, ticker, positionName));
        if (security === undefined) {
            security = {
                tempId: -newSecurities.length - 1,
                tickerSymbol: ticker,
                positionName: positionName,
                segmentId: segmentId,
                blendedFundPercentages,
            };
            newSecurities.push(security);
        }
        return security;
    }, [newSecurities]);

    const getPossessionSecurityId = useCallback((securities: IasSecurityDataModel[], possession: ClassifiedVisionPossession) =>
        securities.find(s => isMatchingSecurity(s, null, possession.account ?? possession.name))?.id
            ?? findOrCreateSecurityId("", possession.account ?? possession.name, possession.segmentId ?? -1, possession.blendedFundPercentages).tempId,
    [findOrCreateSecurityId]);

    const accounts: RefreshAccount[] = useMemo(() => {
        const accounts: RefreshAccount[] = populatedPortfolio?.accounts.map(a => ({
            accountNumber: a.accountNumber,
            owner: a.owner,
            custodian: a.custodian,
            originalCustodian: a.originalCustodian,
            typeId: a.typeId,
            dataSource: DataSource.BlackDiamond,
            positions: filterMap(a.holdings, h => {
                const classifiedHolding = classifiedHoldings.find(holding => holding.assetId === h.assetId);
                const segmentId = h.segmentId
                    ?? classifiedHolding?.segmentId
                    ?? ias.securities.find(s => isMatchingSecurity(s, h.ticker, h.name))?.segmentId;
                if (!isNumber(segmentId)) {
                    return null;
                }
                const blendedFundPercentages = classifiedHolding?.blendedFundPercentages ?? [];
                return {
                    sourceId: h.assetId,
                    currentValue: round(h.marketValue),
                    asOfDate: h.returnDate,
                    dataSource: DataSource.BlackDiamond,
                    securityId: ias.securities.find(s => isMatchingSecurity(s, h.ticker, h.name))?.id
                        ?? findOrCreateSecurityId(h.ticker ?? "", h.name, segmentId, blendedFundPercentages).tempId,
                    shares: h.shares
                };
            })
        })) ?? [];
        newVisionPossessions.forEach(possession => {
            const accountNumber = (possession.accountType?.id === AccountType.Direct_Taxable || possession.accountType?.id === AccountType.Direct_Tax_Adv)
                ? "DIRECT"
                : "ALTERNATIVE";
            accounts.push({
                accountNumber: possession.account ?? accountNumber,
                owner: possession.owner,
                custodian: possession.custodian ?? "",
                originalCustodian: undefined,
                typeId: possession.accountType?.id ?? -1,
                dataSource: DataSource.Vision,
                positions: [{
                    currentValue: round(possession.endValue),
                    asOfDate: possession.endDate,
                    dataSource: DataSource.Vision,
                    sourceId: possession.id,
                    securityId: getPossessionSecurityId(ias.securities, possession),
                }]
            });
        });
        Array.from(visionPossessions).forEach(possession => {
            const account = ias.accounts.find(a => a.positions.some(p => p.dataSource === DataSource.Vision && p.sourceId === possession.id));
            if (account) {
                const refreshAccount = accounts.find(a => accountsMatch(a, account) && a.dataSource === DataSource.Vision);
                if (refreshAccount) {
                    refreshAccount.positions.push({
                        currentValue: round(possession.endValue),
                        asOfDate: possession.endDate,
                        dataSource: DataSource.Vision,
                        sourceId: possession.id,
                        securityId: getPossessionSecurityId(ias.securities, possession),
                    });
                } else {
                    accounts.push({
                        positions: [{
                            currentValue: round(possession.endValue),
                            asOfDate: possession.endDate,
                            dataSource: DataSource.Vision,
                            sourceId: possession.id,
                            securityId: getPossessionSecurityId(ias.securities, possession),
                        }],
                        accountNumber: account.accountNumber,
                        owner: account.owner,
                        custodian: account.custodian,
                        originalCustodian: undefined,
                        typeId: account.typeId,
                        dataSource: DataSource.Vision
                    });
                }
            }
        });
        return accounts;
    },
    [findOrCreateSecurityId, getPossessionSecurityId, ias.accounts, ias.securities, newVisionPossessions, populatedPortfolio?.accounts, classifiedHoldings, visionPossessions]);

    useEffect(() => {
        if (status === "idle") {
            telemetry.trackEvent(EventType.DataUpdated);

            if (haveVisionPossessionsChanged(newVisionPossessions, visionPossessions, originalVisionPossessions)) {
                telemetry.trackEvent(EventType.VisionSelectionsChanged);
            }

            if (newSecurities.some(security => security.segmentId === Segment.Blended)) {
                telemetry.trackEvent(EventType.BlendedFundUsed);
            }

            mutate({
                dateRefreshed: new Date(),
                accounts: accounts,
                newSecurities: newSecurities,
                lastModifiedDate: ias.dateModified
            });
        }
    }, [accounts, ias.dateModified, mutate, newSecurities, newVisionPossessions, originalVisionPossessions, status, visionPossessions]);

    useEffect(() => {
        if (status === "success") {
            window.location.reload();
        }
    }, [status]);

    return <LinearProgressOverlay
        text="Saving data..."
        fullscreen
    />;
}