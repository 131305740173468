export enum IasState {
    Draft = "Draft",
    ForReview = "ForReview",
    Final = "Final",
}

export const IasStates = Object.keys(IasState) as IasState[];

export function isFinal(state: IasState): boolean {
    return state === IasState.Final;
}

export enum IasStateText {
    Draft = "Draft",
    ForReview = "For Review",
    Final = "Final",
}

export const IasStateTexts = Object.values(IasStateText) as IasStateText[];

export function textForIasState(state: IasState): IasStateText {
    return IasStateTexts[IasStates.indexOf(state)];
}