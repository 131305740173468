import React, { PropsWithChildren } from "react";
import { telemetry } from "services/telemetryService";

export class LoggingErrorBoundary extends React.Component<PropsWithChildren> {
    static getDerivedStateFromError(_error: Error) {
        return {};
    }

    componentDidCatch(error: Error, _errorInfo: React.ErrorInfo): void {
        telemetry.trackException(error);
    }

    render(): React.ReactNode {
        return this.props.children;
    }
}