import classnames from "classnames";
import { IconName } from "iconNames";
import { useCallback, useMemo, useState } from "react";
import { MenuSurfaceAnchor, Menu, MenuItem, IconButton } from "rmwc";
import styles from "./CustomSegmentNameMenu.module.scss";

interface CustomSegmentNameMenuProps {
    editCustomName: VoidFunction;
    revertCustomName?: VoidFunction;
}

export function CustomSegmentNameMenu(props: CustomSegmentNameMenuProps) {
    const { editCustomName, revertCustomName } = props;

    const [kebabVisible, setKebabVisible] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuOpen = useCallback(() => {
        setKebabVisible(true);
    }, []);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
        setKebabVisible(false);
    }, []);

    const handleKebabClick = useCallback(() => {
        setMenuOpen((menuOpen) => !menuOpen);
    }, []);

    const menuClassName = useMemo(() => classnames(styles.menu, {
        [styles.widestOptionIsEditLabel]: !revertCustomName,
        [styles.widestOptionIsRevertLabel]: revertCustomName,
    }), [revertCustomName]);

    return <MenuSurfaceAnchor className={styles.customSegmentNameMenu}>
        <Menu
            anchorCorner="bottomLeft"
            open={menuOpen}
            onOpen={handleMenuOpen}
            onClose={handleMenuClose}
            className={menuClassName}
        >
            <MenuItem
                onClick={editCustomName}
                className={styles.menuItem}
            >
                Edit Label
            </MenuItem>
            {
                revertCustomName &&
                    <MenuItem
                        onClick={revertCustomName}
                        className={styles.menuItem}
                    >
                        Revert Label
                    </MenuItem>
            }
        </Menu>
        <IconButton
            icon={IconName.MoreVert}
            onClick={handleKebabClick}
            className={classnames({ [styles.visible]: kebabVisible })}
        />
    </MenuSurfaceAnchor>;
}