import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SegmentDataModel } from "../../../../dataModels/segmentDataModel";
import { SegmentGroupDataModel } from "dataModels/segmentGroupDataModel";
import { sumValue } from "utils/ArrayUtils";
import { createValueModel, ValueModel } from "../../IasWorkspaceTypes";
import { ModelPortfolioViewModel } from "./ModelPortfolioViewModel";
import { SecurityViewModel } from "./SecurityViewModel";
import { CustomGroupViewModel } from "./CustomGroupViewModel";
import { SecurityDisplayViewModel } from "./SecurityDisplayViewModel";

export interface SegmentViewModel extends ValueModel {
    readonly segment: SegmentDataModel;
    readonly assetClass: AssetClassDataModel;
    readonly group?: SegmentGroupDataModel;
    readonly securities: SecurityViewModel[];
    readonly customGroups: CustomGroupViewModel[];
    isEmpty: () => boolean;
    includeInDisplay: () => boolean;
    containsChanges: () => boolean;
    containsAccount: (accountId: number) => boolean;
    allSecurities: () => SecurityViewModel[];
    allSecurityDisplays: () => SecurityDisplayViewModel[];
    allSecurityDisplaysWithChanges: () => SecurityDisplayViewModel[];
}

export const createSegment = (segment: SegmentDataModel, assetClass: AssetClassDataModel, modelPortfolio: ModelPortfolioViewModel, group?: SegmentGroupDataModel): SegmentViewModel => {
    const securities: SecurityViewModel[] = [];
    const customGroups: CustomGroupViewModel[] = [];

    const allSecurities = () => customGroups.flatMap(customGroup => customGroup.securities).concat(securities);

    const isEmpty = () => allSecurities().length === 0;

    const currentValue = () => sumValue(allSecurities(), security => security.currentValue());
    const changeValue = () => sumValue(allSecurities(), security => security.changeValue());

    const includeInDisplay = () => !isEmpty() || modelPortfolio.getTargetForSegment(segment.id) > 0;

    const containsChanges = () => allSecurities().some(security => security.containsChanges());
    const containsAccount = (accountId: number) => allSecurities().some(security => security.containsAccount(accountId));

    const allSecurityDisplays = () => (customGroups as SecurityDisplayViewModel[]).concat(securities);

    const allSecurityDisplaysWithChanges = () => allSecurityDisplays().filter(child => child.containsChanges());

    return {
        ...createValueModel(currentValue, changeValue),
        segment,
        assetClass,
        group,
        securities,
        customGroups,
        isEmpty,
        includeInDisplay,
        containsChanges,
        containsAccount,
        allSecurities,
        allSecurityDisplays,
        allSecurityDisplaysWithChanges,
    };
};