import SegmentSelection from "components/shared/SegmentSelection";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { BlackDiamondHolding } from "dataModels/blackDiamond/holding";
import { Fragment, useState, MouseEvent } from "react";
import { DataTableRow, DataTableCell } from "rmwc";
import { PopulatedAccount } from "services/blackDiamondService";
import { hasContent } from "utils/StringUtils";
import styles from "./BDHoldingClassificationRow.module.scss";
import ExpandableToggleButton from "components/shared/ExpandableToggleButton";
import classnames from "classnames";

interface BDHoldingClassificationRowProps {
    holding: BlackDiamondHolding;
    assetClasses: AssetClassDataModel[];
    blackDiamondAccounts: PopulatedAccount[];
    onSegmentChange: (segmentId: number, holding: BlackDiamondHolding) => void;
}

export function BDHoldingClassificationRow(props: BDHoldingClassificationRowProps) {
    const {holding, assetClasses, blackDiamondAccounts, onSegmentChange} = props;

    const [open, setOpen] = useState(false);
    const accounts = blackDiamondAccounts.filter(a => a.holdings.some(h => h.assetId === holding.assetId));

    function onRowClick(event: MouseEvent) {
        if((event.target as Element).closest(".mdc-select") === null) {
            setOpen(!open);
        }
    }

    return <Fragment>
        <DataTableRow
            className={classnames("clickable", styles.row)}
            data-testid="holding-row"
            onClick={onRowClick}
        >
            <DataTableCell className={styles.toggleButtonCell}>
                <ExpandableToggleButton
                    open={open}
                    setOpen={setOpen}
                />
            </DataTableCell>
            <DataTableCell className={styles.nameCell}>
                {hasContent(holding.ticker) && <span className={styles.ticker}>{holding.ticker}</span>}
                {holding.name}
            </DataTableCell>
            <DataTableCell className={styles.selectCell}>
                <SegmentSelection
                    assetClasses={assetClasses}
                    onChange={(segmentId: number) => onSegmentChange(segmentId, holding)}
                    segmentId={holding.segmentId}
                    hideFloatingLabel={true}
                    includeBlended={true}
                    data-testid="segment"
                />
            </DataTableCell>
        </DataTableRow>
        {
            open && <DataTableRow className={styles.accountInfoRow}>
                <DataTableCell />
                <DataTableCell className={styles.accountLabel}>
                    Held in the following accounts:
                </DataTableCell>
            </DataTableRow>
        }
        {
            open && accounts.map(account =>
                <DataTableRow
                    className={styles.accountInfoRow}
                    key={account.id}
                >
                    <DataTableCell />
                    <DataTableCell className={styles.accountInfoCell}>
                        <div>
                            {account.accountNumber}  |  {account.owner.split(" ")[0]}  |  {account.custodian}  |  {account.type}
                        </div>
                    </DataTableCell>
                </DataTableRow>)
        }
    </Fragment>;
}