import { sum } from "lodash";
import { ModelPortfolioSharedViewModel, ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { AssetClassNames } from "constants/assetClassNames";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";

export function createCustomModelPortfolioViewModel(shared: ModelPortfolioSharedViewModel, customModelPortfolio: CustomModelPortfolioDataModel, assetClasses: AssetClassDataModel[]): ModelPortfolioViewModel {
    const getTargetForAssetClass = (id: number) => {
        const assetClass = assetClasses.find(ac => ac.id === id);
        return assetClass
            ? sum(assetClass.segmentGroups.map(group => getTargetForSegmentGroup(group.id))
                .concat(assetClass.segments.map(segment => getTargetForSegment(segment.id))))
            : 0;
    };
    const getTargetForSegment = (id: number): number => {
        return customModelPortfolio.customSegmentTargets.find(segmentTarget => segmentTarget.segmentId === id)?.targetPercentage ?? 0;
    };
    const getTargetForSegmentGroup = (id: number): number => {
        return customModelPortfolio.customSegmentGroupTargets.find(groupTarget => groupTarget.segmentGroupId === id)?.targetPercentage ?? 0;
    };

    return {
        ...shared,
        cashTarget: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Cash)?.id ?? -1),
        alternativesTarget: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Alternatives)?.id ?? -1),
        getTargetForSegment,
        getTargetForSegmentGroup,
        getTargetForAssetClass,
        getTargetForCash: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Cash)?.id ?? -1),
        getTargetForFixedIncome: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.FixedIncome)?.id ?? -1),
        getTargetForEquities: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Equities)?.id ?? -1),
        getTargetForAlternatives: () => getTargetForAssetClass(assetClasses.find(ac => ac.name === AssetClassNames.Alternatives)?.id ?? -1),
    };
}