import "@fontsource/zilla-slab/latin.css";
import "@fontsource/outfit/latin.css";
import "@fontsource/material-icons";
import classnames from "classnames";
import { IconName } from "iconNames";

export function EagerFontLoader() {
    const fonts = ["outfit", "zilla"];
    const weights = ["300", "400", "500", "600", "700"];
    return <div className="invisible-fonts">
        {
            fonts.flatMap(font =>
                weights.map(weight =>
                    <span
                        key={`${font}-${weight}`}
                        className={classnames(font, `weight-${weight}`)}
                    />
                )
            )
        }
        {
            Object.values(IconName).map(icon =>
                <span
                    key={icon}
                    className="material-icons"
                >{icon}</span>
            )
        }
    </div>;
}