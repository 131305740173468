import classnames from "classnames";
import { IndeterminateTristate, IndeterminateCheckbox } from "components/controls/IndeterminateCheckbox";
import NumberDisplay from "components/numeric-values/NumberDisplay";
import { sum } from "lodash";
import { useMemo, useCallback } from "react";
import { DataTableRow, DataTableCell } from "rmwc";
import { VisionCategory, VisionSubcategory } from "services/visionService";
import styles from "./ImportVisionAssetsContent.module.scss";
import { SelectionProps, allPossessions, isSubcategory } from "./VisionAssetsCommonFunctions";

interface VisionContainerRowProps {
    container: VisionCategory | VisionSubcategory,
    toggleButton: JSX.Element,
    onClick: VoidFunction
}

export function VisionContainerRow(props: VisionContainerRowProps & SelectionProps) {
    const { addSelections, removeSelections, container, selectedElements } = props;
    const possessions = useMemo(() => allPossessions(container), [container]);
    const testId = `container-${container.name}`;

    const onCheckboxClick = useCallback(() => {
        if (selectedElements.get(container.uniqueId) === IndeterminateTristate.Solid) {
            removeSelections(possessions);
        } else {
            addSelections(possessions);
        }
    }, [addSelections, container.uniqueId, possessions, removeSelections, selectedElements]);

    return <DataTableRow
        data-testid={testId}
        onClick={props.onClick}
    >
        <DataTableCell className={classnames(styles.column1, styles.mediumFont)}>
            <div className={styles.alignVerticalCenter}>
                {isSubcategory(props.container) && <div className={styles.indent1} />}
                <div className={styles.chevron}>
                    {props.toggleButton}
                </div>
                <IndeterminateCheckbox
                    tristate={selectedElements.get(container.uniqueId) ?? IndeterminateTristate.Empty}
                    onClick={onCheckboxClick}
                    data-testid={`${testId}-tristate`}
                />
                {container.name}
            </div>
        </DataTableCell>
        <DataTableCell className={styles.column2} />
        <DataTableCell className={styles.column3}>
            <NumberDisplay value={sum(possessions.map(possession => possession.endValue))} />
        </DataTableCell>
        <DataTableCell className={styles.column4} />
    </DataTableRow>;
}