import classnames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";
import { PercentageEntry, inactiveDisplayOfPercentageValue, isPercentageValueValid } from "components/numeric-values/PercentageEntry";
import styles from "./EditModelPortfolioControls.module.scss";
import { useNumericTextField } from "components/numeric-values/useNumericTextField";
import { ModelPortfolioSelect } from "./ModelPortfolioSelect";
import { ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { AlternativeTargetGuidanceTooltip } from "./AlternativeTargetGuidanceTooltip";
import { Button } from "rmwc";
import { activeDisplayOfCurrencyValue, inactiveDisplayOfCurrencyValue, isCurrencyValueValid, parseCurrencyValueFromDisplay } from "components/numeric-values/CurrencyEntry";
import { NumericTextField } from "components/numeric-values/NumericTextField";

interface EditModelPortfolioControlsProps {
    modelPortfolio: ModelPortfolioViewModel;
    modelPortfolios: ModelPortfolioDataModel[];
    patchTargets: (targetsUpdates: Partial<TargetsDataModel>) => void;
    setValid: (isValid: boolean) => void;
    cashTargetInDollarsSelected: boolean;
    setCashTargetInDollarsSelected: (selected: boolean) => void;
    alternativesTargetInDollarsSelected: boolean;
    setAlternativesTargetInDollarsSelected: (selected: boolean) => void;
    readonly?: boolean;
}

export default function EditModelPortfolioControls(props: EditModelPortfolioControlsProps) {
    const [carveOutsVisible, setCarveOutsVisible] = useState(props.modelPortfolio.alternativesTarget() !== 0);

    const cashTarget = useNumericTextField(props.modelPortfolio.cashTarget(), inactiveDisplayOfPercentageValue, isPercentageValueValid);
    const cashTargetInDollars = useNumericTextField(props.modelPortfolio.cashTargetInDollars() ?? 0, inactiveDisplayOfCurrencyValue, isCurrencyValueValid);
    const alternativesTarget = useNumericTextField(props.modelPortfolio.alternativesTarget(), inactiveDisplayOfPercentageValue, isPercentageValueValid);
    const alternativesTargetInDollars = useNumericTextField(props.modelPortfolio.alternativesTargetInDollars() ?? 0, inactiveDisplayOfCurrencyValue, isCurrencyValueValid);
    const cashCarveOut = useNumericTextField(props.modelPortfolio.cashCarveOut(), inactiveDisplayOfPercentageValue, isPercentageValueValid);
    const fixedIncomeCarveOut = useNumericTextField(props.modelPortfolio.fixedIncomeCarveOut(), inactiveDisplayOfPercentageValue, isPercentageValueValid);
    const equitiesCarveOut = useNumericTextField(props.modelPortfolio.equitiesCarveOut(), inactiveDisplayOfPercentageValue, isPercentageValueValid);

    const inputNumbersAreValid = useMemo(() => {
        const carveOutsUsed = props.modelPortfolio.alternativesTarget() !== 0;
        const cashTargetIsValid = cashTargetInDollars.value >= 0 && cashTarget.isValid;
        const alternativesTargetIsValid = alternativesTargetInDollars.value >= 0 && alternativesTarget.isValid;
        return cashTargetIsValid && alternativesTargetIsValid &&
            (!carveOutsUsed || (fixedIncomeCarveOut.isValid && cashCarveOut.isValid && equitiesCarveOut.isValid));
    }, [alternativesTarget.isValid, alternativesTargetInDollars.value, cashCarveOut.isValid, cashTarget.isValid,
        cashTargetInDollars.value,equitiesCarveOut.isValid, fixedIncomeCarveOut.isValid, props.modelPortfolio]);
    
    useEffect(() => {
        const carveOutsUsed = props.modelPortfolio.alternativesTarget() !== 0;
        const carveOutsSumTo100 = !carveOutsUsed || (props.modelPortfolio.cashCarveOut() + props.modelPortfolio.fixedIncomeCarveOut() + props.modelPortfolio.equitiesCarveOut() === 100);
        props.setValid(carveOutsSumTo100 && inputNumbersAreValid);
    },
    [alternativesTarget.isValid,
        alternativesTargetInDollars.isValid,
        cashCarveOut.isValid,
        cashTarget.isValid,
        cashTargetInDollars.isValid,
        equitiesCarveOut.isValid,
        fixedIncomeCarveOut.isValid,
        inputNumbersAreValid, props]);

    const onValidCashTargetChange = useCallback(() => {
        props.patchTargets({ cashTarget: cashTarget.value });
    }, [cashTarget.value, props]);

    const onValidCashTargetInDollarsChange = useCallback(() => {
        props.patchTargets({ cashTargetInDollars: cashTargetInDollars.value });
    }, [cashTargetInDollars.value, props]);

    const onValidAlternativesTargetChange = useCallback(() => {
        props.patchTargets({ alternativesTarget: alternativesTarget.value });
        setCarveOutsVisible(alternativesTarget.value !== 0);
    }, [alternativesTarget.value, props]);

    const onValidAlternativesTargetInDollarsChange = useCallback(() => {
        props.patchTargets({ alternativesTargetInDollars: alternativesTargetInDollars.value });
        setCarveOutsVisible(alternativesTargetInDollars.value !== 0);
    }, [alternativesTargetInDollars.value, props]);

    const onValidCashCarveOutChange = useCallback(() => {
        props.patchTargets({ cashCarveOut: cashCarveOut.value });
    }, [cashCarveOut.value, props]);

    const onValidFixedIncomeCarveOutChange = useCallback(() => {
        props.patchTargets({ fixedIncomeCarveOut: fixedIncomeCarveOut.value });
    }, [fixedIncomeCarveOut.value, props]);

    const onValidEquitiesCarveOutChange = useCallback(() => {
        props.patchTargets({ equitiesCarveOut: equitiesCarveOut.value });
    }, [equitiesCarveOut.value, props]);

    const onCashTargetInPercentSelected = useCallback(() => {
        props.setCashTargetInDollarsSelected(false);
        props.patchTargets({ cashTargetInDollars: undefined, cashTarget: 0 });
    }, [props]);

    const onCashTargetInDollarsSelected = useCallback(() => {
        props.setCashTargetInDollarsSelected(true);
        props.patchTargets({ cashTarget: undefined, cashTargetInDollars: 0 });
    }, [props]);

    const onAlternativesTargetInPercentSelected = useCallback(() => {
        props.setAlternativesTargetInDollarsSelected(false);
        props.patchTargets({ alternativesTargetInDollars: undefined, alternativesTarget: 0 });
        setCarveOutsVisible(false);
    }, [props]);

    const onAlternativesTargetInDollarsSelected = useCallback(() => {
        props.setAlternativesTargetInDollarsSelected(true);
        props.patchTargets({ alternativesTarget: undefined, alternativesTargetInDollars: 0 });
        setCarveOutsVisible(false);
    }, [props]);

    return <div className={styles.elevation}>
        <div className={styles.controlsContainer}>
            <div className={styles.leftControls}>
                <div className={styles.leftControlsTopLevel}>
                    <ModelPortfolioSelect
                        modelPortfolio={props.modelPortfolio}
                        modelPortfolios={props.modelPortfolios}
                        patchTargets={props.patchTargets}
                        readonly={props.readonly}
                    />
                    <div className={styles.targetUnitsButtons}>
                        <Button
                            label="%"
                            className={classnames(styles.targetUnitsButton, styles.targetUnitsButtonTop, { [styles.selectedUnitsButton]: !props.cashTargetInDollarsSelected })}
                            outlined
                            onClick={onCashTargetInPercentSelected}
                        />
                        <Button
                            label="$"
                            className={classnames(styles.targetUnitsButton, styles.targetUnitsButtonBottom, { [styles.selectedUnitsButton]: props.cashTargetInDollarsSelected })}
                            outlined
                            onClick={onCashTargetInDollarsSelected}
                            data-testid="cash-in-dollars-button"
                        />
                    </div>
                    {
                        props.cashTargetInDollarsSelected && <NumericTextField
                            label="Cash Target"
                            parseNumericValueFromDisplay={parseCurrencyValueFromDisplay}
                            inactiveDisplayOfNumericValue={inactiveDisplayOfCurrencyValue}
                            activeDisplayOfNumericValue={activeDisplayOfCurrencyValue}
                            isValueValid={(value: number) => value >= 0}
                            display={cashTargetInDollars.display}
                            setDisplay={cashTargetInDollars.setDisplay}
                            numericValue={cashTargetInDollars.value}
                            setNumericValue={cashTargetInDollars.setValue}
                            valueIsValid={cashTargetInDollars.isValid}
                            setValueIsValid={cashTargetInDollars.setIsValid}
                            onValidChange={onValidCashTargetInDollarsChange}
                            className={classnames(styles.cashTarget)}
                            prefix="$"
                            align="start"
                            data-testid="cash-target-dollars"
                        />
                    }
                    {
                        !props.cashTargetInDollarsSelected && <PercentageEntry
                            label="Cash Target"
                            numericValue={cashTarget.value}
                            setNumericValue={cashTarget.setValue}
                            display={cashTarget.display}
                            setDisplay={cashTarget.setDisplay}
                            valueIsValid={cashTarget.isValid}
                            setValueIsValid={cashTarget.setIsValid}
                            onValidChange={onValidCashTargetChange}
                            className={classnames(styles.percentageEntry, styles.cashTarget)}
                            data-testid="cash-target"
                            disabled={props.readonly}
                        />
                    }
                    <div className={styles.targetUnitsButtons}>
                        <Button
                            label="%"
                            className={classnames(styles.targetUnitsButton, styles.targetUnitsButtonTop, { [styles.selectedUnitsButton]: !props.alternativesTargetInDollarsSelected })}
                            outlined
                            onClick={onAlternativesTargetInPercentSelected}
                        />
                        <Button
                            label="$"
                            className={classnames(styles.targetUnitsButton, styles.targetUnitsButtonBottom,  { [styles.selectedUnitsButton]: props.alternativesTargetInDollarsSelected })}
                            outlined
                            onClick={onAlternativesTargetInDollarsSelected}
                            data-testid="alternatives-in-dollars-button"
                        />
                    </div>
                    {
                        props.alternativesTargetInDollarsSelected && <NumericTextField
                            label="Alternatives Target"
                            parseNumericValueFromDisplay={parseCurrencyValueFromDisplay}
                            inactiveDisplayOfNumericValue={inactiveDisplayOfCurrencyValue}
                            activeDisplayOfNumericValue={activeDisplayOfCurrencyValue}
                            isValueValid={(value: number) => value >= 0}
                            display={alternativesTargetInDollars.display}
                            setDisplay={alternativesTargetInDollars.setDisplay}
                            numericValue={alternativesTargetInDollars.value}
                            setNumericValue={alternativesTargetInDollars.setValue}
                            valueIsValid={alternativesTargetInDollars.isValid}
                            setValueIsValid={alternativesTargetInDollars.setIsValid}
                            onValidChange={onValidAlternativesTargetInDollarsChange}
                            className={classnames(styles.alternativesTarget)}
                            prefix="$"
                            align="start"
                            data-testid="alternatives-target-dollars"
                            icon={<AlternativeTargetGuidanceTooltip />}
                        />
                    }
                    {
                        !props.alternativesTargetInDollarsSelected && <PercentageEntry
                            label="Alternatives Target"
                            numericValue={alternativesTarget.value}
                            setNumericValue={alternativesTarget.setValue}
                            display={alternativesTarget.display}
                            setDisplay={alternativesTarget.setDisplay}
                            valueIsValid={alternativesTarget.isValid}
                            setValueIsValid={alternativesTarget.setIsValid}
                            onValidChange={onValidAlternativesTargetChange}
                            className={classnames(styles.percentageEntry, styles.alternativesTarget)}
                            data-testid="alternatives-target"
                            disabled={props.readonly}
                            icon={<AlternativeTargetGuidanceTooltip />}
                        />
                    }
                </div>
                {props.modelPortfolio.cashTarget() > 0 && <div className={classnames(styles.leftControlsHelpText, styles.cashTargetHelper)}>Cash Target comes from Fixed Income then Equities.</div>}
            </div>
            {
                carveOutsVisible &&
                <div className={styles.carveouts}>
                    <div className={styles.rightControls}>
                        <PercentageEntry
                            label="From Cash"
                            numericValue={cashCarveOut.value}
                            setNumericValue={cashCarveOut.setValue}
                            display={cashCarveOut.display}
                            setDisplay={cashCarveOut.setDisplay}
                            valueIsValid={cashCarveOut.isValid}
                            setValueIsValid={cashCarveOut.setIsValid}
                            onValidChange={onValidCashCarveOutChange}
                            className={classnames(styles.percentageEntry, styles.cashCarveOut)}
                            data-testid="cash-carve-out" 
                            disabled={props.readonly}
                        />
                        <PercentageEntry
                            label="From Fixed Income"
                            numericValue={fixedIncomeCarveOut.value}
                            setNumericValue={fixedIncomeCarveOut.setValue}
                            display={fixedIncomeCarveOut.display}
                            setDisplay={fixedIncomeCarveOut.setDisplay}
                            valueIsValid={fixedIncomeCarveOut.isValid}
                            setValueIsValid={fixedIncomeCarveOut.setIsValid}
                            onValidChange={onValidFixedIncomeCarveOutChange}
                            className={classnames(styles.percentageEntry, styles.fixedIncomeCarveOut)}
                            data-testid="fixed-income-carve-out" 
                            disabled={props.readonly}
                        />
                        <PercentageEntry
                            label="From Equities"
                            numericValue={equitiesCarveOut.value}
                            setNumericValue={equitiesCarveOut.setValue}
                            display={equitiesCarveOut.display}
                            setDisplay={equitiesCarveOut.setDisplay}
                            valueIsValid={equitiesCarveOut.isValid}
                            setValueIsValid={equitiesCarveOut.setIsValid}
                            onValidChange={onValidEquitiesCarveOutChange}
                            className={classnames(styles.percentageEntry, styles.equitiesCarveOut)}
                            data-testid="equities-carve-out" 
                            disabled={props.readonly}
                        />
                    </div>
                    <div className={styles.helpText}>The sum of these carve-outs must equal 100%.</div>
                </div>
            }
        </div>
    </div>;
}