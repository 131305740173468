import { inactiveDisplayOfPercentageValue, isPercentageValueValid, PercentageEntry } from "components/numeric-values/PercentageEntry";
import { useNumericTextField } from "components/numeric-values/useNumericTextField";
import { useEffect } from "react";
import styles from "./BlendedPercentageEntry.module.scss";

export interface BlendedPercentageEntryProps {
    percentage: number;
    setPercentage: (percentage: number) => void;
    setPercentageIsValid: (isValid: boolean) => void;
    "data-testid"?: string;
}

export function BlendedPercentageEntry(props: BlendedPercentageEntryProps) {
    const { percentage, setPercentage, setPercentageIsValid } = props;

    const target = useNumericTextField(percentage, inactiveDisplayOfPercentageValue, isPercentageValueValid);

    useEffect(() => {
        setPercentageIsValid(target.isValid);
    }, [setPercentageIsValid, target.isValid]);

    return <PercentageEntry
        numericValue={target.value} 
        setNumericValue={target.setValue}
        display={target.display}
        setDisplay={target.setDisplay}
        valueIsValid={target.isValid}
        setValueIsValid={target.setIsValid}
        onValidChange={setPercentage}
        className={styles.percentageEntry}
        data-testid={props["data-testid"]}
    />;
}