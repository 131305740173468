import classnames from "classnames";
import PercentageDisplay from "components/numeric-values/PercentageDisplay";
import { isPercentageValueValid } from "components/numeric-values/PercentageEntry";
import SegmentSelection from "components/shared/SegmentSelection";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { IconName } from "iconNames";
import { sum } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, IconButton } from "rmwc";
import { filterMap, pickMap } from "utils/ArrayUtils";
import { isNumber } from "utils/NumberUtils";
import { hasContent } from "utils/StringUtils";
import { v4 as uuidv4 } from "uuid";
import styles from "./BlendedFundControls.module.scss";
import { BlendedPercentageEntry } from "./BlendedPercentageEntry";

export interface BlendedFundPercentage {
    segmentId: number | null;
    percentage: number;
}

export interface BlendedFundControlsProps {
    securityTicker: string;
    securityName: string;
    assetClasses: AssetClassDataModel[];
    percentages: BlendedFundPercentage[];
    setPercentages: (percentages: BlendedFundPercentage[]) => void;
    percentageAssigned: number;
    setPercentageAssigned: (percentage: number) => void;
    setIsValid: (isValid: boolean) => void;
}

export interface BlendedFundControlsRow extends BlendedFundPercentage {
    id: string;
    percentageIsValid: boolean;
    setSegmentId: (segmentId: number) => void;
    setPercentage: (percentage: number) => void;
    setPercentageIsValid: (isValid: boolean) => void;
    remove: VoidFunction;
}

export function BlendedFundControls(props: BlendedFundControlsProps) {
    const { securityTicker, securityName, assetClasses, percentages, setPercentages, percentageAssigned, setPercentageAssigned, setIsValid } = props;

    const sortedSegmentIds: number[] = useMemo(() => assetClasses.flatMap(ac => [
        ...ac.segmentGroups.flatMap(group => group.segments).map(segment => segment.id),
        ...ac.segments.map(segment => segment.id),
    ]), [assetClasses]);

    const [rows, setRows] = useState<BlendedFundControlsRow[]>(() => percentages
        .map(blend => {
            const id = uuidv4();
            return {
                id,
                segmentId: blend.segmentId,
                percentage: blend.percentage,
                percentageIsValid: isPercentageValueValid(blend.percentage),
                setSegmentId: (segmentId: number) => setRowSegmentId(id, segmentId),
                setPercentage: (percentage: number) => setRowPercentage(id, percentage),
                setPercentageIsValid: (isValid: boolean) => setRowPercentageIsValid(id, isValid),
                remove: () => removeRow(id),
            };
        })
        .sort((a, b) => sortedSegmentIds.indexOf(a.segmentId ?? -1) - sortedSegmentIds.indexOf(b.segmentId ?? -1))
    );

    const setRowSegmentId = useCallback((id: string, segmentId: number) => {
        setRows((rows) => {
            const newRows = rows.slice();
            newRows[rows.findIndex(row => row.id === id)].segmentId = segmentId;
            return newRows;
        });
    }, []);

    const setRowPercentage = useCallback((id: string, percentage: number) => {
        setRows((rows) => {
            const newRows = rows.slice();
            newRows[rows.findIndex(row => row.id === id)].percentage = percentage;
            return newRows;
        });
    }, []);

    const setRowPercentageIsValid = useCallback((id: string, isValid: boolean) => {
        setRows((rows) => {
            const newRows = rows.slice();
            newRows[rows.findIndex(row => row.id === id)].percentageIsValid = isValid;
            return newRows;
        });
    }, []);

    const removeRow = useCallback((id: string) => {
        setRows((rows) => {
            const newRows = rows.slice();
            newRows.splice(rows.findIndex(row => row.id === id), 1);
            return newRows;
        });
    }, []);

    const addRow = useCallback(() => {
        setRows((rows) => {
            const id = uuidv4();
            const newRow: BlendedFundControlsRow = {
                id,
                segmentId: null,
                percentage: 0,
                percentageIsValid: true,
                setSegmentId: (segmentId: number) => setRowSegmentId(id, segmentId),
                setPercentage: (percentage: number) => setRowPercentage(id, percentage),
                setPercentageIsValid: (isValid: boolean) => setRowPercentageIsValid(id, isValid),
                remove: () => removeRow(id),
            };
            return rows.concat(newRow);
        });
    }, [removeRow, setRowPercentage, setRowPercentageIsValid, setRowSegmentId]);

    useEffect(() => {
        setPercentages(rows);
    }, [rows, setPercentages]);

    useEffect(() => {
        setPercentageAssigned(sum(rows.map(row => row.percentage)));
    }, [rows, setPercentageAssigned]);

    useEffect(() => {
        if (rows.find(row => !row.percentageIsValid)) {
            setIsValid(false);
        } else {
            setIsValid(
                percentageAssigned === 100
                    && percentages.every(percentage => isNumber(percentage.segmentId))
                    && new Set(percentages.map(percentage => percentage.segmentId)).size === percentages.length
            );
        }
    }, [percentageAssigned, percentages, rows, setIsValid]);

    const percentageRemaining = 100 - percentageAssigned;
    const percentageRemainingClassNames = useMemo(() => classnames(
        styles.percentageRemaining,
        {
            [styles.negativePercentageRemaining]: percentageRemaining < 0,
        },
    ), [percentageRemaining]);

    return <div data-testid="blended-fund-controls">
        <div className={styles.name}>
            {hasContent(securityTicker) && <span className={styles.ticker}>{securityTicker}</span>}
            {securityName}
        </div>
        {
            rows.map((row, index) => <div
                className={styles.row}
                key={row.id}
            >
                <div className={styles.column1}>
                    <SegmentSelection
                        assetClasses={assetClasses}
                        segmentId={row.segmentId}
                        onChange={row.setSegmentId}
                        includeBlended={false}
                        hideFloatingLabel={true}
                        excludeSegmentIds={filterMap(rows, r => r.id === row.id ? null : r.segmentId)}
                    />
                </div>
                <div className={styles.column2}>
                    <BlendedPercentageEntry
                        percentage={row.percentage}
                        setPercentage={row.setPercentage}
                        setPercentageIsValid={row.setPercentageIsValid}
                        data-testid={`blended-percentage-entry-${index + 1}`}
                    />
                </div>
                <div className={styles.column3}>
                    <IconButton
                        icon={IconName.Close}
                        onClick={row.remove}
                        width={14}
                        height={14}
                        data-testid={`blended-remove-row-${index + 1}`}
                    />
                </div>
            </div>)
        }
        <div className={styles.row}>
            <div className={styles.column1}>
                <Button
                    onClick={addRow}
                    className={styles.addRow}
                    data-testid="blended-add-row"
                >
                    Add
                </Button>
            </div>
            <div
                className={styles.column2}
                data-testid="blended-percent-remaining"
            >
                <PercentageDisplay
                    value={percentageRemaining}
                    precision={5}
                    className={percentageRemainingClassNames}
                />
                <div className={percentageRemainingClassNames}> Remaining</div>
            </div>
        </div>
    </div>;
}

export function normalizePercentages(percentages: BlendedFundPercentage[]) {
    return pickMap(percentages, "segmentId", "percentage")
        .sort((a, b) => (a.segmentId ?? -1) - (b.segmentId ?? -1));
}