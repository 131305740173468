import { DataTableCell, DataTableRow } from "rmwc";
import { Fragment, useCallback, useContext, useMemo, useState, MouseEvent } from "react";
import classnames from "classnames";
import AccountPositionRow from "../account-position-row/AccountPositionRow";
import { SecurityViewModel } from "../view-models/SecurityViewModel";
import sharedStyles from "../SharedStyles.module.scss";
import NumberDisplay from "../../../numeric-values/NumberDisplay";
import styles from "./SecurityRow.module.scss";
import ExpandableToggleButton from "../../../shared/ExpandableToggleButton";
import { WorkspaceContext } from "contexts/workspaceContext";
import { ExpansionType, useExpansionState } from "services/expansionStateCache";
import { hasContent } from "utils/StringUtils";
import { IasDataModel } from "dataModels/iasDataModel";
import RecommendedFund from "./RecommendedFund";
import { SecurityMenu } from "./SecurityMenu";
import { EditSecurityModal } from "./EditSecurityModal";
import { ModelPortfolioViewModel } from "../view-models/ModelPortfolioViewModel";
import { ValueModel } from "../../IasWorkspaceTypes";
import { IconName } from "iconNames";
import { applyAccountFilter } from "components/ias-workspace/add-position/AccountSelect";

interface SecurityRowProps {
    viewModel: SecurityViewModel;
    depth: number;
    ias: IasDataModel;
    overallValues: ValueModel;
    assetClassTarget: number;
    modelPortfolio: ModelPortfolioViewModel;
    onStartCreatingGroup?: (security: SecurityViewModel) => void;
}

export function SecurityRow(props: SecurityRowProps) {
    const { onStartCreatingGroup } = props;

    const context = useContext(WorkspaceContext);

    const positionsFilteredByAccount = applyAccountFilter(context.filterByAccountId)
        ? props.viewModel.accountPositions.filter(ap => ap.account.account.id === context.filterByAccountId)
        : props.viewModel.accountPositions;
    const positionsFilteredByChanges = context.onlyChanges ? positionsFilteredByAccount.filter(p => p.changeValue() !== 0) : positionsFilteredByAccount;
    const sortedAccounts = positionsFilteredByChanges.sort((a, b) => a.account.account.accountNumber.localeCompare(b.account.account.accountNumber));

    const [open, setOpen] = useExpansionState(ExpansionType.Security, props.viewModel.key);

    const [isEditingSecurity, setIsEditingSecurity] = useState(false);

    const onClickRow = useCallback((evt: MouseEvent<HTMLElement>) => {
        const isKebab = evt.target instanceof HTMLButtonElement && evt.target.textContent === IconName.MoreVert;
        const isMenuOption = evt.target instanceof HTMLLIElement;
        if (!isEditingSecurity && !isKebab && !isMenuOption) {
            setOpen(!open);
        }
    }, [isEditingSecurity, open, setOpen]);

    const isRecommendedFund = useMemo(() => {
        return props.modelPortfolio.recommendedFunds().some(target => target.ticker === props.viewModel.security.tickerSymbol);
    }, [props.modelPortfolio, props.viewModel.security.tickerSymbol]);

    const openEditSecurityModal = useCallback(() => {
        setIsEditingSecurity(true);
    }, []);

    const openCreateCustomGroupModal = useCallback(() => {
        onStartCreatingGroup?.(props.viewModel);
    }, [onStartCreatingGroup, props.viewModel]);

    const depthStyle = sharedStyles[`indent_${props.depth}`];
    return (<Fragment>
        <DataTableRow
            data-testid="security-row"
            className={classnames(styles.row, "clickable")}
            onClick={onClickRow}
        >
            <DataTableCell className={classnames(depthStyle, {[sharedStyles.wide]: !context.sidebarOpen })}>
                <div className={classnames(styles.securityName, sharedStyles.collapsibleGroup)}>
                    <ExpandableToggleButton
                        open={open}
                        setOpen={setOpen}
                    />
                    <div>
                        {hasContent(props.viewModel.security.tickerSymbol) && <span className={styles.ticker}>{props.viewModel.security.tickerSymbol.toLocaleUpperCase()}</span>}
                        {props.viewModel.security.positionName.toLocaleUpperCase()}
                    </div>
                </div>
            </DataTableCell>
            <DataTableCell>
                {
                    isRecommendedFund && <RecommendedFund
                        modelPortfolio={props.modelPortfolio}
                        ticker={props.viewModel.security.tickerSymbol}
                        assetClassTarget={props.assetClassTarget}
                        overallValues={props.overallValues}
                    />
                }
            </DataTableCell>
            <DataTableCell className={classnames(sharedStyles.value_column, styles.securityRow)}>
                <NumberDisplay value={props.viewModel.currentValue()} />
            </DataTableCell>
            <DataTableCell className={classnames(sharedStyles.value_column, styles.securityRow)}>
                <NumberDisplay value={props.viewModel.changeValue()} />
            </DataTableCell>
            <DataTableCell className={classnames(sharedStyles.value_column, styles.securityRow)}>
                <NumberDisplay value={props.viewModel.proposedValue()} />
            </DataTableCell>
            <DataTableCell className={classnames(styles.securityRow, styles.kebabCell)}>
                <SecurityMenu
                    security={props.viewModel}
                    openEditSecurityModal={openEditSecurityModal}
                    openCreateCustomGroupModal={openCreateCustomGroupModal}
                    visibleClassName={styles.visible}
                />
                {
                    isEditingSecurity && <EditSecurityModal
                        open={isEditingSecurity}
                        setOpen={setIsEditingSecurity}
                        className={styles.disableRowBasedCursor}
                        ias={props.ias}
                        security={props.viewModel}
                    />
                }
            </DataTableCell>
        </DataTableRow>
        {
            open && sortedAccounts.map(account => <AccountPositionRow
                key={account.position.id}
                depth={props.depth + 1}
                viewModel={account}
                ias={props.ias}
                data-testid={props.viewModel.security.positionName.toLocaleUpperCase()}
            />)
        }
    </Fragment>);
}