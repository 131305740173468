import { DataTableCell, DataTableRow } from "rmwc";
import MeetingGroup from "./MeetingGroup";
import styles from "./RecentGroup.module.scss";
import depth from "./Depth.module.scss";
import { MeetingDataModel } from "../../dataModels/meetingDataModel";
import { Fragment, useState } from "react";
import ExpandableToggleButton from "../shared/ExpandableToggleButton";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

interface RecentGroupProps {
    meetings: MeetingDataModel[];
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    clientId: number;
    allMeetings: MeetingDataModel[];
}

export default function RecentGroup(props: RecentGroupProps) {
    const meetings = props.meetings;

    const [open, setOpen] = useState(true);

    return (
        <Fragment>
            <DataTableRow
                className="clickable"
                onClick={() => setOpen(!open)}
            >
                <DataTableCell className={`${styles.recentCell} ${depth.depth0}`}>
                    <ExpandableToggleButton
                        open={open}
                        setOpen={setOpen}
                    />
                    Recent
                </DataTableCell>
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
            </DataTableRow>
            {
                open && meetings.map((meeting) => <MeetingGroup
                    key={meeting.id}
                    meeting={meeting}
                    modelPortfolios={props.modelPortfolios}
                    assetClasses={props.assetClasses}
                    depth={1}
                    open
                    clientId={props.clientId}
                    allMeetings={props.allMeetings}
                />)
            }
        </Fragment>
    );
}