import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, IconButton, List, ListItem } from "rmwc";
import { IconName } from "../../iconNames";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import styles from "./NavMenu.module.scss";
import { ValeoTextField } from "components/shared/ValeoTextField";
import classnames from "classnames";
import { determineNavigationType, NavigationType, useKeyboardAwareNavigate } from "utils/UseKeyboardAwareNavigate";
import { ClientSearchResult } from "dataModels/clientDataModel";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { useClientListSearch } from "services/clientService";
import { hasContent } from "utils/StringUtils";

interface NavMenuProps {
    open: boolean;
    setOpen: BooleanCallback;
    initialResults: ClientSearchResult;
    statusIds: number[];
    currentClientId?: number;
    currentClientKey?: string;
}

interface ClientListItemProps {
    clientId: number;
    clientKey: string;
    selected: boolean;
    handleOnClick: (event: React.MouseEvent<HTMLElement>, clientId: number) => void;
}

function ClientListItem(props: ClientListItemProps) {
    return <ListItem
        className={classnames(styles.client, {[styles.selected]: props.selected})}
        onClick={(event: React.MouseEvent<HTMLElement>) => props.handleOnClick(event, props.clientId)}
    >
        <span className={styles.clientKey}>{props.clientKey}</span>
        <Icon icon={IconName.ChevronRight} />
    </ListItem>;
}

export function NavMenu(props: NavMenuProps) {
    const [searchString, setSearchString] = useState("");
    const [clients, setClients] = useState(props.initialResults.clients);
    const [showGoToFullClientList, setShowGoToFullClientList] = useState(props.initialResults.pagination.pageCount > 1);

    const clientListSearch = useClientListSearch();

    const performSearch = useCallback(async (search: string) => {
        const results = await clientListSearch(props.statusIds, search, undefined, true);
        if (results) {
            setClients(results.clients);
            setShowGoToFullClientList(results.pagination.pageCount > 1);
        }
    }, [clientListSearch, props.statusIds]);

    const onSearchChange = useCallback(async (evt: ChangeEvent<HTMLInputElement>) => {
        setSearchString(evt.target.value);
    }, []);

    const handleDrawerClosed = useCallback(() => {
        props.setOpen(false);
        setSearchString("");
    }, [props]);

    const navigate = useKeyboardAwareNavigate();
    const handleOnClick = useCallback((event: MouseEvent<HTMLElement>, clientId: number) => {
        if (determineNavigationType(event) === NavigationType.Normal) {
            handleDrawerClosed();
        }
        telemetry.trackEvent(EventType.SideNavClientLink);
        navigate(event, `/client/${clientId}`);
    }, [handleDrawerClosed, navigate]);

    const trackLogoNavigation = useCallback(() => {
        telemetry.trackEvent(EventType.LogoNav);
    }, []);

    const goToFullClientList = useCallback((event: MouseEvent<HTMLElement>) => {
        if (hasContent(props.currentClientKey)) {
            navigate(event, "/");
        } else {
            window.location.reload();
        }
    }, [navigate, props.currentClientKey]);

    useEffect(() => {
        void performSearch(searchString);
    }, [performSearch, searchString, props.statusIds]);

    return <Drawer
        direction="left"
        open={props.open}
        onClose={handleDrawerClosed}
        className={styles.navMenu}
        overlayOpacity={0}
        size="270px"
        style={{height: "100%"}}
    >
        <div data-testid="nav-menu">
            <div className={styles.header}>
                <div className={styles.close}>
                    <IconButton
                        icon={IconName.Close}
                        onClick={handleDrawerClosed}
                        className={styles.closeButton}
                        width={14}
                        height={14}
                        data-testid="side-nav-close-button"
                    />
                </div>
                <div className={styles.vtap}>
                    <Link
                        to="/"
                        onClick={trackLogoNavigation}
                        data-testid="nav-menu-logo-link"
                    >
                        <img
                            src="/logo-white.svg"
                            alt="logo"
                            className={styles.logo}
                        />
                    </Link>
                </div>
            </div>
            <div className={styles.content}>
                <ValeoTextField
                    label="Search"
                    value={searchString}
                    onChange={onSearchChange}
                    className={styles.search}
                    outlined
                />
                <div className={styles.clientList}>Client List</div>
                <List>
                    {
                        (props.currentClientId !== undefined && props.currentClientKey !== undefined) &&
                        <ClientListItem
                            clientId={props.currentClientId}
                            clientKey={props.currentClientKey}
                            selected
                            handleOnClick={handleOnClick}
                        />
                    }
                    {
                        clients.filter(client => client.id !== props.currentClientId).map((client) =>
                            <ClientListItem
                                key={client.key}
                                clientId={client.id}
                                clientKey={client.key}
                                selected={false}
                                handleOnClick={handleOnClick}
                            />
                        )
                    }
                </List>
                {
                    showGoToFullClientList && <div className={styles.fullClientListContainer}>
                        <Button
                            label="Go to Full Client List"
                            onClick={goToFullClientList}
                            data-testid="go-to-full-client-list"
                        />
                    </div>
                }
            </div>
        </div>
    </Drawer>;
}