import { QueryStatus } from "@tanstack/react-query";
import { ChangeEvent, Fragment, useCallback, useState } from "react";
import { Button } from "rmwc";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { hasContent } from "utils/StringUtils";
import styles from "./PositionName.module.scss";
import { ModelPortfolioViewModel } from "../asset-table/view-models/ModelPortfolioViewModel";

export interface PositionNameProps {
    modelPortfolio: ModelPortfolioViewModel;
    securityTicker: string;
    securityStatus: QueryStatus;
    nameResultForTicker: string;
    manualName: string;
    setManualName: FunctionStringCallback;
    disabled?: boolean;
}

export function PositionName(props: PositionNameProps) {
    const { modelPortfolio, securityTicker, securityStatus, nameResultForTicker, manualName, setManualName } = props;
    const disabled = props.disabled ?? false;

    const [isEnteringManualName, setIsEnteringManualName] = useState(false);

    const onClickEnterName = useCallback(() => {
        setIsEnteringManualName(true);
    }, [setIsEnteringManualName]);

    const onUserEnterName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const userInput = event.currentTarget.value;
        setManualName(userInput);
    }, [setManualName]);

    const onEnterNameBlur = useCallback(() => {
        setIsEnteringManualName(false);
    }, [setIsEnteringManualName]);

    const onEnterNameFocus = useCallback(() => {
        setIsEnteringManualName(true);
    }, [setIsEnteringManualName]);

    return <div className={styles.positionName}>
        {
            securityStatus === "success" && hasContent(nameResultForTicker)
                ? <div className={styles.securityName}>
                    <div className={styles.securityNameText}>{nameResultForTicker}</div>
                    {
                        modelPortfolio.recommendedFunds().find((target) => target.ticker === securityTicker) && <img
                            src="/rec-fund.svg"
                            alt="recommended fund"
                            className={styles.recommendedFundIcon}
                            data-testid="recommended-fund-icon"
                        />
                    }
                </div>
                : hasContent(manualName) || isEnteringManualName
                    ? <ValeoTextField
                        label="Name"
                        value={manualName}
                        onInput={onUserEnterName}
                        onBlur={onEnterNameBlur}
                        onFocus={onEnterNameFocus}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={!hasContent(manualName)}
                        disabled={disabled}
                        className={styles.enterNameField}
                        data-testid="name-text-field"
                    />
                    : <Fragment>
                        <Button
                            label="Enter Name"
                            onClick={onClickEnterName}
                            className={styles.enterNameButton}
                            data-testid="enter-name-button"
                        />
                        {securityStatus === "success" && !hasContent(nameResultForTicker) && <div className={styles.noSecurityResults}>No Results</div>}
                    </Fragment>
        }
    </div>;
}