import { useQuery } from "@tanstack/react-query";
import { IasUserDataModel } from "dataModels/iasDataModel";
import { getWithAuth } from "./apiService";
import { useCommonErrorDetection } from "./dataErrorService";

const getCurrentUser = () => getWithAuth<IasUserDataModel>("/user");

export function useUserQuery() {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(["user"], () => getCurrentUser(), {
        onError: commonErrorDetection
    });
}