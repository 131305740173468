import classnames from "classnames";
import { NumericTextField, FormattedNumericTextFieldProps } from "./NumericTextField";
import styles from "./CurrencyEntry.module.scss";
import { formatDisplay, parseDisplay } from "utils/FormatNumber";
import { isWhiteSpace } from "utils/StringUtils";
import { isNumber } from "utils/NumberUtils";

export function parseCurrencyValueFromDisplay(text: string): number {
    return isWhiteSpace(text)
        ? 0 // interpret blank/whitespace input as 0
        : parseDisplay(text.trim(), true) ?? NaN;
}

export function inactiveDisplayOfCurrencyValue(value: number): string {
    return formatDisplay(value, true);
}

export function activeDisplayOfCurrencyValue(value: number): string {
    return value === 0
        ? "" // when active, format 0 as blank to accept user input
        : value.toString();
}

export function isCurrencyValueValid(value: number): boolean {
    return isNumber(value);
}

export function CurrencyEntry(props: FormattedNumericTextFieldProps) {
    const { numericValue, className } = props;
    const isValueValid = props.isValueValid ?? isCurrencyValueValid;

    return <NumericTextField
        parseNumericValueFromDisplay={parseCurrencyValueFromDisplay}
        inactiveDisplayOfNumericValue={inactiveDisplayOfCurrencyValue}
        activeDisplayOfNumericValue={activeDisplayOfCurrencyValue}
        {...props}
        isValueValid={isValueValid}
        className={
            classnames(className, {
                [styles.ghost]: numericValue === 0,
            })
        }
        onValidBlur={props.onValidBlur}
    />;
}