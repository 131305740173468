import classnames from "classnames";
import { AccountDataModel } from "../../dataModels/accountDataModel";
import styles from "./AccountInfo.module.scss";

interface AccountInfoProps {
    account: AccountDataModel;
    className?: string;
}

export default function AccountInfo(props: AccountInfoProps) {
    const account = props.account;
    return <span className={classnames(styles.accountInfo, props.className)}>
        {account.owner.split(" ")[0]}  |  {account.custodian}  |  {account.type}
    </span>;
}