import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ClientDataModel, ClientSearchResult, MinimalClient } from "../dataModels/clientDataModel";
import { TargetsDataModel, TargetsWithoutDollars } from "../dataModels/targetsDataModel";
import { getWithAuth, postWithAuth, putWithAuthAndDiscardResponse } from "./apiService";
import { sortAndGroupAssetClasses } from "./assetClassService";
import { isNumber } from "utils/NumberUtils";
import { useCommonErrorDetection, useUnknownSaveErrorMutator } from "./dataErrorService";
import { useCallback, useEffect, useMemo } from "react";
import { CustomModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { addQueryParams } from "utils/AddQueryParams";
import { CancellationError, CancellationToken, CancellationTokenProducer, createCancellationTokenProducer } from "utils/CancellationToken";
import { CustomSegmentName } from "dataModels/customSegmentName";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { MeetingDataModel } from "dataModels/meetingDataModel";
import { CustomSegmentGroupName } from "dataModels/customSegmentGroupName";

const getClientList = (statusIds: number[], search: string, page: number, keySearchOnly: boolean, cancellationToken?: CancellationToken) => {
    const fullUrl = addQueryParams("/client", [
        ["search", search],
        ["page", page],
        ["pageSize", 50],
        ["keySearchOnly", keySearchOnly],
        ...statusIds.map(id => ["statusIdsToInclude", id]),
    ]);
    return getWithAuth<ClientSearchResult>(fullUrl, { cancellationToken });
};

interface ClientResponse extends MinimalClient, TargetsWithoutDollars {
    meetings : MeetingDataModel[];
    assetClasses: AssetClassDataModel[];
    cashTargetInDollars?: number | null;
    alternativesTargetInDollars?: number | null;
}

const get = async (id: number): Promise<ClientDataModel> => {
    const clientResult = await getWithAuth<ClientResponse>(`/client/${id}`);
    clientResult.meetings.forEach(meeting => {
        meeting.date = new Date(meeting.date);
        meeting.ias.forEach(ias => {
            ias.dateModified = new Date(ias.dateModified);
        });
    });
    clientResult.assetClasses = sortAndGroupAssetClasses(clientResult.assetClasses);
    return {
        ...clientResult,
        alternativesTargetInDollars: clientResult.alternativesTargetInDollars === null ? undefined : clientResult.alternativesTargetInDollars,
        cashTargetInDollars: clientResult.cashTargetInDollars === null ? undefined : clientResult.cashTargetInDollars
    };
};

export interface ClientBasicInformation extends TargetsDataModel {
    customSegmentNames?: CustomSegmentName[];
    customSegmentGroupNames?: CustomSegmentGroupName[];
}

const put = async (id: number, data: ClientBasicInformation) => {
    await putWithAuthAndDiscardResponse(`/client/${id}`, data);
};

export interface ClientCustomModelPortfolioInformation {
    customModelPortfolio: CustomModelPortfolioDataModel;
    customSegmentNames?: CustomSegmentName[];
    customSegmentGroupNames?: CustomSegmentGroupName[];
}

const putCustomModelPortfolio = async (id: number, data: ClientCustomModelPortfolioInformation) => {
    await putWithAuthAndDiscardResponse(`/client/${id}/custom-model-portfolio`, data);
};

export function useRemoveClientQuery(id: number) {
    const queryClient = useQueryClient();
    return useCallback(() => {
        queryClient.removeQueries(["client", id]);
    }, [id, queryClient]);
}

export const useClientQuery = (id: number | null) => {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(["client", id], () => get(id ?? -1), {
        enabled: isNumber(id),
        onError: commonErrorDetection,
        staleTime: 0
    });
};

export const useClientListQuery = (statusIds: number[]) => {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(["clientList", statusIds], () => getClientList(statusIds, "", 1, false), {
        onError: commonErrorDetection,
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: statusIds.length > 0
    });
};

export const useClientListSearch = (inputCancellationTokenProducer?: CancellationTokenProducer) => {
    const commonErrorDetection = useCommonErrorDetection(false);
    const cancellationTokenProducer = useMemo(() => inputCancellationTokenProducer ?? createCancellationTokenProducer(), [inputCancellationTokenProducer]);

    useEffect(() => {
        return () => {
            // Cancel the token when the component is unmounted
            cancellationTokenProducer.cancel();
        };
    }, [cancellationTokenProducer]);

    return useCallback(async (statusIds: number[], search: string, page = 1, keySearchOnly = false): Promise<ClientSearchResult | undefined> => {
        cancellationTokenProducer.cancel();
        try {
            return await getClientList(statusIds, search, page, keySearchOnly, cancellationTokenProducer.token());
        } catch (error) {
            if (!(error instanceof CancellationError)) {
                commonErrorDetection(error);
            }
        }
    }, [cancellationTokenProducer, commonErrorDetection]);
};

export const postMeeting = (date: Date, clientId: number): Promise<number> => {
    return postWithAuth<number>("/meeting", {
        date,
        clientId
    });
};

export function useClientCustomModelPortfolioMutation(id: number) {
    const queryClient = useQueryClient();
    const unknownSaveErrorMutator = useUnknownSaveErrorMutator();
    return useMutation((info: ClientCustomModelPortfolioInformation) => putCustomModelPortfolio(id, info), {
        onMutate: async (info) => {
            const cachedClient = queryClient.getQueryData<ClientDataModel>(["client", id]);
            if (cachedClient) {
                const { customModelPortfolio, customSegmentNames, customSegmentGroupNames } = info;
                const newClient: ClientDataModel = {
                    ...cachedClient,
                    customModelPortfolio,
                    modelPortfolio: undefined
                };
                newClient.assetClasses = cachedClient.assetClasses.map(ac => ({
                    ...ac,
                    segments: ac.segments.map(s => {
                        const customSegmentName = customSegmentNames?.find(csn => csn.segmentId === s.id);
                        return customSegmentName
                            ? { ...s, name: customSegmentName.name }
                            : { ...s, name: s.originalName };
                    }),
                    segmentGroups: ac.segmentGroups.map(sg => {
                        const customSegmentGroupName = customSegmentGroupNames?.find(csgn => csgn.segmentGroupId === sg.id);
                        return customSegmentGroupName
                            ? { ...sg, name: customSegmentGroupName.name }
                            : { ...sg, name: sg.originalName };
                    }),
                }));
                queryClient.setQueryData(["client", id], newClient);
            }
        },
        onError: (err) => {
            unknownSaveErrorMutator(true);
        },
        onSuccess: (data) => {
            void queryClient.invalidateQueries(["client", id]);
        }
    });
}

export function useClientMutation(id: number) {
    const queryClient = useQueryClient();
    const unknownSaveErrorMutator = useUnknownSaveErrorMutator();
    return useMutation((info: ClientBasicInformation) => put(id, info), {
        onMutate: async (info) => {
            const cachedClient = queryClient.getQueryData<ClientDataModel>(["client", id]);
            if (cachedClient) {
                const { customSegmentNames, customSegmentGroupNames, ...targets } = info;
                const newClient: ClientDataModel = {
                    ...cachedClient,
                    cashTarget: targets.cashTarget,
                    cashTargetInDollars: targets.cashTargetInDollars,
                    alternativesTarget: targets.alternativesTarget,
                    alternativesTargetInDollars: targets.alternativesTargetInDollars,
                    cashCarveOut: targets.cashCarveOut,
                    fixedIncomeCarveOut: targets.fixedIncomeCarveOut,
                    equitiesCarveOut: targets.equitiesCarveOut,
                    modelPortfolio: targets.modelPortfolio
                };
                newClient.assetClasses = cachedClient.assetClasses.map(ac => ({
                    ...ac,
                    segments: ac.segments.map(s => {
                        const customSegmentName = customSegmentNames?.find(csn => csn.segmentId === s.id);
                        return customSegmentName
                            ? { ...s, name: customSegmentName.name }
                            : { ...s, name: s.originalName };
                    }),
                    segmentGroups: ac.segmentGroups.map(sg => {
                        const customSegmentGroupName = customSegmentGroupNames?.find(csgn => csgn.segmentGroupId === sg.id);
                        return customSegmentGroupName
                            ? { ...sg, name: customSegmentGroupName.name }
                            : { ...sg, name: sg.originalName };
                    }),
                }));
                queryClient.setQueryData(["client", id], newClient);
            }
        },
        onError: (err) => {
            unknownSaveErrorMutator(true);
        },
        onSuccess: (data) => {
            void queryClient.invalidateQueries(["client", id]);
        }
    });
}