import { DataTableCell, DataTableRow } from "rmwc";
import { MouseEventHandler, useCallback } from "react";
import styles from "./ClientTableRow.module.scss";
import { useKeyboardAwareNavigate } from "utils/UseKeyboardAwareNavigate";
import { MinimalClient } from "dataModels/clientDataModel";

interface ClientTableRowProps {
    client: MinimalClient
    status?: string
}

export default function ClientTableRow({client, status}: ClientTableRowProps) {

    const navigate = useKeyboardAwareNavigate();
    const handleOnClick: MouseEventHandler = useCallback((event) => navigate(event, `/client/${client.id}`), [navigate, client.id]);

    return (
        <DataTableRow
            key={client.id}
            onClick={handleOnClick}
            className="clickable"
        >
            <DataTableCell className={styles.keyCell}>{client.key}</DataTableCell>
            <DataTableCell className={styles.nameCell}>{client.reportingName}</DataTableCell>
            <DataTableCell>{status}</DataTableCell>
        </DataTableRow>
    );
}