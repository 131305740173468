import { PropsWithChildren } from "react";
import { BaseModalFooter } from "./BaseModalFooter";

export interface BackNextFooterProps extends PropsWithChildren {
    backBtn: JSX.Element;
    nextBtn: JSX.Element;
}

export function BackNextFooter(props: BackNextFooterProps) {
    const { backBtn, nextBtn, children } = props;

    return <BaseModalFooter>
        <div data-testid="dialog-button-back">
            {backBtn}
        </div>
        {children}
        <div data-testid="dialog-button-next">
            {nextBtn}
        </div>
    </BaseModalFooter>;
}