import { ValeoDialog } from "../../shared/ValeoDialog";
import { ValeoTextArea } from "../../shared/ValeoTextArea";
import { ValeoToggle } from "../../shared/ValeoToggle";
import { AccountPositionViewModel } from "./view-models/AccountPositionViewModel";
import styles from "./EditPositionCommentDialog.module.scss";
import { FormEvent, useState } from "react";
import { usePositionMutation } from "services/positionService";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { hasContent } from "utils/StringUtils";

interface EditPositionCommentDialogProps {
    viewModel: AccountPositionViewModel;
    open: boolean;
    setOpen: BooleanCallback;
    ias: IasDataModel;
}

enum CommentTab {
    Internal = "Internal Comment",
    Client = "Client Comment (Printed in PDF)"
}

export function EditPositionCommentDialog(props: EditPositionCommentDialogProps) {
    const position = props.viewModel.position;
    const account = props.viewModel.account.account;

    const [tab, setTab] = useState(CommentTab.Client);
    const [internalComment, setInternalComment] = useState(position.internalComment);
    const [clientComment, setClientComment] = useState(position.clientComment);

    const mutator = usePositionMutation(position.id, account.id, props.ias.id);

    const internalCommentChanged = internalComment !== position.internalComment;
    const clientCommentChanged = clientComment !== position.clientComment;

    const acceptComment = () => {
        if (internalCommentChanged || clientCommentChanged) {
            mutator.mutate({
                ...(internalCommentChanged && { internalComment }),
                ...(clientCommentChanged && { clientComment }),
                lastModifiedDate: props.ias.dateModified
            });
        }
    };

    const handleCommentInput = (event: FormEvent<HTMLInputElement>) => {
        tab === CommentTab.Internal && setInternalComment(event.currentTarget.value);
        tab === CommentTab.Client && setClientComment(event.currentTarget.value);
    };

    const handleTabChange = (tab: number) => {
        tab === 0 ? setTab(CommentTab.Client) : setTab(CommentTab.Internal);
    };

    return (
        <ValeoDialog
            title="Comment"
            width={448}
            fixedHeight={361}
            open={props.open}
            setOpen={props.setOpen}
            proceedDisabled={!internalCommentChanged && !clientCommentChanged}
            onAccept={acceptComment}
        >
            <div className={styles.dialogContents}>
                <div className={styles.position}>
                    {hasContent(props.viewModel.security.tickerSymbol) && <span className={styles.ticker}>{props.viewModel.security.tickerSymbol}</span>}
                    {props.viewModel.security.positionName}
                </div>
                <div className={styles.account}>
                    <div className={styles.accountNumber}>{account.accountNumber}</div>
                    <div className={styles.accountInfo}>{account.owner.split(" ")[0]} | {account.custodian} | {account.type}</div>
                </div>
                <ValeoToggle
                    onActivate={(evt) => handleTabChange(evt.detail.index)}
                    tabOneLabel="Client"
                    tabTwoLabel="Internal"
                />
                <ValeoTextArea
                    label={tab}
                    value={tab === CommentTab.Internal ? internalComment : clientComment}
                    onInput={handleCommentInput}
                    className={styles.textArea}
                />
            </div>
        </ValeoDialog>
    );
}