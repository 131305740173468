import { ValueModel } from "../../IasWorkspaceTypes";
import type { CustomGroupViewModel } from "./CustomGroupViewModel";
import type { SecurityViewModel } from "./SecurityViewModel";

export enum SecurityDisplay {
    Security = "Security",
    CustomGroup = "CustomGroup",
}

export interface SecurityDisplayViewModel extends ValueModel {
    securityDisplay: SecurityDisplay;
    readonly name: string;
    readonly segmentId: number;
    readonly key: string;
    containsChanges: () => boolean;
    containsAccount: (accountId: number) => boolean;
}

export function isSecurityViewModel(viewModel: SecurityDisplayViewModel): viewModel is SecurityViewModel {
    return viewModel.securityDisplay === SecurityDisplay.Security;
}

export function isCustomGroupViewModel(viewModel: SecurityDisplayViewModel): viewModel is CustomGroupViewModel {
    return viewModel.securityDisplay === SecurityDisplay.CustomGroup;
}