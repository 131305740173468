import { BaseModal } from "components/shared/modal/BaseModal";
import { BaseModalContent } from "components/shared/modal/BaseModalContent";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { NextFooter } from "components/shared/modal/footer/NextFooter";
import { CancelHeader } from "components/shared/modal/header/CancelHeader";
import { ValeoTextArea } from "components/shared/ValeoTextArea";
import { ValeoToggle } from "components/shared/ValeoToggle";
import { AccountDataModel } from "dataModels/accountDataModel";
import { FormEvent, useCallback, useMemo, useState } from "react";
import { TabBarOnActivateEventT } from "rmwc";
import { useAccountMutation } from "services/accountService";
import styles from "./AccountCommentModal.module.scss";

interface AccountCommentModalProps {
    account: AccountDataModel,
    setOpen: (open: boolean) => void,
    iasId: number,
    lastModifiedDate: Date
}

enum CommentTab {
    Internal = "Internal Comment",
    Client = "Client Comment (Printed in PDF)"
}

export function AccountCommentModal(props: AccountCommentModalProps) {
    const { account, setOpen, iasId, lastModifiedDate } = props;

    const [tab, setTab] = useState(CommentTab.Client);
    const [internalComment, setInternalComment] = useState(account.internalComment);
    const [clientComment, setClientComment] = useState(account.clientComment);

    const handleCommentInput = useCallback((event: FormEvent<HTMLInputElement>) => {
        tab === CommentTab.Internal && setInternalComment(event.currentTarget.value);
        tab === CommentTab.Client && setClientComment(event.currentTarget.value);
    }, [tab]);

    const handleTabChange = useCallback((evt: TabBarOnActivateEventT) => {
        const tab = evt.detail.index;
        tab === 0 ? setTab(CommentTab.Client) : setTab(CommentTab.Internal);
    }, []);

    const mutator = useAccountMutation(iasId, account.id);

    const internalCommentChanged = useMemo(() => internalComment !== account.internalComment, [account.internalComment, internalComment]);
    const clientCommentChanged = useMemo(() => clientComment !== account.clientComment, [account.clientComment, clientComment]);

    const saveComments = () => {
        if (internalCommentChanged || clientCommentChanged) {
            mutator.mutate({
                ...(internalCommentChanged && { internalComment }),
                ...(clientCommentChanged && { clientComment }),
                lastModifiedDate: lastModifiedDate
            });
        }
        setOpen(false);
    };

    const clickCancel = useCallback(() => setOpen(false), [setOpen]);

    return <BaseModal open>
        <BaseModalContent
            data-testid="account-comment-modal"
            width={448}
            fixedHeight={325}
            header={
                <CancelHeader
                    title="Comment"
                    cancelBtn={
                        <ModalActionButton
                            isDefaultAction={false}
                            onClick={clickCancel}
                            label="Cancel"
                        />
                    }
                />
            }
            content={
                <div className={styles.modalContents}>
                    <div className={styles.account}>
                        <div className={styles.accountNumber}>{account.accountNumber}</div>
                        <div className={styles.accountInfo}>{account.owner.split(" ")[0]} | {account.custodian} | {account.type}</div>
                    </div>
                    <ValeoToggle
                        onActivate={handleTabChange}
                        tabOneLabel="Client"
                        tabTwoLabel="Internal"
                    />
                    <ValeoTextArea
                        label={tab}
                        value={tab === CommentTab.Internal ? internalComment : clientComment}
                        onInput={handleCommentInput}
                        className={styles.textArea}
                    />
                </div>
            }
            footer={
                <NextFooter nextBtn={
                    <ModalActionButton
                        label="Save"
                        isDefaultAction={true}
                        onClick={saveComments}
                        disabled={!internalCommentChanged && !clientCommentChanged}
                    />
                }
                />
            }
        />
    </BaseModal>;
}