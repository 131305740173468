import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ICustomProperties, SeverityLevel } from "@microsoft/applicationinsights-web";
import { EventType } from "constants/eventType";
import { isString } from "utils/StringUtils";

let reactPlugin: ReactPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

const trackException = (error: Error) => {
    if (appInsights) {
        appInsights.trackException({
            error,
            severityLevel: SeverityLevel.Error
        });
    }
};

const trackTrace = (message: string) => {
    if (appInsights) {
        appInsights.trackTrace({
            message,
            severityLevel: SeverityLevel.Information,
        });
    }
};

const trackError = (message: string) => {
    if (appInsights) {
        appInsights.trackTrace({
            message,
            severityLevel: SeverityLevel.Error,
        });
    }
};

const trackEvent = (eventType: EventType, additionalData?: ICustomProperties) => {
    if (appInsights) {
        appInsights.trackEvent({
            name: eventType.toString(),
        }, additionalData);
    }
};

const adjustPageNames = (eventData: Record<string, unknown>) => {
    if (isString(eventData.uri)) {
        if (eventData.uri.includes("/client/")) {
            return "Client Profile";
        } else if (eventData.uri.includes("/ias/")) {
            return "IAS Workspace";
        }
    }

    return eventData.name;
};

const createTelemetryService = () => {
    const initialize = (applicationInsightsConnectionString: string) => {
        reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
            config: {
                connectionString: applicationInsightsConnectionString,
                enableAutoRouteTracking: true,
                extensions: [reactPlugin],
            },
        });

        appInsights.addTelemetryInitializer((item) => {
            // Re-write the page name to avoid client names / keys
            switch (item.name.toLowerCase())
            {
            case "microsoft.applicationinsights.{0}.pageview":
            case "microsoft.applicationinsights.{0}.pageviewperformance":
                if (item.baseData) {
                    item.baseData.name = adjustPageNames(item.baseData);
                }
                break;
            }
        });

        appInsights.loadAppInsights();
    };

    return {
        initialize,
        trackEvent,
        trackException,
        trackError,
        trackTrace,
    };
};

export const telemetry = createTelemetryService();