import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IasState } from "constants/iasState";
import { AccountDataModel } from "dataModels/accountDataModel";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { CustomModelPortfolioDataModel, ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "./apiService";
import { modelPortfolioQueryKey } from "./modelPortfolioService";
import { isNumber } from "utils/NumberUtils";
import { useCommonErrorDetection } from "./dataErrorService";
import { DataSource } from "constants/dataSource";
import { BlendedFundSegmentPercentage } from "dataModels/blendedFundSegmentPercentage";
import { ClientDataModel } from "dataModels/clientDataModel";
import { isString } from "utils/StringUtils";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { sortAndGroupAssetClasses } from "./assetClassService";
import { CustomSegmentName } from "dataModels/customSegmentName";
import { CustomSegmentGroupName } from "dataModels/customSegmentGroupName";

interface AccountResponse extends Omit<AccountDataModel, "type" | "typeId" | "taxable"> {
    accountType: AccountTypeDataModel;
}

interface IasResponse extends Omit<IasDataModel, "accounts" | "dateModified" | "dateRefreshed" | "fidelityTradesSentTimestamp" | "cashTargetInDollars" | "alternativesTargetInDollars"> {
    accounts: AccountResponse[];
    dateModified: string;
    dateRefreshed: string;
    fidelityTradesSentTimestamp: string | null;
    cashTargetInDollars?: number | null;
    alternativesTargetInDollars?: number | null;
}

interface GetIasResponse {
    meetingDate: string;
    clientId: number;
    ias: IasResponse;
    assetClasses: AssetClassDataModel[];
}

const get = async (id: number): Promise<IasDataModel> => {
    const result = await getWithAuth<GetIasResponse>(`/ias/${id}`);
    const meetingDate = new Date(result.meetingDate);
    return {
        ...result.ias,
        accounts: result.ias.accounts.map((account) => ({
            ...account,
            type: account.accountType.name,
            typeId: account.accountType.id,
            taxable: account.accountType.taxable,
        })),
        dateModified: new Date(result.ias.dateModified),
        dateRefreshed: new Date(result.ias.dateRefreshed),
        meetingDate,
        clientId: result.clientId,
        fidelityTradesSentTimestamp: isString(result.ias.fidelityTradesSentTimestamp) ? new Date(result.ias.fidelityTradesSentTimestamp) : undefined,
        assetClasses: sortAndGroupAssetClasses(result.assetClasses),
        cashTargetInDollars: result.ias.cashTargetInDollars === null ? undefined : result.ias.cashTargetInDollars,
        alternativesTargetInDollars: result.ias.alternativesTargetInDollars === null ? undefined : result.ias.alternativesTargetInDollars,
    };
};

export interface IasModifiedResponseDataModel {
    dateModified: Date;
    lastModifiedBy: string;
}

export interface IasBasicInformation {
    lastModifiedDate: Date,
    title?: string,
    state?: IasState,
    modelPortfolioId?: number,
    clientNotes?: string,
    valeoNotes?: string
    cashTarget?: number,
    cashTargetInDollars?: number,
    alternativesTarget?: number,
    alternativesTargetInDollars?: number,
    cashCarveOut?: number,
    fixedIncomeCarveOut?: number,
    equitiesCarveOut?: number,
    showAltTargets?: boolean,
    customSegmentNames?: CustomSegmentName[],
    customSegmentGroupNames?: CustomSegmentGroupName[],
    reportTitle?: string
}

export interface IasCustomModelPortfolioInformation {
    lastModifiedDate: Date,
    customModelPortfolio: CustomModelPortfolioDataModel,
    customSegmentNames?: CustomSegmentName[],
    customSegmentGroupNames?: CustomSegmentGroupName[],
}

export interface WritablePosition {
    currentValue: number,
    overwrittenCurrentValue: number | null,
    change: number,
    asOfDate: string,
    dataSource: DataSource,
    securityId: number,
    newSecurity?: WritableSecurity | null,
    internalComment: string,
    clientComment: string,
    sourceId: number | null,
    shares?: number
}

export interface WritableAccount {
    accountNumber: string,
    owner: string,
    custodian: string,
    originalCustodian: string | null | undefined,
    typeId: number,
    positions: WritablePosition[],
    dataSource: DataSource,
    internalComment: string,
    clientComment: string
}

export interface WritableSecurity {
    tempId: number,
    tickerSymbol: string,
    positionName: string,
    segmentId: number,
    customGroupId?: number | null,
    blendedFundPercentages: BlendedFundSegmentPercentage[],
}

export interface WritableCustomGroup {
    tempId: number,
    name: string,
    segmentId: number,
}

export interface WritableIas {
    title: string,
    modelPortfolioId?: number,
    modelPortfolioTargetsId?: number,
    customModelPortfolio?: CustomModelPortfolioDataModel,
    accounts: WritableAccount[],
    securities: WritableSecurity[],
    customGroups: WritableCustomGroup[],
    meetingId: number,
    valeoNotes: string,
    clientNotes: string,
    cashTarget?: number,
    cashTargetInDollars?: number,
    alternativesTarget?: number,
    alternativesTargetInDollars?: number,
    cashCarveOut?: number,
    fixedIncomeCarveOut?: number,
    equitiesCarveOut?: number,
    dateRefreshed: Date,
    sourcePortfolioId?: string,
    showAltTargets: boolean,
    customSegmentNames: CustomSegmentName[],
    customSegmentGroupNames: CustomSegmentGroupName[],
    reportTitle: string
}

export const iasQueryKey = (id: number) => ["ias", id];

const put = async (id: number, data: IasBasicInformation): Promise<IasModifiedResponseDataModel> => {
    return await putWithAuth(`/ias/${id}`, data);
};

const putCustomModelPortfolio = async (id: number, data: IasCustomModelPortfolioInformation): Promise<IasModifiedResponseDataModel> => {
    return await putWithAuth(`/ias/${id}/custom-model-portfolio`, data);
};

export const postIas = async (data: WritableIas): Promise<number> => {
    return await postWithAuth("/ias", data);
};

export function useIasQuery(id: number, enabled: boolean = true) {
    const commonErrorDetection = useCommonErrorDetection(false);
    return useQuery(iasQueryKey(id), () => get(id), {
        enabled,
        onError: commonErrorDetection,
    });
}

export function useIasMutation(id: number) {
    const queryClient = useQueryClient();
    const key = iasQueryKey(id);
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation(key, (info: IasBasicInformation) => put(id, info), {
        onMutate: async (info) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(key);
            const cachedIas = queryClient.getQueryData<IasDataModel>(key);
            if (cachedIas) {
                const { modelPortfolioId, customSegmentNames, customSegmentGroupNames, ...dataModelUpdates } = info;
                const updatedQueryData: IasDataModel = { ...cachedIas, ...dataModelUpdates };
                if (isNumber(modelPortfolioId)) {
                    const cachedModelPortfolios = queryClient.getQueryData<ModelPortfolioDataModel[]>(modelPortfolioQueryKey());
                    if (cachedModelPortfolios) {
                        const selectedModelPortfolio = cachedModelPortfolios.find((mp) => mp.id === modelPortfolioId);
                        if (selectedModelPortfolio) {
                            updatedQueryData.modelPortfolio = selectedModelPortfolio;
                        } else {
                            throw new Error(`Expected QueryProvider [${modelPortfolioQueryKey().join(", ")}] cache item to include item with id = ${modelPortfolioId} but found none.`);
                        }
                    } else {
                        throw new Error(`Expected QueryProvider [${modelPortfolioQueryKey().join(", ")}] cache item but found none.`);
                    }
                }
                updatedQueryData.assetClasses = cachedIas.assetClasses.map(ac => ({
                    ...ac,
                    segments: ac.segments.map(s => {
                        const customSegmentName = customSegmentNames?.find(csn => csn.segmentId === s.id);
                        return customSegmentName
                            ? { ...s, name: customSegmentName.name }
                            : { ...s, name: s.originalName };
                    }),
                    segmentGroups: ac.segmentGroups.map(sg => {
                        const customSegmentGroupName = customSegmentGroupNames?.find(csgn => csgn.segmentGroupId === sg.id);
                        return customSegmentGroupName
                            ? { ...sg, name: customSegmentGroupName.name }
                            : { ...sg, name: sg.originalName };
                    }),
                }));
                queryClient.setQueryData(key, updatedQueryData);
            }
            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (data) => {
            const cached = queryClient.getQueryData<IasDataModel>(key);
            if (cached) {
                queryClient.setQueryData(key, { ...cached, dateModified: new Date(data.dateModified), lastModifiedUser: { name: data.lastModifiedBy } });
            }
        },
    });
}

export function useIasCustomModelPortfolioMutation(id: number) {
    const queryClient = useQueryClient();
    const key = iasQueryKey(id);
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: IasCustomModelPortfolioInformation) => putCustomModelPortfolio(id, info), {
        onMutate: async (info) => {
            const cachedIas = queryClient.getQueryData<IasDataModel>(key);
            if (cachedIas) {
                const { customModelPortfolio, customSegmentNames, customSegmentGroupNames } = info;
                const newIas: IasDataModel = {
                    ...cachedIas,
                    customModelPortfolio,
                    modelPortfolio: undefined
                };
                newIas.assetClasses = cachedIas.assetClasses.map(ac => ({
                    ...ac,
                    segments: ac.segments.map(s => {
                        const customSegmentName = customSegmentNames?.find(csn => csn.segmentId === s.id);
                        return customSegmentName
                            ? { ...s, name: customSegmentName.name }
                            : { ...s, name: s.originalName };
                    }),
                    segmentGroups: ac.segmentGroups.map(sg => {
                        const customSegmentGroupName = customSegmentGroupNames?.find(csgn => csgn.segmentGroupId === sg.id);
                        return customSegmentGroupName
                            ? { ...sg, name: customSegmentGroupName.name }
                            : { ...sg, name: sg.originalName };
                    }),
                }));
                queryClient.setQueryData(key, newIas);
            }
        },
        onError: commonErrorDetection,
        onSuccess: (data) => {
            const cached = queryClient.getQueryData<IasDataModel>(key);
            if (cached) {
                queryClient.setQueryData(key, { ...cached, dateModified: new Date(data.dateModified), lastModifiedUser: { name: data.lastModifiedBy } });
            }
        },
    });
}

const deleteIas = async (id: number): Promise<IasModifiedResponseDataModel> => {
    return await deleteWithAuth(`/ias/${id}`);
};

export function useIasDeletion(id: number, clientId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation(iasQueryKey(id), () => deleteIas(id), {
        onMutate: async () => {
            const cachedClient = queryClient.getQueryData<ClientDataModel>(["client", clientId]);
            if (cachedClient) {
                const newClient: ClientDataModel = {
                    ...cachedClient,
                    meetings: cachedClient.meetings.map(meeting => ({
                        ...meeting,
                        ias: meeting.ias.filter(ias => ias.id !== id),
                    })),
                };
                queryClient.setQueryData(["client", clientId], newClient);
            }
        },
        onSuccess: () => {
            queryClient.removeQueries(iasQueryKey(id));
        },
        onError: commonErrorDetection,
    });
}

export interface RefreshPosition {
    currentValue: number,
    asOfDate: string,
    dataSource: DataSource,
    sourceId: number,
    securityId: number,
    shares?: number
}
export interface RefreshAccount {
    positions: RefreshPosition[],
    accountNumber: string,
    owner: string,
    custodian: string,
    originalCustodian: string | null | undefined,
    typeId: number,
    dataSource: DataSource
}
export interface IasRefreshInformation {
    dateRefreshed: Date,
    accounts: RefreshAccount[],
    newSecurities: WritableSecurity[],
    lastModifiedDate: Date,
}

async function putRefresh(id: number, data: IasRefreshInformation): Promise<IasModifiedResponseDataModel> {
    return await putWithAuth(`/ias/${id}/refresh`, data);
}

export function useRefreshIasMutator(id: number) {
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation(iasQueryKey(id), (data: IasRefreshInformation) => putRefresh(id, data), {
        onError: commonErrorDetection
    });
}