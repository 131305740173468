import { useQueryClient } from "@tanstack/react-query";
import { createRef, useCallback, useEffect, useState } from "react";
import { BaseModal } from "../../shared/modal/BaseModal";
import { clearClientQueries } from "services/blackDiamondService";
import { clearExpansionStateTypes, ExpansionType } from "services/expansionStateCache";
import { clearVisionQueries, useVisionData } from "services/visionService";
import { useIasQuery } from "services/iasService";
import { useAccountTypeQuery } from "services/accountTypeService";
import { AddIasWizardViewController } from "./AddIasWizardViewController";
import { AddIasWizardProps, AddIasWizardViewControllerState } from "./AddIasWizardConstants";
import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import styles from "./AddIasWizard.module.scss";
import { isTokenRevokedError } from "services/TokenRevokedError";
import { TokenRevokedDialog } from "../../shared/TokenRevokedDialog";

export function AddIasWizard(props: AddIasWizardProps) {
    const { client } = props;
    const [state, setState] = useState(AddIasWizardViewControllerState.StartingPage);

    const modalRef = createRef<HTMLElement>();
    const scrollToTop = useCallback(() => modalRef.current?.scroll(0, 0), [modalRef]);

    useEffect(() => {
        clearExpansionStateTypes(ExpansionType.VisionRow);
    }, []);

    const queryClient = useQueryClient();

    useEffect(() => {
        clearVisionQueries(queryClient);
    }, [queryClient]);

    useEffect(() => {
        clearClientQueries(queryClient, client.key);
    }, [queryClient, client.key]);

    // Kick off data preloading
    const visionQuery = useVisionData(props.client.key);
    const mostRecentlyFinalizedFullQuery = useIasQuery(props.mostRecentlyFinalized?.id ?? -1, !!props.mostRecentlyFinalized);
    const accountTypeQuery = useAccountTypeQuery();

    if (state === AddIasWizardViewControllerState.FastPassVisionConnectionFailed) {
        return <ConfirmationDialog
            title={<span className={styles.redWarning}>Vision failed to connect, so a new IAS was not created.</span>}
            confirmButton="Okay"
            onConfirm={() => props.setOpen(false)}
            content="The system can not connect to Vision at this time. Please try again later to create a new IAS from defaults."
        />;
    }

    if (state === AddIasWizardViewControllerState.FastPassBDConnectionFailed) {
        return <ConfirmationDialog
            title={<span className={styles.redWarning}>Black Diamond failed to connect, so a new IAS was not created.</span>}
            confirmButton="Okay"
            onConfirm={() => props.setOpen(false)}
            content="The system can not connect to Black Diamond at this time. Please try again later to create a new IAS from defaults."
        />;
    }

    if (isTokenRevokedError(visionQuery.data)) {
        return <TokenRevokedDialog />;
    }

    return <BaseModal
        open={true}
        ref={modalRef}
    >
        <AddIasWizardViewController
            {...props}
            state={state}
            scrollToTop={scrollToTop}
            setState={setState}
            accountTypesQuery={accountTypeQuery}
            mostRecentlyFinalizedFullQuery={mostRecentlyFinalizedFullQuery}
            visionQuery={visionQuery}
        />
    </BaseModal>;
}
