export interface Selectable<T> {
    value: T;
    selectionMade: boolean;
}

export function mapSelectable<T, K>(selection: Selectable<T>, fct: (t:T) => K): Selectable<K | null> {
    if (selection.selectionMade) {
        return { value: fct(selection.value), selectionMade: true};
    }
    return { value: null, selectionMade: false };
}
