import { Fragment, useEffect } from "react";
import { createModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { useIasQuery } from "services/iasService";
import { useClientQuery } from "services/clientService";
import { downloadPdf } from "utils/downloadPdf";
import { useModelPortfolioQuery, useModelPortfolioReturnsQuery } from "services/modelPortfolioService";
import { createAssetTables } from "components/ias-workspace/asset-table/view-models/AssetTablesViewModel";
import { totalValue } from "utils/AccountUtils";

interface PdfDownloaderProps {
    iasId: number;
    clientId: number;
    downloadComplete: VoidFunction;
}

export function PdfDownloader(props: PdfDownloaderProps) {
    const { downloadComplete } = props;
    const { data: ias } = useIasQuery(props.iasId);
    const { data: client } = useClientQuery(props.clientId);
    const { data: modelPortfolios } = useModelPortfolioQuery();
    const { data: modelPortfolioReturns } = useModelPortfolioReturnsQuery();

    const totalIasValue = totalValue(ias?.accounts);

    useEffect(() => {
        if (ias && client && modelPortfolios && modelPortfolioReturns) {
            const assetClasses = ias.assetClasses;
            void downloadPdf({
                ias,
                client,
                modelPortfolio: createModelPortfolioViewModel(ias, modelPortfolios, assetClasses, totalIasValue),
                assetClasses,
                assetTable: createAssetTables(assetClasses, modelPortfolios, ias),
                modelPortfolios,
                modelPortfolioReturns,
            });
            downloadComplete();
        }
    }, [client, downloadComplete, ias, modelPortfolios, modelPortfolioReturns, totalIasValue]);

    return <Fragment />;
}
