import { QueryStatus } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import styles from "./AddPositionModal.module.scss";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";
import { BaseModal } from "../../shared/modal/BaseModal";
import { BaseModalContent } from "../../shared/modal/BaseModalContent";
import { CancelHeader } from "../../shared/modal/header/CancelHeader";
import { ModalActionButton } from "../../shared/modal/footer/ModalActionButton";
import { inactiveDisplayOfBlankZerosCurrencyValue } from "components/numeric-values/BlankZerosCurrencyEntry";
import { isCurrencyValueValid } from "components/numeric-values/CurrencyEntry";
import { NumericTextFieldHooks } from "components/numeric-values/useNumericTextField";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { AddPositionControls } from "./AddPositionControls";
import { AccountForCreation } from "services/accountService";
import { DataSource } from "constants/dataSource";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { Segment } from "constants/segment";
import { BlendedFundPercentage } from "../../shared/blended-fund/BlendedFundControls";
import { AddBlendedPositionControls } from "./AddBlendedPositionControls";
import { AddPositionModalFooter } from "./AddPositionModalFooter";
import { PositionAddedCallback } from "./AddPositionCommon";

export interface AddPositionModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    assetTableViewModel: AssetTablesViewModel;
    assetClasses: AssetClassDataModel[];
    recommendedFundSecurities: SecurityLookupDataModel[];
    accountTypes: AccountTypeDataModel[];
    ticker: string;
    setTicker: FunctionStringCallback;
    securityStatus: QueryStatus;
    nameResultForTicker: string;
    manualName: string;
    setManualName: FunctionStringCallback;
    accountId: number | null;
    setAccountId: Callback<number | null>;
    currentValue: NumericTextFieldHooks;
    segmentId: number | null;
    setSegmentId: Callback<number | null>;
    isSegmentSelectDisabled: boolean;
    addPosition: PositionAddedCallback;
    newAccount: AccountForCreation,
    setNewAccount: Callback<AccountForCreation>
    percentages: BlendedFundPercentage[];
    setPercentages: (percentages: BlendedFundPercentage[]) => void;
    addingNewAccount: boolean;
}

export function AddPositionModal(props: AddPositionModalProps) {
    const { open, setOpen, assetTableViewModel, assetClasses, recommendedFundSecurities, accountTypes, ticker, setTicker, securityStatus, nameResultForTicker, manualName, setManualName,
        accountId, setAccountId, currentValue, segmentId, setSegmentId, isSegmentSelectDisabled, addPosition, newAccount, setNewAccount, percentages, setPercentages, addingNewAccount } = props;
    const { ias } = assetTableViewModel;

    const isBlended = useMemo(() => segmentId === Segment.Blended, [segmentId]);
    const [isEditingBlend, setIsEditingBlend] = useState(false);
    const [isPreBlendValid, setIsPreBlendValid] = useState(false);
    const [preBlendErrorText, setPreBlendErrorText] = useState("");
    const [isBlendValid, setIsBlendValid] = useState(false);
    const [blendErrorText, setBlendErrorText] = useState("");
    const [shouldClearControls, setShouldClearControls] = useState(false);

    const startEditingBlend = useCallback(() => {
        setIsEditingBlend(true);
    }, []);

    const stopEditingBlend = useCallback(() => {
        setIsEditingBlend(false);
    }, []);

    const clearFormExceptAccount = useCallback(() => {
        setIsEditingBlend(false);

        setTicker("");
        setManualName("");

        currentValue.setValue(0);
        currentValue.setDisplay(inactiveDisplayOfBlankZerosCurrencyValue(0));
        currentValue.setIsValid(isCurrencyValueValid(0));

        setSegmentId(null);

        setNewAccount({
            iasId: ias.id,
            lastModifiedDate: ias.dateModified,
            accountNumber: "",
            owner: "",
            custodian: "",
            originalCustodian: undefined,
            typeId: 0,
            accountTypeName: "",
            taxable: true,
            positions: [],
            dataSource: DataSource.Manual,
            internalComment: "",
            clientComment: "",
        });

        setShouldClearControls(true);
    }, [currentValue, ias.dateModified, ias.id, setManualName, setNewAccount, setSegmentId, setTicker]);

    const clearForm = useCallback(() => {
        clearFormExceptAccount();
        setAccountId(null);
    }, [clearFormExceptAccount, setAccountId]);

    const closeModal = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return <BaseModal
        open={open}
        className={styles.addPositionModal}
    >
        <BaseModalContent
            data-testid="add-position-modal"
            width={844}
            minHeight={320}
            header={
                <CancelHeader
                    title="Add Position"
                    cancelBtn={
                        <ModalActionButton
                            isDefaultAction={false}
                            onClick={closeModal}
                        >
                            Cancel
                        </ModalActionButton>
                    }
                />
            }
            content={
                <div className={styles.dialogContents}>
                    {
                        isEditingBlend
                            ? <AddBlendedPositionControls
                                assetTableViewModel={assetTableViewModel}
                                assetClasses={assetClasses}
                                ticker={ticker}
                                setTicker={setTicker}
                                nameResultForTicker={nameResultForTicker}
                                manualName={manualName}
                                setManualName={setManualName}
                                accountId={accountId}
                                setAccountId={setAccountId}
                                currentValue={currentValue}
                                segmentId={segmentId}
                                setSegmentId={setSegmentId}
                                isSegmentSelectDisabled={isSegmentSelectDisabled}
                                securityStatus={securityStatus}
                                shouldClearControls={shouldClearControls}
                                setShouldClearControls={setShouldClearControls}
                                accountTypes={accountTypes}
                                newAccount={newAccount}
                                setNewAccount={setNewAccount}
                                percentages={percentages}
                                setPercentages={setPercentages}
                                setIsValid={setIsBlendValid}
                                setErrorText={setBlendErrorText}
                            />
                            : <AddPositionControls
                                assetTableViewModel={assetTableViewModel}
                                assetClasses={assetClasses}
                                ticker={ticker}
                                setTicker={setTicker}
                                nameResultForTicker={nameResultForTicker}
                                manualName={manualName}
                                setManualName={setManualName}
                                accountId={accountId}
                                setAccountId={setAccountId}
                                currentValue={currentValue}
                                segmentId={segmentId}
                                setSegmentId={setSegmentId}
                                isSegmentSelectDisabled={isSegmentSelectDisabled}
                                securityStatus={securityStatus}
                                shouldClearControls={shouldClearControls}
                                setShouldClearControls={setShouldClearControls}
                                recommendedFundSecurities={recommendedFundSecurities}
                                accountTypes={accountTypes}
                                newAccount={newAccount}
                                setNewAccount={setNewAccount}
                                setIsValid={setIsPreBlendValid}
                                setErrorText={setPreBlendErrorText}
                            />
                    }
                </div>
            }
            footer={
                <AddPositionModalFooter
                    isNewBlend={isBlended && !isSegmentSelectDisabled}
                    isEditingBlend={isEditingBlend}
                    startEditingBlend={startEditingBlend}
                    stopEditingBlend={stopEditingBlend}
                    isPreBlendValid={isPreBlendValid}
                    preBlendErrorText={preBlendErrorText}
                    isBlendValid={isBlendValid}
                    blendErrorText={blendErrorText}
                    addPosition={addPosition}
                    clearForm={clearForm}
                    clearFormExceptAccount={clearFormExceptAccount}
                    setAccountId={setAccountId}
                    closeModal={closeModal}
                    addingNewAccount={addingNewAccount}
                />
            }
        />
    </BaseModal>;
}
