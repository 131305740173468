import { calculateUnroundedPercentage, valueForPercentage } from "utils/Percentages";

export interface ValuePercentagePair {
    value: number;
    percentage: number;
}

export interface CalculatedPercentages {
    current: ValuePercentagePair;
    proposed: ValuePercentagePair;
    target: ValuePercentagePair;
    proposedVariance: ValuePercentagePair;
    currentVariance: ValuePercentagePair;
}

export function calculatePercentages(current: number, currentOverall: number, proposed: number, proposedOverall: number, targetPercentage: number): CalculatedPercentages {
    const currentPercentage = calculateUnroundedPercentage(current, currentOverall);
    const proposedPercentage = calculateUnroundedPercentage(proposed, proposedOverall);

    const targetValue = valueForPercentage(currentOverall, targetPercentage);

    const proposedVariancePercentage = proposedPercentage - targetPercentage;
    const proposedVarianceValue = proposed - targetValue;

    return {
        current: {
            percentage: currentPercentage,
            value: current,
        },
        proposed: {
            percentage: proposedPercentage,
            value: proposed,
        },
        target: {
            percentage: targetPercentage,
            value: targetValue,
        },
        proposedVariance: {
            percentage: proposedVariancePercentage,
            value: proposedVarianceValue,
        },
        currentVariance: {
            percentage: currentPercentage - targetPercentage,
            value: current - targetValue
        }
    };
}
