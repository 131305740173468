import { AssetClassTableViewModel, createAssetClassTable } from "components/ias-workspace/asset-table/view-models/AssetClassTableViewModel";
import { ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SegmentDataModel } from "dataModels/segmentDataModel";
import { SegmentGroupDataModel } from "dataModels/segmentGroupDataModel";
import { createAssetClassesViewModel } from "viewModels/AssetClassesViewModel";

export interface ModelPortfolioTargetsTableEntry {
    name: string;
    target: number;
    segmentWithCustomizableName: SegmentDataModel | null;
    segmentGroupWithCustomizableName: SegmentGroupDataModel | null;
}

export interface ModelPortfolioTargetsTableViewModel {
    readonly assetClassesHeaderEntries: ModelPortfolioTargetsTableEntry[];
    readonly assetClassesSetsOfRowEntries: ModelPortfolioTargetsTableEntry[][];
}

export const createModelPortfolioTargetsTableViewModel = (modelPortfolio: ModelPortfolioViewModel, assetClasses: AssetClassDataModel[]): ModelPortfolioTargetsTableViewModel => {
    const assetClassesViewModel = createAssetClassesViewModel(assetClasses);

    const createAssetClassTarget = ({ assetClass }: AssetClassTableViewModel): ModelPortfolioTargetsTableEntry => ({
        name: assetClass.name,
        target: modelPortfolio.getTargetForAssetClass(assetClass.id),
        segmentWithCustomizableName: null,
        segmentGroupWithCustomizableName: null,
    });

    const createAssetClassSubTargets = ({ segmentGroups, segments }: AssetClassTableViewModel): ModelPortfolioTargetsTableEntry[] => {
        return [
            ...segmentGroups.map(({ group }) => ({
                name: group.name,
                target: modelPortfolio.getTargetForSegmentGroup(group.id),
                segmentWithCustomizableName: null,
                segmentGroupWithCustomizableName: group,
            })),
            ...segments.map(({ segment }) => ({
                name: segment.name,
                target: modelPortfolio.getTargetForSegment(segment.id),
                segmentWithCustomizableName: segment,
                segmentGroupWithCustomizableName: null,
            })),
        ];
    };

    const cash = createAssetClassTable(assetClassesViewModel.cash.assetClass, modelPortfolio);
    const fixedIncome = createAssetClassTable(assetClassesViewModel.fixedIncome.assetClass, modelPortfolio);
    const equities = createAssetClassTable(assetClassesViewModel.equities.assetClass, modelPortfolio);
    const alternatives = createAssetClassTable(assetClassesViewModel.alternatives.assetClass, modelPortfolio);

    const assetClassesHeaderEntries = [cash, fixedIncome, equities, alternatives].map((assetClass) => createAssetClassTarget(assetClass));

    return {
        assetClassesHeaderEntries,
        assetClassesSetsOfRowEntries: [
            [],
            createAssetClassSubTargets(fixedIncome),
            createAssetClassSubTargets(equities),
            createAssetClassSubTargets(alternatives),
        ],
    };
};