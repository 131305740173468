import { Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AddIasWizard } from "./add-ias-wizard/AddIasWizard";
import IasTable from "./IasTable";
import { useClientQuery, useRemoveClientQuery } from "services/clientService";
import Layout from "../layout/Layout";
import { Button } from "rmwc";
import styles from "./ClientPage.module.scss";
import CenteredContent from "../layout/CenteredContent";
import { IconName } from "../../iconNames";
import { TitleValueCard } from "../ias-workspace/workspace-header/TitleValueCard";
import { EditClientModelPortfolioDialog } from "./EditClientModelPortfolioDialog";
import { isFinal } from "constants/iasState";
import { useModelPortfolioQuery } from "services/modelPortfolioService";
import { LinearProgressOverlay } from "components/shared/LinearProgressOverlay";
import { combineQueryStatuses } from "utils/ReactQueryUtils";
import { ClientDataModel } from "dataModels/clientDataModel";
import { useDocumentTitle } from "utils/useDocumentTitle";
import { hasContent } from "utils/StringUtils";
import { createModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { useIasQuery } from "services/iasService";
import { createAssetTables } from "components/ias-workspace/asset-table/view-models/AssetTablesViewModel";

export default function ClientPage() {
    const { id } = useParams();
    const clientId = parseInt(id ?? "-1");
    const removeClientQuery = useRemoveClientQuery(clientId);

    useEffect(() => {
        removeClientQuery();
    }, [removeClientQuery]);
    
    const { status: clientStatus, data: clientData } = useClientQuery(clientId);
    const { status: modelPortfolioStatus, data: modelPortfolioData } = useModelPortfolioQuery();

    const client = useMemo(() => clientData ?? defaultClient(), [clientData]);
    const modelPortfolios = useMemo(() => modelPortfolioData ?? [], [modelPortfolioData]);
    const assetClasses = client.assetClasses;

    const modelPortfolio = useMemo(() => createModelPortfolioViewModel(client, modelPortfolios, assetClasses), [assetClasses, client, modelPortfolios]);

    const [showAddIasDialog, setShowAddIasDialog] = useState(false);
    const [showModelPortfolioDialog, setShowModelPortfolioDialog] = useState(false);
 
    useDocumentTitle(hasContent(clientData?.key) ? `IAS ${clientData?.key} - ${clientData?.reportingName}` : "IAS");
    
    const finalizedRecords = useMemo(() => client.meetings
        .flatMap(meeting => meeting.ias)
        .filter(ias => isFinal(ias.state))
        .sort((a, b) => b.dateModified.getTime() - a.dateModified.getTime())
    , [client.meetings]);
    const mostRecent = finalizedRecords.at(0);
    
    const {status: mostRecentIasStatus, data: mostRecentFullIas} = useIasQuery(mostRecent?.id ?? -1, !!mostRecent);

    const combinedStatus = combineQueryStatuses(modelPortfolioStatus, clientStatus, mostRecent?.id === undefined ? "success" : mostRecentIasStatus);

    const mostRecentIasTotalValue = useMemo(() => {
        if(mostRecentIasStatus === "success") {
            const assetClassTables = createAssetTables(mostRecentFullIas.assetClasses, modelPortfolios, mostRecentFullIas);
            return assetClassTables.currentValue();
        }
        return 0;
    }, [modelPortfolios, mostRecentFullIas, mostRecentIasStatus]);

    const meetings = useMemo(() => client.meetings
        .filter(meeting => meeting.ias.length > 0)
    , [client.meetings]);

    return <Fragment>
        <Layout
            clientKey={client.key}
            clientReportingName={client.reportingName}
            clientId={client.id}
        >
            <div className={styles.header}>
                <div>
                    <div className={styles.modelPortfolio}>
                        <TitleValueCard
                            title="Model Portfolio"
                            value={modelPortfolio.name()}
                            maxLines={1}
                            onClick={() => setShowModelPortfolioDialog(true)}
                            editable
                        />
                    </div>
                </div>
                <Button
                    label="NEW IAS"
                    icon={IconName.Add}
                    raised
                    onClick={() => setShowAddIasDialog(true)}
                    className={styles.addIasButton}
                    data-testid="new-ias-button"
                />
                {
                    showAddIasDialog && <AddIasWizard
                        setOpen={setShowAddIasDialog}
                        client={client}
                        modelPortfolios={modelPortfolios}
                        mostRecentlyFinalized={mostRecent}
                    />
                }
            </div>
            {
                showModelPortfolioDialog && <EditClientModelPortfolioDialog
                    open={showModelPortfolioDialog}
                    setOpen={setShowModelPortfolioDialog}
                    modelPortfolios={modelPortfolios}
                    client={client}
                    mostRecentlyFinalizedPortfolioValue={mostRecentIasTotalValue}
                />
            }
            <CenteredContent>
                <IasTable
                    meetings={meetings}
                    modelPortfolios={modelPortfolios}
                    assetClasses={assetClasses}
                    clientId={clientId}
                    allMeetings={client.meetings}
                />
            </CenteredContent>
            <div className={styles.bottomWhitespace} />
        </Layout>
        {
            combinedStatus === "loading" &&
            <LinearProgressOverlay
                text="Loading client profile..."
                fullscreen
            />
        }
    </Fragment>;
}

function defaultClient() {
    const client: ClientDataModel = {
        id: 0,
        key: "",
        meetings: [],
        assetClasses: [],
        reportingName: ""
    };
    return client;
}