import classnames from "classnames";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { BaseModal, BaseModalProps } from "components/shared/modal/BaseModal";
import { BaseModalContent } from "components/shared/modal/BaseModalContent";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { NextFooter } from "components/shared/modal/footer/NextFooter";
import { CancelHeader } from "components/shared/modal/header/CancelHeader";
import SegmentSelection from "components/shared/SegmentSelection";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { IasDataModel } from "dataModels/iasDataModel";
import { useCustomGroupMutation } from "services/customGroupService";
import { hasContent } from "utils/StringUtils";
import modalStyles from "./EditSecurityModal.module.scss";
import controlsStyles from "./EditSecurityControls.module.scss";
import { CustomGroupViewModel } from "../view-models/CustomGroupViewModel";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

export interface EditCustomGroupNameAssetClassModalProps extends BaseModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    ias: IasDataModel;
    customGroup: CustomGroupViewModel;
}

export function EditCustomGroupNameAssetClassModal(props: EditCustomGroupNameAssetClassModalProps) {
    const { setOpen, ias, customGroup, ...nativeProps } = props;

    const assetClasses = ias.assetClasses;

    const { mutate } = useCustomGroupMutation(customGroup.customGroup.id, ias.id);

    const [name, setName] = useState(customGroup.name);
    const [segmentId, setSegmentId] = useState(customGroup.segmentId);

    const [isValid, setIsValid] = useState(true);
    const [isModified, setIsModified] = useState(false);

    const onNameInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setName(event.currentTarget.value);
    }, []);

    const onSegmentChange = useCallback((segmentId: number) => {
        setSegmentId(segmentId);
    }, []);

    const saveChanges = useCallback(() => {
        if (segmentId !== customGroup.segmentId) {
            telemetry.trackEvent(EventType.CustomGroupSegmentChanged);
        }
        mutate({
            name,
            segmentId,
            lastModifiedDate: ias.dateModified,
        });
        setOpen(false);
    }, [ias.dateModified, mutate, name, segmentId, setOpen, customGroup.segmentId]);

    const closeModal = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useEffect(() => {
        setIsValid(hasContent(name));
    }, [name]);

    useEffect(() => {
        setIsModified(name !== customGroup.name
            || segmentId !== customGroup.segmentId);
    }, [customGroup.name, customGroup.segmentId, name, segmentId]);

    return <BaseModal
        {...nativeProps}
        onClose={closeModal}
        renderToPortal
    >
        <BaseModalContent
            data-testid="edit-custom-group-name-asset-class-modal"
            width={844}
            fixedHeight={236}
            className={modalStyles.editSecurityModal}
            header={
                <CancelHeader
                    title="Edit Name/Asset Class"
                    cancelBtn={
                        <ModalActionButton
                            label="Cancel"
                            isDefaultAction={false}
                            onClick={closeModal}
                        />
                    }
                />
            }
            content={
                <div className={modalStyles.modalContents}>
                    <div className={controlsStyles.header}>Group Info</div>
                    <div className={controlsStyles.row}>
                        <ValeoTextField
                            label="Name"
                            value={name}
                            onInput={onNameInput}
                            className={classnames(controlsStyles.nameTextField, { [controlsStyles.nonEmptyName]: hasContent(name) })}
                            data-testid="name-text-field"
                        />
                        <SegmentSelection
                            assetClasses={assetClasses}
                            onChange={onSegmentChange}
                            segmentId={segmentId}
                        />
                    </div>
                </div>
            }
            footer={
                <NextFooter
                    nextBtn={
                        <ModalActionButton
                            label="Save"
                            isDefaultAction={false}
                            onClick={saveChanges}
                            disabled={!(isValid && isModified)}
                        />
                    }
                />
            }
        />
    </BaseModal>;
}