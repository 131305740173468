import { IndeterminateTristate } from "components/controls/IndeterminateCheckbox";
import { VisionPossession, VisionCategory, VisionSubcategory } from "services/visionService";

export interface SelectionProps {
    addSelections: (possessions: VisionPossession[]) => void;
    removeSelections: (possessions: VisionPossession[]) => void;
    selectedElements: ReadonlyMap<string | number, IndeterminateTristate>;
}

export function allPossessions(container: VisionCategory | VisionSubcategory) {
    if (isCategory(container)) {
        return container.subcategories.flatMap(subcategory => subcategory.possessions);
    }
    return container.possessions;
}

export function isCategory(obj: VisionCategory | VisionSubcategory): obj is VisionCategory {
    return "subcategories" in obj;
}

export function isSubcategory(obj: VisionCategory | VisionSubcategory): obj is VisionSubcategory {
    return "possessions" in obj;
}