import { BlackDiamondHolding } from "../../dataModels/blackDiamond/holding";
import { IasDataModel } from "../../dataModels/iasDataModel";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { postMeeting } from "services/clientService";
import { postIas, WritableAccount, WritableIas, WritablePosition, WritableSecurity } from "services/iasService";
import { filterMap } from "utils/ArrayUtils";
import { hasContent, isWhiteSpace } from "utils/StringUtils";
import { isNumber } from "utils/NumberUtils";
import { VisionPossession } from "services/visionService";
import { AccountType } from "constants/accountType";
import { DataSource } from "constants/dataSource";
import { ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { isMatchingSecurity } from "utils/securityUtils";
import { round } from "lodash";
import { BlendedFundSegmentPercentage } from "dataModels/blendedFundSegmentPercentage";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { Segment } from "constants/segment";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { getCustomSegmentGroupNames, getCustomSegmentNames } from "utils/CustomSegmentNameUtils";
import { ClientDataModel } from "dataModels/clientDataModel";

export interface ClassifiedBlackDiamondHolding extends BlackDiamondHolding {
    readonly blendedFundPercentages: BlendedFundSegmentPercentage[];
}

export interface ClassifiedVisionPossession extends VisionPossession {
    readonly blendedFundPercentages: BlendedFundSegmentPercentage[];
}

function findOrCreateSecurity(securities: WritableSecurity[], ticker: string, positionName: string, segmentId: number, blendedFundPercentages: BlendedFundSegmentPercentage[]) {
    let security = securities.find(s => isMatchingSecurity(s, ticker, positionName));
    if (security === undefined) {
        security = {
            tempId: securities.length + 1,
            tickerSymbol: ticker,
            positionName,
            segmentId,
            blendedFundPercentages,
        };
        securities.push(security);
    }
    return security;
}

function setupBlackDiamondAccounts(portfolio: PopulatedPortfolio | null, classifiedHoldings: ClassifiedBlackDiamondHolding[], securities: WritableSecurity[]) {
    const blackDiamondAccounts = (portfolio?.accounts ?? []).map(account => {
        const result: WritableAccount = {
            accountNumber: account.accountNumber,
            owner: account.owner,
            custodian: account.custodian,
            originalCustodian: account.originalCustodian,
            typeId: account.typeId,
            dataSource: DataSource.BlackDiamond,
            internalComment: "",
            clientComment: "",
            positions: filterMap(account.holdings, holding => {
                const classifiedHolding = classifiedHoldings.find(classifiedHolding => classifiedHolding.name === holding.name);
                const segmentId = holding.segmentId
                    ?? classifiedHolding?.segmentId;
                if (!isNumber(segmentId)) {
                    return null;
                }
                const blendedFundPercentages = classifiedHolding?.blendedFundPercentages ?? [];
                const position: WritablePosition = {
                    currentValue: round(holding.marketValue),
                    overwrittenCurrentValue: null,
                    change: 0,
                    asOfDate: holding.returnDate,
                    dataSource: DataSource.BlackDiamond,
                    securityId: findOrCreateSecurity(securities, holding.ticker ?? "", holding.name, segmentId, blendedFundPercentages).tempId,
                    internalComment: "",
                    clientComment: "",
                    sourceId: holding.assetId,
                    shares: holding.shares
                };
                return position;
            })
        };
        return result;
    });
    return blackDiamondAccounts;
}

function setupVisionAccounts(visionAssets: ClassifiedVisionPossession[], securities: WritableSecurity[]) {
    const visionWritableAccounts: WritableAccount[] = [];
    const visionHoldings = visionAssets.filter(possession => !hasContent(possession.account));
    const visionAccounts = visionAssets.filter(possession => hasContent(possession.account));

    visionHoldings.forEach(possession => {
        const position: WritablePosition = {
            currentValue: round(possession.endValue),
            overwrittenCurrentValue: null,
            change: 0,
            asOfDate: possession.endDate,
            dataSource: DataSource.Vision,
            securityId: findOrCreateSecurity(securities, "", possession.name, possession.segmentId ?? -1, possession.blendedFundPercentages).tempId,
            internalComment: "",
            clientComment: "",
            sourceId: possession.id
        };

        const index = visionWritableAccounts.findIndex(visionAccount => {
            return visionAccount.owner === possession.owner && visionAccount.custodian === possession.custodian && visionAccount.typeId === possession.accountType?.id;
        });

        if(index === -1) {
            const accountNumber = (possession.accountType?.id === AccountType.Direct_Taxable || possession.accountType?.id === AccountType.Direct_Tax_Adv)
                ? "DIRECT"
                : "ALTERNATIVE";
            const account: WritableAccount =  {
                accountNumber,
                owner: possession.owner,
                custodian: possession.custodian ?? "",
                originalCustodian: undefined,
                dataSource: DataSource.Vision,
                typeId: possession.accountType?.id ?? AccountType.Unknown,
                positions: [position],
                internalComment: "",
                clientComment: ""
            };

            visionWritableAccounts.push(account);
        } else {
            visionWritableAccounts[index].positions.push(position);
        }
    });

    visionAccounts.forEach((possession) => {
        const position: WritablePosition = {
            currentValue: round(possession.endValue),
            overwrittenCurrentValue: null,
            change: 0,
            asOfDate: possession.endDate,
            dataSource: DataSource.Vision,
            securityId: findOrCreateSecurity(securities, "", possession.account ?? "", possession.segmentId ?? -1, possession.blendedFundPercentages).tempId,
            internalComment: "",
            clientComment: "",
            sourceId: possession.id
        };

        const account: WritableAccount = {
            accountNumber: possession.account ?? "",
            owner: possession.owner,
            custodian: possession.custodian ?? "",
            originalCustodian: undefined,
            dataSource: DataSource.Vision,
            typeId: possession.accountType?.id ?? AccountType.Unknown,
            positions: [position],
            internalComment: "",
            clientComment: ""
        };

        visionWritableAccounts.push(account);
    });

    return visionWritableAccounts;
}

function setupManualAccounts(ias: IasDataModel, securities: WritableSecurity[]) {
    const manualAccounts = ias.accounts.filter(account => account.dataSource === DataSource.Manual);
    const writableAccounts: WritableAccount[] = manualAccounts.map(account => ({
        accountNumber: account.accountNumber,
        owner: account.owner,
        custodian: account.custodian,
        originalCustodian: account.originalCustodian,
        typeId: account.typeId,
        positions: filterMap(account.positions, position => {
            const security = ias.securities.find(security => security.id === position.securityId);
            if(!security) {
                return null;
            }
            const writablePosition: WritablePosition = {
                currentValue: position.currentValue,
                overwrittenCurrentValue: position.overwrittenCurrentValue,
                change: position.change,
                asOfDate: position.asOfDate,
                dataSource: position.dataSource,
                securityId: findOrCreateSecurity(securities, security.tickerSymbol, security.positionName, security.segmentId, security.blendedFundPercentages).tempId,
                internalComment: position.internalComment,
                clientComment: position.clientComment,
                sourceId: position.sourceId,
            };
            return writablePosition;
        }),
        dataSource: account.dataSource,
        internalComment: account.internalComment,
        clientComment: account.clientComment
    }));

    return writableAccounts;
}

export async function createIas(
    date: Date,
    title: string,
    modelPortfolio: ModelPortfolioViewModel,
    client: ClientDataModel,
    portfolio: PopulatedPortfolio | null,
    classifiedHoldings: ClassifiedBlackDiamondHolding[],
    visionAssets: ClassifiedVisionPossession[],
    assetClasses: AssetClassDataModel[],
    mostRecentlyFinalized?: IasDataModel,
): Promise<number> {
    const securities: WritableSecurity[] = [];

    const blackDiamondAccounts = setupBlackDiamondAccounts(portfolio, classifiedHoldings, securities);
    const visionWritableAccounts = setupVisionAccounts(visionAssets, securities);

    const accounts = blackDiamondAccounts.concat(visionWritableAccounts);

    const meetingId = await postMeeting(date, client.id);

    const customSegmentNames = getCustomSegmentNames(assetClasses);
    const customSegmentGroupNames = getCustomSegmentGroupNames(assetClasses);

    const writableIas: WritableIas = {
        title: isWhiteSpace(title) ? "IAS" : title,
        modelPortfolioId: modelPortfolio.modelPortfolioId(),
        customModelPortfolio: modelPortfolio.customModelPortfolioInUse(),
        accounts,
        securities,
        customGroups: [],
        meetingId,
        valeoNotes: "",
        clientNotes: "",
        cashTarget: modelPortfolio.cashTarget(),
        cashTargetInDollars: modelPortfolio.cashTargetInDollars(),
        alternativesTarget: modelPortfolio.alternativesTarget(),
        alternativesTargetInDollars: modelPortfolio.alternativesTargetInDollars(),
        cashCarveOut: modelPortfolio.cashCarveOut(),
        fixedIncomeCarveOut: modelPortfolio.fixedIncomeCarveOut(),
        equitiesCarveOut: modelPortfolio.equitiesCarveOut(),
        sourcePortfolioId: portfolio?.id,
        dateRefreshed: new Date(),
        showAltTargets: false,
        customSegmentNames,
        customSegmentGroupNames,
        reportTitle: client.reportingName
    };

    if (mostRecentlyFinalized) {
        writableIas.clientNotes = mostRecentlyFinalized.clientNotes;
        writableIas.valeoNotes = mostRecentlyFinalized.valeoNotes;

        const manualAccounts = setupManualAccounts(mostRecentlyFinalized, securities);
        accounts.push(...manualAccounts);

        for (const account of accounts.filter(a => a.dataSource === DataSource.BlackDiamond)) {
            let matchingAccount = undefined;
            for(const finalizedAccount of mostRecentlyFinalized.accounts.filter(a => a.dataSource === DataSource.BlackDiamond)) {
                if(finalizedAccount.accountNumber === account.accountNumber) {
                    matchingAccount = finalizedAccount;
                }
            }

            if (matchingAccount) {
                account.accountNumber = matchingAccount.accountNumber;
                account.custodian = matchingAccount.custodian;
                account.owner = matchingAccount.owner;
                account.typeId = matchingAccount.typeId;
            }
        }
    }

    telemetry.trackEvent(EventType.IasCreated, {
        includesBlackDiamondPortfolio: portfolio !== null,
        includesVisionAssets: visionAssets.length > 0,
    });

    if (writableIas.securities.some(security => security.segmentId === Segment.Blended)) {
        telemetry.trackEvent(EventType.BlendedFundUsed);
    }

    return postIas(writableIas);
}
