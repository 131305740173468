import { useCallback, useState } from "react";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { isSome } from "utils/isNullOrUndefined";
import { LogIntoBlackDiamond } from "./LogIntoBlackDiamond";
import { LoadBlackDiamondAccounts } from "./LoadBlackDiamondAccounts";
import { BlackDiamondClassification } from "./BlackDiamondClassification";
import { BDAuthProgress } from "components/ias-workspace/workspace-header/BDAuthProgress";
import { LinearProgressPage } from "./LinearProgressPage";
import { ClassifiedBlackDiamondHolding } from "components/client-page/createIas";
import { ClientDataModel } from "dataModels/clientDataModel";
import { AddIasWizardViewControllerState } from "../AddIasWizardConstants";

interface BlackDiamondWorkflowProps {
    sourcePortfolioId: string;
    report: Callback<ClassifiedBlackDiamondHolding[]>;
    portfolio: PopulatedPortfolio | null;
    setPortfolio: Callback<PopulatedPortfolio | null>;
    client: ClientDataModel;
    onCancel: VoidFunction;
    setState: Callback<AddIasWizardViewControllerState>;
    bdHoldings: ClassifiedBlackDiamondHolding[] | null;
    setBdHoldings: Callback<ClassifiedBlackDiamondHolding[]>;
}

export function BlackDiamondWorkflow(props: BlackDiamondWorkflowProps) {
    const { portfolio, setPortfolio: setParentPortfolio, bdHoldings, setBdHoldings } = props;
    const [bdAuthProgress, setBdAuthProgress] = useState(BDAuthProgress.InProgress);

    const setPortfolio = useCallback((portfolio: PopulatedPortfolio | null) => {
        if (!isSome(portfolio)) {
            setBdHoldings([]);
        }
        setParentPortfolio(portfolio);
    }, [setBdHoldings, setParentPortfolio]);

    if (!isSome(bdHoldings)) {
        if (bdAuthProgress === BDAuthProgress.Success) {
            if (isSome(portfolio)) {
                return <BlackDiamondClassification
                    portfolio={portfolio}
                    setClassifiedBlackDiamondHoldings={setBdHoldings}
                    client={props.client}
                    onCancel={props.onCancel}
                    setState={props.setState}
                />;
            }

            return <LoadBlackDiamondAccounts
                client={props.client}
                portfolioId={props.sourcePortfolioId}
                setState={props.setState}
                setPortfolio={setPortfolio}
            />;
        }

        return <LogIntoBlackDiamond setAuthProgress={setBdAuthProgress} />;
    }

    return <LinearProgressPage text="Loading Black Diamond accounts..." />;
}
