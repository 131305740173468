import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export enum NavigationType {
    Normal,
    NewTab
}

export function determineNavigationType(event: {ctrlKey: boolean, metaKey: boolean}) {
    if (event.ctrlKey || event.metaKey) {
        return NavigationType.NewTab;
    }

    return NavigationType.Normal;
}

export function useKeyboardAwareNavigate() {
    const navigate = useNavigate();
    const callback = useCallback((event: {ctrlKey: boolean, metaKey: boolean}, url: string) => {
        if (determineNavigationType(event) === NavigationType.NewTab) {
            window.open(url, "_blank");
        }
        else {
            navigate(url, {replace: false});
        }
    }, [navigate]);
    return callback;
}