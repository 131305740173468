import classnames from "classnames";
import SegmentSelection from "components/shared/SegmentSelection";
import { ValeoTextField } from "components/shared/ValeoTextField";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { isEqual } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { hasContent } from "utils/StringUtils";
import sharedStyles from "./EditSecurityControls.module.scss";
import styles from "./EditBlendedSecurityControls.module.scss";
import { BlendedFundControls, BlendedFundPercentage, normalizePercentages } from "../../../shared/blended-fund/BlendedFundControls";

export interface EditBlendedSecurityControlsProps {
    security: IasSecurityDataModel;
    assetClasses: AssetClassDataModel[];
    name: string;
    segmentId: number;
    percentages: BlendedFundPercentage[];
    setPercentages: (percentages: BlendedFundPercentage[]) => void;
    setIsValid: (isValid: boolean) => void;
    setIsModified: (isModified: boolean) => void;
}

export function EditBlendedSecurityControls(props: EditBlendedSecurityControlsProps) {
    const { security, assetClasses, name, segmentId, percentages, setPercentages, setIsValid, setIsModified } = props;

    const [percentageAssigned, setPercentageAssigned] = useState(0);
    const [originalPercentages] = useState<BlendedFundPercentage[]>(percentages);

    useEffect(() => {
        setIsModified(!isEqual(normalizePercentages(originalPercentages), normalizePercentages(percentages)));
    }, [originalPercentages, percentages, setIsModified]);

    return <Fragment>
        <div className={sharedStyles.header}>Position Info</div>
        <div className={sharedStyles.row}>
            <ValeoTextField
                label="Ticker"
                value={security.tickerSymbol}
                disabled
                className={classnames(sharedStyles.tickerTextField, { [sharedStyles.nonEmptyTicker]: hasContent(security.tickerSymbol) })}
                data-testid="ticker-text-field"
            />
            <SegmentSelection
                assetClasses={assetClasses}
                segmentId={segmentId}
                includeBlended
                disabled
            />
        </div>
        <div className={sharedStyles.row}>
            <ValeoTextField
                label="Name"
                value={name}
                disabled
                className={classnames(sharedStyles.nameTextField, { [sharedStyles.nonEmptyName]: hasContent(name) })}
                data-testid="name-text-field"
            />
        </div>
        <div className={styles.preBlendSpacer} />
        <div className={sharedStyles.header}>Blended Asset Class</div>
        <BlendedFundControls
            securityTicker={security.tickerSymbol}
            securityName={name}
            assetClasses={assetClasses}
            percentages={percentages}
            setPercentages={setPercentages}
            percentageAssigned={percentageAssigned}
            setPercentageAssigned={setPercentageAssigned}
            setIsValid={setIsValid}
        />
    </Fragment>;
}