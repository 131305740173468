import classnames from "classnames";
import { MouseEvent } from "react";
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, Typography } from "rmwc";
import styles from "./ConfirmationDialog.module.scss";

interface ConfirmationDialogProps {
    title: string | JSX.Element;
    confirmButton: string;
    onConfirm: VoidFunction;
    onCancel?: VoidFunction;
    content: string | JSX.Element;
    width?: number;
    useWarningStyling?: boolean;
    className?: string;
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
    const handleCancel = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onCancel?.();
    };

    const handleAccept = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onConfirm();
    };

    const width = props.width ?? 640;

    const dialogInlineStyles = {
        width,
        minWidth: width,
        maxWidth: width,
    };

    return <Dialog
        open
        preventOutsideDismiss
        className={classnames(styles.dialog, props.className)}
        data-testid="confirmation-dialog"
    >
        <div style={dialogInlineStyles}>
            <DialogTitle className={styles.dialogTitle}>
                <Typography
                    use="headline3"
                    className={styles.title}
                >{props.title}</Typography>
            </DialogTitle>
            <DialogContent className={styles.dialogBody}>
                { props.content }
            </DialogContent>
            <DialogActions className={styles.actions}>
                {
                    props.onCancel &&
                    <DialogButton
                        action="close"
                        onClick={handleCancel}
                        className={classnames(styles.dialogButton, styles.buttonCancel)}
                        data-testid="confirmation-dialog-cancel"
                    >
                    Cancel
                    </DialogButton>
                }
                <DialogButton
                    action="accept"
                    onClick={handleAccept}
                    className={classnames(styles.dialogButton, {[styles.buttonWarning]: props.useWarningStyling, [styles.buttonBlue]: props.useWarningStyling !== true})}
                    data-testid="confirmation-dialog-confirm"
                >
                    {props.confirmButton}
                </DialogButton>
            </DialogActions>
        </div>
    </Dialog>;
}