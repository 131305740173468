import { BlendedFundControls, BlendedFundPercentage, normalizePercentages } from "components/shared/blended-fund/BlendedFundControls";
import { useEffect, useState } from "react";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import styles from "./BlendedAssetClassDefinitionOverlay.module.scss";
import { isEqual } from "lodash";
import { IconName } from "iconNames";
import { IconButton } from "rmwc";

export interface BlendedAssetClassDefinitionOverlayProps {
    securityTicker: string;
    securityName: string;
    assetClasses: AssetClassDataModel[];
    percentages: BlendedFundPercentage[];
    setPercentages: (percentages: BlendedFundPercentage[]) => void;
    percentageAssigned: number;
    setPercentageAssigned: (percentage: number) => void;
    setIsBlendValid: (isValid: boolean) => void;
    setIsBlendModified: (isModified: boolean) => void;
    onCancel: VoidFunction;
}

export function BlendedAssetClassDefinitionOverlay(props: BlendedAssetClassDefinitionOverlayProps) {
    const { securityTicker, securityName, assetClasses, percentages, setPercentages, percentageAssigned, setPercentageAssigned, setIsBlendValid, setIsBlendModified, onCancel } = props;

    const [originalPercentages] = useState(percentages);

    useEffect(() => {
        setIsBlendModified(!isEqual(normalizePercentages(originalPercentages), normalizePercentages(percentages)));
    }, [originalPercentages, percentages, setIsBlendModified]);

    return <div className={styles.overlay}>
        <div className={styles.cancelContainer}>
            <IconButton
                icon={IconName.Close}
                onClick={onCancel}
                width={28}
                height={28}
                className={styles.cancelButton}
                data-testid="cancel-blended-fund-definition"
            />
        </div>
        <div className={styles.content}>
            <div className={styles.helperText}>Please choose classification breakdown for this blend.</div>
            <BlendedFundControls
                securityTicker={securityTicker}
                securityName={securityName}
                assetClasses={assetClasses}
                percentages={percentages}
                setPercentages={setPercentages}
                percentageAssigned={percentageAssigned}
                setPercentageAssigned={setPercentageAssigned}
                setIsValid={setIsBlendValid}
            />
        </div>
    </div>;
}