import { useCallback, useContext, useMemo, useState } from "react";
import { BaseModalProps } from "components/shared/modal/BaseModal";
import { isSecurityViewModel, SecurityDisplayViewModel } from "../view-models/SecurityDisplayViewModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { CommonCustomGroupModal } from "./CommonCustomGroupModal";
import { CustomGroupViewModel } from "../view-models/CustomGroupViewModel";
import { useCustomGroupContentsMutation } from "services/customGroupService";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { sortSecurities } from "../workspaceSorting";
import { WorkspaceContext } from "contexts/workspaceContext";

interface EditCustomGroupContentsModalProps extends BaseModalProps {
    open: boolean;
    onClose: VoidFunction;
    ias: IasDataModel;
    segmentName: string;
    segmentChildren: SecurityDisplayViewModel[];
    customGroup: CustomGroupViewModel;
}

export function EditCustomGroupContentsModal(props: EditCustomGroupContentsModalProps) {
    const { open, onClose, ias, segmentName, segmentChildren, customGroup, ...nativeProps } = props;

    const context = useContext(WorkspaceContext);

    const { mutate: editCustomGroup } = useCustomGroupContentsMutation(customGroup.customGroup.id, ias.id);

    const [customGroupSelections, setCustomGroupSelections] = useState(customGroup.securities.map(security => security.security.id));

    const options = useMemo(() => {
        const unsortedOptions = segmentChildren
            .filter(isSecurityViewModel)
            .filter(child => !child.isBlended && !child.isInCustomGroup)
            .concat(customGroup.securities);
        return sortSecurities(unsortedOptions, context.sortBy);
    }, [context.sortBy, customGroup.securities, segmentChildren]);

    const onSave = useCallback(() => {
        telemetry.trackEvent(EventType.CustomGroupContentsChanged);
        editCustomGroup({lastModifiedDate: ias.dateModified, securityIds: customGroupSelections});
        onClose();
    }, [customGroupSelections, editCustomGroup, ias.dateModified, onClose]);

    return <CommonCustomGroupModal
        open={open}
        onClose={onClose}
        segmentName={segmentName}
        options={options}
        title="Edit Group"
        nameFieldDisabled={true}
        onSave={onSave}
        customGroupSelections={customGroupSelections}
        originalCustomGroupSelections={customGroupSelections}
        setCustomGroupSelections={setCustomGroupSelections}
        customGroupName={customGroup.name}
        {...nativeProps}
    />;
}