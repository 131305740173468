import { DataTableCell, DataTableRow, IconButton, MenuItem, MenuSurfaceAnchor, Menu } from "rmwc";
import classnames from "classnames";
import { MeetingDataModel } from "../../dataModels/meetingDataModel";
import IasRow from "./IasRow";
import styles from "./MeetingGroup.module.scss";
import depth from "./Depth.module.scss";
import { Fragment, MouseEvent, useCallback, useMemo, useState } from "react";
import { formatMeetingDate } from "utils/FormatDate";
import ExpandableToggleButton from "../shared/ExpandableToggleButton";
import { EditMeetingDateDialog } from "./EditMeetingDateDialog";
import { IconName } from "../../iconNames";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

interface MeetingGroupProps {
    meeting: MeetingDataModel;
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    depth: number;
    open: boolean;
    clientId: number;
    allMeetings: MeetingDataModel[];
}

export default function MeetingGroup(props: MeetingGroupProps) {
    const { meeting } = props;

    const iasList = useMemo(() => meeting.ias.slice()
        .sort((a, b) => new Date(b.dateModified).getTime() - new Date(a.dateModified).getTime())
    , [meeting.ias]);

    const [menuOpen, setMenuOpen] = useState(false);
    const [groupOpen, setGroupOpen] = useState(props.open);
    const [kebabVisible, setKebabVisible] = useState(false);
    const [editMeetingDateDialogOpen, setEditMeetingDateDialogOpen] = useState(false);

    const handleRowClick = useCallback((event: MouseEvent) => {
        if (((event.target as Element).attributes.getNamedItem("role")?.value !== "button")) {
            if (!editMeetingDateDialogOpen) {
                setGroupOpen(!groupOpen);
            }
        }
    }, [editMeetingDateDialogOpen, groupOpen]);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
        setKebabVisible(false);
    }, []);

    const handleMenuOpen = useCallback(() => {
        setMenuOpen(true);
        setKebabVisible(true);
    }, []);

    const handleEditMeetingDateClick = useCallback((event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMenuOpen(false);
        setKebabVisible(false);
        setEditMeetingDateDialogOpen(true);
    }, []);

    const allMeetingDates = useMemo(() => props.allMeetings.filter(m => m.id !== meeting.id).map(m => m.date), [meeting.id, props.allMeetings]);

    return (
        <Fragment>
            <DataTableRow
                onClick={handleRowClick}
                className={`clickable ${styles.row}`}
            >
                <DataTableCell className={`${styles.meetingCell} ${depth[`depth${props.depth}`]}`}>
                    <ExpandableToggleButton
                        open={groupOpen}
                        setOpen={setGroupOpen}
                    />
                    {formatMeetingDate(meeting.date)}
                </DataTableCell>
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell className={styles.kebabCell}>
                    <MenuSurfaceAnchor>
                        <IconButton
                            icon={IconName.MoreVert}
                            onClick={handleMenuOpen}
                            className={classnames(styles.kebab, { [styles.visible]: kebabVisible })}
                        />
                        <Menu
                            anchorCorner="bottomLeft"
                            open={menuOpen}
                            onClose={handleMenuClose}
                            className={styles.menu}
                        >
                            <MenuItem
                                className={styles.menuItem}
                                onClick={handleEditMeetingDateClick}
                            >Edit Meeting Date</MenuItem>
                        </Menu>
                    </MenuSurfaceAnchor>
                    {
                        editMeetingDateDialogOpen &&
                        <div className={styles.dialog}>
                            <EditMeetingDateDialog
                                open={editMeetingDateDialogOpen}
                                setOpen={setEditMeetingDateDialogOpen}
                                initialDate={props.meeting.date}
                                meetingId={props.meeting.id}
                                clientId={props.clientId}
                                allMeetingDates={allMeetingDates}
                            />
                        </div>
                    }
                </DataTableCell>
            </DataTableRow>
            {
                groupOpen && iasList.map((ias) =>
                    <IasRow
                        key={ias.id}
                        ias={ias}
                        modelPortfolios={props.modelPortfolios}
                        assetClasses={props.assetClasses}
                        depth={props.depth + 1}
                        meetingId={meeting.id}
                        clientId={props.clientId}
                    />
                )
            }
        </Fragment>
    );
}