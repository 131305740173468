import { useCallback } from "react";
import { Button } from "rmwc";
import { useClearError } from "services/dataErrorService";
import { useKeyboardAwareNavigate } from "utils/UseKeyboardAwareNavigate";
import { FullScreenOverlay } from "./FullScreenOverlay";
import styles from "./NoAccessOverlay.module.scss";

export const NoAccessOverlay = () => {
    const navigate = useKeyboardAwareNavigate();
    const clearError = useClearError();
    const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        clearError();
        navigate(event, "/");
    }, [navigate, clearError]);

    return <FullScreenOverlay>
        <div>
            <div className={styles.iconRow}>
                <img
                    className={styles.icon}
                    alt="lock icon"
                    src="/lock.svg"
                />
            </div>
            <div className={styles.messageRow}>
                <div className={styles.text}>
                    <p>
                        You do not have access to this client.
                    </p>
                    <p>
                        To request access, please do so in NetSuite.
                    </p>
                </div>
            </div>

            <div className={styles.buttonContainer}>
                <Button
                    className={styles.button}
                    label="Go To Client List"
                    onClick={(event: React.MouseEvent<HTMLElement>) => handleOnClick(event)}
                />
            </div>
        </div>
    </FullScreenOverlay>;
};
