import { ClassifiedVisionPossession } from "components/client-page/createIas";
import { DataSource } from "constants/dataSource";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { VisionCategory } from "services/visionService";
import { filterMap } from "../../../../utils/ArrayUtils";
import { isSome } from "../../../../utils/isNullOrUndefined";

export function populateVisionSelectionsFromIas(categories: VisionCategory[], ias: IasDataModel, accountTypes: AccountTypeDataModel[]): ClassifiedVisionPossession[] {
    const visionPositionsInIas = ias.accounts.flatMap(account => account.positions.filter(p => p.dataSource === DataSource.Vision).map(position => ({account, position})));
    return categories.flatMap(c => c.subcategories.flatMap(s => filterMap(s.possessions, possession => {
        const found = visionPositionsInIas.find(p => p.position.sourceId === possession.id);
        if(!isSome(found)) {
            return null;
        }
        const { position, account } = found;
        const security = ias.securities.find(s => s.id === position.securityId);
        return {
            ...possession,
            segmentId: security?.segmentId,
            accountType: accountTypes.find(type => type.id === account.typeId),
            owner: account.owner,
            custodian: account.custodian,
            blendedFundPercentages: security?.blendedFundPercentages ?? [],
        };
    })))
        .filter(isSome);
}
