import { Fragment, useEffect, useState } from "react";
import { DataTable, DataTableBody,  DataTableCell, DataTableContent, DataTableRow, LinearProgress, Radio } from "rmwc";
import styles from "./BlackDiamondPortfolioRow.module.scss";
import NumberDisplay from "../../../../numeric-values/NumberDisplay";
import ExpandableToggleButton from "../../../../shared/ExpandableToggleButton";
import { BlackDiamondAccount } from "../../../../../dataModels/blackDiamond/account";
import { BlackDiamondPortfolio } from "../../../../../dataModels/blackDiamond/portfolio";
import { useAccountsQuery, useHoldingsQuery } from "services/blackDiamondService";
import { PortfolioIdSelection } from "./PortfolioSelection";
import { ValeoChip } from "components/shared/ValeoChip";
import { isConnectionFailure } from "services/ConnectionFailure";

export interface BlackDiamondPortfolioRowProps {
    portfolio: BlackDiamondPortfolio;
    selectedPortfolioId: PortfolioIdSelection;
    setSelectedPortfolioId: (id: string | null) => void;
    relationshipId: string;
    clientReportingName: string;
    reportConnectionFailure: VoidFunction;
}

export function BlackDiamondPortfolioRow(props: BlackDiamondPortfolioRowProps) {
    const [open, setOpen] = useState(false);

    const BlackDiamondAccountHoldings = (props: { account: BlackDiamondAccount, reportConnectionFailure: VoidFunction }) => {
        const { status: holdingsStatus, data: holdings } = useHoldingsQuery(props.account.id);

        useEffect(() => {
            if (isConnectionFailure(holdings)) {
                props.reportConnectionFailure();
            }
        }, [holdings, props]);

        if (holdingsStatus === "success" && !isConnectionFailure(holdings)) {
            return <DataTableCell className={styles.accountValue}>
                <NumberDisplay value={holdings.reduce((sum, { marketValue }) => sum + marketValue, 0)} />
            </DataTableCell>;
        }
        return <DataTableCell className={styles.loadingAccountValue}>
            <LinearProgress className={styles.loading} />
        </DataTableCell>;
    };

    const BlackDiamondAccounts = () => {
        const { status: accountsStatus, data: accounts } = useAccountsQuery(props.relationshipId, props.portfolio.id);

        useEffect(() => {
            if (isConnectionFailure(accounts)) {
                props.reportConnectionFailure();
            }
        }, [accounts]);

        if (accountsStatus === "success" && !isConnectionFailure(accounts)) {
            return <DataTableRow className={styles.accounts}>
                <DataTableCell>
                    <DataTable>
                        <DataTableContent>
                            <DataTableBody>
                                {
                                    accounts.slice().sort((a, b) => a.name.localeCompare(b.name)).map((account) => <DataTableRow
                                        className={styles.account}
                                        key={`${props.portfolio.id}|${account.name}`}
                                        data-testid="black-diamond-account-row"
                                    >
                                        <DataTableCell className={styles.accountInfo}>
                                            <div>{account.name}  |  {account.owner.split(" ")[0]}  |  {account.custodian}  |  {account.type}</div>
                                        </DataTableCell>
                                        <BlackDiamondAccountHoldings
                                            account={account}
                                            reportConnectionFailure={props.reportConnectionFailure}
                                        />
                                    </DataTableRow>)
                                }
                            </DataTableBody>
                        </DataTableContent>
                    </DataTable>
                </DataTableCell>
            </DataTableRow>;
        }
        return <DataTableRow className={styles.loadingAccounts}>
            <DataTableCell>
                <LinearProgress className={styles.loading} />
            </DataTableCell>
        </DataTableRow>;
    };

    return <Fragment>
        <DataTableRow
            className={`${styles.portfolio} clickable`}
            key={props.portfolio.id}
            onClick={() => setOpen(!open)}
            data-testid="portfolio row"
        >
            <DataTableCell className={styles.chevron}>
                <ExpandableToggleButton
                    open={open}
                    setOpen={setOpen}
                />
            </DataTableCell>
            <DataTableCell className={styles.portfolioName}>
                <Radio
                    value={props.portfolio.name}
                    checked={props.selectedPortfolioId.selectionMade && props.selectedPortfolioId.value === props.portfolio.id}
                    onClick={
                        (e) => {
                            e.stopPropagation();
                        }
                    }
                    onChange={
                        () => {
                            props.setSelectedPortfolioId(props.portfolio.id);
                        }
                    }
                    data-testid={`${props.portfolio.name} radio button`}
                />
                <span>{props.portfolio.name}</span>
            </DataTableCell>
            <DataTableCell />
            <DataTableCell className={styles.primaryPortfolio}>
                {props.portfolio.isPrimary && <ValeoChip label="Primary Portfolio" />}
            </DataTableCell>
        </DataTableRow>
        {open && <BlackDiamondAccounts />}
    </Fragment>;
}