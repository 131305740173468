import { useMemo, useState } from "react";
import { useMeetingMutation } from "services/meetingService";
import { ValeoDialog } from "../shared/ValeoDialog";
import { ValeoDatePicker } from "../shared/ValeoDatePicker";
import styles from "./EditMeetingDateDialog.module.scss";
import { areDatesOnlyEqual } from "utils/DateUtils";

interface EditMeetingDateDialogProps {
    meetingId: number;
    clientId: number;
    open: boolean;
    setOpen: BooleanCallback;
    initialDate: Date;
    allMeetingDates: Date[];
}

export function EditMeetingDateDialog(props: EditMeetingDateDialogProps) {
    const mutation = useMeetingMutation(props.meetingId, props.clientId);

    const [date, setDate] = useState(props.initialDate);

    const initialDateIsSelected = useMemo(() => areDatesOnlyEqual(date, props.initialDate), [date, props.initialDate]);

    const dateAlreadyExists = useMemo(() => props.allMeetingDates.some(d => d.getTime() === date.getTime()), [date, props.allMeetingDates]);

    const handleAccept = () => {
        if (!initialDateIsSelected) {
            mutation.mutate(date);
        }
    };

    return (
        <ValeoDialog
            title="Edit Meeting Date"
            width={448}
            fixedHeight={482}
            open={props.open}
            setOpen={props.setOpen}
            proceedDisabled={initialDateIsSelected || dateAlreadyExists}
            extraAcceptInput={dateAlreadyExists && <span className={styles.helpText}>The client already has a meeting on this date.</span>}
            onAccept={handleAccept}
            renderToPortal
        >
            <div className={styles.dialogContent}>
                <ValeoDatePicker
                    date={date}
                    setDate={setDate}
                />
            </div>
        </ValeoDialog>
    );
}