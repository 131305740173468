import { Fragment, useMemo } from "react";
import styles from "./BDHoldingsClassificationPage.module.scss";
import { BackNextFooter } from "../../../../shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "../../../../shared/modal/footer/ModalActionButton";
import { BlackDiamondHolding } from "../../../../../dataModels/blackDiamond/holding";
import { IasBaseModalContent } from "../IasBaseModalContent";
import { isNumber } from "utils/NumberUtils";
import { BDHoldingsClassificationTable } from "./BDHoldingsClassificationTable";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { PopulatedAccount } from "services/blackDiamondService";

export interface BDHoldingsClassificationPageProps {
    stepper: JSX.Element;
    onCancel: VoidFunction;
    onBack: VoidFunction;
    onNext: VoidFunction;
    assetClasses: AssetClassDataModel[];
    blackDiamondAccounts: PopulatedAccount[];
    holdingsToClassify: BlackDiamondHolding[];
    setHoldingsToClassify: Callback<BlackDiamondHolding[]>;
    overrideNextButtonLabel?: string;
}

export function BDHoldingsClassificationPage(props: BDHoldingsClassificationPageProps) {
    const { stepper, onCancel, onBack, onNext, assetClasses, blackDiamondAccounts, holdingsToClassify, setHoldingsToClassify } = props;

    const hasUnclassifiedHoldings = useMemo(() => holdingsToClassify.some(holding => !isNumber(holding.segmentId)), [holdingsToClassify]);

    return <IasBaseModalContent
        onCancel={onCancel}
        content={
            <Fragment>
                {stepper}
                <BDHoldingsClassificationTable
                    blackDiamondAccounts={blackDiamondAccounts}
                    holdingsToClassify={holdingsToClassify}
                    setHoldingsToClassify={setHoldingsToClassify}
                    assetClasses={assetClasses}
                />
            </Fragment>
        }
        footer={
            <BackNextFooter
                backBtn={
                    <ModalActionButton
                        label="Back"
                        isDefaultAction={true}
                        onClick={onBack}
                    />
                }
                nextBtn={
                    <ModalActionButton
                        label={props.overrideNextButtonLabel ?? "Next"}
                        isDefaultAction={true}
                        onClick={onNext}
                        disabled={hasUnclassifiedHoldings}
                    />
                }
            >
                {
                    hasUnclassifiedHoldings && <div className={styles.helpTextContainer}>
                        <span className={styles.helpText}>All assets/entities above need to be classified to continue.</span>
                    </div>
                }
            </BackNextFooter>
        }
    />;
}
