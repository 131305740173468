import classnames from "classnames";
import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import { Dialog, DialogProps } from "rmwc";
import styles from "./BaseModal.module.scss";

export interface BaseModalProps extends DialogProps, PropsWithChildren {
    className?: string;
}

export const BaseModal = forwardRef((props: BaseModalProps, ref: ForwardedRef<HTMLElement>) => {
    const { children, ...nativeProps } = props;
    const { className } = nativeProps;

    return <Dialog
        {...nativeProps}
        preventOutsideDismiss
        className={classnames(styles.dialog, className)}
        ref={ref}
    >
        {children}
    </Dialog>;
});