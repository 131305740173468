import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { NextFooter } from "components/shared/modal/footer/NextFooter";
import { BackNextFooter } from "components/shared/modal/footer/BackNextFooter";
import { Fragment } from "react";
import styles from "./EditSecurityModalFooter.module.scss";

export interface EditSecurityModalFooterProps {
    isBlended: boolean;
    isEditingBlend: boolean;
    isPreBlendValid: boolean;
    isPreBlendModified: boolean;
    isBlendValid: boolean;
    isBlendModified: boolean;
    startEditingBlend: VoidFunction;
    stopEditingBlend: VoidFunction;
    saveChanges: VoidFunction;
    securityAlreadyExists: boolean;
}

export function EditSecurityModalFooter(props: EditSecurityModalFooterProps) {
    const { isBlended, isEditingBlend, isPreBlendValid, isPreBlendModified, isBlendValid, isBlendModified, startEditingBlend, stopEditingBlend, saveChanges } = props;

    const acceptDisabled = !(isPreBlendValid && (isBlended || isPreBlendModified)) || props.securityAlreadyExists;

    return <Fragment>
        {
            isEditingBlend
                ? <BackNextFooter
                    backBtn={
                        <ModalActionButton
                            label="Back"
                            isDefaultAction={false}
                            onClick={stopEditingBlend}
                        />
                    }
                    nextBtn={
                        <ModalActionButton
                            label="Save"
                            isDefaultAction={false}
                            onClick={saveChanges}
                            disabled={!(isBlendValid && (isPreBlendModified || isBlendModified))}
                        />
                    }
                />
                : <NextFooter
                    nextBtn={
                        <ModalActionButton
                            label={isBlended ? "Next" : "Save"}
                            isDefaultAction={false}
                            onClick={isBlended ? startEditingBlend : saveChanges}
                            disabled={acceptDisabled}
                        />
                    }
                >
                    {props.securityAlreadyExists && <span className={styles.helpText}>This security already exists.</span>}
                </NextFooter>
        }
    </Fragment>;
}