import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow, Elevation } from "rmwc";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { RecommendedFundTargetDataModel } from "dataModels/modelPortfolioDataModel";
import { isString } from "utils/StringUtils";
import { SegmentDataModel } from "dataModels/segmentDataModel";
import { SegmentGroupDataModel } from "dataModels/segmentGroupDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import NumberDisplay from "components/numeric-values/NumberDisplay";
import { ModelPortfolioViewModel } from "../asset-table/view-models/ModelPortfolioViewModel";
import PercentageDisplay from "components/numeric-values/PercentageDisplay";
import { Fragment, useContext } from "react";
import styles from "./RecommendedFundsTable.module.scss";
import classnames from "classnames";
import { WorkspaceContext } from "contexts/workspaceContext";

interface RecommendedFundsTableProps {
    viewModel: AssetTablesViewModel;
}

interface AssetClassDisplay {
    assetClass: string;
    recommendedFunds: RecommendedFundDisplay[];
}

interface RecommendedFundDisplay {
    segmentOrGroupName: string;
    ticker: string;
    targetPercentage: number;
    proposedValue: number;
    proposedPercentage: number;
    targetValue: number;
}

function setupRow(assetClassTarget: number, segmentOrGroupName: string, recommendedFund: RecommendedFundTargetDataModel): RecommendedFundDisplay {
    return {
        segmentOrGroupName,
        ticker: recommendedFund.ticker,
        targetPercentage: recommendedFund.targetPercentage * assetClassTarget / 100,
        proposedValue: NaN,
        targetValue: NaN,
        proposedPercentage: NaN,
    };
}

function getRowsForSegment(assetClassTarget: number, groupPrefix: string, segment: SegmentDataModel, recommendedFunds: RecommendedFundTargetDataModel[]) {
    const relevantFunds = recommendedFunds
        .filter(fund => fund.segmentId === segment.id && isString(fund.ticker))
        .sort((a, b) => a.ticker.localeCompare(b.ticker));
    return relevantFunds.map(fund => setupRow(assetClassTarget, `${groupPrefix}${segment.name}`, fund));
}

function getRowsForGroup(assetClassTarget: number, group: SegmentGroupDataModel, recommendedFunds: RecommendedFundTargetDataModel[]) {
    const relevantFunds = recommendedFunds
        .filter(fund => fund.segmentGroupId === group.id && isString(fund.ticker))
        .sort((a, b) => a.ticker.localeCompare(b.ticker));
    const rows = relevantFunds.map(fund => setupRow(assetClassTarget, group.name, fund));

    group.segments.forEach(segment => {
        rows.push(...getRowsForSegment(assetClassTarget, `${group.name} - `, segment, recommendedFunds));
    });

    return rows;
}

function getRowsForAssetClass(assetClass: AssetClassDataModel, modelPortfolio: ModelPortfolioViewModel): AssetClassDisplay {
    const assetClassTarget = modelPortfolio.getTargetForAssetClass(assetClass.id);
    const fundRows = [
        ...assetClass.segmentGroups.flatMap(group => getRowsForGroup(assetClassTarget, group, modelPortfolio.recommendedFunds())),
        ...assetClass.segments.flatMap(segment => getRowsForSegment(assetClassTarget, "", segment, modelPortfolio.recommendedFunds())),
    ];
    return {
        assetClass: assetClass.name,
        recommendedFunds: fundRows,
    };
}

export function RecommendedFundsTable(props: RecommendedFundsTableProps) {
    const toDisplay = [
        getRowsForAssetClass(props.viewModel.cash.assetClass, props.viewModel.modelPortfolio),
        getRowsForAssetClass(props.viewModel.fixedIncome.assetClass, props.viewModel.modelPortfolio),
        getRowsForAssetClass(props.viewModel.equities.assetClass, props.viewModel.modelPortfolio),
        getRowsForAssetClass(props.viewModel.alternatives.assetClass, props.viewModel.modelPortfolio),
    ].filter(item => item.recommendedFunds.length > 0);
    const flattened = toDisplay.flatMap(item => item.recommendedFunds);

    flattened.forEach(displayItem => {
        const proposedValue = props.viewModel.allSegments()
            .flatMap(segment => segment.securities)
            .filter(security => security.security.tickerSymbol === displayItem.ticker)
            .flatMap(security => security.accountPositions)
            .map(position => position.proposedValue())
            .reduce((previous, current) => previous + current, 0);

        displayItem.proposedValue = proposedValue;
        displayItem.targetValue = displayItem.targetPercentage / 100 * props.viewModel.currentValue();
        displayItem.proposedPercentage = props.viewModel.proposedValue() !== 0
            ? proposedValue / props.viewModel.proposedValue() * 100
            : 0;
    });

    const context = useContext(WorkspaceContext);

    return <Elevation
        z={2}
        wrap
    >
        <DataTable
            className={classnames(styles.table, {[styles.wide]: !context.sidebarOpen}, {[styles.narrow]: context.sidebarOpen})}
            data-testid="recommended-fund-table"
        >
            <DataTableContent>
                <DataTableHead>
                    <DataTableRow>
                        <DataTableHeadCell>Asset Class</DataTableHeadCell>
                        <DataTableHeadCell>Ticker</DataTableHeadCell>
                        <DataTableHeadCell className={styles.valueColumn}>Proposed</DataTableHeadCell>
                        <DataTableHeadCell
                            className={styles.valueColumn}
                            colSpan={2}
                        >Target</DataTableHeadCell>
                        <DataTableHeadCell
                            className={styles.valueColumn}
                            colSpan={2}
                        >Variance</DataTableHeadCell>
                    </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                    {
                        toDisplay.map((assetClassItem, assetIndex) => <Fragment key={assetIndex}>
                            <DataTableRow>
                                <DataTableCell
                                    className={styles.assetClass}
                                    colSpan={7}
                                >
                                    {assetClassItem.assetClass}
                                </DataTableCell>
                            </DataTableRow>
                            {
                                assetClassItem.recommendedFunds.map((displayItem, index) => 
                                    <DataTableRow
                                        key={index}
                                        data-testid="recommended-fund-table-row"
                                    >
                                        <DataTableCell className={styles.segment}>{displayItem.segmentOrGroupName}</DataTableCell>
                                        <DataTableCell className={classnames(styles.ticker, styles.rightBorder)}>{displayItem.ticker}</DataTableCell>
                                        <DataTableCell className={styles.value}><NumberDisplay value={displayItem.proposedValue} /></DataTableCell>
                                        <DataTableCell className={classnames(styles.percentage, styles.rightBorder)}>
                                            <PercentageDisplay
                                                value={displayItem.proposedPercentage}
                                                precision={1}
                                            />
                                        </DataTableCell>
                                        <DataTableCell className={styles.value}><NumberDisplay value={displayItem.targetValue} /></DataTableCell>
                                        <DataTableCell className={classnames(styles.percentage, styles.rightBorder)}>
                                            <PercentageDisplay
                                                value={displayItem.targetPercentage}
                                                precision={1}
                                            />
                                        </DataTableCell>
                                        <DataTableCell className={styles.value}><NumberDisplay value={displayItem.proposedValue - displayItem.targetValue} /></DataTableCell>
                                    </DataTableRow>
                                )
                            }
                        </Fragment>)
                    }
                </DataTableBody>
            </DataTableContent>
        </DataTable>
    </Elevation>;
}