import { isSome } from "utils/isNullOrUndefined";

export interface TokenRevokedError {
    isTokenRevokedError: true;
}

export function isTokenRevokedError(t: object | null | undefined): t is TokenRevokedError {
    return isSome(t) && "isTokenRevokedError" in t && typeof(t.isTokenRevokedError) === "boolean" && t.isTokenRevokedError;
}

export function createTokenRevokedError(): TokenRevokedError {
    return {
        isTokenRevokedError: true
    };
}