import { AccountDataModel } from "dataModels/accountDataModel";
import { sum } from "lodash";

export function normalizeAccountNumber(accountNumber : string) {
    // Address normal differences between Vision and BD account numbers. This does have the
    // risk of having false positives, but that is outweighed by the number of places where
    // the accounts really are the same but some contain dashes.
    // 
    // Also uppercase the accounts for case insenstive matching.
    return accountNumber.replaceAll("-", "").toLocaleUpperCase();
}

interface GenericAccount {
    accountNumber: string,
    custodian: string,
    owner: string,
    typeId: number
}

export function accountsMatch(a: GenericAccount, b: GenericAccount) {
    return a.accountNumber === b.accountNumber
    && a.custodian === b.custodian
    && a.owner === b.owner
    && a.typeId === b.typeId;
}

export function formatAccountNumber(accountNumber: string) {
    return `x${accountNumber.substring(accountNumber.length - 4).toLocaleUpperCase()}`;
}

export function totalValue(accounts: AccountDataModel[] | undefined) {
    if(accounts === undefined) {
        return undefined;
    }
    return sum(accounts.flatMap(account => account.positions.flatMap(position => position.overwrittenCurrentValue ?? position.currentValue)));
}