import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useIasCustomModelPortfolioMutation, useIasMutation } from "services/iasService";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { Checkbox } from "rmwc";
import styles from "./EditIasModelPortfolioDialog.module.scss";
import { useClientMutation, useClientCustomModelPortfolioMutation } from "services/clientService";
import { EditModelPortfolioDialog } from "components/shared/modelPortfolio/EditModelPortfolioDialog";
import { CustomModelPortfolioDataModel, ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { createEditModelPortfolioViewModel } from "../asset-table/view-models/EditModelPortfolioViewModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";
import { ModelPortfolioChangeType } from "utils/TargetsUtils";
import { useEditCustomSegmentNames } from "components/shared/custom-segment-name/useEditCustomSegmentNames";

export interface EditIasModelPortfolioDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    modelPortfolios: ModelPortfolioDataModel[];
    ias: IasDataModel;
    readonly: boolean;
    totalIasValue: number;
}

export function EditIasModelPortfolioDialog(props: EditIasModelPortfolioDialogProps) {
    const { open, setOpen, modelPortfolios, ias } = props;

    const iasMutation = useIasMutation(ias.id);
    const iasCmpMutation = useIasCustomModelPortfolioMutation(ias.id);
    const clientMutation = useClientMutation(ias.clientId);
    const clientCmpMutation = useClientCustomModelPortfolioMutation(ias.clientId);

    const { assetClasses, customSegmentNames, customSegmentNamesAreValid, customSegmentNamesAreModified, editCustomSegmentNamesProps,
        customSegmentGroupNames, customSegmentGroupNamesAreValid, customSegmentGroupNamesAreModified } = useEditCustomSegmentNames(ias.assetClasses);

    const [changeType, setChangeType] = useState(ModelPortfolioChangeType.None);

    const propsTargets: TargetsDataModel = {
        modelPortfolio: ias.modelPortfolio,
        customModelPortfolio: ias.customModelPortfolio,
        cashTarget: ias.cashTarget,
        cashTargetInDollars: ias.cashTargetInDollars,
        alternativesTarget: ias.alternativesTarget,
        alternativesTargetInDollars: ias.alternativesTargetInDollars,
        cashCarveOut: ias.cashCarveOut,
        fixedIncomeCarveOut: ias.fixedIncomeCarveOut,
        equitiesCarveOut: ias.equitiesCarveOut,
        modelPortfolioTargets: ias.modelPortfolioTargets,
    };
    const [originalTargets] = useState(propsTargets);
    const [targets, setTargets] = useState(propsTargets);

    const patchTargets = useCallback((targetsUpdates: Partial<TargetsDataModel>) => {
        setTargets((targets: TargetsDataModel) => ({
            ...targets,
            ...targetsUpdates,
        }));
    }, []);

    const patchCustomModelPortfolio = useCallback((cmpUpdates: Partial<CustomModelPortfolioDataModel>) => {
        setTargets((targets: TargetsDataModel) => ({
            ...targets,
            ...(targets.customModelPortfolio && {
                customModelPortfolio: {
                    ...targets.customModelPortfolio,
                    ...cmpUpdates,
                },
            }),
        }));
    }, []);

    const [modelPortfolio, setModelPortfolio] = useState(() => createEditModelPortfolioViewModel(targets, modelPortfolios, assetClasses, originalTargets, props.totalIasValue));
    useEffect(() => {
        setModelPortfolio(createEditModelPortfolioViewModel(targets, modelPortfolios, assetClasses, originalTargets, props.totalIasValue));
    }, [assetClasses, targets, modelPortfolios, originalTargets, props.totalIasValue]);

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [makeDefaultPortfolio, setMakeDefaultPortfolio] = useState(true);

    const onAccept = useCallback(() => {
        const customSegmentNamesUpdates = customSegmentNamesAreModified
            ? { customSegmentNames }
            : {};
        const customSegmentGroupNamesUpdates = customSegmentGroupNamesAreModified ? { customSegmentGroupNames } : {};
        const customModelPortfolio = modelPortfolio.customModelPortfolioInUse();
        if (customModelPortfolio) {
            iasCmpMutation.mutate({
                customModelPortfolio,
                ...customSegmentNamesUpdates,
                ...customSegmentGroupNamesUpdates,
                lastModifiedDate: ias.dateModified,
            });

            if (makeDefaultPortfolio) {
                clientCmpMutation.mutate({
                    customModelPortfolio,
                    customSegmentNames,
                    customSegmentGroupNames
                });
            }
        } else {
            iasMutation.mutate({
                ...targets,
                ...(targets.modelPortfolio && { modelPortfolioId: targets.modelPortfolio.id }),
                ...customSegmentNamesUpdates,
                ...customSegmentGroupNamesUpdates,
                lastModifiedDate: ias.dateModified,
            });

            if (makeDefaultPortfolio) {
                clientMutation.mutate({
                    ...targets,
                    customSegmentNames,
                    customSegmentGroupNames
                });
            }
        }
    }, [customSegmentNamesAreModified, customSegmentNames, customSegmentGroupNamesAreModified, customSegmentGroupNames, modelPortfolio, iasCmpMutation, ias.dateModified, makeDefaultPortfolio, clientCmpMutation, iasMutation, targets, clientMutation]);

    const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMakeDefaultPortfolio(event.currentTarget.checked);
    };

    const extraInput = useMemo(() => {
        switch (changeType) {
        case ModelPortfolioChangeType.Values:
            return <Checkbox
                className={styles.checkbox}
                label="Make client default portfolio"
                checked={makeDefaultPortfolio}
                onChange={onCheckboxChange}
            />;
        case ModelPortfolioChangeType.TargetsOnly:
            return <span
                className={styles.changedTargetsMessage}
                data-testid="targets-change-message"
            >
                This model changed. Please check the new values before saving.
            </span>;
        default:
            return <Fragment />;
        }
    }, [changeType, makeDefaultPortfolio]);

    return <EditModelPortfolioDialog
        open={open}
        setOpen={setOpen}
        onAccept={onAccept}
        isSaveEnabled={isSaveEnabled}
        setIsSaveEnabled={setIsSaveEnabled}
        setChangeType={setChangeType}
        modelPortfolio={modelPortfolio}
        patchTargets={patchTargets}
        patchCustomModelPortfolio={patchCustomModelPortfolio}
        extraAcceptInput={isSaveEnabled && extraInput}
        modelPortfolios={modelPortfolios}
        assetClasses={assetClasses}
        readonly={props.readonly}
        customSegmentNamesAreValid={customSegmentNamesAreValid}
        customSegmentNamesAreModified={customSegmentNamesAreModified}
        assumedTotalPortfolioValue={props.totalIasValue}
        customSegmentGroupNamesAreValid={customSegmentGroupNamesAreValid}
        customSegmentGroupNamesAreModified={customSegmentGroupNamesAreModified}
        {...editCustomSegmentNamesProps}
    />;
}