import { DataTableCell, DataTableRow } from "rmwc";
import { Fragment, useState } from "react";
import { MeetingDataModel } from "../../dataModels/meetingDataModel";
import depth from "./Depth.module.scss";
import styles from "./ArchiveGroup.module.scss";
import YearGroup from "./YearGroup";
import ExpandableToggleButton from "../shared/ExpandableToggleButton";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";

interface ArchiveGroupProps {
    meetings: MeetingDataModel[];
    modelPortfolios: ModelPortfolioDataModel[];
    assetClasses: AssetClassDataModel[];
    clientId: number;
    allMeetings: MeetingDataModel[];
}

export default function ArchiveGroup(props: ArchiveGroupProps) {
    const meetings = props.meetings;

    const [open, setOpen] = useState(false);

    const years = meetings.map((meeting) => new Date(meeting.date).getFullYear());
    const uniqueYears = Array.from(new Set(years));

    return (
        <Fragment>
            <DataTableRow
                className="clickable"
                onClick={() => setOpen(!open)}
            >
                <DataTableCell className={`${styles.archiveCell} ${depth.depth0}`}>
                    <ExpandableToggleButton
                        open={open}
                        setOpen={setOpen}
                    />
                    Archive
                </DataTableCell>
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
                <DataTableCell />
            </DataTableRow>
            {
                open && uniqueYears.map((year) =>
                    <YearGroup
                        key={year}
                        year={year}
                        meetings={meetings.filter((meeting) => new Date(meeting.date).getFullYear() === year)}
                        modelPortfolios={props.modelPortfolios}
                        assetClasses={props.assetClasses}
                        depth={1}
                        clientId={props.clientId}
                        allMeetings={props.allMeetings}
                    />
                )
            }
        </Fragment>
    );
}