import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow } from "rmwc";
import sharedStyles from "./ModelPortfolioTargetsTable.module.scss";
import styles from "./CustomModelPortfolioTable.module.scss";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { CustomModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { cloneDeep, zip } from "lodash";
import { toTestId } from "utils/StringUtils";
import { ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { AssetClassesViewModel, createAssetClassesViewModel } from "viewModels/AssetClassesViewModel";
import { AssetClassViewModel } from "viewModels/AssetClassViewModel";
import { CustomModelPortfolioTableSegmentOrGroupCell } from "./CustomModelPortfolioTableSegmentOrGroupCell";
import { EditCustomSegmentNamesProps } from "../custom-segment-name/useEditCustomSegmentNames";
import { ModelPortfolioTableSegmentOrGroupViewModel } from "./ModelPortfolioTableSegmentOrGroupCell";
import { ModelPortfolioTableAssetClassHeaderCell } from "./ModelPortfolioTableAssetClassHeaderCell";

interface CustomModelPortfolioTableProps extends EditCustomSegmentNamesProps {
    modelPortfolio: ModelPortfolioViewModel;
    assetClasses: AssetClassDataModel[];
    customModelPortfolio: CustomModelPortfolioDataModel;
    readonly: boolean;
    setEvenMoreExtraAcceptInput: (input: JSX.Element | null) => void;
    setInputValid: (isValid: boolean) => void;
    setCustomModelPortfolio: (cmp: CustomModelPortfolioDataModel) => void;
}

export function CustomModelPortfolioTable(props: CustomModelPortfolioTableProps) {
    const { modelPortfolio, setInputValid, setEvenMoreExtraAcceptInput } = props;

    const assetClasses: AssetClassesViewModel = useMemo(() => createAssetClassesViewModel(props.assetClasses), [props.assetClasses]);

    const cmpAssetClasses = useMemo(() => ({
        cash: createCmpAssetClass(modelPortfolio, assetClasses.cash, props.customModelPortfolio, props.setCustomModelPortfolio),
        fixedIncome: createCmpAssetClass(modelPortfolio, assetClasses.fixedIncome, props.customModelPortfolio, props.setCustomModelPortfolio),
        equities: createCmpAssetClass(modelPortfolio, assetClasses.equities, props.customModelPortfolio, props.setCustomModelPortfolio),
        alternatives: createCmpAssetClass(modelPortfolio, assetClasses.alternatives, props.customModelPortfolio, props.setCustomModelPortfolio),
    }), [assetClasses, modelPortfolio, props.customModelPortfolio, props.setCustomModelPortfolio]);

    const [segmentValidityMap, setSegmentValidityMap] = useState(new Map<string, boolean>());
    const setSegmentValidity = useCallback((name: string, isValid: boolean) => {
        setSegmentValidityMap((map) => {
            map.set(name, isValid);
            return new Map(map);
        });
    }, []);

    useEffect(() => () => setEvenMoreExtraAcceptInput(null), [setEvenMoreExtraAcceptInput]);

    useEffect(() => {
        if (Array.from(segmentValidityMap).find(([_segmentId, isValid]) => !isValid)) {
            setInputValid(false);
            setEvenMoreExtraAcceptInput(null);
        }
        else if (cmpAssetClasses.cash.targetPercentage + cmpAssetClasses.fixedIncome.targetPercentage + cmpAssetClasses.equities.targetPercentage + cmpAssetClasses.alternatives.targetPercentage === 100) {
            setInputValid(true);
            setEvenMoreExtraAcceptInput(null);
        } else {
            setInputValid(false);
            setEvenMoreExtraAcceptInput(<span className={styles.warningLabel}>All four asset classes must equal 100%.</span>);
        }
    }, [segmentValidityMap, cmpAssetClasses.alternatives.targetPercentage, cmpAssetClasses.cash.targetPercentage, cmpAssetClasses.equities.targetPercentage, cmpAssetClasses.fixedIncome.targetPercentage, setEvenMoreExtraAcceptInput, setInputValid]);

    return <DataTable className={sharedStyles.table}>
        <DataTableContent>
            <DataTableHead>
                <DataTableRow>
                    <DataTableHeadCell>TARGET</DataTableHeadCell>
                    <DataTableHeadCell>TARGET</DataTableHeadCell>
                    <DataTableHeadCell>TARGET</DataTableHeadCell>
                    <DataTableHeadCell>TARGET</DataTableHeadCell>
                </DataTableRow>
                <CustomModelPortfolioAssetClassRow
                    {...props}
                    {...cmpAssetClasses}
                    setSegmentValidity={setSegmentValidity}
                />
            </DataTableHead>
            <DataTableBody>
                <CustomModelPortfolioSegmentRows
                    {...props}
                    {...cmpAssetClasses}
                    setSegmentValidity={setSegmentValidity}
                />
            </DataTableBody>
        </DataTableContent>
    </DataTable>;
}

interface CMPAssetClasses {
    cash: CustomModelPortfolioAssetClassViewModel;
    fixedIncome: CustomModelPortfolioAssetClassViewModel;
    equities: CustomModelPortfolioAssetClassViewModel;
    alternatives: CustomModelPortfolioAssetClassViewModel;
}

interface CMPAssetClassesDisplay extends CMPAssetClasses, EditCustomSegmentNamesProps {
    readonly: boolean;
    setSegmentValidity: (name: string, isValid: boolean) => void;
}

function CustomModelPortfolioSegmentRows(props: CMPAssetClassesDisplay) {
    return <Fragment>
        {
            zip(props.fixedIncome.segments, props.equities.segments, props.alternatives.segments)
                .map((rowData, index) => <CustomModelPortfolioSegmentRow
                    {...props}
                    rowData={rowData}
                    key={index}
                />)
        }
    </Fragment>;
}

interface CMPSegmentRowProps extends EditCustomSegmentNamesProps {
    rowData: (ModelPortfolioTableSegmentOrGroupViewModel | undefined)[];
    readonly: boolean;
    setSegmentValidity: (name: string, isValid: boolean) => void;
}

function CustomModelPortfolioSegmentRow(props: CMPSegmentRowProps) {
    return  <DataTableRow>
        <DataTableCell />
        <CustomModelPortfolioTableSegmentOrGroupCell
            {...props}
            cellData={props.rowData[0]}
        />
        <CustomModelPortfolioTableSegmentOrGroupCell
            {...props}
            cellData={props.rowData[1]}
        />
        <CustomModelPortfolioTableSegmentOrGroupCell
            {...props}
            cellData={props.rowData[2]}
        />
    </DataTableRow>;
}

function CustomModelPortfolioAssetClassRow(props: CMPAssetClassesDisplay) {
    const { cash, fixedIncome, equities, alternatives, setSegmentValidity } = props;

    return <DataTableRow>
        <ModelPortfolioTableAssetClassHeaderCell
            key={cash.name}
            name={cash.name}
            targetPercentage={cash.targetPercentage}
            segmentWithEditableTargetPercentage={cash.segments[0]}
            setSegmentValidity={setSegmentValidity}
            readonly={props.readonly}
        />
        {
            [fixedIncome, equities, alternatives].map(acvm => <ModelPortfolioTableAssetClassHeaderCell
                key={acvm.name}
                name={acvm.name}
                targetPercentage={acvm.targetPercentage}
                segmentWithEditableTargetPercentage={null}
                readonly={props.readonly}
                data-testid={`${toTestId(acvm.name)}-target`}
            />)
        }
        
    </DataTableRow>;
}

interface CustomModelPortfolioAssetClassViewModel {
    readonly name: string;
    readonly targetPercentage: number;
    readonly segments: ModelPortfolioTableSegmentOrGroupViewModel[];
}

function createCmpAssetClass(modelPortfolio: ModelPortfolioViewModel, assetClass: AssetClassViewModel, cmp: CustomModelPortfolioDataModel, setCmp: (cmp: CustomModelPortfolioDataModel) => void): CustomModelPortfolioAssetClassViewModel {
    const localGroups: ModelPortfolioTableSegmentOrGroupViewModel[] = assetClass.segmentGroups
        .map(segmentGroup => {
            return {
                name: segmentGroup.name,
                targetPercentage: modelPortfolio.getTargetForSegmentGroup(segmentGroup.id),
                onValidChange: (newTargetPercentage: number) => {
                    const newCmp = cloneDeep(cmp);
                    const newGroupTarget = newCmp.customSegmentGroupTargets.find(gt => gt.segmentGroupId === segmentGroup.id);
                    if (newGroupTarget) {
                        newGroupTarget.targetPercentage = newTargetPercentage;
                    } else {
                        newCmp.customSegmentGroupTargets.push({ segmentGroupId: segmentGroup.id, targetPercentage: newTargetPercentage });
                    }
                    setCmp(newCmp);
                },
                segmentWithCustomizableName: null,
                segmentGroupWithCustomizableName: segmentGroup
            };
        });
    const localSegments: ModelPortfolioTableSegmentOrGroupViewModel[] = assetClass.segments
        .map(segment => {
            return {
                name: segment.name,
                targetPercentage: modelPortfolio.getTargetForSegment(segment.id),
                onValidChange: (newTargetPercentage: number) => {
                    const newCmp = cloneDeep(cmp);
                    const newSegmentTarget = newCmp.customSegmentTargets.find(st => st.segmentId === segment.id);
                    if (newSegmentTarget) {
                        newSegmentTarget.targetPercentage = newTargetPercentage;
                    } else {
                        newCmp.customSegmentTargets.push({ segmentId: segment.id, targetPercentage: newTargetPercentage });
                    }
                    setCmp(newCmp);
                },
                segmentWithCustomizableName: segment,
                segmentGroupWithCustomizableName: null
            };
        });
    return {
        name: assetClass.assetClass.name,
        segments: localGroups.concat(localSegments),
        targetPercentage: modelPortfolio.getTargetForAssetClass(assetClass.assetClass.id),
    };
}