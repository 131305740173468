import classnames from "classnames";
import { ChangeEvent, KeyboardEvent, useCallback } from "react";
import { Select, SelectHTMLProps, SelectProps } from "rmwc";
import { getCustomSelectEvent } from "utils/CustomSelect";
import { hasContent } from "utils/StringUtils";
import styles from "./ValeoSelect.module.scss";

interface ValeoSelectProps extends Omit<SelectProps, "value">, Omit<SelectHTMLProps, "value"> {
    value: string | null;
    onSelectIndex: (selectedIndex: number) => void;
    hideFloatingLabel?: boolean;
    "data-testid"?: string;
}

export function ValeoSelect(props: ValeoSelectProps) {
    const { onSelectIndex, hideFloatingLabel, ...selectProps } = props;
    const testId = props["data-testid"];

    const onChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const optionIndex = getCustomSelectEvent(event).currentTarget.index;
        onSelectIndex(optionIndex === -1
            ? 0 // keyboard navigation caused invalid event index -> fallback to selecting first option
            : optionIndex
        );
    }, [onSelectIndex]);

    const stopKeyEvents = useCallback((event: KeyboardEvent<HTMLSelectElement>) => {
        switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
            event.stopPropagation();
            break;
        }
    }, []);

    return <div
        className={styles.selectContainer}
        data-testid={testId}
    >
        <Select
            {...selectProps}
            value={hasContent(props.value) ? props.value : -1}
            onChange={onChange}
            onKeyDown={stopKeyEvents}
            onKeyDownCapture={stopKeyEvents}
            outlined
            enhanced
            className={
                classnames(styles.select, props.className, {
                    [styles.disabled]: props.disabled,
                    [styles.hideFloatingLabel]: hideFloatingLabel
                })
            }
            data-testid={`${testId}-menu`} // RMWC places data-testid on the menu, not the button
        />
    </div>;
}