import ReactDOM from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import authorizationService from "./services/authorizationService";
import { staticConfiguration } from "./services/configurationService";

const rootElement = document.getElementById("root");
if (!rootElement) {
    throw new Error("Root element not found.");
}

const root = ReactDOM.createRoot(rootElement);

if (staticConfiguration.useMsal()) {
    root.render(
        <MsalProvider instance={authorizationService.instance}>
            <App />
        </MsalProvider>
    );
} else {
    root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
