import { RecommendedFundTargetDataModel } from "dataModels/modelPortfolioDataModel";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";

export interface RecommendedFundViewModel extends RecommendedFundTargetDataModel {
    name: string | undefined;
}

export function createRecommendedFundViewModel(recommendedFund: RecommendedFundTargetDataModel, securities: SecurityLookupDataModel[]): RecommendedFundViewModel {
    return {
        ...recommendedFund,
        name: securities.find((security) => security.ticker === recommendedFund.ticker)?.name,
    };
}