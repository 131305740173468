import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import styles from "./TokenRevokedDialog.module.scss";
import { removeCachedCredentialsAndRedirect } from "services/authorizationService";

export function TokenRevokedDialog() {
    return <ConfirmationDialog
        title={<span className={styles.redWarning}>Login again to access Vision data</span>}
        confirmButton="Login"
        onConfirm={() => removeCachedCredentialsAndRedirect()}
        content=
            {
                <div>
                    <p>You need to re-authenticate to access Vision data. Please login again.</p>
                    <p>This process will temporarily leave the IAS tool to allow your login.</p>
                </div>
            }
    />;
}
