import styles from "./NotesCard.module.scss";
import { Card, Elevation } from "rmwc";
import { FormEvent, useContext, useState } from "react";
import { IasBasicInformation, useIasMutation } from "services/iasService";
import { debounce } from "lodash";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import { ValeoToggle } from "../../shared/ValeoToggle";
import { ValeoTextArea } from "../../shared/ValeoTextArea";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { isNumber } from "utils/NumberUtils";
import { isString } from "utils/StringUtils";
import { usePropWatch } from "utils/usePropWatch";

interface NotesCardProps {
    valeoNotes: string;
    clientNotes: string;
    ias?: IasDataModel;
}

const onChange = (iasId: number | undefined, data: IasBasicInformation,
    mutator: { mutate: (data: IasBasicInformation) => void }) => {
    if (isNumber(iasId)) {
        mutator.mutate(data);
    }
};

const debouncedOnChange = debounce(onChange, 750);

enum NoteTab {
    Valeo = "Internal Notes",
    Client = "Client Notes (Printed in PDF)"
}

export function NotesCard(props: NotesCardProps) {
    const { ias } = props;
    const context = useContext(WorkspaceContext);

    const [tab, setTab] = useState(NoteTab.Client);
    const [valeoNotes, setValeoNotes] = usePropWatch(props.valeoNotes);
    const [clientNotes, setClientNotes] = usePropWatch(props.clientNotes);

    const handleTabChange = (tab: number) => {
        tab === 0 ? setTab(NoteTab.Client) : setTab(NoteTab.Valeo);
        debouncedOnChange.flush();
    };

    const mutator = useIasMutation(ias?.id ?? 0);

    const handleOnInput = (event: FormEvent<HTMLInputElement>) => {
        if (!ias) return;
        const data = tab === NoteTab.Valeo ? { lastModifiedDate: ias.dateModified, valeoNotes: event.currentTarget.value } : { lastModifiedDate: ias.dateModified, clientNotes: event.currentTarget.value };
        if (isString(data.clientNotes)) {
            setClientNotes(data.clientNotes);
        }
        if (isString(data.valeoNotes)) {
            setValeoNotes(data.valeoNotes);
        }
        debouncedOnChange(ias.id, data, mutator);
    };

    return (<Elevation
        z={2}
        className={`${styles.cardElevation} ${styles.cardElevationHarder}`}
    >
        <Card className={styles.card}>
            <div className={styles.textArea}>
                <ValeoTextArea
                    disabled={context.readonlyMode}
                    label={tab}
                    value={tab === NoteTab.Valeo ? valeoNotes : clientNotes}
                    className={styles.valeoTextArea}
                    onInput={handleOnInput}
                />
            </div>
            <div className={styles.bottomRow}>
                <ValeoToggle
                    onActivate={(evt) => handleTabChange(evt.detail.index)}
                    tabOneLabel="Client"
                    tabTwoLabel="Internal"
                />
            </div>
        </Card>
    </Elevation>);
}