import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import { AccountType } from "constants/accountType";
import { IasDataModel } from "dataModels/iasDataModel";
import { Fragment } from "react";
import { sumValue } from "utils/ArrayUtils";
import styles from "./FinalConfirmationDialog.module.scss";

interface FinalConfirmationDialogProps {
    onConfirm: VoidFunction;
    onCancel: VoidFunction;
    ias: IasDataModel;
}

export function FinalConfirmationDialog(props: FinalConfirmationDialogProps) {
    const extraWarnings: string[] = [];
    if (props.ias.accounts.some(a => a.typeId === AccountType.Unknown)) {
        extraWarnings.push("Account Types marked 'Unknown'");
    }
    const assetClassTables = Array.from(document.querySelectorAll("[data-id=\"asset-class-table\"]"));
    if (assetClassTables.some(element => element.querySelector("[data-changevalid=\"false\"]") !== null)) {
        extraWarnings.push("Change column errors");
    }
    if (assetClassTables.some(element => element.querySelector("[data-currentvalid=\"false\"]") !== null)) {
        extraWarnings.push("Current column errors");
    }
    if (sumValue(props.ias.accounts.flatMap(a => a.positions), p => p.change) !== 0) {
        extraWarnings.push("Non-zero Excess / (Remaining) balance");
    }
    const finalizeConfirmText = <div>When you change the state to “Final”, this means you can not undo this state nor make any edits to this IAS without making a copy.</div>;

    const warnings = extraWarnings.length > 0 ? <div className={styles.warnings}>{"\nHere are some existing items:"}
        <ul>
            {
                extraWarnings.map(warning =>
                    <li key={warning}>{warning}</li>
                )
            }
        </ul>
    </div> : <Fragment />;

    return <ConfirmationDialog
        title="Are your changes complete and final?"
        confirmButton="FINALIZE"
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        content={<div>{finalizeConfirmText}{warnings}</div>}
    />;
}