import { VisionTable } from "components/client-page/add-ias-wizard/pages/vision/MoreVisionInfoPage/VisionTable";
import { BackNextFooter } from "components/shared/modal/footer/BackNextFooter";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { Fragment } from "react";
import { VisionPossession } from "services/visionService";
import { hasContent } from "utils/StringUtils";
import { UpdateDataWizardBaseModalContent } from "./UpdateDataWizardBaseModalContent";
import sharedStyles from "./UpdateDataWizardPage.module.scss";

interface UpdateMoreVisionInfoProps {
    onNext: VoidFunction;
    onBack: VoidFunction;
    onCancel: VoidFunction;
    selectedPossessions: Set<VisionPossession>;
    setSelectedPossessions: (possessions: Set<VisionPossession>) => void;
    hasNewBlendedAssetClasses: boolean;
}

export function UpdateMoreVisionInfo(props: UpdateMoreVisionInfoProps) {
    const {onNext, onBack, onCancel, selectedPossessions, setSelectedPossessions} = props;

    const allPossessionsComplete = Array.from(selectedPossessions).every(possession =>
        hasContent(possession.custodian) && hasContent(possession.owner) && hasContent(possession.accountType?.name)
    );

    return <UpdateDataWizardBaseModalContent
        onCancel={onCancel}
        content={
            <Fragment>
                <div className={sharedStyles.title}>Some additional Vision information is needed.</div>
                <VisionTable
                    selectedPossessions={selectedPossessions}
                    setSelectedPossessions={setSelectedPossessions}
                />
            </Fragment>
        }
        footer={
            <BackNextFooter
                nextBtn={
                    <ModalActionButton
                        isDefaultAction={true}
                        onClick={onNext}
                        disabled={!allPossessionsComplete}
                        label={props.hasNewBlendedAssetClasses ? "Next" : "Finish"}
                    />
                }
                backBtn={
                    <ModalActionButton
                        isDefaultAction={true}
                        onClick={onBack}
                        label="Back"
                    />
                }
            />
        }
    />;
}