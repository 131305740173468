import { CustomModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { intersectionWith, isEqual } from "lodash";

export function defaultCustomModelPortfolio(): CustomModelPortfolioDataModel {
    return {
        name: "",
        customSegmentGroupTargets: [],
        customSegmentTargets: [],
        baseModelPortfolioId: null
    };
}

export function customModelPortfoliosAreEqual(lhs: CustomModelPortfolioDataModel, rhs: CustomModelPortfolioDataModel) {
    return lhs.baseModelPortfolioId === rhs.baseModelPortfolioId &&
        lhs.name === rhs.name &&
        lhs.customSegmentGroupTargets.length === rhs.customSegmentGroupTargets.length &&
        lhs.customSegmentTargets.length === rhs.customSegmentTargets.length &&
        intersectionWith(lhs.customSegmentGroupTargets, rhs.customSegmentGroupTargets, isEqual ).length === lhs.customSegmentGroupTargets.length &&
        intersectionWith(lhs.customSegmentTargets, rhs.customSegmentTargets, isEqual).length === lhs.customSegmentTargets.length;
}