import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { ClientDataModel } from "dataModels/clientDataModel";
import { IasDataModel, MinimalIasDataModel } from "dataModels/iasDataModel";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { ConnectionFailure } from "services/ConnectionFailure";
import { TokenRevokedError } from "services/TokenRevokedError";
import { VisionResponse } from "services/visionService";
import { PartialQueryResult } from "../../../utils/PartialQueryResult";

export interface PreloadProps {
    visionQuery: PartialQueryResult<VisionResponse | ConnectionFailure | TokenRevokedError>;
    mostRecentlyFinalizedFullQuery: PartialQueryResult<IasDataModel>;
    accountTypesQuery: PartialQueryResult<AccountTypeDataModel[]>;
}

export interface MeetingDateProps {
    name: string;
    setName: Callback<string>;
    date: Date | undefined;
    setDate: Callback<Date | undefined>;
}

export interface AddIasWizardProps {
    setOpen: BooleanCallback;
    client: ClientDataModel;
    modelPortfolios: ModelPortfolioDataModel[];
    mostRecentlyFinalized?: MinimalIasDataModel
}

export const iasWizardStepNames: readonly string[] = [
    "Meeting Date",
    "Model Portfolio",
    "BD Portfolios",
    "Vision Assets",
];

export enum AddIasWizardViewControllerState {
    StartingPage,
    FastPass,
    Full,
    FastPassVisionConnectionFailed,
    FastPassBDConnectionFailed
}
