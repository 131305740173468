import { useCallback, useEffect, useMemo, useState } from "react";
import { BaseModal, BaseModalProps } from "components/shared/modal/BaseModal";
import { BaseModalContent } from "components/shared/modal/BaseModalContent";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { CancelHeader } from "components/shared/modal/header/CancelHeader";
import { IasDataModel } from "dataModels/iasDataModel";
import styles from "./EditSecurityModal.module.scss";
import { useSecurityMutation } from "services/securityService";
import { EditSecurityControls } from "./EditSecurityControls";
import { Segment } from "constants/segment";
import { EditBlendedSecurityControls } from "./EditBlendedSecurityControls";
import { BlendedFundPercentage } from "../../../shared/blended-fund/BlendedFundControls";
import { SecurityViewModel } from "../view-models/SecurityViewModel";
import { EditSecurityModalFooter } from "./EditSecurityModalFooter";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

export interface EditSecurityModalProps extends BaseModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    ias: IasDataModel;
    security: SecurityViewModel;
}

export function EditSecurityModal(props: EditSecurityModalProps) {
    const { setOpen, ias, ...nativeProps } = props;
    const { security, isInCustomGroup } = props.security;

    const assetClasses = ias.assetClasses;

    const { mutate } = useSecurityMutation(security.id, ias.id);

    const [name, setName] = useState(security.positionName);
    const [segmentId, setSegmentId] = useState(security.segmentId);
    const [blendedFundPercentages, setBlendedFundPercentages] = useState<BlendedFundPercentage[]>(security.blendedFundPercentages);

    const [isBlended, setIsBlended] = useState(false);
    const [isEditingBlend, setIsEditingBlend] = useState(false);
    const [isPreBlendValid, setIsPreBlendValid] = useState(true);
    const [isPreBlendModified, setIsPreBlendModified] = useState(false);
    const [isBlendValid, setIsBlendValid] = useState(true);
    const [isBlendModified, setIsBlendModified] = useState(false);

    const startEditingBlend = useCallback(() => {
        setIsEditingBlend(true);
    }, []);

    const stopEditingBlend = useCallback(() => {
        setIsEditingBlend(false);
    }, []);

    const saveChanges = useCallback(() => {
        if (segmentId !== security.segmentId) {
            telemetry.trackEvent(EventType.SecuritySegmentChanged);
        }

        if (segmentId === Segment.Blended) {
            telemetry.trackEvent(EventType.BlendedFundUsed);
        }

        mutate({
            positionName: name,
            segmentId,
            blendedFundPercentages: isBlended
                ? blendedFundPercentages.map(blend => {
                    const segmentId = blend.segmentId ?? -1; // save validation prevents default
                    return {
                        segmentId,
                        percentage: blend.percentage,
                    };
                })
                : [],
            lastModifiedDate: ias.dateModified,
        });
        setOpen(false);
    }, [blendedFundPercentages, ias.dateModified, isBlended, mutate, name, segmentId, setOpen, security.segmentId]);

    const closeModal = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useEffect(() => {
        setIsBlended(segmentId === Segment.Blended);
    }, [segmentId]);

    const securityAlreadyExists = useMemo(() =>
        ias.securities.some(s => s.id !== security.id && s.tickerSymbol === security.tickerSymbol && s.positionName === name),
    [ias.securities, name, security.id, security.tickerSymbol]);

    return <BaseModal
        {...nativeProps}
        onClose={closeModal}
        renderToPortal
    >
        <BaseModalContent
            data-testid="edit-security-modal"
            width={844}
            minHeight={316}
            className={styles.editSecurityModal}
            header={
                <CancelHeader
                    title={isInCustomGroup ? "Edit Name" : "Edit Name/Asset Class"}
                    cancelBtn={
                        <ModalActionButton
                            label="Cancel"
                            isDefaultAction={false}
                            onClick={closeModal}
                        />
                    }
                />
            }
            content={
                <div className={styles.modalContents}>
                    {
                        isEditingBlend
                            ? <div className={styles.blendedControls}>
                                <EditBlendedSecurityControls
                                    security={security}
                                    assetClasses={assetClasses}
                                    name={name}
                                    segmentId={segmentId}
                                    percentages={blendedFundPercentages}
                                    setPercentages={setBlendedFundPercentages}
                                    setIsValid={setIsBlendValid}
                                    setIsModified={setIsBlendModified}
                                />
                            </div>
                            : <EditSecurityControls
                                security={props.security}
                                assetClasses={assetClasses}
                                name={name}
                                setName={setName}
                                segmentId={segmentId}
                                setSegmentId={setSegmentId}
                                setIsValid={setIsPreBlendValid}
                                setIsModified={setIsPreBlendModified}
                            />
                    }
                </div>
            }
            footer={
                <EditSecurityModalFooter
                    isBlended={isBlended}
                    isEditingBlend={isEditingBlend}
                    isPreBlendValid={isPreBlendValid}
                    isPreBlendModified={isPreBlendModified}
                    isBlendValid={isBlendValid}
                    isBlendModified={isBlendModified}
                    startEditingBlend={startEditingBlend}
                    stopEditingBlend={stopEditingBlend}
                    saveChanges={saveChanges}
                    securityAlreadyExists={securityAlreadyExists}
                />
            }
        />
    </BaseModal>;
}