import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import { IasDataModel } from "../../../dataModels/iasDataModel";
import { ValueModel } from "../IasWorkspaceTypes";
import { CreateCustomGroupModal } from "./custom-group/CreateCustomGroupModal";
import { SecurityDisplayRow } from "./security-row/SecurityDisplayRow";
import SegmentGroupRow from "./SegmentGroupRow";
import SegmentRow from "./SegmentRow";
import { AssetClassTableViewModel } from "./view-models/AssetClassTableViewModel";
import { ModelPortfolioViewModel } from "./view-models/ModelPortfolioViewModel";
import { SecurityViewModel } from "./view-models/SecurityViewModel";
import { sortSecurities } from "./workspaceSorting";
import { applyAccountFilter } from "../add-position/AccountSelect";

interface AssetClassTableProps {
    viewModel: AssetClassTableViewModel;
    overallValues: ValueModel;
    ias: IasDataModel;
    modelPortfolio: ModelPortfolioViewModel;
    varianceClass: string;
    assetClassTarget: number;
}

export default function AssetClassTableData(props: AssetClassTableProps) {
    const context = useContext(WorkspaceContext);

    const segmentsFilteredByAccount = useMemo(() =>
        applyAccountFilter(context.filterByAccountId)
            ? props.viewModel.segments.filter(segment => segment.containsAccount(context.filterByAccountId))
            : props.viewModel.segments,
    [context.filterByAccountId, props.viewModel.segments]);
    const allSecurityDisplays = useMemo(() => segmentsFilteredByAccount.flatMap(segment => segment.allSecurityDisplays()), [segmentsFilteredByAccount]);
    const sortedSecurityDisplays = useMemo(() => sortSecurities(allSecurityDisplays, context.sortBy), [context.sortBy, allSecurityDisplays]);

    const filteredSecurityDisplays = useMemo(() => {
        const securityDisplaysFilteredByChanges = context.onlyChanges
            ? segmentsFilteredByAccount.flatMap(segment => segment.allSecurityDisplaysWithChanges())
            : segmentsFilteredByAccount.flatMap(segment => segment.allSecurityDisplays());
        const sortedSecurityDisplays = sortSecurities(securityDisplaysFilteredByChanges, context.sortBy);
        return applyAccountFilter(context.filterByAccountId)
            ? sortedSecurityDisplays.filter(security => security.containsAccount(context.filterByAccountId))
            : sortedSecurityDisplays;
    }, [context.filterByAccountId, context.onlyChanges, context.sortBy, segmentsFilteredByAccount]);

    const [isCreatingCustomGroup, setIsCreatingCustomGroup] = useState(false);
    const [initiatingSecurityForGroup, setInitiatingSecurityForGroup] = useState<SecurityViewModel | null>(null);

    const onCloseCustomGroupModal = useCallback(() => {
        setIsCreatingCustomGroup(false);
    }, []);

    const onStartCreatingGroup = useCallback((security: SecurityViewModel) => {
        setInitiatingSecurityForGroup(security);
        setIsCreatingCustomGroup(true);
    }, []);

    if (props.viewModel.segmentForClassificationActions) {
        return (<Fragment>
            {
                filteredSecurityDisplays.map((child) => props.viewModel.segmentForClassificationActions && <SecurityDisplayRow
                    key={child.key}
                    viewModel={child}
                    depth={0}
                    ias={props.ias}
                    assetClassTarget={props.assetClassTarget}
                    modelPortfolio={props.modelPortfolio}
                    overallValues={props.overallValues}
                    onStartCreatingGroup={onStartCreatingGroup}
                    segmentName={props.viewModel.segmentForClassificationActions.name}
                    segmentChildren={sortedSecurityDisplays}
                />)
            }
            {
                isCreatingCustomGroup && <CreateCustomGroupModal
                    open={isCreatingCustomGroup}
                    onClose={onCloseCustomGroupModal}
                    ias={props.ias}
                    segment={props.viewModel.segmentForClassificationActions}
                    segmentName={props.viewModel.segmentForClassificationActions.name}
                    segmentChildren={sortedSecurityDisplays}
                    initiatingSecurity={initiatingSecurityForGroup}
                />
            }
        </Fragment>);
    }

    const segmentGroupsFilteredByAccount = applyAccountFilter(context.filterByAccountId)
        ? props.viewModel.segmentGroups.filter(group => group.containsAccount(context.filterByAccountId))
        : props.viewModel.segmentGroups;
    const filteredGroups = context.onlyChanges ? segmentGroupsFilteredByAccount.filter(group => group.containsChanges()) : segmentGroupsFilteredByAccount;
    const segmentGroupsToDisplay = filteredGroups.filter(group => group.includeInDisplay());

    const filteredSegments = context.onlyChanges ? segmentsFilteredByAccount.filter(segment => segment.containsChanges()) : segmentsFilteredByAccount;
    const segmentsToDisplay = filteredSegments.filter(segment => segment.includeInDisplay());

    return (<Fragment>
        {
            segmentGroupsToDisplay.map(group => <SegmentGroupRow
                key={group.group.id}
                viewModel={group}
                overallValues={props.overallValues}
                depth={1}
                ias={props.ias}
                assetClass={props.viewModel.assetClass.name}
                modelPortfolio={props.modelPortfolio}
                varianceClass={props.varianceClass}
                assetClassTarget={props.assetClassTarget}
            />)
        }
        {
            segmentsToDisplay.map(segment => <SegmentRow
                key={segment.segment.id}
                viewModel={segment}
                overallValues={props.overallValues}
                depth={1}
                ias={props.ias}
                assetClass={props.viewModel.assetClass.name}
                modelPortfolio={props.modelPortfolio}
                varianceClass={props.varianceClass}
                assetClassTarget={props.assetClassTarget}
            />)
        }
    </Fragment>);
}