import { ChangeEvent, useCallback, useContext, useMemo } from "react";
import { Button, Checkbox } from "rmwc";
import { ValeoSelect } from "../../shared/ValeoSelect";
import { SortBy, WorkspaceContext } from "../../../contexts/workspaceContext";
import styles from "./AssetTableTools.module.scss";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { setAllToCollapsed, setAllToExpanded } from "services/expansionStateCache";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import { AccountDataModel } from "dataModels/accountDataModel";
import { AccountSelect } from "../add-position/AccountSelect";

interface AssetTableToolsProps {
    setOnlyChanges: BooleanCallback;
    setSortBy: Callback<SortBy>;
    setFilterByAccountId: Callback<number>;
    viewModel: AssetTablesViewModel;
    accounts: AccountDataModel[];
}

export default function AssetTableTools(props: AssetTableToolsProps) {
    const context = useContext(WorkspaceContext);

    const onChecked = (event: ChangeEvent<HTMLInputElement>) => {
        props.setOnlyChanges(!!event.currentTarget.checked);
    };

    const sortByOptions = useMemo(() => [
        SortBy.PositionName,
        SortBy.Current,
        SortBy.Proposed,
    ], []);

    const onSelectSortBy = useCallback((optionIndex: number) => {
        props.setSortBy(sortByOptions[optionIndex]);
    }, [props, sortByOptions]);

    const handleExpandAll = useCallback(() => {
        telemetry.trackEvent(EventType.ExpandCollapseAll);
        setAllToExpanded(props.viewModel);
    }, [props.viewModel]);

    const handleCollapseAll = useCallback(() => {
        telemetry.trackEvent(EventType.ExpandCollapseAll);
        setAllToCollapsed(props.viewModel);
    }, [props.viewModel]);

    const onSelectAccount = useCallback((accountId: number | null) => {
        accountId != null && props.setFilterByAccountId(accountId);
    }, [props]);

    return (
        <div className={styles.assetTableTools}>
            <ValeoSelect
                label="Sort by"
                options={sortByOptions}
                value={context.sortBy}
                onSelectIndex={onSelectSortBy}
                className={styles.sortBySelect}
            />
            <AccountSelect
                label="Filter by"
                additionalOptionText="All Accounts"
                accounts={props.accounts}
                accountId={context.filterByAccountId}
                setAccountId={onSelectAccount}
            />
            <Checkbox
                className={styles.checkbox}
                label="Changes Only"
                checked={context.onlyChanges}
                onChange={onChecked}
            />
            <span className={styles.buttons}>
                <Button
                    label="Expand All"
                    onClick={handleExpandAll}
                    id="expand-all-button"
                />
                <Button
                    label="Collapse All"
                    onClick={handleCollapseAll}
                />
            </span>
        </div>
    );
}