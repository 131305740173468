import { DataTableCell } from "rmwc";
import { EditCustomSegmentNamesProps } from "../custom-segment-name/useEditCustomSegmentNames";
import { ModelPortfolioTableSegmentOrGroupCell, ModelPortfolioTableSegmentOrGroupViewModel } from "./ModelPortfolioTableSegmentOrGroupCell";

interface CustomModelPortfolioTableSegmentOrGroupCellProps extends EditCustomSegmentNamesProps {
    cellData: ModelPortfolioTableSegmentOrGroupViewModel | undefined;
    readonly: boolean;
    setSegmentValidity: (name: string, isValid: boolean) => void;
}

export function CustomModelPortfolioTableSegmentOrGroupCell(props: CustomModelPortfolioTableSegmentOrGroupCellProps) {
    const { cellData } = props;

    if (!cellData) {
        return <DataTableCell />;
    }

    return <PopulatedCell
        {...props}
        cellData={cellData}
    />;
}

interface PopulatedCellProps extends CustomModelPortfolioTableSegmentOrGroupCellProps {
    cellData: ModelPortfolioTableSegmentOrGroupViewModel;
}

function PopulatedCell(props: PopulatedCellProps) {
    return <ModelPortfolioTableSegmentOrGroupCell
        {...{
            ...props,
            ...props.cellData,
        }}
        targetPercentagesAreEditable={true}
    />;
}