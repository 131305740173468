import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { createModelPortfolioViewModel, ModelPortfolioViewModel } from "./ModelPortfolioViewModel";
import { customModelPortfoliosAreEqual } from "utils/customModelPortfolioUtils";
import { ModelPortfolioChangeType, modelHasChanged } from "utils/TargetsUtils";

export interface EditModelPortfolioViewModel extends ModelPortfolioViewModel {
    hasChanged: () => ModelPortfolioChangeType;
}

export const createEditModelPortfolioViewModel = (targets: TargetsDataModel, modelPortfolios: ModelPortfolioDataModel[], assetClasses: AssetClassDataModel[], originalTargets: TargetsDataModel, totalIasValue: number): EditModelPortfolioViewModel => {
    const viewModel = createModelPortfolioViewModel(targets, modelPortfolios, assetClasses, totalIasValue);

    return {
        ...viewModel,
        hasChanged: () => {
            const mp = targets.modelPortfolio;
            const originalMp = originalTargets.modelPortfolio;

            if (mp && originalMp) {
                // continued to use standard - may have changed
                return modelHasChanged(targets, originalTargets);
            }

            if (!mp && !originalMp) {
                const cmp = targets.customModelPortfolio;
                const originalCmp = originalTargets.customModelPortfolio;

                if (cmp && originalCmp) {
                    // continued to use custom - may have changed
                    return !customModelPortfoliosAreEqual(cmp, originalCmp) ? ModelPortfolioChangeType.Values : ModelPortfolioChangeType.None;
                }

                if (!cmp && !originalCmp) {
                    // continued to have no model
                    return ModelPortfolioChangeType.None;
                }

                // created new custom
                return ModelPortfolioChangeType.Values;
            }

            // switched between standard and custom
            return ModelPortfolioChangeType.Values;
        },
    };
};
