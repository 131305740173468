import { ConfirmationDialog } from "components/shared/ConfirmationDialog";
import { IasState } from "constants/iasState";
import { ClientDataModel } from "dataModels/clientDataModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { format as formatDate } from "date-fns";
import { Fragment, useCallback, useState } from "react";
import { Button } from "rmwc";
import { isTradeResponse, TradeDataExceptionReason, useCreateGetTradeData, useUploadTradeDataMutator } from "services/tradeDataService";
import { downloadText } from "utils/downloadText";
import { isSome } from "utils/isNullOrUndefined";
import { TradeDataExceptionDialog } from "./TradeDataExceptionDialog";
import styles from "./FidelityTradeButtons.module.scss";
import { useFeatureToggle } from "services/featureTogglesService";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

interface FidelityTradeButtonsProps {
    ias: IasDataModel;
    client: ClientDataModel;
}

export function FidelityTradeButtons(props: FidelityTradeButtonsProps) {
    const { ias } = props;
    const [tradeDataExceptionReasons, setTradeDataExceptionReasons] = useState<TradeDataExceptionReason[]>([]);

    const closeTradeDataExceptionDialog = useCallback(() => setTradeDataExceptionReasons([]), []);

    if (ias.state !== IasState.Final) {
        return <Fragment />;
    }

    return <Fragment>
        <SendToFidelityButton
            ias={props.ias}
            setTradeDataExceptionReasons={setTradeDataExceptionReasons}
        />
        <DownloadFidelityTradesButton
            {...props}
            setTradeDataExceptionReasons={setTradeDataExceptionReasons}
        />
        {
            tradeDataExceptionReasons.length > 0 && <TradeDataExceptionDialog
                onConfirm={closeTradeDataExceptionDialog}
                exceptionReasons={tradeDataExceptionReasons}
            />
        }
    </Fragment>;
}

interface FidelityExceptionDialogProps {
    setTradeDataExceptionReasons: Callback<TradeDataExceptionReason[]>
}

function DownloadFidelityTradesButton(props: FidelityTradeButtonsProps & FidelityExceptionDialogProps) {
    const { ias, client, setTradeDataExceptionReasons } = props;
    const getFidelityTrades = useCreateGetTradeData(ias.id);

    const onDownloadFidelityTradesClick = useCallback(async () => {
        telemetry.trackEvent(EventType.DownloadTrades);
        const tradeData = await getFidelityTrades();
        if (tradeData) {
            downloadText(tradeData.trades, `${client.key}-${formatDate(new Date(), "yyyyMMdd")}-Trades.csv`);
            setTradeDataExceptionReasons(tradeData.exceptions.map(exception => exception.reason));
        }
    }, [client.key, getFidelityTrades, setTradeDataExceptionReasons]);

    return <Button
        label="Download Fidelity Trades"
        trailingIcon="keyboard_arrow_right"
        className={styles.downloadFidelityTradesButton}
        onClick={onDownloadFidelityTradesClick}
    />;
}

interface SendToFidelityButtonProps {
    ias: IasDataModel;
    setTradeDataExceptionReasons: Callback<TradeDataExceptionReason[]>;
}

function SendToFidelityButton(props: SendToFidelityButtonProps) {
    const { ias, setTradeDataExceptionReasons } = props;
    const { mutateAsync: postTrades } = useUploadTradeDataMutator(ias.id);
    const [confirmationDialog, setConfirmationDialogOpen] = useState(false);
    const featureToggle = useFeatureToggle("FidelityTrades");
    const [connectionFailureDialog, setConnectionFailureDialogOpen] = useState(false);

    const openConfirmationDialog = useCallback(() => setConfirmationDialogOpen(true), []);
    const onCancel = useCallback(() => setConfirmationDialogOpen(false), []);
    const onConfirmed = useCallback(async () => {
        setConfirmationDialogOpen(false);
        telemetry.trackEvent(EventType.SendTrades);
        const response = await postTrades();
        if (isTradeResponse(response)) {
            setTradeDataExceptionReasons(response.exceptions.map(exception => exception.reason));
        } else {
            setConnectionFailureDialogOpen(true);
        }
    }, [postTrades, setTradeDataExceptionReasons]);

    if (!featureToggle) {
        return <div className={styles.absentButtonFiller} />;
    }

    return <Fragment>
        {
            isSome(ias.fidelityTradesSentTimestamp)
                ? <div className={styles.sentToFidelityLabel}>Fidelity Trades Sent</div>
                : <Button
                    label="SEND TO FIDELITY"
                    trailingIcon="keyboard_arrow_right"
                    className={styles.sendToFidelityButton}
                    onClick={openConfirmationDialog}
                />
        }
        {
            confirmationDialog && <SendTradesToFidelityConfirmationDialog
                onCancel={onCancel}
                onConfirm={onConfirmed}
            />
        }
        {
            connectionFailureDialog && <ConfirmationDialog
                title={<span className={styles.redWarning}>Fidelity failed to connect. No trades were sent.</span>}
                confirmButton="Okay"
                onConfirm={() => setConnectionFailureDialogOpen(false)}
                content="The system can not connect to Fidelity at this time. Please try again later."
            />
        }
    </Fragment>;
}

interface SendTradesToFidelityConfirmationDialogProps {
    onCancel: VoidFunction;
    onConfirm: VoidFunction;
}

function SendTradesToFidelityConfirmationDialog(props: SendTradesToFidelityConfirmationDialogProps) {
    return <ConfirmationDialog
        confirmButton="Send"
        onCancel={props.onCancel}
        content="You can not undo this action. Further management of these trades must occur in Wealthscape."
        onConfirm={props.onConfirm}
        title="Send trades to Fidelity?"
        width={448}
    />;
}