import { useEffect } from "react";
import { setAuthorizeResponse } from "services/blackDiamondService";
import { hasContent } from "utils/StringUtils";

export default function BlackDiamondCallback() {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const state = params.get("state");
        const code = params.get("code");

        if (hasContent(state) && hasContent(code)) {
            setAuthorizeResponse(code, state);
        }

        window.close();
    }, []);

    return <div>This window will close automatically.</div>;
}