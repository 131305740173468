import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { CustomGroupDataModel } from "dataModels/customGroupDataModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { isNumber } from "utils/NumberUtils";
import { deleteWithAuthAndBody, postWithAuth, putWithAuth } from "./apiService";
import { useCommonErrorDetection } from "./dataErrorService";
import { IasModifiedResponseDataModel, iasQueryKey } from "./iasService";

export interface WritableCustomGroup {
    tempId: number;
    name: string;
    securityIds: number[];
    lastModifiedDate: Date;
}

export interface WritableSegmentCustomGroup extends WritableCustomGroup {
    iasId: number;
    segmentId: number;
}

export interface CreateCustomGroupResponse {
    id: number;
    dateModified: Date;
    lastModifiedBy: string;
}

const post = async (customGroup: WritableSegmentCustomGroup): Promise<CreateCustomGroupResponse> => {
    return await postWithAuth("/customGroup", customGroup);
};

export function useCustomGroupCreation(iasId: number, segmentId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((customGroup: WritableCustomGroup) => post({
        ...customGroup,
        iasId,
        segmentId,
    }), {
        onMutate: async (customGroup) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const newSecurities: IasSecurityDataModel[] = cachedIas.securities.map(security => {
                if (customGroup.securityIds.includes(security.id)) {
                    return {
                        ...security,
                        customGroupId: customGroup.tempId,
                    };
                }
                return security;
            });
            const newCustomGroups: CustomGroupDataModel[] = cachedIas.customGroups.slice();
            newCustomGroups.push({
                id: customGroup.tempId,
                name: customGroup.name,
                segmentId,
            });
            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                securities: newSecurities,
                customGroups: newCustomGroups,
            });
            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (response, customGroupInfo) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const newSecurities: IasSecurityDataModel[] = cachedIas.securities.map(security => {
                if (customGroupInfo.securityIds.includes(security.id)) {
                    return {
                        ...security,
                        customGroupId: response.id,
                    };
                }
                return security;
            });
            const newCustomGroups: CustomGroupDataModel[] = cachedIas.customGroups.map(customGroup => {
                if (customGroup.id === customGroupInfo.tempId) {
                    return {
                        ...customGroup,
                        id: response.id,
                    };
                }
                return customGroup;
            });
            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                securities: newSecurities,
                customGroups: newCustomGroups,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: { name: response.lastModifiedBy },
            });
        },
    });
}

export interface PutCustomGroupInformation {
    name?: string;
    segmentId?: number;
    securityIds?: number[];
    lastModifiedDate: Date;
}

const put = async (customGroupId: number, info: PutCustomGroupInformation): Promise<IasModifiedResponseDataModel> => {
    return await putWithAuth(`/customGroup/${customGroupId}`, info);
};

export interface CustomGroupInformation {
    name?: string;
    segmentId?: number;
    lastModifiedDate: Date;
}

export function useCustomGroupMutation(customGroupId: number, iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: CustomGroupInformation) => put(customGroupId, info), {
        onMutate: async (info) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            const oldCustomGroup = cachedIas.customGroups.find(customGroup => customGroup.id === customGroupId);
            if (oldCustomGroup) {
                const newCustomGroup: CustomGroupDataModel = {
                    ...oldCustomGroup,
                    ...info,
                };

                const newCustomGroups = cachedIas.customGroups.slice();
                newCustomGroups[newCustomGroups.indexOf(oldCustomGroup)] = newCustomGroup;

                const newSegmentId = info.segmentId;
                const newSecurities = isNumber(newSegmentId)
                    ? cachedIas.securities.map(security => {
                        if (security.customGroupId === customGroupId) {
                            return {
                                ...security,
                                segmentId: newSegmentId,
                            };
                        }
                        return security;
                    })
                    : cachedIas.securities.slice();

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    securities: newSecurities,
                    customGroups: newCustomGroups,
                });
            } else {
                throw new Error(`Expected QueryProvider ['ias', ${iasId}] cache item to include custom group with id ${customGroupId} but found none.`);
            }

            return { cached: cachedIas };
        },
        onError: commonErrorDetection,
        onSuccess: (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);

            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: { name: response.lastModifiedBy },
            });
        },
    });
}

export interface EditCustomGroupContentsInfo {
    lastModifiedDate: Date,
    securityIds: number[]
}

export function useCustomGroupContentsMutation(groupId: number, iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: EditCustomGroupContentsInfo) => put(groupId, info), {
        onMutate: async (info) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            cachedIas.securities.filter(security => info.securityIds.includes(security.id)).forEach(security => security.customGroupId = groupId);
            cachedIas.securities.filter(security => !info.securityIds.includes(security.id) && security.customGroupId === groupId).forEach(security => security.customGroupId = null);
        },
        onError: commonErrorDetection,
        onSuccess: (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: {name: response.lastModifiedBy}
            });
        }
    });
}

export interface DeleteCustomGroupInfo {
    lastModifiedDate: Date
}

async function deleteCustomGroup(groupId: number, info: DeleteCustomGroupInfo): Promise<IasModifiedResponseDataModel> {
    return await deleteWithAuthAndBody(`/customGroup/${groupId}`, info);
}

export function useCustomGroupDeletion(groupId: number, iasId: number) {
    const queryClient = useQueryClient();
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation((info: DeleteCustomGroupInfo) => deleteCustomGroup(groupId, info), {
        onMutate: async () => {
            const cachedIas = getCachedIas(queryClient, iasId);
            cachedIas.securities.filter(security => security.customGroupId === groupId).forEach(security => security.customGroupId = null);

            const customGroup = cachedIas.customGroups.find(group => group.id === groupId);
            if(customGroup) {
                const newCustomGroups = cachedIas.customGroups.slice();
                newCustomGroups.splice(newCustomGroups.indexOf(customGroup), 1);

                queryClient.setQueryData(iasQueryKey(iasId), {
                    ...cachedIas,
                    customGroups: newCustomGroups
                });
            }
        },
        onError: commonErrorDetection,
        onSuccess: (response) => {
            const cachedIas = getCachedIas(queryClient, iasId);
            queryClient.setQueryData(iasQueryKey(iasId), {
                ...cachedIas,
                dateModified: new Date(response.dateModified),
                lastModifiedUser: {name: response.lastModifiedBy}
            });
        }
    });
}

function getCachedIas(queryClient: QueryClient, iasId: number): IasDataModel {
    const cachedIas = queryClient.getQueryData<IasDataModel>(iasQueryKey(iasId));
    if (cachedIas) {
        return cachedIas;
    } else {
        throw new Error(`Expected QueryProvider ['ias', ${iasId}] cache item but found none.`);
    }
}