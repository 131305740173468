import { useCallback, useMemo } from "react";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import { ValeoChip } from "components/shared/ValeoChip";
import { IasDataModel } from "dataModels/iasDataModel";
import styles from "./RecommendedFundRow.module.scss";
import { RecommendedFundViewModel } from "../asset-table/view-models/RecommendedFundViewModel";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

export interface RecommendedFundRowProps {
    ias: IasDataModel;
    ticker: string;
    name: string;
    newPositionFund: RecommendedFundViewModel | undefined;
    setNewPositionTicker: FunctionStringCallback;
}

export function RecommendedFundRow(props: RecommendedFundRowProps) {
    const { ias, ticker, name, newPositionFund, setNewPositionTicker } = props;

    const allIasSecurities = useMemo(() => ias.securities, [ias]);
    const allIasAccountPositions = useMemo(() => ias.accounts.flatMap((account) =>
        account.positions.map((position) => ({
            ...position,
            accountId: account.id,
        }))
    ), [ias]);

    const matchingSecurity = allIasSecurities.find(s => s.tickerSymbol === ticker);
    const matchingIasAccountPositions = allIasAccountPositions.filter((position) => position.securityId === matchingSecurity?.id);

    const isSelectionDisabled = useMemo(() => newPositionFund && newPositionFund.ticker === ticker, [ticker, newPositionFund]);

    const selectFund = useCallback(() => {
        telemetry.trackEvent(EventType.RecommendedFundSelected);
        setNewPositionTicker(ticker);
    }, [ticker, setNewPositionTicker]);

    return <div
        className={styles.recommendedFundRow}
        data-testid={`recommended-fund-${ticker}`}
    >
        <ModalActionButton
            label={
                newPositionFund
                    ? newPositionFund.ticker === ticker
                        ? "Selected"
                        : "Switch"
                    : "Select"
            }
            onClick={selectFund}
            disabled={isSelectionDisabled}
            className={styles.actionButton}
        />
        <div className={styles.fundDetails}>{`${ticker}    ${name}`}</div>
        {
            matchingIasAccountPositions.length > 0 && <ValeoChip
                label="In IAS"
                className={styles.inIasChip}
            />
        }
    </div>;
}