import { FormEvent } from "react";
import { TextField } from "rmwc";
import styles from "./ValeoTextArea.module.scss";

interface ValeoTextAreaProps {
    disabled?: boolean;
    label: string;
    value: string;
    onInput: (event: FormEvent<HTMLInputElement>) => void;
    className?: string;
}

export function ValeoTextArea(props: ValeoTextAreaProps) {
    return (
        <TextField
            disabled={props.disabled}
            textarea
            label={props.label}
            value={props.value}
            onInput={props.onInput}
            className={`${styles.textField} ${props.className}`}
        />
    );
}