import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { IasSecurityDataModel } from "dataModels/iasSecurityDataModel";
import { differenceWith, intersectionWith } from "lodash";
import { useCallback, useMemo } from "react";
import { DataTable, DataTableBody, DataTableContent } from "rmwc";
import { VisionCategory, VisionPossession, VisionSubcategory } from "services/visionService";
import { filterMap } from "utils/ArrayUtils";
import { IndeterminateTristate } from "../../../../controls/IndeterminateCheckbox";
import styles from "./ImportVisionAssetsContent.module.scss";
import { allPossessions, SelectionProps } from "./VisionAssetsCommonFunctions";
import { VisionContainerBlock } from "./VisionContainerBlock";

interface ImportVisionAssetsContentProps {
    assetClasses: AssetClassDataModel[];
    visionCategories: VisionCategory[];
    portfolioAccounts: string[];
    selectedPossessions: Set<VisionPossession>;
    setSelectedPossessions: (possessions: Set<VisionPossession>) => void;
    iasSecurities?: IasSecurityDataModel[];
}

function setSelectedElements(selections: Set<VisionPossession>, categories: VisionCategory[]): ReadonlyMap<string | number, IndeterminateTristate> {
    const selectionsArray = Array.from(selections);
    function getTristateFor(container: VisionCategory | VisionSubcategory): IndeterminateTristate {
        const possessions = allPossessions(container);
        const selectedPossessions = intersectionWith(possessions, Array.from(selections), (a, b) => a.id === b.id);
        if (possessions.length === selectedPossessions.length) {
            return IndeterminateTristate.Solid;
        } else if (selectedPossessions.length > 0) {
            return IndeterminateTristate.Some;
        }
        return IndeterminateTristate.Empty;
    }

    const result = new Map<string | number, IndeterminateTristate>();
    for (const category of categories) {
        result.set(category.uniqueId, getTristateFor(category));
        for (const subcategory of category.subcategories) {
            result.set(subcategory.uniqueId, getTristateFor(subcategory));
            for (const possession of subcategory.possessions) {
                const possessionIndex = selectionsArray.findIndex(selection => selection.id === possession.id);
                if (possessionIndex !== -1) {
                    possession.segmentId = selectionsArray[possessionIndex].segmentId;
                }
                result.set(possession.id, possessionIndex === -1 ? IndeterminateTristate.Empty : IndeterminateTristate.Solid);
            }
        }
    }
    return result;
}

export function ImportVisionAssetsContent(props: ImportVisionAssetsContentProps) {
    const { assetClasses, visionCategories, portfolioAccounts, selectedPossessions, setSelectedPossessions } = props;

    const cleanedVisionCategories = useMemo(() => cleanVisionData(visionCategories), [visionCategories]);

    const selectedElements = useMemo(() => {
        if (cleanedVisionCategories.length > 0) {
            return setSelectedElements(selectedPossessions, cleanedVisionCategories);
        }
        return new Map<string | number, IndeterminateTristate>();
    }, [selectedPossessions, cleanedVisionCategories]);

    const addSelections = useCallback((possessions: VisionPossession[]) => {
        const newSet = new Set(selectedPossessions);
        possessions.forEach(possession => newSet.add(possession));
        setSelectedPossessions(newSet);
    }, [selectedPossessions, setSelectedPossessions]);

    const removeSelections = useCallback((possessions: VisionPossession[]) => {
        const newSet = differenceWith(Array.from(selectedPossessions), possessions, (a, b) => a.id === b.id);
        setSelectedPossessions(new Set<VisionPossession>(newSet));
    }, [selectedPossessions, setSelectedPossessions]);

    const selectionProps: SelectionProps = useMemo(() => ({
        addSelections,
        removeSelections,
        selectedElements
    }), [addSelections, removeSelections, selectedElements]);

    if (cleanedVisionCategories.length === 0) {
        return <div className={styles.noAssets}>
            <div>No assets found</div>
        </div>;
    }

    return <DataTable className={styles.table}>
        <DataTableContent>
            <DataTableBody className="clickable">
                {
                    cleanedVisionCategories.map(category => <VisionContainerBlock
                        {...selectionProps}
                        container={category}
                        key={category.name}
                        defaultOpen={category.name === "Investments"}
                        portfolioAccounts={portfolioAccounts}
                        assetClasses={assetClasses}
                        iasSecurities={props.iasSecurities}
                    />)
                }
            </DataTableBody>
        </DataTableContent>
    </DataTable>;
}

function cleanVisionData(data: VisionCategory[]) {
    return filterEmptyCategories(data);
}

function filterEmptyCategories(categories: VisionCategory[]): VisionCategory[] {
    return filterMap(categories, category => filterEmptyCategory(category));
}

function filterEmptyCategory(category: VisionCategory): VisionCategory | null {
    const subcategories = filterEmptySubcategories(category.subcategories);
    if (subcategories.length === 0) {
        return null;
    }
    return {
        ...category,
        subcategories,
    };
}

function filterEmptySubcategories(subcategories: VisionSubcategory[]): VisionSubcategory[] {
    return filterMap(subcategories, subcategory => filterEmptySubcategory(subcategory));
}

function filterEmptySubcategory(subcategory: VisionSubcategory): VisionSubcategory | null {
    if (subcategory.possessions.length === 0) {
        return null;
    }
    return subcategory;
}