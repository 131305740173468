import classnames from "classnames";
import { useCallback, useMemo } from "react";
import { FormattedOption } from "rmwc";
import { ValeoSelect } from "../ValeoSelect";
import styles from "./ModelPortfolioSelect.module.scss";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { TargetsDataModel } from "dataModels/targetsDataModel";
import { ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { defaultCustomModelPortfolio } from "utils/customModelPortfolioUtils";

export interface ModelPortfolioSelectProps {
    modelPortfolio: ModelPortfolioViewModel;
    modelPortfolios: ModelPortfolioDataModel[];
    patchTargets: (targetsUpdates: Partial<TargetsDataModel>) => void;
    readonly?: boolean;
}

export function ModelPortfolioSelect(props: ModelPortfolioSelectProps) {

    const onSelectModelPortfolio = useCallback((optionIndex: number) => {
        const selectedModelPortfolio = props.modelPortfolios.at(optionIndex);
        props.patchTargets({
            modelPortfolio: selectedModelPortfolio,
            modelPortfolioTargets: selectedModelPortfolio?.modelPortfolioTargetSet,
            ...(!selectedModelPortfolio && {
                customModelPortfolio: props.modelPortfolio.savedCustomModelPortfolio() ?? defaultCustomModelPortfolio(),
            }),
        });
    }, [props]);

    const modelPortfolioName = props.modelPortfolio.name();

    const modelPortfolioOptions: FormattedOption[] = useMemo(() =>
        props.modelPortfolios.map(({ name }) => ({
            label: <div>{name}</div>,
            value: name,
        })).concat({
            label: <div className={styles.customOption}>Custom*</div>,
            value: "Custom*",
        })
    , [props.modelPortfolios]);

    const selectClassNames = classnames(styles.select, {
        [styles.nonEmptyName]: !!props.modelPortfolio.customModelPortfolioInUse() || modelPortfolioName.length > 0
    });

    return <ValeoSelect
        label="Portfolio"
        options={modelPortfolioOptions}
        value={props.modelPortfolio.customModelPortfolioInUse() ? "Custom*" : modelPortfolioName}
        onSelectIndex={onSelectModelPortfolio}
        className={selectClassNames}
        disabled={props.readonly}
        data-testid="model-portfolio-select"
    />;
}
