import { useCallback, useEffect, useState } from "react";
import { TargetsDataModel } from "../../dataModels/targetsDataModel";
import { useClientMutation, useClientCustomModelPortfolioMutation } from "services/clientService";
import { ClientDataModel } from "../../dataModels/clientDataModel";
import { EditModelPortfolioDialog } from "components/shared/modelPortfolio/EditModelPortfolioDialog";
import { CustomModelPortfolioDataModel, ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { createEditModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/EditModelPortfolioViewModel";
import { useEditCustomSegmentNames } from "components/shared/custom-segment-name/useEditCustomSegmentNames";

export interface EditClientModelPortfolioDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    modelPortfolios: ModelPortfolioDataModel[];
    client: ClientDataModel;
    mostRecentlyFinalizedPortfolioValue: number;
}

export function EditClientModelPortfolioDialog(props: EditClientModelPortfolioDialogProps) {
    const { open, setOpen, modelPortfolios, client } = props;

    const clientMutation = useClientMutation(client.id);
    const cmpMutation = useClientCustomModelPortfolioMutation(client.id);

    const { assetClasses, customSegmentNames, customSegmentNamesAreValid, customSegmentNamesAreModified, editCustomSegmentNamesProps,
        customSegmentGroupNames, customSegmentGroupNamesAreValid, customSegmentGroupNamesAreModified } = useEditCustomSegmentNames(client.assetClasses);

    const propsTargets: TargetsDataModel = {
        modelPortfolio: client.modelPortfolio,
        customModelPortfolio: client.customModelPortfolio,
        cashTarget: client.cashTarget,
        cashTargetInDollars: client.cashTargetInDollars,
        alternativesTarget: client.alternativesTarget,
        alternativesTargetInDollars: client.alternativesTargetInDollars,
        cashCarveOut: client.cashCarveOut,
        fixedIncomeCarveOut: client.fixedIncomeCarveOut,
        equitiesCarveOut: client.equitiesCarveOut,
    };
    const [originalTargets] = useState(propsTargets);
    const [targets, setTargets] = useState(propsTargets);

    const patchTargets = useCallback((targetsUpdates: Partial<TargetsDataModel>) => {
        setTargets((targets: TargetsDataModel) => ({
            ...targets,
            ...targetsUpdates,
        }));
    }, []);

    const patchCustomModelPortfolio = useCallback((cmpUpdates: Partial<CustomModelPortfolioDataModel>) => {
        setTargets((targets: TargetsDataModel) => ({
            ...targets,
            ...(targets.customModelPortfolio && {
                customModelPortfolio: {
                    ...targets.customModelPortfolio,
                    ...cmpUpdates,
                },
            }),
        }));
    }, []);

    const [portfolioValue, setPortfolioValue] = useState(props.mostRecentlyFinalizedPortfolioValue);

    const [modelPortfolio, setModelPortfolio] = useState(() => createEditModelPortfolioViewModel(targets, modelPortfolios, assetClasses, originalTargets, portfolioValue));
    useEffect(() => {
        setModelPortfolio(createEditModelPortfolioViewModel(targets, modelPortfolios, assetClasses, originalTargets, portfolioValue));
    }, [assetClasses, targets, modelPortfolios, originalTargets, portfolioValue]);

    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    const onAccept = useCallback(() => {
        const customSegmentNamesUpdates = customSegmentNamesAreModified
            ? { customSegmentNames }
            : {};
        const customSegmentGroupNamesUpdates = customSegmentGroupNamesAreModified ? { customSegmentGroupNames } : {};
        const customModelPortfolio = modelPortfolio.customModelPortfolioInUse();
        if (customModelPortfolio) {
            cmpMutation.mutate({
                customModelPortfolio,
                ...customSegmentNamesUpdates,
                ...customSegmentGroupNamesUpdates,
            });
        } else {
            clientMutation.mutate({
                ...targets,
                ...customSegmentNamesUpdates,
                ...customSegmentGroupNamesUpdates,
            });
        }
    }, [clientMutation, cmpMutation, customSegmentGroupNames, customSegmentGroupNamesAreModified, customSegmentNames, customSegmentNamesAreModified, modelPortfolio, targets]);

    return <EditModelPortfolioDialog
        open={open}
        setOpen={setOpen}
        onAccept={onAccept}
        isSaveEnabled={isSaveEnabled}
        setIsSaveEnabled={setIsSaveEnabled}
        modelPortfolio={modelPortfolio}
        patchTargets={patchTargets}
        patchCustomModelPortfolio={patchCustomModelPortfolio}
        modelPortfolios={modelPortfolios}
        assetClasses={assetClasses}
        readonly={false}
        customSegmentNamesAreValid={customSegmentNamesAreValid}
        customSegmentNamesAreModified={customSegmentNamesAreModified}
        assumedTotalPortfolioValue={props.mostRecentlyFinalizedPortfolioValue}
        setPortfolioValue={props.mostRecentlyFinalizedPortfolioValue === 0 ? setPortfolioValue : undefined}
        customSegmentGroupNamesAreValid={customSegmentGroupNamesAreValid}
        customSegmentGroupNamesAreModified={customSegmentGroupNamesAreModified}
        {...editCustomSegmentNamesProps}
    />;
}