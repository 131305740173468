/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classnames from "classnames";
import NumberDisplay from "components/numeric-values/NumberDisplay";
import { WorkspaceContext } from "contexts/workspaceContext";
import { IasDataModel } from "dataModels/iasDataModel";
import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { DataTableCell } from "rmwc";
import CurrentValueTextField from "../CurrentValueTextField";
import { AccountPositionViewModel } from "../view-models/AccountPositionViewModel";
import sharedStyles from "../SharedStyles.module.scss";
import styles from "./CommentsAndCurrentValue.module.scss";
import CommentsIconCell from "./CommentsIconCell";
import { EditCurrentValueDialog } from "./EditCurrentValueDialog";

interface CommentsAndCurrentValueProps {
    editCurrentValue: boolean;
    setEditCurrentValue: (value: boolean) => void;
    viewModel: AccountPositionViewModel;
    ias: IasDataModel;
    setCommentDialogOpen: (value: boolean) => void;
}

export function CommentsAndCurrentValue(props: CommentsAndCurrentValueProps) {
    const { editCurrentValue, setEditCurrentValue } = props;
    const context = useContext(WorkspaceContext);
    const [editCurrentValueBlendedOpen, setEditCurrentValueBlendedOpen] = useState(false);

    const clickToEdit = useMemo(() => !context.readonlyMode && props.viewModel.currentValueModified, [context.readonlyMode, props.viewModel.currentValueModified]);

    const onClick = useCallback(() => {
        if (clickToEdit) {
            if (props.viewModel.isBlended) {
                setEditCurrentValueBlendedOpen(true);
            } else {
                setEditCurrentValue(true);
            }
        }
    }, [clickToEdit, props.viewModel.isBlended, setEditCurrentValue]);

    const commentsIconCell = <CommentsIconCell
        viewModel={props.viewModel}
        setCommentDialogOpen={props.setCommentDialogOpen}
        readonlyMode={context.readonlyMode}
    />;

    if (editCurrentValue) {
        const isWide = !context.sidebarOpen;
        return <Fragment>
            {isWide && commentsIconCell}
            <DataTableCell
                className={classnames(sharedStyles.value_column, { [styles.wide]: isWide })}
                data-testid="current"
                colSpan={isWide ? 1 : 2}
            >
                <CurrentValueTextField
                    ias={props.ias}
                    positionId={props.viewModel.position.id}
                    accountId={props.viewModel.account.account.id}
                    currentValue={props.viewModel.currentValue()}
                    onValidBlur={() => setEditCurrentValue(false)}
                    data-testid="edit-current-value"
                />
            </DataTableCell>
        </Fragment>;
    }

    return <Fragment>
        {commentsIconCell}
        <DataTableCell
            className={sharedStyles.value_column}
            data-testid="current"
        >
            <div
                onClick={onClick}
                className={classnames({clickable: clickToEdit})}
                data-testid="current-value"
            >
                <NumberDisplay
                    className={classnames({ [styles.underlined]: props.viewModel.currentValueModified })}
                    value={props.viewModel.currentValue()}
                />
            </div>
            {
                editCurrentValueBlendedOpen && <EditCurrentValueDialog
                    viewModel={props.viewModel}
                    ias={props.ias}
                    open={editCurrentValueBlendedOpen}
                    setOpen={setEditCurrentValueBlendedOpen}
                />
            }
        </DataTableCell>
    </Fragment>;
}
