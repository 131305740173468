import "rmwc/dist/styles"; // Note: importing rmwc styles must happen before importing components
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import ClientList from "./components/client-list/ClientList";
import ClientPage from "./components/client-page/ClientPage";
import IasWorkspace from "./components/ias-workspace/IasWorkspace";
import { ConfigurationResponse, staticConfiguration } from "./services/configurationService";
import AuthenticationError from "./components/authentication/AuthenticationError";
import SigningIn from "./components/authentication/SigningIn";
import "@fontsource/zilla-slab/latin.css";
import "@fontsource/outfit/latin.css";
import "@fontsource/material-icons";
import { Fragment, lazy, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BlackDiamondCallback from "./components/authentication/BlackDiamondCallback";
import { ConfigurationContext, emptyConfiguration } from "./contexts/configurationContext";
import { telemetry } from "services/telemetryService";
import ScrollToTop from "components/layout/ScrollToTop";
import { NetworkError } from "services/NetworkError";
import { Portal } from "rmwc";
import { getWithAuth } from "services/apiService";
import { LoggingErrorBoundary } from "LoggingErrorBoundary";
import { EagerFontLoader } from "components/shared/EagerFontLoader";
import { Zendesk } from "components/zendesk/Zendesk";

const IasPdfPage = lazy(() => import("components/ias-workspace/IasPdfPage"));

const doNotRetryAuthorizationErrors = (failureCount: number, error: unknown): boolean => {
    if (error instanceof NetworkError && [401, 403, 406].includes(error.errorCode)) {
        return false;
    }
    return failureCount < 3;
};

const getDynamicConfiguration = async () => {
    return getWithAuth<ConfigurationResponse>("/configuration/config");
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: doNotRetryAuthorizationErrors,
            staleTime: Infinity,
            cacheTime: Infinity
        },
        mutations: {
            retry: doNotRetryAuthorizationErrors,
            networkMode: "always"
        }
    }
});

function AuthenticatedContents() {
    const [configuration, setConfiguration] = useState<ConfigurationResponse>(emptyConfiguration);

    useEffect(() => {
        void getDynamicConfiguration().then((value) => {
            setConfiguration(value);

            if (value.applicationInsights.enabled) {
                telemetry.initialize(value.applicationInsights.connectionString);
            }
        });
    }, []);

    const additionalRoutes = staticConfiguration.includeControlsPage()
        ? <Fragment>
            <Route
                path="/ias/:id/pdf"
                element={<IasPdfPage />}
            />
            <Route
                path="/ias/:id/pdf/:page"
                element={<IasPdfPage />}
            />
        </Fragment>
        : <Fragment/>;

    return <Fragment>
        <EagerFontLoader />
        <QueryClientProvider client={queryClient}>
            <ConfigurationContext.Provider value={configuration}>
                <BrowserRouter>
                    <ScrollToTop>
                        <Routes>
                            <Route
                                path="/"
                                element={<ClientList />}
                            />
                            <Route
                                path="/client/:id"
                                element={<ClientPage />}
                            />
                            <Route
                                path="/ias/:id"
                                element={<IasWorkspace />}
                            />
                            {additionalRoutes}
                        </Routes>
                    </ScrollToTop>
                </BrowserRouter>
            </ConfigurationContext.Provider>
            {staticConfiguration.zendesk.enabled() && <Zendesk zendeskKey={staticConfiguration.zendesk.key()} />}
        </QueryClientProvider>
        <Portal/>
    </Fragment>;
}

export default function App() {
    if (window.location.pathname.endsWith("/health-check")) {
        return <Fragment>Healthy</Fragment>;
    }

    if (window.location.pathname.includes("/blackDiamond/callback")) {
        return <BlackDiamondCallback />;
    }

    if (staticConfiguration.useMsal()) {
        return (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                loadingComponent={SigningIn}
                errorComponent={AuthenticationError}
            >
                <LoggingErrorBoundary>
                    <AuthenticatedContents />
                </LoggingErrorBoundary>
            </MsalAuthenticationTemplate>
        );
    }

    return <AuthenticatedContents />;
}