import { Elevation, IconButton } from "rmwc";
import React, { useCallback, useContext } from "react";
import styles from "./RightSidebar.module.scss";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import BarChart from "./BarChart";
import ExcessRemainingTable from "./ExcessRemainingTable";
import classnames from "classnames";
import { WorkspaceContext } from "../../../contexts/workspaceContext";
import { IconName } from "../../../iconNames";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";

interface RightSidebarProps {
    viewModel: AssetTablesViewModel;
    setSidebarOpen: BooleanCallback;
    iasId: number;
    lastModifiedDate: Date;
    accountTypes: AccountTypeDataModel[];
}

export default function RightSidebar(props: RightSidebarProps) {
    const context = useContext(WorkspaceContext);

    const toggleSideBar = useCallback(() => {
        telemetry.trackEvent(EventType.SidebarToggled);
        props.setSidebarOpen(!context.sidebarOpen);
    }, [props, context.sidebarOpen]);

    const onSidebarClick = () => {
        if (!context.sidebarOpen) {
            telemetry.trackEvent(EventType.SidebarToggled);
            props.setSidebarOpen(true);
        }
    };

    return (
        <Elevation
            z={2}
            className={classnames(styles.sidebar, {[styles.closedSidebar]: !context.sidebarOpen, "clickable": !context.sidebarOpen })}
            data-testid="sidebar-card"
            onClick={onSidebarClick}
        >
            <IconButton
                onClick={toggleSideBar}
                icon={context.sidebarOpen ? IconName.ArrowForward : IconName.ArrowBack}
                className={styles.arrow}
            />
            {
                context.sidebarOpen && <div
                    className={classnames(styles.sidebarContent, {[styles.hidden]: !context.sidebarOpen })}
                    data-testid="sidebar"
                >
                    <BarChart viewModel={props.viewModel} />
                    <ExcessRemainingTable
                        viewModel={props.viewModel}
                        iasId={props.iasId}
                        lastModifiedDate={props.lastModifiedDate}
                        accountTypes={props.accountTypes}
                    />
                </div>
            }
        </Elevation>
    );
}