import { useMutation } from "@tanstack/react-query";
import { ModelPortfolioViewModel } from "components/ias-workspace/asset-table/view-models/ModelPortfolioViewModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { IasDataModel } from "dataModels/iasDataModel";
import { PopulatedPortfolio } from "services/blackDiamondService";
import { useCommonErrorDetection } from "services/dataErrorService";
import { createIas, ClassifiedBlackDiamondHolding, ClassifiedVisionPossession } from "./createIas";
import { ClientDataModel } from "dataModels/clientDataModel";

export interface CreateIasParams {
    date: Date;
    title: string;
    modelPortfolio: ModelPortfolioViewModel;
    client: ClientDataModel;
    portfolio: PopulatedPortfolio | null;
    classifiedHoldings: ClassifiedBlackDiamondHolding[];
    visionAssets: ClassifiedVisionPossession[];
    assetClasses: AssetClassDataModel[];
    mostRecentlyFinalized?: IasDataModel;
}

export const useCreateIasMutator = () => {
    const commonErrorDetection = useCommonErrorDetection();
    return useMutation(["createIas"], (args: CreateIasParams) => createIas(args.date, args.title, args.modelPortfolio,
        args.client, args.portfolio, args.classifiedHoldings, args.visionAssets, args.assetClasses, args.mostRecentlyFinalized), {
        onError: commonErrorDetection
    });
};