import { useMemo } from "react";
import { DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableRow, Radio } from "rmwc";
import { BlackDiamondPortfolio } from "../../../../../dataModels/blackDiamond/portfolio";
import styles from "./BDPortfoliosTable.module.scss";
import { BlackDiamondPortfolioRow } from "./BlackDiamondPortfolioRow";
import { PortfolioIdSelection } from "./PortfolioSelection";

interface BDPortfoliosTableProps {
    clientReportingName: string;
    relationshipId: string;
    portfolios: BlackDiamondPortfolio[];
    selectedPortfolioId: PortfolioIdSelection;
    setSelectedPortfolioId: (id: string | null) => void;
    reportConnectionFailure: VoidFunction;
}

export function BDPortfoliosTable(props: BDPortfoliosTableProps) {
    const { clientReportingName, relationshipId, portfolios, selectedPortfolioId, setSelectedPortfolioId } = props;

    const sortedPortfolios = useMemo(() => {
        const portfolioSort = (a: BlackDiamondPortfolio, b: BlackDiamondPortfolio) => {
            if (a.isPrimary && !b.isPrimary) {
                return -1;
            }
            if (b.isPrimary && !a.isPrimary) {
                return 1;
            }
            return a.name.localeCompare(b.name);
        };

        return portfolios.slice().sort(portfolioSort);
    }, [portfolios]);

    return <div className={styles.portfoliosTable}>
        <DataTable>
            <DataTableContent>
                <DataTableBody>
                    {
                        sortedPortfolios.map((portfolio) => <BlackDiamondPortfolioRow
                            key={portfolio.id}
                            portfolio={portfolio}
                            selectedPortfolioId={selectedPortfolioId}
                            setSelectedPortfolioId={setSelectedPortfolioId}
                            relationshipId={relationshipId}
                            clientReportingName={clientReportingName}
                            reportConnectionFailure={props.reportConnectionFailure}
                        />)
                    }
                    <DataTableRow className={styles.noPortfolioRow}>
                        <DataTableCell className={styles.chevron} />
                        <DataTableCell className={styles.radio}>
                            <Radio
                                checked={selectedPortfolioId.selectionMade && selectedPortfolioId.value === null}
                                onClick={
                                    (e) => {
                                        e.stopPropagation();
                                    }
                                }
                                onChange={
                                    () => {
                                        setSelectedPortfolioId(null);
                                    }
                                }
                                data-testid="Choose no portfolio. radio button"
                            />
                            <span>Choose no portfolio.</span>
                        </DataTableCell>
                    </DataTableRow>
                </DataTableBody>
            </DataTableContent>
        </DataTable>
    </div>;
}