import { isSome } from "./isNullOrUndefined";
import { pick } from "./ObjectUtils";

export const sumValue = <T>(items: T[], selector: (item: T) => number) => {
    return items.map(selector).reduce((prev, curr) => prev + curr, 0);
};

export const filterMap = <TIn, TOut>(items: TIn[], selector: (item: TIn) => TOut | null | undefined): TOut[] => {
    return items.map(selector).filter(isSome);
};

export function pickMap<T, K extends keyof T>(items: T[], ...keys: K[]): Pick<T, K>[] {
    return items.map(item => pick(item, ...keys));
}
