import { AssetClassNames } from "../constants/assetClassNames";
import { AssetClassDataModel } from "../dataModels/assetClassDataModel";

const assetClassSortOrder = [
    AssetClassNames.Cash,
    AssetClassNames.FixedIncome,
    AssetClassNames.Equities,
    AssetClassNames.Alternatives,
];

export const assetClassQueryKey = () => ["assetClassList"];

export function sortAndGroupAssetClasses(assetClasses: AssetClassDataModel[]): AssetClassDataModel[] {
    assetClasses.sort((a, b) => assetClassSortOrder.findIndex(item => item === a.name) - assetClassSortOrder.findIndex(item => item === b.name));

    assetClasses.forEach(assetClass => {
        assetClass.segmentGroups.sort((a, b) => a.sortPriority - b.sortPriority);

        assetClass.segmentGroups.forEach(group => {
            group.segments.sort((a, b) => a.sortPriority - b.sortPriority);
        });

        assetClass.segments = assetClass.segments.filter(segment => !assetClass.segmentGroups.some(group => group.segments.find(s => s.id === segment.id)));
        assetClass.segments.sort((a, b) => a.sortPriority - b.sortPriority);
    });

    return assetClasses;
}