import { NavMenu } from "components/topbar/NavMenu";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Icon, IconButton } from "rmwc";
import { useUserQuery } from "services/userService";
import { IconName } from "../../iconNames";
import styles from "./ClientProfileTopbar.module.scss";
import { ClientSearchResult } from "dataModels/clientDataModel";

interface ClientProfileTopbarProps {
    shownStatusIds: number[];
    clientResults: ClientSearchResult;
}

export function ClientProfileTopbar(props: ClientProfileTopbarProps) {
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const { data: user } = useUserQuery();

    return <Fragment>
        <NavMenu
            open={sideMenuOpen}
            setOpen={setSideMenuOpen}
            initialResults={props.clientResults}
            statusIds={props.shownStatusIds}
        />
        <div
            className={styles.topbar}
            data-testid="topbar"
        >
            <div className={styles.hamburger}>
                <IconButton
                    icon={IconName.Menu}
                    onClick={() => setSideMenuOpen(!sideMenuOpen)}
                />
            </div>
            <div className={styles.vtap}>
                <Link to="/">
                    <img
                        src="/logo-blue-gray.svg"
                        alt="logo"
                        className={styles.logo}
                    />
                </Link>
                <div>IAS</div>
            </div>
            <div className={styles.userContainer}>
                <div className={styles.user}>
                    <Icon
                        icon={IconName.Person}
                        className={styles.userIcon}
                    />
                    <span className={styles.userName}>{user?.name}</span>
                </div>
            </div>
        </div>
    </Fragment>;
}