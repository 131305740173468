import classnames from "classnames";
import { DataTable, DataTableContent, DataTableBody, DataTableCell, DataTableRow } from "rmwc";
import { ModalActionButton } from "components/shared/modal/footer/ModalActionButton";
import styles from "./BlendedAssetClassesTable.module.scss";
import { ModalAlternativeButton } from "components/shared/modal/footer/ModalAlternativeButton";
import { useMemo } from "react";
import type { BlendDefinition } from "./BlendedAssetClassesPage";
import { hasContent } from "utils/StringUtils";

interface BlendedAssetClassesTableProps {
    blendDefinitions: BlendDefinition[];
    startDefiningBlend: (holdingId: number) => void;
}

interface BlendedAssetClassTableRow extends BlendDefinition {
    label: string;
    startDefining: VoidFunction;
}

export function BlendedAssetClassesTable(props: BlendedAssetClassesTableProps) {
    const { blendDefinitions, startDefiningBlend } = props;

    const rows: BlendedAssetClassTableRow[] = useMemo(() => {
        return blendDefinitions.map(blend => ({
            ...blend,
            label: `${hasContent(blend.securityTicker) ? `${blend.securityTicker} ` : ""}${blend.securityName}`,
            startDefining: () => startDefiningBlend(blend.id),
        }));
    }, [blendDefinitions, startDefiningBlend]);

    return <DataTable className={styles.table}>
        <DataTableContent>
            <DataTableBody>
                {
                    rows.map((row, index) => {
                        const hasDefinition = row.percentages.length > 0;

                        return <DataTableRow
                            key={row.label}
                            className={
                                classnames({
                                    "cursor-default": true,
                                    [styles.bgTransparent]: true,
                                })
                            }
                        >
                            <DataTableCell className={styles.column1}>
                                <div
                                    className={styles.alignVerticalCenter}
                                    data-testid={`blended-holding-name-${index + 1}`}
                                >
                                    <div className={styles.holdingName}>{row.label}</div>
                                </div>
                            </DataTableCell>
                            <DataTableCell className={styles.column2}>
                                {
                                    hasDefinition && <ModalAlternativeButton
                                        label="Edit"
                                        isDefaultAction={false}
                                        onClick={row.startDefining}
                                        data-testid={`edit-blended-holding-${index + 1}`}
                                    />
                                }
                            </DataTableCell>
                            <DataTableCell className={styles.column3}>
                                <ModalActionButton
                                    label="Define"
                                    isDefaultAction={false}
                                    onClick={row.startDefining}
                                    disabled={hasDefinition}
                                    data-testid={`define-blended-holding-${index + 1}`}
                                />
                            </DataTableCell>
                        </DataTableRow>;
                    })
                }
            </DataTableBody>
        </DataTableContent>
    </DataTable>;
}