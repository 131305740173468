/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classnames from "classnames";
import { MouseEvent, useCallback } from "react";
import styles from "./IndeterminateCheckbox.module.scss";

export enum IndeterminateTristate {
    Empty,
    Some,
    Solid
}

interface IndeterminateCheckboxProps {
    tristate: IndeterminateTristate,
    onClick: VoidFunction,
    "data-testid"?: string
}

export const IndeterminateCheckbox = (props: IndeterminateCheckboxProps) => {
    const onClick = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        props.onClick();
    }, [props]);

    const tristates = new Map<IndeterminateTristate, JSX.Element>();
    tristates.set(IndeterminateTristate.Empty, TristateEmpty());
    tristates.set(IndeterminateTristate.Some, TristateSome());
    tristates.set(IndeterminateTristate.Solid, TristateSolid());

    return <div
        className={classnames(styles.alignVerticalCenter, "clickable")}
        onClick={onClick}
        data-testid={props["data-testid"]}
    >
        {tristates.get(props.tristate)}
    </div>;
};

function TristateSome() {
    return <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-tristate="some"
        role="svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 11H27C28.1 11 29 11.9 29 13V27C29 28.1 28.1 29 27 29H13C11.9 29 11 28.1 11 27V13C11 11.9 11.9 11 13 11ZM27 13H13V27H27V13Z"
            fill="#807D79"
        />
        <rect
            x="14"
            y="19"
            width="12"
            height="2"
            fill="#1C355C"
        />
    </svg>;
}

function TristateSolid() {
    return <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-tristate="solid"
        role="svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 11C11.8954 11 11 11.8954 11 13V27C11 28.1046 11.8954 29 13 29H27C28.1046 29 29 28.1046 29 27V13C29 11.8954 28.1046 11 27 11H13ZM26 19H14V21H26V19Z"
            fill="#1C355C"
        />
    </svg>;
}

function TristateEmpty() {
    return <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-tristate="empty"
        role="svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 11H27C28.1 11 29 11.9 29 13V27C29 28.1 28.1 29 27 29H13C11.9 29 11 28.1 11 27V13C11 11.9 11.9 11 13 11ZM27 13H13V27H27V13Z"
            fill="#807D79"
        />
    </svg>;
}