import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SegmentGroupDataModel } from "dataModels/segmentGroupDataModel";
import { SegmentDataModel } from "../dataModels/segmentDataModel";

export interface AssetClassViewModel {
    readonly assetClass: AssetClassDataModel;
    readonly segmentGroups: SegmentGroupDataModel[];
    readonly segments: SegmentDataModel[];
    allSegments: () => SegmentDataModel[];
}

export function createAssetClassViewModel(assetClass: AssetClassDataModel): AssetClassViewModel {
    const segmentGroups = assetClass.segmentGroups;
    const segments = assetClass.segments;

    const allSegments = () => segmentGroups.flatMap(segmentGroup => segmentGroup.segments).concat(segments);

    return {
        assetClass,
        segmentGroups,
        segments,
        allSegments,
    };
}