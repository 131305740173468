import { BrowserAuthError, Configuration, InteractionRequiredAuthError, PublicClientApplication, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { isString } from "utils/StringUtils";
import { staticConfiguration } from "./configurationService";
import { telemetry } from "./telemetryService";

// The built in PublicClientApplication does not expose a way to retrieve the refresh token. This class wraps that to provide
// access to the underlying browser storage cache that will contain the key.
class BrowserStorageExposedPublicClientApplication extends PublicClientApplication {
    getBrowserStorage() {
        return this.browserStorage;
    }
}

const configuration: Configuration = {
    auth: {
        clientId: staticConfiguration.oauth.clientId(),
        authority: staticConfiguration.oauth.tenantAuthorityUri(),
        redirectUri: staticConfiguration.oauth.redirectUri(),
    },
};

const loginRequest: RedirectRequest = {
    scopes: [ staticConfiguration.oauth.scopes() ],
    prompt: "select_account",
};

const instance = new BrowserStorageExposedPublicClientApplication(configuration);

const handlePreviousRedirect = async () => {
    try {
        await instance.handleRedirectPromise();
    }
    catch (error) {
        console.error(error);
        telemetry.trackException(error as Error);
    }
};

export async function removeCachedCredentialsAndRedirect(): Promise<void> {
    const storage = instance.getBrowserStorage();

    await storage.clear();
    await instance.acquireTokenRedirect(loginRequest);
}

const getToken = async (): Promise<string> => {
    await handlePreviousRedirect();

    const account = instance.getAllAccounts()[0];

    const request: SilentRequest = {
        ...loginRequest,
        account: account,
    };

    try {
        const tokenResponse = await instance.acquireTokenSilent(request);
        return tokenResponse.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(loginRequest);
        } else if (error instanceof BrowserAuthError) {
            await instance.acquireTokenRedirect(loginRequest);
        } else {
            console.error(error);
            telemetry.trackException(error as Error);
        }
        return await Promise.reject();
    }
};

const getRefreshToken = async (): Promise<string> => {
    // Ensure an up-to-date token
    await getToken();

    const storage = instance.getBrowserStorage();

    const refreshKey = storage.getKeys().find(key => key.includes("refreshtoken"));
    if (isString(refreshKey)) {
        const item = storage.getItem(refreshKey);
        if (isString(item)) {
            const parsed = JSON.parse(item);
            return parsed.secret;
        }
    }

    telemetry.trackError("Could not find current refresh token");
    throw new Error("Could not find current refresh token");
};

const authorizationService = {
    loginRequest,
    instance,
    getToken,
    getRefreshToken,
};

export default authorizationService;