import { Fragment, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Icon, IconButton, Typography } from "rmwc";
import { useUserQuery } from "services/userService";
import { useKeyboardAwareNavigate } from "utils/UseKeyboardAwareNavigate";
import { IconName } from "../../iconNames";
import { useClientListQuery } from "../../services/clientService";
import { NavMenu } from "./NavMenu";
import styles from "./Topbar.module.scss";
import { telemetry } from "services/telemetryService";
import { EventType } from "constants/eventType";
import classnames from "classnames";
import { useClientStatusesQuery } from "services/clientStatusService";
import { filterMap } from "utils/ArrayUtils";
import { getStoredClientStatusFilters } from "services/clientStatusStorageService";

interface TopbarProps {
    clientKey: string;
    clientReportingName: string;
    clientId: number;
}

export function Topbar(props: TopbarProps) {
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const { data: user } = useUserQuery();

    const {data: clientStatusIds} = useClientStatusesQuery();
    const statusIdsInStorage = getStoredClientStatusFilters();
    const shownStatusIds = statusIdsInStorage ?? filterMap(clientStatusIds ?? [], status => status.defaultToShown ? status.id : null);

    const { data: clientResults } = useClientListQuery(shownStatusIds);

    const navigate = useKeyboardAwareNavigate();
    const handleClientKeyClick = (event: React.MouseEvent<HTMLElement>) => {
        telemetry.trackEvent(EventType.ClientKeyNav);
        navigate(event, `/client/${props.clientId}`);
    };

    const toggleSideMenuOpenState = useCallback(() => {
        const newState = !sideMenuOpen;
        setSideMenuOpen(newState);
        if (newState) {
            telemetry.trackEvent(EventType.SideNavOpened);
        }
    }, [sideMenuOpen, setSideMenuOpen]);

    const trackLogoNavigation = useCallback(() => {
        telemetry.trackEvent(EventType.LogoNav);
    }, []);

    const clientNameForDisplay = props.clientKey.includes("_") ?
        `${props.clientKey}\xa0\xa0-\xa0\xa0${props.clientReportingName}`
        : props.clientReportingName;

    return (<Fragment>
        {
            clientResults && <NavMenu
                open={sideMenuOpen}
                setOpen={setSideMenuOpen}
                initialResults={clientResults}
                currentClientId={props.clientId}
                currentClientKey={props.clientKey}
                statusIds={shownStatusIds}
            />
        }
        <div
            className={styles.topbar}
            data-testid="topbar"
        >
            <IconButton
                icon={IconName.Menu}
                className={styles.hamburger}
                onClick={toggleSideMenuOpenState}
            />
            <Link
                to="/"
                className={styles.logoLink}
                onClick={trackLogoNavigation}
                data-testid="topbar-logo-link"
            >
                <img
                    src="/logo-white.svg"
                    alt="logo"
                    className={styles.logo}
                />
            </Link>
            <Typography
                use="headline2"
                data-testid="client-key"
                onClick={(event: React.MouseEvent<HTMLElement>) => handleClientKeyClick(event)}
                className={classnames("clickable", styles.clientName)}
            >
                {clientNameForDisplay}
            </Typography>
            <div className={styles.userContainer}>
                <Icon
                    icon={IconName.Person}
                    className={styles.userIcon}
                />
                <span className={styles.userName}>{user?.name}</span>
            </div>
        </div>
    </Fragment>
    );
}