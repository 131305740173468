import { QueryStatus } from "@tanstack/react-query";
import { NumericTextFieldHooks } from "components/numeric-values/useNumericTextField";
import { AccountTypeDataModel } from "dataModels/accountTypeDataModel";
import { AssetClassDataModel } from "dataModels/assetClassDataModel";
import { SecurityLookupDataModel } from "dataModels/securityLookupDataModel";
import { isNumber } from "lodash";
import { Fragment, useEffect, useMemo } from "react";
import { AccountForCreation } from "services/accountService";
import { isCaseInsensitiveContentMatch, hasContent } from "utils/StringUtils";
import { AddNewAccount } from "./AddNewAccount";
import { AddPositionInfoControls } from "./AddPositionInfoControls";
import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { noAccountSelectedId } from "./AccountSelect";
import { RecommendedFundsList } from "./RecommendedFundsList";

export interface AddPositionControlsProps {
    assetTableViewModel: AssetTablesViewModel;
    assetClasses: AssetClassDataModel[];
    ticker: string;
    setTicker: FunctionStringCallback;
    nameResultForTicker: string;
    manualName: string;
    setManualName: FunctionStringCallback;
    accountId: number | null;
    setAccountId: Callback<number | null>;
    currentValue: NumericTextFieldHooks;
    segmentId: number | null;
    setSegmentId: Callback<number | null>;
    isSegmentSelectDisabled: boolean;
    securityStatus: QueryStatus;
    shouldClearControls: boolean;
    setShouldClearControls: BooleanCallback;
    recommendedFundSecurities: SecurityLookupDataModel[];
    accountTypes: AccountTypeDataModel[];
    newAccount: AccountForCreation;
    setNewAccount: Callback<AccountForCreation>;
    setIsValid: (isValid: boolean) => void;
    setErrorText: (errorText: string) => void;
}

export function AddPositionControls(props: AddPositionControlsProps) {
    const {
        assetTableViewModel,
        assetClasses,
        ticker, setTicker,
        nameResultForTicker,
        manualName, setManualName,
        accountId, setAccountId,
        currentValue,
        segmentId, setSegmentId,
        isSegmentSelectDisabled,
        securityStatus,
        shouldClearControls, setShouldClearControls,
        recommendedFundSecurities,
        accountTypes,
        newAccount, setNewAccount,
        setIsValid, setErrorText,
    } = props;
    const { ias } = assetTableViewModel;
    const name = hasContent(nameResultForTicker) ? nameResultForTicker : manualName;

    const isDuplicatePosition: boolean = useMemo(() => {
        const matchingSecurity = ias.securities.find(security => isCaseInsensitiveContentMatch(security.tickerSymbol, ticker) && isCaseInsensitiveContentMatch(security.positionName, name));
        return !!ias.accounts.find((account) => account.id === accountId)?.positions.find((position) =>
            position.securityId === matchingSecurity?.id
        );
    }, [ias.accounts, ias.securities, accountId, ticker, name]);

    const isDuplicateAccount: boolean = useMemo(() => {
        return ias.accounts.some(other => isCaseInsensitiveContentMatch(other.accountNumber, newAccount.accountNumber)
            && isCaseInsensitiveContentMatch(other.owner, newAccount.owner)
            && isCaseInsensitiveContentMatch(other.custodian, newAccount.custodian)
            && other.typeId === newAccount.typeId);
    }, [ias.accounts, newAccount.accountNumber, newAccount.typeId, newAccount.custodian, newAccount.owner]);

    const newAccountIsValid = useMemo(() => {
        return hasContent(newAccount.accountNumber)
            && hasContent(newAccount.owner)
            && hasContent(newAccount.custodian)
            && newAccount.typeId !== 0
            && !isDuplicateAccount;
    }, [newAccount.accountNumber, newAccount.typeId, newAccount.custodian, newAccount.owner, isDuplicateAccount]);

    useEffect(() => {
        if (isDuplicatePosition) {
            setIsValid(false);
            setErrorText("This entry already exists.");
        } else if (isDuplicateAccount) {
            setIsValid(false);
            setErrorText("This account info already exists.");
        } else {
            setIsValid(hasContent(name)
                && isNumber(accountId)
                && currentValue.isValid
                && isNumber(segmentId)
                && (accountId === noAccountSelectedId ? newAccountIsValid : true)
            );
            setErrorText("");
        }
    }, [name, accountId, currentValue.isValid, segmentId, isDuplicatePosition, newAccountIsValid, isDuplicateAccount, setIsValid, setErrorText]);

    return <Fragment>
        <AddPositionInfoControls
            assetTableViewModel={assetTableViewModel}
            assetClasses={assetClasses}
            ticker={ticker}
            setTicker={setTicker}
            nameResultForTicker={nameResultForTicker}
            manualName={manualName}
            setManualName={setManualName}
            accountId={accountId}
            setAccountId={setAccountId}
            currentValue={currentValue}
            segmentId={segmentId}
            setSegmentId={setSegmentId}
            isSegmentSelectDisabled={isSegmentSelectDisabled}
            securityStatus={securityStatus}
            shouldClearControls={shouldClearControls}
            setShouldClearControls={setShouldClearControls}
        />
        <RecommendedFundsList
            assetTableViewModel={assetTableViewModel}
            newPositionTicker={ticker}
            setNewPositionTicker={setTicker}
            recommendedFundSecurities={recommendedFundSecurities}
            segmentId={segmentId}
        />
        {
            accountId === noAccountSelectedId && <AddNewAccount
                accountTypes={accountTypes}
                newAccount={newAccount}
                setNewAccount={setNewAccount}
            />
        }
    </Fragment>;
}