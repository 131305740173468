import { AssetTablesViewModel } from "../asset-table/view-models/AssetTablesViewModel";
import { Fragment, PropsWithChildren, useState } from "react";
import styles from "./BarChart.module.scss";
import { Tooltip, Typography } from "rmwc";
import { AssetClassTableViewModel } from "../asset-table/view-models/AssetClassTableViewModel";
import { formatDisplay } from "utils/FormatNumber";
import { calculateUnroundedPercentage } from "utils/Percentages";
import { CurrentSelector, ProposedSelector, ValueModeSelector } from "../IasWorkspaceTypes";
import { clamp } from "lodash";
import PercentageDisplay from "../../numeric-values/PercentageDisplay";
import classnames from "classnames";
import { EventLoggingTooltipContent } from "components/shared/EventLoggingTooltipContent";
import { EventType } from "constants/eventType";

interface BarChartProperties {
    viewModel: AssetTablesViewModel
}

interface Metrics {
    fullWidth: number;
    fullHeight: number;
    rightMargin: number;
    topMargin: number;
    bottomMargin: number;
    contentWidth: number;
    contentHeight: number;
    xBandWidth: number;
}

interface BarChartCommonProperties {
    viewModel: AssetTablesViewModel;
    metrics: Metrics;
}

function YAxis({ metrics }: BarChartCommonProperties) {
    return <g className={styles.yAxis}>
        {/* Line on the left */}
        <line
            className={styles.strokeGrey}
            x1={0}
            x2={0}
            y1={metrics.topMargin}
            y2={metrics.fullHeight - metrics.bottomMargin}
        />
        {/* Line on the right */}
        <line
            className={styles.strokeGrey}
            x1={metrics.contentWidth}
            x2={metrics.contentWidth}
            y1={metrics.topMargin}
            y2={metrics.fullHeight - metrics.bottomMargin}
        />

        {/* Grid lines */}
        {
            [100, 90, 80, 70, 60, 50, 40, 30, 20, 10].map((percentage, i) => {
                const y = metrics.topMargin + i * metrics.contentHeight / 10;
                const textYOffset = 3;
                const textXOffset = 5;
                return <g key={i}>
                    <line
                        className={styles.strokeGrey}
                        x1={0}
                        x2={metrics.contentWidth}
                        y1={y}
                        y2={y}
                    />
                    <text
                        x={metrics.contentWidth + textXOffset}
                        y={y + textYOffset}
                    >{`${percentage}%`}</text>
                </g>;
            })
        }
    </g>;
}

interface XAxisLabelTooltipContentsProps {
    assetClass: AssetClassTableViewModel;
    viewModel: AssetTablesViewModel;
    includeSegmentsInTooltips: boolean;
}

function XAxisLabelTooltipContents({ assetClass, viewModel, includeSegmentsInTooltips}: XAxisLabelTooltipContentsProps) {
    return <EventLoggingTooltipContent eventType={EventType.BarChartHover}>
        <TooltipFrame
            assetClass={assetClass}
            testId="tooltip-label"
        >
            <TooltipGroup
                assetClass={assetClass}
                modeSelector={CurrentSelector}
                overall={viewModel.currentValue()}
                includeSegmentsInTooltips={includeSegmentsInTooltips}
            />
            <TooltipGroup
                className={styles.proposedGroup}
                assetClass={assetClass}
                modeSelector={ProposedSelector}
                overall={viewModel.proposedValue()}
                includeSegmentsInTooltips={includeSegmentsInTooltips}
            />
        </TooltipFrame>
    </EventLoggingTooltipContent>;
}

function XAxisLabelWithTooltip({ assetClass, viewModel, metrics, index, includeSegmentsInTooltips }: BarChartCommonProperties & { assetClass: AssetClassTableViewModel, index: number, includeSegmentsInTooltips: boolean }) {
    const labelYOffset = 13;
    const label = <text
        x={(index * 2 + 1) * metrics.xBandWidth}
        y={metrics.topMargin + metrics.contentHeight + labelYOffset}
        className={styles.axisLabel}
    >{assetClass.assetClass.name}</text>;
    return <Tooltip
        align="bottom"
        showArrow={true}
        className="sidebar-tooltip"
        content={
            <XAxisLabelTooltipContents
                assetClass={assetClass}
                viewModel={viewModel}
                includeSegmentsInTooltips={includeSegmentsInTooltips}
            />
        }
    >
        {label}
    </Tooltip>;
}

function XAxis(props: BarChartCommonProperties) {
    return <g>
        <XAxisLabelWithTooltip
            {...props}
            assetClass={props.viewModel.cash}
            index={0}
            includeSegmentsInTooltips={false}
        />
        <XAxisLabelWithTooltip
            {...props}
            assetClass={props.viewModel.fixedIncome}
            index={1}
            includeSegmentsInTooltips={true}
        />
        <XAxisLabelWithTooltip
            {...props}
            assetClass={props.viewModel.equities}
            index={2}
            includeSegmentsInTooltips={true}
        />
        <XAxisLabelWithTooltip
            {...props}
            assetClass={props.viewModel.alternatives}
            index={3}
            includeSegmentsInTooltips={true}
        />
    </g>;
}

interface DataBarTooltipProps {
    modeSelector: ValueModeSelector;
    assetClass: AssetClassTableViewModel;
    includeSegmentsInTooltips: boolean;
    overall: number;
}

function DataBarTooltipContents({ assetClass, modeSelector, includeSegmentsInTooltips, overall }: DataBarTooltipProps) {
    return <EventLoggingTooltipContent eventType={EventType.BarChartHover}>
        <TooltipFrame
            assetClass={assetClass}
            testId={`tooltip-${modeSelector.name}`}
        >
            <TooltipGroup
                assetClass={assetClass}
                modeSelector={modeSelector}
                overall={overall}
                includeSegmentsInTooltips={includeSegmentsInTooltips}
            />
        </TooltipFrame>
    </EventLoggingTooltipContent>;
}

interface DataBarProperties extends BarChartCommonProperties {
    index: number;
    modeSelector: ValueModeSelector;
    assetClass: AssetClassTableViewModel;
    includeSegmentsInTooltips: boolean;
}

function DataBar({ metrics, assetClass, index, modeSelector, viewModel, includeSegmentsInTooltips }: DataBarProperties) {
    const [showTooltip, setShowToolTip] = useState(false);
    const overall = clamp(modeSelector.getValue(viewModel), 0, Infinity);
    if (overall === 0) {
        return <Fragment />;
    }

    const testId = assetClass.assetClass.name.toLowerCase().replaceAll(" ", "_") + "_" + modeSelector.name;
    const className = styles[assetClass.assetClass.name.toLowerCase().replaceAll(" ", "_") + (modeSelector.name === "Current" ? "_80" : "_130")];

    const money = clamp(modeSelector.getValue(assetClass), 0, Infinity);
    const percentage = money / overall;
    const height = (percentage > 1) ? metrics.contentHeight : metrics.contentHeight * percentage;

    return <g>
        <rect
            key={className}
            data-testid={testId}
            className={className}
            strokeWidth={0}
            x={index * metrics.xBandWidth}
            y={metrics.fullHeight - height - metrics.bottomMargin}
            height={height}
            width={metrics.xBandWidth}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
        />
        {
            showTooltip &&
            <Tooltip
                className="sidebar-tooltip"
                content={
                    <DataBarTooltipContents
                        assetClass={assetClass}
                        includeSegmentsInTooltips={includeSegmentsInTooltips}
                        modeSelector={modeSelector}
                        overall={overall}
                    />
                }
                open={true}
                align="bottom"
                showArrow={true}
            >
                <rect
                    height={1}
                    width={1}
                    x={index * metrics.xBandWidth + metrics.xBandWidth / 2}
                    y={metrics.fullHeight - metrics.bottomMargin}
                    opacity={0}
                />
            </Tooltip>
        }
    </g>;
}

function TooltipGroup({ modeSelector, assetClass, overall, className, includeSegmentsInTooltips }: { assetClass: AssetClassTableViewModel, modeSelector: ValueModeSelector, overall: number, className?: string, includeSegmentsInTooltips: boolean }) {
    const money = modeSelector.getValue(assetClass);
    const percentage = calculateUnroundedPercentage(money, overall);

    const groupsForTooltip = includeSegmentsInTooltips ? assetClass.segmentGroups.filter(group => group.includeInDisplay()) : [];
    const segmentsForTooltip = includeSegmentsInTooltips ? assetClass.segments.filter(segment => segment.includeInDisplay()) : [];

    return <Fragment>
        <div
            className={`${styles.tooltipRow} ${className}`}
            data-testid={modeSelector.name}
        >
            <div className={styles.col1}><Typography use="headline5">{modeSelector.name}</Typography></div>
            <div className={styles.col2}><Typography use="headline5"><PercentageDisplay value={percentage} /></Typography></div>
            <div className={styles.col3}><Typography use="headline5">{formatDisplay(money)}</Typography></div>
        </div>
        {
            groupsForTooltip.map(group => {
                const groupValue = modeSelector.getValue(group);
                return <div
                    key={group.group.name}
                    className={styles.tooltipRow}
                    data-testid="tooltip-segment-row"
                >
                    <div className={`${styles.col1} ${styles.rowPad}`}><Typography use="headline6">{abbreviate(group.group.name)}</Typography></div>
                    <div className={`${styles.col2} ${styles.rowPad}`}><Typography use="headline6"><PercentageDisplay value={calculateUnroundedPercentage(groupValue, overall)} /></Typography></div>
                    <div className={`${styles.col3} ${styles.rowPad}`}><Typography use="headline6">{formatDisplay(groupValue)}</Typography></div>
                </div>;
            })
        }
        {
            segmentsForTooltip.map(segment => {
                const groupValue = modeSelector.getValue(segment);
                const segmentName = segment.segment.name;
                return <div
                    key={segmentName}
                    className={styles.tooltipRow}
                    data-testid="tooltip-segment-row"
                >
                    <div className={`${styles.col1} ${styles.rowPad}`}><Typography use="headline6">{abbreviate(segmentName)}</Typography></div>
                    <div className={`${styles.col2} ${styles.rowPad}`}><Typography use="headline6"><PercentageDisplay value={calculateUnroundedPercentage(groupValue, overall)} /></Typography></div>
                    <div className={`${styles.col3} ${styles.rowPad}`}><Typography use="headline6">{formatDisplay(groupValue)}</Typography></div>
                </div>;
            })
        }
    </Fragment>;
}

function TooltipFrame({ assetClass, testId, children }: PropsWithChildren & { assetClass: AssetClassTableViewModel, testId: string }) {
    return <div data-testid={`${testId}-${assetClass.assetClass.name.toLocaleLowerCase().replace(" ", "_")}`}>
        <div>
            <Typography use="headline5">{assetClass.assetClass.name}</Typography>
        </div>
        {children}
    </div>;
}

function DataBars(props: BarChartCommonProperties) {
    return <g>
        <DataBar
            {...props}
            assetClass={props.viewModel.cash}
            index={0}
            modeSelector={CurrentSelector}
            includeSegmentsInTooltips={false}
        />
        <DataBar
            {...props}
            assetClass={props.viewModel.cash}
            index={1}
            modeSelector={ProposedSelector}
            includeSegmentsInTooltips={false}
        />
        <DataBar
            {...props}
            assetClass={props.viewModel.fixedIncome}
            index={2}
            modeSelector={CurrentSelector}
            includeSegmentsInTooltips={true}
        />
        <DataBar
            {...props}
            assetClass={props.viewModel.fixedIncome}
            index={3}
            modeSelector={ProposedSelector}
            includeSegmentsInTooltips={true}
        />
        <DataBar
            {...props}
            assetClass={props.viewModel.equities}
            index={4}
            modeSelector={CurrentSelector}
            includeSegmentsInTooltips={true}
        />
        <DataBar
            {...props}
            assetClass={props.viewModel.equities}
            index={5}
            modeSelector={ProposedSelector}
            includeSegmentsInTooltips={true}
        />
        <DataBar
            {...props}
            assetClass={props.viewModel.alternatives}
            index={6}
            modeSelector={CurrentSelector}
            includeSegmentsInTooltips={true}
        />
        <DataBar
            {...props}
            assetClass={props.viewModel.alternatives}
            index={7}
            modeSelector={ProposedSelector}
            includeSegmentsInTooltips={true}
        />
    </g>;
}

interface TargetLineTooltipContentsProps {
    viewModel: AssetTablesViewModel;
    assetClass: AssetClassTableViewModel;
    assetClassPercentage: number;
    includeSegmentsInTooltips: boolean;
    targetValueForPercentage: (value: number) => number;
}

function TargetLineTooltipContents(props: TargetLineTooltipContentsProps) {
    const { viewModel, assetClass, includeSegmentsInTooltips, assetClassPercentage, targetValueForPercentage } = props;

    const assetClassMoney = targetValueForPercentage(assetClassPercentage);

    const groupsForTooltip = includeSegmentsInTooltips ? assetClass.segmentGroups.filter(group => group.includeInDisplay()) : [];
    const segmentsForTooltip =includeSegmentsInTooltips ? assetClass.segments.filter(segment => segment.includeInDisplay()) : [];

    return <EventLoggingTooltipContent eventType={EventType.BarChartHover}>
        <TooltipFrame
            assetClass={assetClass}
            testId="tooltip-target"
        >
            <div className={styles.tooltipRow}>
                <div className={styles.col1}><Typography use="headline5">Target</Typography></div>
                <div className={styles.col2}><Typography use="headline5"><PercentageDisplay value={assetClassPercentage} /></Typography></div>
                <div className={styles.col3}><Typography use="headline5">{formatDisplay(assetClassMoney)}</Typography></div>
            </div>
            {
                groupsForTooltip.map(({ group }) => {
                    const groupPercentage = Math.round(viewModel.modelPortfolio.getTargetForSegmentGroup(group.id));
                    const groupMoney = targetValueForPercentage(groupPercentage);
                    return <div
                        key={group.name}
                        className={styles.tooltipRow}
                        data-testid="tooltip-segment-row"
                    >
                        <div className={`${styles.col1} ${styles.rowPad}`}><Typography use="headline6">{abbreviate(group.name)}</Typography></div>
                        <div className={`${styles.col2} ${styles.rowPad}`}><Typography use="headline6"><PercentageDisplay value={groupPercentage} /></Typography></div>
                        <div className={`${styles.col3} ${styles.rowPad}`}><Typography use="headline6">{formatDisplay(groupMoney)}</Typography></div>
                    </div>;
                })
            }
            {
                segmentsForTooltip.map(({ segment }) => {
                    const segmentPercentage = Math.round(viewModel.modelPortfolio.getTargetForSegment(segment.id));
                    const segmentMoney = targetValueForPercentage(segmentPercentage);
                    return <div
                        key={segment.name}
                        className={styles.tooltipRow}
                        data-testid="tooltip-segment-row"
                    >
                        <div className={`${styles.col1} ${styles.rowPad}`}><Typography use="headline6">{abbreviate(segment.name)}</Typography></div>
                        <div className={`${styles.col2} ${styles.rowPad}`}><Typography use="headline6"><PercentageDisplay value={segmentPercentage} /></Typography></div>
                        <div className={`${styles.col3} ${styles.rowPad}`}><Typography use="headline6">{formatDisplay(segmentMoney)}</Typography></div>
                    </div>;
                })
            }
        </TooltipFrame>
    </EventLoggingTooltipContent>;
}

function TargetLine(props: BarChartCommonProperties & { assetClass: AssetClassTableViewModel, index: number, includeSegmentsInTooltips: boolean }) {
    const [showTooltip, setShowToolTip] = useState(false);

    const { viewModel, assetClass, metrics, index } = props;

    const targetValueForPercentage = (targetPercentage: number) => Math.round(viewModel.currentValue() * (targetPercentage / 100));

    const assetClassPercentage = viewModel.modelPortfolio.getTargetForAssetClass(assetClass.assetClass.id);

    const height = metrics.contentHeight * assetClassPercentage / 100.0;

    const hitbox = <rect
        fillOpacity={0}
        x={index * metrics.xBandWidth}
        y={metrics.fullHeight - height - metrics.bottomMargin - 3}
        width={metrics.xBandWidth * 2}
        height={8}
        data-testid={`target-line-${assetClass.assetClass.name.replaceAll(" ", "").toLowerCase()}`}
        onMouseEnter={() => setShowToolTip(true)}
        onMouseLeave={() => setShowToolTip(false)}
    />;

    return <g>
        <line
            strokeWidth={2}
            stroke="black"
            fill="white"
            strokeDasharray="4,4"
            strokeDashoffset={2}
            x1={index * metrics.xBandWidth}
            x2={(index + 2) * metrics.xBandWidth}
            y1={metrics.fullHeight - height - metrics.bottomMargin}
            y2={metrics.fullHeight - height - metrics.bottomMargin}

        />
        {hitbox}
        {
            showTooltip && <Tooltip
                align="bottom"
                open={true}
                showArrow={true}
                content={
                    <TargetLineTooltipContents
                        assetClass={assetClass}
                        viewModel={viewModel}
                        includeSegmentsInTooltips={props.includeSegmentsInTooltips}
                        assetClassPercentage={assetClassPercentage}
                        targetValueForPercentage={targetValueForPercentage}
                    />
                }
                className={classnames(styles.targetTooltip, "sidebar-tooltip")}
            >
                <rect
                    height={1}
                    width={1}
                    x={(index + 1) * metrics.xBandWidth}
                    y={metrics.fullHeight - metrics.bottomMargin}
                    opacity={0}
                />
            </Tooltip>
        }

    </g>;
}

function TargetDescender({ index, metrics, viewModel, previous, next }: BarChartCommonProperties & { previous: AssetClassTableViewModel | null, next: AssetClassTableViewModel | null, index: number }) {
    const y1 = next ? metrics.contentHeight * viewModel.modelPortfolio.getTargetForAssetClass(next.assetClass.id) / 100.0 : 0;
    const y2 = previous ? metrics.contentHeight * viewModel.modelPortfolio.getTargetForAssetClass(previous.assetClass.id) / 100.0 : 0;
    const height = Math.max(y1, y2);
    return <line
        strokeWidth={2}
        stroke="black"
        fill="white"
        strokeDasharray="4,4"
        strokeDashoffset={2}
        x1={index * metrics.xBandWidth}
        x2={index * metrics.xBandWidth}
        y1={metrics.fullHeight - metrics.bottomMargin}
        y2={metrics.fullHeight - height - metrics.bottomMargin}
    />;
}

function TargetLines(props: BarChartCommonProperties) {
    return <g>
        <TargetDescender
            {...props}
            index={0}
            previous={null}
            next={props.viewModel.cash}
        />
        <TargetDescender
            {...props}
            index={2}
            previous={props.viewModel.cash}
            next={props.viewModel.fixedIncome}
        />
        <TargetDescender
            {...props}
            index={4}
            previous={props.viewModel.fixedIncome}
            next={props.viewModel.equities}
        />
        <TargetDescender
            {...props}
            index={6}
            previous={props.viewModel.equities}
            next={props.viewModel.alternatives}
        />
        <TargetDescender
            {...props}
            index={8}
            previous={props.viewModel.alternatives}
            next={null}
        />

        <TargetLine
            {...props}
            assetClass={props.viewModel.cash}
            index={0}
            includeSegmentsInTooltips={false}
        />
        <TargetLine
            {...props}
            assetClass={props.viewModel.fixedIncome}
            index={2}
            includeSegmentsInTooltips={true}
        />
        <TargetLine
            {...props}
            assetClass={props.viewModel.equities}
            index={4}
            includeSegmentsInTooltips={true}
        />
        <TargetLine
            {...props}
            assetClass={props.viewModel.alternatives}
            index={6}
            includeSegmentsInTooltips={true}
        />
    </g>;
}

function Key() {
    const width = 32;
    const height = 8;
    const margin = 12;

    return <g>
        {
            [
                ["80", "Current"],
                ["130", "Proposed"]
            ].map((item, itemIndex) => {
                const x_offset = ((width * 4) + margin) * itemIndex;
                return <g key={item[0]}>
                    {
                        ["cash", "fixed_income", "equities", "alternatives"].map((assetClass, assetClassIndex) => <rect
                            key={`${assetClass}_${item[0]}`}
                            className={styles[`${assetClass}_${item[0]}`]}
                            strokeWidth={0}
                            x={x_offset + assetClassIndex * width}
                            y={1}
                            height={height}
                            width={width}
                        />)
                    }
                    <text
                        className={`${styles.keyLabel} ${styles.axisLabel}`}
                        x={x_offset + width * 2}
                        y={22}
                    >{item[1]}</text>
                </g>;
            })
        }
        <rect
            strokeWidth={2}
            stroke="black"
            fill="white"
            strokeDasharray="4,4"
            strokeDashoffset={2}
            x={((width * 4) + margin) * 2}
            y={1}
            height={height}
            width={width * 4}
        />
        <text
            className={`${styles.keyLabel} ${styles.axisLabel}`}
            x={((width * 4) + margin) * 2 + width * 2}
            y={22}
        >Target</text>
    </g>;
}

export default function BarChart(props: BarChartProperties) {
    const metrics = createMetrics(412, 262, 28, 39, 23);
    const commonProps = { ...props, metrics };

    return <svg
        className={styles.barChart}
        width={metrics.fullWidth}
        height={metrics.fullHeight}
        data-testid="asset-class-bar-chart"
    >
        <Key />
        <YAxis {...commonProps} />
        <XAxis {...commonProps} />
        <DataBars {...commonProps} />
        <TargetLines {...commonProps} />
    </svg>;
}

function createMetrics(fullWidth: number, fullHeight: number, rightMargin: number, topMargin: number, bottomMargin: number): Metrics {
    return {
        fullWidth,
        fullHeight,
        rightMargin,
        topMargin,
        bottomMargin,
        contentWidth: fullWidth - rightMargin,
        contentHeight: fullHeight - topMargin - bottomMargin,
        xBandWidth: (fullWidth - rightMargin) / 8
    };
}

function abbreviate(name: string): string {
    return name.toUpperCase().replace("/", " / ").split(" ").map(word => word[0]).join("");
}
