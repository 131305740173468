import { AssetClassDataModel } from "../../../../dataModels/assetClassDataModel";
import { IasDataModel } from "../../../../dataModels/iasDataModel";
import { sumValue } from "utils/ArrayUtils";
import { createValueModel, ValueModel } from "../../IasWorkspaceTypes";
import { AccountViewModel, createAccount } from "./AccountViewModel";
import { createModelPortfolioViewModel, ModelPortfolioViewModel } from "./ModelPortfolioViewModel";
import { SegmentViewModel } from "./SegmentViewModel";
import { AssetClassTableViewModel, createAssetClassTable } from "./AssetClassTableViewModel";
import { createBlendedSecurity, createSecurity, SecurityViewModel } from "./SecurityViewModel";
import { ModelPortfolioDataModel } from "dataModels/modelPortfolioDataModel";
import { createAssetClassesViewModel } from "viewModels/AssetClassesViewModel";
import { groupSecurities } from "./groupSecurities";
import { isCustomGroupViewModel } from "./SecurityDisplayViewModel";
import { AssetClassNames } from "constants/assetClassNames";
import { SegmentDataModel } from "dataModels/segmentDataModel";
import { totalValue } from "utils/AccountUtils";

export interface AssetTablesViewModel extends ValueModel {
    readonly ias: IasDataModel;
    readonly cash: AssetClassTableViewModel;
    readonly fixedIncome: AssetClassTableViewModel;
    readonly equities: AssetClassTableViewModel;
    readonly alternatives: AssetClassTableViewModel;
    readonly accounts: AccountViewModel[];
    readonly modelPortfolio: ModelPortfolioViewModel;
    allSegments: () => SegmentViewModel[];
}

export const createAssetTables = (assetClasses: AssetClassDataModel[], modelPortfolios: ModelPortfolioDataModel[], ias: IasDataModel): AssetTablesViewModel => {
    const assetClassesViewModel = createAssetClassesViewModel(assetClasses);
    const totalIasValue = totalValue(ias.accounts);
    const modelPortfolio = createModelPortfolioViewModel(ias, modelPortfolios, assetClasses, totalIasValue);

    const cashSegment: SegmentDataModel | undefined = assetClasses
        .find(ac => ac.name === AssetClassNames.Cash)
        ?.segments.find(s => s.name === AssetClassNames.Cash);
    const cash = createAssetClassTable(assetClassesViewModel.cash.assetClass, modelPortfolio, cashSegment);
    const fixedIncome = createAssetClassTable(assetClassesViewModel.fixedIncome.assetClass, modelPortfolio);
    const equities = createAssetClassTable(assetClassesViewModel.equities.assetClass, modelPortfolio);
    const alternatives = createAssetClassTable(assetClassesViewModel.alternatives.assetClass, modelPortfolio);

    const assetClassTables = [cash, fixedIncome, equities, alternatives];
    const accounts = ias.accounts.map(account => createAccount(account, assetClassTables));

    const flattenedSegments = assetClassTables
        .flatMap(assetClass => assetClass.segmentGroups)
        .flatMap(group => group.segments)
        .concat(assetClassTables.flatMap(assetClass => assetClass.segments));

    const segmentMap = new Map<number, SegmentViewModel>();
    flattenedSegments.forEach(segment => segmentMap.set(segment.segment.id, segment));

    const referencedSecurities = ias.accounts.flatMap(account => account.positions.map(position => position.securityId));

    const securityMap = new Map<number, SecurityViewModel[]>();
    ias.securities.filter(security => referencedSecurities.includes(security.id)).forEach(security => {
        const securityViewModels: SecurityViewModel[] = [];

        if (security.blendedFundPercentages.length > 0) {
            security.blendedFundPercentages.forEach(blend => {
                const segment = segmentMap.get(blend.segmentId);
                if (segment) {
                    const securityViewModel = createBlendedSecurity(security, blend.percentage, blend.segmentId);
                    securityViewModels.push(securityViewModel);
                }
            });

        } else {
            const segment = segmentMap.get(security.segmentId);
            if (segment) {
                const securityViewModel = createSecurity(security, segment.segment.id);
                securityViewModels.push(securityViewModel);
            }
        }

        securityMap.set(security.id, securityViewModels);
    });

    const allSecurities = Array.from(securityMap).flatMap(([_securityId, security]) => security);
    const securityDisplays = groupSecurities(allSecurities, ias.customGroups);
    securityDisplays.forEach(security => {
        const segment = segmentMap.get(security.segmentId);
        if (segment) {
            if (isCustomGroupViewModel(security)) {
                segment.customGroups.push(security);
            } else {
                segment.securities.push(security as SecurityViewModel);
            }
        }
    });

    accounts.forEach(account => {
        account.account.positions.forEach(position => {
            const securityViewModels = securityMap.get(position.securityId);
            if (securityViewModels) {
                securityViewModels.forEach(securityViewModel => {
                    securityViewModel.addAccountPosition(account, position);
                });
            }
        });
    });

    const currentValue = () => sumValue(flattenedSegments, segment => segment.currentValue());
    const changeValue = () => sumValue(flattenedSegments, segment => segment.changeValue());

    return {
        ...createValueModel(currentValue, changeValue),
        ias,
        cash,
        fixedIncome,
        equities,
        alternatives,
        accounts,
        modelPortfolio,
        allSegments: () => [cash, fixedIncome, equities, alternatives].flatMap((assetClass) => assetClass.allSegments()),
    };
};