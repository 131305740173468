import { Fragment, PropsWithChildren } from "react";
import { ClientProfileTopbar } from "../client-list/ClientProfileTopbar";
import styles from "./ClientListLayout.module.scss";
import { ClientSearchResult } from "dataModels/clientDataModel";

interface ClientListLayoutProps extends PropsWithChildren {
    shownStatusIds: number[];
    clientList: ClientSearchResult;
}

export default function ClientListLayout(props: ClientListLayoutProps) {
    return <Fragment>
        <div className={styles.background}>
            <ClientProfileTopbar
                shownStatusIds={props.shownStatusIds}
                clientResults={props.clientList}
            />
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
        </div>
    </Fragment>;
}