import { useCallback, useState } from "react";
import { AssetClassTableViewModel } from "../components/ias-workspace/asset-table/view-models/AssetClassTableViewModel";
import { AssetTablesViewModel } from "../components/ias-workspace/asset-table/view-models/AssetTablesViewModel";
import { SegmentGroupViewModel } from "../components/ias-workspace/asset-table/view-models/SegmentGroupViewModel";
import { SegmentViewModel } from "../components/ias-workspace/asset-table/view-models/SegmentViewModel";
import { isNullOrUndefined } from "utils/isNullOrUndefined";
import { isCustomGroupViewModel } from "components/ias-workspace/asset-table/view-models/SecurityDisplayViewModel";

export enum ExpansionType {
    AssetClass,
    CustomGroup,
    SegmentGroup,
    Segment,
    Security,
    VisionRow
}

interface CachedExpansionState {
    value: boolean;
    setValue: (value: boolean) => void;
}

const cache = new Map<ExpansionType, Map<string | number, CachedExpansionState>>();

const getGroupCache = (type: ExpansionType): Map<string | number, CachedExpansionState> => {
    const groupCache = cache.get(type);
    if (!groupCache) {
        const groupCache = new Map();
        cache.set(type, groupCache);
        return groupCache;
    }
    return groupCache;
};

export const useExpansionState = (type: ExpansionType, key: string | number, defaultValue = false, childDefaults: [ExpansionType, string | number, boolean][] = []): [boolean, (arg0: boolean) => void] => {
    const [state, setState] = useState(getExpansionState(type, key, defaultValue));

    // initialize this group's cache with setter that can be called from outside itself
    getGroupCache(type).set(key, {
        value: state,
        setValue: setState,
    });

    const internalSetState = useCallback((newState: boolean) => {
        setCachedExpansionState(type, key, newState);
        setState(newState);
    }, [key, type]);

    // create cached states containing default values for children to use when they call this hook
    childDefaults.forEach(([childType, childKey, childDefaultValue]) => {
        const childCache = getGroupCache(childType);
        if (!childCache.get(childKey)) {
            childCache.set(childKey, {
                value: childDefaultValue,
                setValue: () => {},
            });
        }
    });

    return [state, internalSetState];
};

export const getExpansionState = (type: ExpansionType, key: string | number, defaultValue = false): boolean => {
    return getGroupCache(type).get(key)?.value ?? defaultValue;
};

const setCachedExpansionState = (type: ExpansionType, key: string | number, state: boolean) => {
    const cachedState = getGroupCache(type).get(key);
    if (cachedState) {
        cachedState.value = state;
    }
};

const setCachedExpansionStateWithCallbacks = (type: ExpansionType, key: string | number, state: boolean) => {
    const cachedExpansionState = getGroupCache(type).get(key);
    if(!isNullOrUndefined(cachedExpansionState)) {
        cachedExpansionState.value = state;
        cachedExpansionState.setValue(state);
    }
};

export const clearExpansionStateCache = () => {
    cache.forEach(typedCache => {
        typedCache.clear();
    });
};

export const clearExpansionStateTypes = (...types: ExpansionType[]) => {
    for (const type of types) {
        cache.get(type)?.clear();
    }
};

const setSegmentExpansionState = (segment: SegmentViewModel, state: boolean) => {
    segment.allSecurityDisplays().forEach(child => {
        if (isCustomGroupViewModel(child)) {
            child.securities.forEach(security => {
                setCachedExpansionStateWithCallbacks(ExpansionType.Security, security.key, state);
            });
            setCachedExpansionStateWithCallbacks(ExpansionType.CustomGroup, child.key, state);
        } else {
            setCachedExpansionStateWithCallbacks(ExpansionType.Security, child.key, state);
        }
    });
    setCachedExpansionStateWithCallbacks(ExpansionType.Segment, segment.segment.id, state);
};

const setSegmentGroupExpansionState = (group: SegmentGroupViewModel, state: boolean) => {
    group.segments.forEach(segment => setSegmentExpansionState(segment, state));
    setCachedExpansionStateWithCallbacks(ExpansionType.SegmentGroup, group.group.id, state);
};

const setAssetClassExpansionState = (assetClass: AssetClassTableViewModel, state: boolean) => {
    assetClass.segmentGroups.forEach(group => setSegmentGroupExpansionState(group, state));
    assetClass.segments.forEach(segment => setSegmentExpansionState(segment, state));
    setCachedExpansionStateWithCallbacks(ExpansionType.AssetClass, assetClass.assetClass.id, state);
};

const setAllExpansionState = (viewModel: AssetTablesViewModel, state: boolean) => {
    setAssetClassExpansionState(viewModel.cash, state);
    setAssetClassExpansionState(viewModel.fixedIncome, state);
    setAssetClassExpansionState(viewModel.equities, state);
    setAssetClassExpansionState(viewModel.alternatives, state);
};

export const setAllToExpanded = (viewModel: AssetTablesViewModel) => {
    setAllExpansionState(viewModel, true);
};

export const setAllToCollapsed = (viewModel: AssetTablesViewModel) => {
    setAllExpansionState(viewModel, false);
};