import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { isSome } from "utils/isNullOrUndefined";
import { getWithAuth } from "./apiService";

function get() {
    return getWithAuth<string[]>("/featureToggles");
}

export function useFeatureToggle(toggle: string) {
    const { data: featureToggles } = useQuery(["featureToggles"], () => get());

    const enabled = useMemo(() => isSome(featureToggles) && featureToggles.some(featureToggle => featureToggle.localeCompare(toggle, undefined, { sensitivity: "accent" }) === 0), [featureToggles, toggle]);

    return enabled;
}